import { useEffect, useState, JSX } from 'react'
import RadarChart from './RadarChart'
import QuadrantChart from './QuadrantChart'
import './styles.scss'
import { Color } from 'core'

// ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend)

interface ResultChartProps {
  apiData: any
}

const ResultChart = ({ apiData }: ResultChartProps) => {
  const [chartData, setChartData] = useState<JSX.Element>(<></>)
  const [isDataLoaded, setIsDataLoaded] = useState(false)

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadData = () => {
    if (apiData.type === 'radar') {
      setChartData(<RadarChart apiData={apiData} />)
    } else {
      setChartData(<QuadrantChart apiData={apiData} />)
    }
    setIsDataLoaded(true)
  }

  return (
    <div className="result-chart">
      {apiData.type === 'quadrant_plot' && isDataLoaded && (
        <div className="quadrantLegend">
          {[...apiData.datasets].reverse().map((dataset, index) => (
            <div
              key={index}
              className={`legendItem ${
                dataset.kind === 'CANDIDATE' ? 'legendItem--candidate' : 'legendItem--organization'
              }`}>
              {dataset.kind === 'ORGANIZATION' ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  width="17px"
                  fill={Color.OrgBlue}>
                  {/* <!--!Font Awesome Pro 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--> */}
                  <path d="M0 480L256 32 512 480H0z" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  width="20px"
                  fill={Color.Magenta}>
                  {/* <!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--> */}
                  <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z" />
                </svg>
              )}
              <span>{dataset.label}</span>
            </div>
          ))}
        </div>
      )}
      <div>{isDataLoaded && chartData}</div>
    </div>
  )
}

export default ResultChart
