import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { handleError, InputField, SelectDropdown, EMAIL_REGEX, CreatableMultiInput } from 'core'
import { Assessment, AudienceType, InvitationKind, useUserContext } from 'provider'
import { createInvitation } from '../../actions/invitations'
import { DropdownOption } from '../../models'
import { mapToOption, onSubmitForm } from '../../utils'
import Modal from '../Modal'
import './styles.scss'
import { Button } from '@chakra-ui/react'

type InvitationForm = {
  firstName: string
  lastName: string
  email: string
  tags?: DropdownOption[]
}

type UserInvitationCreateModalProps = {
  showModal: boolean
  onClose: () => void
  onSave?: () => void
  tenantTags: DropdownOption[]
  isLoadingTags: boolean
}

const UserInvitationCreateModal = ({
  showModal,
  onClose,
  onSave = onClose,
  tenantTags,
  isLoadingTags,
}: UserInvitationCreateModalProps) => {
  const { userContext } = useUserContext()
  const [assessments, setAssessments] = useState<Assessment[]>()
  const [selectedAssessment, setSelectedAssessment] = useState<Assessment>()
  const [assessmentOptions, setAssessmentOptions] = useState<DropdownOption[]>([])
  const [isPosting, setIsPosting] = useState(false)
  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<InvitationForm>()

  const getAssesmentById = useCallback(
    (id: string) => {
      return assessments?.find((assessment) => assessment.id === id)
    },
    [assessments],
  )

  useEffect(() => {
    if (userContext?.tenant && userContext.tenant.assessments) {
      setAssessments(userContext.tenant.assessments)
      setAssessmentOptions(
        userContext.tenant.assessments.map((assessment) => mapToOption(assessment, 'title', 'id')),
      )
    }
  }, [userContext])

  const defaultAssessment = useMemo(() => {
    if (assessmentOptions.length === 1) {
      setSelectedAssessment(getAssesmentById(assessmentOptions[0].value))
      return assessmentOptions[0]
    } else {
      return undefined
    }
  }, [assessmentOptions, getAssesmentById])

  const submitInvitation = async (formData: InvitationForm) => {
    setIsPosting(true)
    const { firstName, lastName, email, tags } = formData
    const newTags = tags ? tags.map((tag) => ({ title: tag.value })) : []

    if (!selectedAssessment) {
      // If no assessment is selected, show an error.
      handleError(undefined, {
        errorMessage: 'Could not create invitation. No assessment was selected.',
      })(new Error('Could not create user invitation.'))
      setIsPosting(false)
      return
    }

    // If required fields are not filled, show an error.
    if (!(firstName && lastName && email)) {
      handleError(undefined, {
        errorMessage: 'Please fill out all required fields.',
      })(new Error('Could not create user invitation.'))
      setIsPosting(false)
      return
    }

    try {
      const payload = {
        assessment: { id: selectedAssessment.id },
        user: {
          firstName,
          lastName,
          email,
        },
        kind: InvitationKind.STANDARD,
        audience: AudienceType.Candidate,
        createTags: newTags,
      }
      await createInvitation(payload).request

      reset()
      onSave()
    } catch (err) {
      handleError(undefined, {
        errorMessage: 'Could not create invitation. An error occurred.',
      })(err)
    }

    setIsPosting(false)
  }

  const setAssessment = (assessment: DropdownOption | undefined) => {
    if (assessment) {
      setSelectedAssessment(getAssesmentById(assessment.value))
    }
  }

  return (
    <Modal showModal={showModal} onClose={onClose} dataCy="orgInviteCreateModal">
      <form onSubmit={onSubmitForm(handleSubmit(submitInvitation))} className="userInvitationModal">
        <div className="header">Standard Assessment Invitation</div>
        <InputField
          placeholder="First Name"
          registerFields={register('firstName', { required: 'Required field' })}
          getValue={() => watch('firstName')}
          errorMessage={errors.firstName?.message}
          isColumn={true}
          hasDefaultMargin={false}
        />
        <InputField
          placeholder="Last Name"
          registerFields={register('lastName', { required: 'Required field' })}
          getValue={() => watch('lastName')}
          errorMessage={errors.lastName?.message}
          isColumn={true}
          hasDefaultMargin={false}
        />
        <InputField
          placeholder="Email Address"
          registerFields={register('email', {
            required: 'Required field',
            pattern: {
              value: EMAIL_REGEX,
              message: 'Invalid email address',
            },
          })}
          getValue={() => watch('email')}
          errorMessage={errors.email?.message}
          isColumn={true}
          hasDefaultMargin={false}
        />

        <SelectDropdown
          defaultValue={defaultAssessment}
          placeholder="Choose Assessment"
          field={{
            onChange: setAssessment,
            onBlur: () => {},
            value: undefined,
            name: 'assessment',
            ref: () => {},
          }}
          fieldState={{
            invalid: false,
            isTouched: false,
            isDirty: false,
          }}
          options={assessmentOptions}
        />

        <Controller
          name="tags"
          render={({ field, fieldState }) => (
            <CreatableMultiInput
              field={field}
              fieldState={fieldState}
              options={tenantTags}
              placeholder="Tags"
              hasDefaultWidthRange={false}
              isLoading={isLoadingTags}
            />
          )}
          control={control}
        />

        <Button
          type="submit"
          variant="brandPrimary"
          className="userInvitationModal__saveBtn"
          isLoading={isPosting}
          isDisabled={isPosting}
          data-cy="saveInvite">
          Send
        </Button>
      </form>
    </Modal>
  )
}

export default UserInvitationCreateModal
