import { api, GetGroupProfile, GroupProfileParams, GetSectionProfile } from 'provider'

export const getGroupProfileDetail = (groupProfileId: string) =>
  api.getGroupProfileDetail(groupProfileId)
export const getGroupProfileCategory = (groupProfileId: string, categoryId: string) =>
  api.getGroupProfileCategory(groupProfileId, categoryId)
export const getGroupProfiles = (payload: GetGroupProfile) => api.getGroupProfiles(payload)
export const getSectionProfiles = (payload: GetSectionProfile) => api.getSectionProfiles(payload)
export const postScore = (groupProfileId: string, payload: any) =>
  api.postScore(groupProfileId, payload)
export const postGroupProfile = (payload: GroupProfileParams) => api.postGroupProfile(payload)
export const finalizeGroupProfile = (groupProfileId: string) =>
  api.finalizeGroupProfile(groupProfileId)
