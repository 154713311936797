export default function getParsedJson(jsonString: string) {
  try {
    var j = JSON.parse(jsonString)
    if (j && typeof j === 'object') {
      return j
    }
  } catch (err) {
    return undefined
  }
}
