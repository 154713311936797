import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons'

import Modal from '../Modal'
import './styles.scss'
import { Button } from '@chakra-ui/react'

type FinalizeModalType = {
  icon?: IconProp
  modalVersion: 'finalize' | 'continue'
  onSuccess: () => void
  onCancel: () => void
  onClose: () => void
  showModal: boolean
}
const FinalizeModal = ({
  icon = faCircleQuestion,
  modalVersion,
  onSuccess,
  onCancel,
  onClose,
  showModal,
}: FinalizeModalType) => {
  const textHeader = modalVersion.charAt(0).toUpperCase() + modalVersion.slice(1)
  const saveButtonText = modalVersion === 'finalize' ? 'Publish' : 'Done'
  const cancelButtonText = modalVersion === 'finalize' ? 'Save' : 'Cancel'
  const textBody =
    modalVersion === 'finalize' ? (
      <>
        Once you publish a profile, it cannot be edited. <br />
        Do you want to Publish this profile or just Save changes so you can edit again later?
      </>
    ) : (
      'Click Cancel to continue working with this profile or click Done to return to the profile list'
    )

  return (
    <Modal showModal={showModal} onClose={onCancel} dataCy="finalizeModal">
      <div className="finalizeModal">
        <div className="finalizeModal__header">
          <FontAwesomeIcon icon={icon} size={'3x'} className="finalizeModal__header__icon" />

          <div className="finalizeModal__header__text">{textHeader}</div>
        </div>
        <div className="finalizeModal__body">
          <p>{textBody}</p>
        </div>
        <div className="finalizeModal__buttons">
          <Button
            type="reset"
            onClick={() => (modalVersion === 'finalize' ? onClose() : onCancel())}
            className="finalizeModal__button__cancel"
            colorScheme="grey"
            data-cy="cancelInvite">
            {cancelButtonText}
          </Button>
          <Button
            type="submit"
            onClick={() => (modalVersion === 'finalize' ? onSuccess() : onClose())}
            className="finalizeModal__button__continue"
            colorScheme="brand"
            data-cy="saveInvite">
            {saveButtonText}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default FinalizeModal
