import React from 'react'
import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/react'
import * as SentryBrowser from '@sentry/browser'
import { ChakraProvider } from '@chakra-ui/react'
import { theme } from './theme'
import { AuthProvider, LRTools, ErrorProvider, MagicLinkProvider } from 'provider'
import * as gitCommitModule from './_git_commit'
import reportWebVitals from './reportWebVitals'
import App from './App'
import './styles/index.scss'
import { env } from './env'
import { startSessionChecker } from './utils/sessionChecker'
import { LoadingIndicator } from 'core'

const { VITE_LOGROCKET_APP_ID, VITE_SENTRY_DSN, VITE_SENTRY_ENVIRONMENT, VITE_SENTRY_SAMPLE_RATE } =
  env

// initialize logrocket
if (VITE_LOGROCKET_APP_ID) {
  LRTools.setAppId(VITE_LOGROCKET_APP_ID)
  LRTools.init()
}

if (VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: VITE_SENTRY_DSN,
    environment: VITE_SENTRY_ENVIRONMENT,
    integrations: [SentryBrowser.browserTracingIntegration()],
    tracesSampleRate: +(VITE_SENTRY_SAMPLE_RATE ?? 0.0),
    attachStacktrace: true,
    release: gitCommitModule.default.commitSha,
  })

  LRTools.getSessionURL((sessionURL: string) => {
    Sentry.getCurrentScope().setExtra('LogRocket-SessionURL', sessionURL)
  })
}

startSessionChecker()

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <ErrorProvider>
        <MagicLinkProvider loader={<LoadingIndicator fullWidth fullHeight />}>
          <AuthProvider>
            <App />
          </AuthProvider>
        </MagicLinkProvider>
      </ErrorProvider>
    </ChakraProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
