import { Tooltip } from '@chakra-ui/react'
import { faFileChartColumn } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AlignType } from 'core'
import { Column } from 'packages/core/src/components/FilterTable/types'
import { SectionUser, WorkflowStatusType } from 'provider'
import { Link } from 'react-router-dom'

export enum InviteType {
  Organization = 'organization',
  Candidate = 'candidate',
}

export enum TabPage {
  Active = 'active',
  Inactive = 'inactive',
  Pending = 'pending',
  OpenPositions = 'open_positions',
  Last30Days = 'last_30_days',
  NotSet = '',
}

export function commonColumnData<T extends SectionUser>(activeTab?: TabPage): Column<T>[] {
  return [
    ...(activeTab !== TabPage.Pending
      ? [
          {
            header: 'Reports',
            accessorFn: () => ({}),
            enableSorting: false,
            meta: {
              align: AlignType.left,
            },
            cell: ({ row }: { row: any }) => {
              const { original } = row

              const isCompleted = original.currentStatus.code === WorkflowStatusType.Completed

              return (
                <div className="report-container">
                  {isCompleted && (
                    <>
                      <div className="report-link">
                        <Tooltip
                          hasArrow
                          bg="brand.500"
                          color="white"
                          placement="right"
                          label={'Workplace Culture Profile'}>
                          <Link to={`/invitations/${original.invitation}/results`}>
                            <FontAwesomeIcon icon={faFileChartColumn} size="2x" color="#3f2c7f" />
                          </Link>
                        </Tooltip>
                      </div>
                    </>
                  )}
                </div>
              )
            },
          },
        ]
      : []),
  ]
}
