import { useCallback, useEffect, useState, JSX } from 'react'
import TagElement, { TagColors } from '../Tag'
import './styles.scss'

export type Tag = {
  text: string | JSX.Element
  active?: boolean
  filled?: boolean
  color?: TagColors
  onActive: () => void
}

type TagGroupProps = {
  tags: Tag[]
  isSingleSelect?: boolean
  isActiveClickable?: boolean
  isManagedByActiveTags?: boolean
  label?: string
}

const mapTags = (tags: Tag[]) => tags.map((tag) => tag.active === true)

const TagGroup = ({
  tags,
  isSingleSelect = true,
  isActiveClickable = false,
  isManagedByActiveTags = false,
  label,
}: TagGroupProps) => {
  const [activeElements, setActiveElements] = useState(mapTags(tags))

  useEffect(() => {
    if (isManagedByActiveTags) {
      setActiveElements(mapTags(tags))
    }
  }, [tags, isManagedByActiveTags])

  const handleClick = useCallback(
    (idx: number) => {
      const clickedButton = tags[idx]
      const isButtonChanging = !activeElements[idx] || isActiveClickable
      const nextState = [...activeElements]

      if (isButtonChanging) {
        nextState[idx] = !nextState[idx]
        clickedButton.onActive?.()

        if (isSingleSelect) {
          nextState.forEach((item, currIdx) => {
            nextState[currIdx] = idx === currIdx ? item : false
          })
        }
      }

      setActiveElements(nextState)
    },
    [activeElements, isActiveClickable, isSingleSelect, tags],
  )

  return (
    <div className="tagGroup">
      {label ? <span className="tagGroup__label">{label}</span> : null}

      {tags.map(({ text, color, filled }, idx) => (
        <TagElement
          key={idx}
          onClick={() => handleClick(idx)}
          color={color}
          filled={filled}
          active={activeElements[idx]}>
          {text}
        </TagElement>
      ))}
    </div>
  )
}

export default TagGroup
