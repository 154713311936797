import { JSX } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

type ProtectedRouteProps = {
  isAllowed: boolean
  redirectPath?: string
  redirectOnSignIn?: boolean
  children?: JSX.Element
}

const ProtectedRoute = ({
  isAllowed,
  redirectPath = '/login',
  redirectOnSignIn = true,
  children,
}: ProtectedRouteProps) => {
  const { pathname, search } = useLocation()

  if (!isAllowed) {
    const queryParams = new URLSearchParams(search)

    if (redirectOnSignIn && pathname !== '/') {
      queryParams.set('redirect', pathname)
    }

    const to = `${redirectPath}?${queryParams}`

    return <Navigate to={to} replace />
  }

  return children ? children : <Outlet />
}

export default ProtectedRoute
