import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/pro-regular-svg-icons'
import { faCircleInfo, faBuildingUser } from '@fortawesome/pro-solid-svg-icons'
import { LoadingIndicator, PageWrapper } from 'core'
import {
  CancellableRequest,
  InvitationWelcome,
  StatusCategories,
  WorkflowStatusType,
} from 'provider'
import { getInvitationWelcome } from '../../../actions/invitations'

import './styles.scss'
import { Button } from '@chakra-ui/react'

const inviteText = (tenant, name, job) => {
  const nameText = name ? (
    <>
      <strong>{name}</strong> on behalf of{' '}
    </>
  ) : (
    ''
  )
  const jobText = job ? (
    <>
      {' '}
      in consideration of a <strong>{job} Position</strong>
    </>
  ) : (
    ''
  )
  return (
    <>
      Invited by {nameText}
      <strong>{tenant}</strong>
      {jobText}
    </>
  )
}

const getInstructionText = (audience) => {
  if (audience === 'CANDIDATE') {
    return (
      <>
        Please read each question carefully and{' '}
        <strong>find the answer that best fits you. </strong>
        We ask that you answer each question as truthfully as you can; keep in mind that this is
        about <strong>who you are at work</strong> and{' '}
        <strong>there are no right or wrong answers</strong>. It is recommended to complete the
        assessment in one sitting.
      </>
    )
  } else if (audience === 'ORGANIZATION') {
    return (
      <>
        While taking the test please read each question carefully and{' '}
        <strong>find the answer that best fits based on your organization</strong>. It is
        recommended to complete the assessment in one sitting.
      </>
    )
  }
}

const getButtonText = (statusCategory): string => {
  let text
  switch (statusCategory) {
    case StatusCategories.Pending:
    case StatusCategories.Invited:
      text = 'Start Now'
      break
    case StatusCategories.InProgress:
      text = 'Resume'
      break
    case StatusCategories.Canceled:
      text = 'Canceled'
      break
    default:
      text = 'Completed'
      break
  }
  return text
}

const InvitationWelcomePage = () => {
  const { invitationId } = useParams<{ invitationId: string }>()
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState<string>()
  const [pageText, setPageText] = useState<InvitationWelcome>()
  const navigate = useNavigate()

  useEffect(() => {
    let isMounted = true
    let resultsRequest: CancellableRequest<InvitationWelcome>

    if (invitationId) {
      resultsRequest = getInvitationWelcome(invitationId)
      resultsRequest.request
        .then((data) => {
          if (isMounted) {
            setPageText(data)
            setIsLoading(false)
          }
        })
        .catch(() => {
          if (isMounted) {
            setIsLoading(false)
            setErrorMessage('Unable to load invitation')
          }
        })
    }
    return () => {
      isMounted = false
      if (resultsRequest && resultsRequest.cancelRequest) {
        resultsRequest.cancelRequest()
      }
    }
  }, [invitationId])

  useEffect(() => {
    const isFinalized = pageText?.currentStatus.isFinal
    const isWaitingForResult = pageText?.currentStatus.code === WorkflowStatusType.WaitingForResult
    if (isFinalized || isWaitingForResult) {
      return navigate(`/invitations/${invitationId}/complete`)
    }
  }, [pageText, invitationId, navigate])

  if (isLoading) {
    return (
      <div style={{ marginTop: '3rem' }}>
        <LoadingIndicator fullWidth={true} />
      </div>
    )
  }

  if (errorMessage || !pageText) {
    return <PageWrapper extraClassNames="errorPage">{errorMessage}</PageWrapper>
  }

  return (
    <PageWrapper extraClassNames="invitationWelcome">
      <h1 className="welcome-header">
        Take the
        <br />
        {pageText.assessmentTitle}
      </h1>
      <div className="assessment-info">
        {pageText.tenantTitle && (
          <div className="assessment-info__invitation">
            <FontAwesomeIcon icon={faBuildingUser} />
            <div className="assessment-info__invitation-text">
              {inviteText(pageText?.tenantTitle, pageText?.invitedByName, pageText?.positionTitle)}
            </div>
          </div>
        )}
        <div className="assessment-info__duration">
          <FontAwesomeIcon icon={faClock} />
          <div className="assessment-info__duration-text">
            Takes about <strong>{pageText.estimatedCompletionTime}</strong> to complete
          </div>
        </div>
      </div>
      <div className="instructions">
        <FontAwesomeIcon icon={faCircleInfo} />
        <div className="instructions-text">
          {getInstructionText(pageText.invitationAudience)}
          <div className="subtext">
            If you need to leave before completing your task, simply use the link in your email to
            return and resume where you left off.
          </div>
        </div>
      </div>

      <div className="invitation-action">
        <Button
          colorScheme="brand"
          onClick={() =>
            navigate(
              `/sections/${pageText.nextModuleUserId}?redirect=/invitations/${invitationId}/welcome`,
            )
          }>
          {getButtonText(pageText.currentStatus.category)}
        </Button>
      </div>

      <div className="more-info">
        <h2 className="more-info__title">{pageText.moduleIntroTitle}</h2>
        <div className="more-info__paragraph">{pageText.moduleIntroParagraph}</div>

        <h3 className="more-info__sections-title">
          This assessment consists of the following section
          {pageText?.sections?.length > 1 ? 's' : ''}:
        </h3>
        <div className="more-info__sections">
          {pageText.sections.map((section) => {
            const { title, description, sectionUserId } = section

            return (
              <div key={sectionUserId}>
                <h4 className="section-title">{title}</h4>
                <div className="section-description">{description}</div>
              </div>
            )
          })}
        </div>
      </div>
    </PageWrapper>
  )
}

export default InvitationWelcomePage
