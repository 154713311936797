export enum AccountStatusType {
  Invoice = 'INVOICE',
  Stripe = 'STRIPE',
}

export enum WorkflowStatusType {
  Completed = 'COMPLT', // 5 - final state
  Draft = 'DRAFT',
  Published = 'PUB',
  Pending = 'PEND', // 1 - initial newly created
  Invited = 'INVITED', // 2 - waiting for invitation to be accepted
  Accepted = 'ACC',
  Revoked = 'REV',
  InSetup = 'SETUP',
  Active = 'ACTIVE',
  Canceled = 'CANCEL',
  Error = 'ERROR',
  Renew = 'RENEW',
  Closed = 'CLOSED',
  Filled = 'FILLED',
  InProgress = 'WIP', // 3 - in progress
  WaitingForResult = 'WAITRES', // 4 - waiting for result / scoring
  Archived = 'ARCHIVED',
}

export enum StatusKind {
  Initial = 'I',
  InProgress = 'T',
  Final = 'F',
}

export type Status = {
  category: string
  code: string
  title: string
  isInitial?: boolean
  isTransitory?: boolean
  isFinal?: boolean
}

export enum StatusCategories {
  Accepted = 'ACTIVE',
  Active = 'ACTIVE',
  InProgress = 'ACTIVE',
  Error = 'ATTN',
  Published = 'CMPLT',
  Filled = 'CMPLT',
  Completed = 'CMPLT',
  Revoked = 'CXCL',
  Canceled = 'CXCL',
  Closed = 'CXCL',
  Draft = 'NEW',
  Pending = 'NEW',
  Renew = 'RENEW',
  Invited = 'WAIT',
  InSetup = 'WAIT',
  WaitingForResult = 'WAITSYS',
  ResultReady = 'WAITSYS',
}

export type StatusEvent = {
  changeAt: string
  createdBy: string
  createdByName: string
  toStatus: Status
}
