import { JSX } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import LoadingIndicator from '../LoadingIndicator'
import { InviteType } from '../../../../disco/src/pages/Management/Invitations/common'
import { checkUserPermissions, useUserContext } from 'provider'

type InviteTypeSecuredRouteProps = {
  isLoadingUser: boolean
  children?: JSX.Element
  notAllowedPage: JSX.Element
}

export const InviteTypeSecuredRoute = ({
  isLoadingUser,
  children,
  notAllowedPage,
}: InviteTypeSecuredRouteProps) => {
  const { type } = useParams()
  const { userContext } = useUserContext()

  if (isLoadingUser) {
    return (
      <div>
        <LoadingIndicator fullWidth={true} fullHeight={true} />
      </div>
    )
  }
  let isAllowed: boolean = false

  if (type === InviteType.Candidate) {
    isAllowed = true
  } else {
    isAllowed = checkUserPermissions(userContext, 'allow_job_alignment')
  }

  if (!isAllowed) {
    return notAllowedPage
  }

  return children ? children : <Outlet />
}

export default InviteTypeSecuredRoute
