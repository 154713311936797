import classNames from 'classnames'
import { Tooltip, IconButton } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons'
import './styles.scss'
import { useState, JSX } from 'react'

export type InputWrapperProps = {
  label?: string
  instructions?: string
  errorMessage?: string
  isColumn?: boolean
  hasDefaultMargin?: boolean
  hasDefaultWidthRange?: boolean
  className?: string
  dataCy?: string
}

export enum InputClassNames {
  Default = 'inputField__input',
  Filled = 'inputField__input--filled',
  Error = 'inputField__input--error',
  Readonly = 'inputField__input--readonly',
}

type ChildrenProps = {
  children: JSX.Element
  statusTag?: JSX.Element
}

const InputWrapper = ({
  children,
  statusTag,
  label,
  instructions,
  errorMessage,
  isColumn = false,
  hasDefaultMargin = true,
  hasDefaultWidthRange = true,
  dataCy,
  className = '',
}: InputWrapperProps & ChildrenProps) => {
  const conditionalClassNames = classNames({
    'inputField--marginRight': hasDefaultMargin && isColumn,
    'inputField--marginBottom': hasDefaultMargin && !isColumn,
    'inputField--widthRange': hasDefaultWidthRange,
  })
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)

  const handleIconClick = () => {
    // Handle the icon click event
  }

  return (
    <div
      className={`inputField ${conditionalClassNames} ${className}`}
      data-cy={dataCy || 'inputWrapper'}>
      {label && (
        <div className="inputField__label-wrapper">
          <div className="inputField__label-container">
            <label className="inputField__label">{label}</label>
            {instructions && (
              <div className="inputField__tooltip">
                <Tooltip
                  hasArrow
                  isOpen={isTooltipOpen}
                  onClose={() => setIsTooltipOpen(false)}
                  closeOnClick={false}
                  bg="brand.500"
                  color="white"
                  placement="right"
                  label={instructions}>
                  <IconButton
                    aria-label="Info Icon"
                    isfocusable={false.toString()}
                    tabIndex={-1}
                    icon={<FontAwesomeIcon icon={faCircleInfo} color="#6c757d" />}
                    onMouseEnter={() => setIsTooltipOpen(true)}
                    onMouseLeave={() => setIsTooltipOpen(false)}
                    onClick={handleIconClick}
                  />
                </Tooltip>
              </div>
            )}
          </div>
          {statusTag}
        </div>
      )}
      {children}
      <label className="inputField__error">{errorMessage}</label>
    </div>
  )
}

export default InputWrapper
