import { useConfirmationModal } from './useConfirmationModal'

export const useSupportModal = () => {
  const textBody = (
    <>
      <p>
        If you have any questions, or need any help, please contact us at{' '}
        <a href="mailto:support@workzinga.com">support@workzinga.com</a>
      </p>
    </>
  )
  const { confirmationModal: supportModal, setShowModal: setShowSupportModal } =
    useConfirmationModal({ textHeader: 'Support', textBody })

  return { supportModal, setShowSupportModal }
}
