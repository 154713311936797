import { useEffect, useRef, useState } from 'react'
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  defaults,
} from 'chart.js'
import { Radar } from 'react-chartjs-2'
import pattern from 'patternomaly'
// import './styles.scss'

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend)

interface RadarChartProps {
  apiData: any
}

const RadarChart = ({ apiData }: RadarChartProps) => {
  // global styling for the chart
  defaults.font.family = 'Istok Web'
  defaults.font.size = 13

  const [chartData, setChartData] = useState<any>({})
  const [isDataLoaded, setIsDataLoaded] = useState(false)

  const chartRef = useRef<ChartJS>(null)

  const chartColors = [
    {
      backgroundColor: pattern.draw('cross', 'rgba(85, 187, 228, 0.5)'),
      borderColor: 'rgba(85, 187, 228, .7)',
      pointBackgroundColor: 'rgba(85, 187, 228, 0.7)',
      pointHoverBorderColor: 'rgb(85, 187, 228)',
    },
    {
      backgroundColor: 'rgba(167, 57, 139, 0.3)',
      borderColor: 'rgba(167, 57, 139, 0.6)',
      pointBackgroundColor: 'rgba(167, 57, 139, 0.7)',
      pointHoverBorderColor: 'rgb(167, 57, 139)',
    },
  ]

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadData = () => {
    const dataLength = apiData.datasets.length

    const cData = apiData.datasets.map((data: any, idx: number) => {
      const colorIdx = dataLength === 1 ? 1 : idx

      return {
        fill: true,
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        ...chartColors[colorIdx],
        ...data,
      }
    })

    setChartData({
      ...apiData,
      datasets: cData,
    })
    setIsDataLoaded(true)
  }

  const config: any = {
    elements: {
      line: {
        borderWidth: 3,
      },
    },
    scales: {
      r: {
        beginAtZero: true,
        ticks: {
          display: false,
        },
      },
    },
  }

  return (
    <div className="radar-chart">
      {isDataLoaded && <Radar options={config} data={chartData} ref={chartRef} />}
    </div>
  )
}

export default RadarChart
