import classNames from 'classnames'
import { LoadingIndicator, ProtectedRoute, RoleBasedRoute, InviteTypeSecuredRoute } from 'core'
import { useState, useEffect } from 'react'
import { useAuth, UserContext, useUserContext } from 'provider'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  Navigate,
  useLocation,
} from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import * as Sentry from '@sentry/react'
import { useLocalStorage } from 'usehooks-ts'

import Footer from './components/Footer'
import SideNav from './components/SideNav'
import TopNav from './components/TopNav'
import {
  InvitationResults,
  InvitationWelcomePage,
  InvitationCompletePage,
  InvitationGuide,
} from './pages/Invitation'
import LoginPage from './pages/Login'
import LogoutPage from './pages/Logout'
import {
  InvitationManagementPage,
  PositionManagementPage,
  UserManagementPage,
} from './pages/Management'
import NotFoundPage from './pages/NotFound'
import NotPermittedPage from './pages/NotPermitted'
import PositionPage from './pages/Position'
import { SectionDashboard, SectionDetails } from './pages/Sections'
import TermsPage from './pages/Legal/terms'
import PrivacyPage from './pages/Legal/privacy'
import CookiesPage from './pages/Legal/cookies'
import { GroupPage } from './pages/Groups'
import { GroupEditPage } from './pages/Groups/GroupEditPage'
import { NewGroupPage } from './pages/Groups/new'
import ScoreProfile from './pages/ScoreProfile'
import WalkthroughPage from './pages/Walkthrough'
import SwitchContextModal from './components/SwitchContextModal'

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

function App() {
  const { isAuthenticated, isLoading: isLoadingAuth, user, signOut } = useAuth()
  const { userContext, isLoadingUserContext } = useUserContext()
  const isLoadingUser = isLoadingAuth || !isAuthenticated || isLoadingUserContext
  const isOrgUser: boolean = userContext?.isOrgUser ?? false
  const allowJobAlignment: boolean = userContext?.allowJobAlignment ?? false
  const isOrgPowerUser: boolean = userContext?.isOrgPowerUser ?? false

  async function handleSignOut() {
    await signOut()
    window.location.href = '/login'
  }

  if (isLoadingAuth || isLoadingUserContext || (isAuthenticated && !userContext)) {
    if (isAuthenticated && !userContext && user?.userContexts && user?.userContexts?.length > 1) {
      // show modal for user to make a context selection
      return <SwitchContextModal isOpen={true} onClose={handleSignOut} />
    }
    return <LoadingIndicator fullWidth={true} />
  }

  function determineNavigationPath(
    isAuthenticated: boolean,
    allowJobAlignment: boolean,
    isOrgPowerUser: boolean,
    isOrgUser: boolean,
    userContext?: UserContext,
  ) {
    if (!isAuthenticated) {
      return '/login'
    } else if (isOrgPowerUser) {
      return allowJobAlignment ? '/positions/manage' : '/invitations/manage/candidate/pending'
    } else if (userContext?.isCandidate && userContext.invitationId) {
      return `/invitations/${userContext.invitationId}/welcome`
    } else {
      return '/sections'
    }
  }

  const navigationIndexPath = determineNavigationPath(
    isAuthenticated,
    allowJobAlignment,
    isOrgPowerUser,
    isOrgUser,
    userContext,
  )

  return (
    <div className="app">
      <Router>
        <SentryRoutes>
          {/* Anonymous pages go here */}
          <Route path="*" element={<NotFoundPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/logout" element={<LogoutPage />} />

          {/* Anonymous Report Sample pages go here */}
          <Route
            path="/sample/interview-guide/:viewerAudience/:invitationKind/:sampleTier/:key"
            element={<InvitationGuide />}
          />
          <Route
            path="/sample/:sampleType/:viewerAudience/:invitationKind/:sampleTier/:key"
            element={<InvitationResults />}
          />

          {/* Anonymous pages with a top nav go here */}
          <Route element={<DefaultContainer isOrgUser={isOrgUser} />}>
            <Route path="terms-of-use" element={<TermsPage />} />
            <Route path="privacy-policy" element={<PrivacyPage />} />
            <Route path="cookies-policy" element={<CookiesPage />} />
          </Route>

          {/* Protected pages go here */}
          <Route path="/" element={<ProtectedRoute isAllowed={isAuthenticated} />}>
            {/* Put stuff in here if it has a top nav */}
            <Route element={<DefaultContainer isOrgUser={isOrgUser} />}>
              <Route
                index
                element={
                  <RoleBasedRoute
                    isAllowed={isAuthenticated}
                    isLoadingUser={isLoadingUser}
                    notAllowedPage={<Navigate to="/login" replace />}>
                    <Navigate to={navigationIndexPath} replace />
                  </RoleBasedRoute>
                }
              />
              <Route
                path="/"
                element={
                  <RoleBasedRoute
                    isAllowed={isOrgPowerUser}
                    isLoadingUser={isLoadingUser}
                    notAllowedPage={<NotPermittedPage />}
                  />
                }>
                <>
                  {/* DEPRECATE: keep this in to reroute to index for now in case users have 
                  bookmarks or there are links in the wild */}
                  <Route path="dashboard" element={<Navigate to="/" replace />} />
                  {/* <Route path="dashboard" element={<DashboardPage />} /> */}

                  <Route path="invitations/manage/:type">
                    <Route
                      path=":tab"
                      element={
                        <InviteTypeSecuredRoute
                          isLoadingUser={isLoadingUser}
                          notAllowedPage={<NotPermittedPage />}>
                          <InvitationManagementPage />
                        </InviteTypeSecuredRoute>
                      }
                    />
                    <Route
                      path=""
                      element={
                        <InviteTypeSecuredRoute
                          isLoadingUser={isLoadingUser}
                          notAllowedPage={<NotPermittedPage />}>
                          <InvitationManagementPage />
                        </InviteTypeSecuredRoute>
                      }
                    />
                  </Route>

                  <Route
                    path="positions"
                    element={
                      <RoleBasedRoute
                        isAllowed={allowJobAlignment}
                        isLoadingUser={isLoadingUser}
                        notAllowedPage={<NotPermittedPage />}>
                        <PositionPage />
                      </RoleBasedRoute>
                    }
                  />
                  <Route
                    path="positions/:positionId"
                    element={
                      <RoleBasedRoute
                        isAllowed={allowJobAlignment}
                        isLoadingUser={isLoadingUser}
                        notAllowedPage={<NotPermittedPage />}>
                        <PositionPage />
                      </RoleBasedRoute>
                    }
                  />
                  <Route
                    path="positions/manage"
                    element={
                      <RoleBasedRoute
                        isAllowed={allowJobAlignment}
                        isLoadingUser={isLoadingUser}
                        notAllowedPage={<NotPermittedPage />}>
                        <PositionManagementPage />
                      </RoleBasedRoute>
                    }
                  />
                  <Route
                    path="groups"
                    element={
                      <RoleBasedRoute
                        isAllowed={allowJobAlignment}
                        isLoadingUser={isLoadingUser}
                        notAllowedPage={<NotPermittedPage />}>
                        <GroupPage />
                      </RoleBasedRoute>
                    }
                  />
                  <Route
                    path="groups/new"
                    element={
                      <RoleBasedRoute
                        isAllowed={allowJobAlignment}
                        isLoadingUser={isLoadingUser}
                        notAllowedPage={<NotPermittedPage />}>
                        <NewGroupPage />
                      </RoleBasedRoute>
                    }
                  />
                  <Route
                    path="groups/:groupId"
                    element={
                      <RoleBasedRoute
                        isAllowed={allowJobAlignment}
                        isLoadingUser={isLoadingUser}
                        notAllowedPage={<NotPermittedPage />}>
                        <GroupEditPage key="edit" path="edit" />
                      </RoleBasedRoute>
                    }
                  />
                  <Route
                    path="groups/:groupId/admin"
                    element={
                      <RoleBasedRoute
                        isAllowed={allowJobAlignment}
                        isLoadingUser={isLoadingUser}
                        notAllowedPage={<NotPermittedPage />}>
                        <GroupEditPage key="admin" path="admin" />
                      </RoleBasedRoute>
                    }
                  />
                  <Route
                    path="groups/:groupId/profiles"
                    element={
                      <RoleBasedRoute
                        isAllowed={allowJobAlignment}
                        isLoadingUser={isLoadingUser}
                        notAllowedPage={<NotPermittedPage />}>
                        <GroupEditPage key="profiles" path="profiles" />
                      </RoleBasedRoute>
                    }
                  />
                  <Route
                    path="scores/:groupProfileId"
                    element={
                      <RoleBasedRoute
                        isAllowed={allowJobAlignment}
                        isLoadingUser={isLoadingUser}
                        notAllowedPage={<NotPermittedPage />}>
                        <ScoreProfile />
                      </RoleBasedRoute>
                    }
                  />
                  <Route path="users/manage" element={<UserManagementPage />} />
                  {/* <Route path="components" element={<ComponentExamplesPage />} /> */}
                </>
              </Route>

              {/* <Route path="subscription" element={<SubscriptionPricingPage />} />
              <Route path="subscription/pricing" element={<SubscriptionPricingPage />} />
              <Route
                path="subscription/:planId/new-account"
                element={<SubscriptionAccountPage />}
              />
              <Route
                path="subscription/:planId/confirmation"
                element={<SubscriptionConfirmationPage />}
              /> */}

              {/** Anyone logged in */}
              <Route path="invitations/:invitationId/welcome" element={<InvitationWelcomePage />} />
              <Route
                path="invitations/:invitationId/complete"
                element={<InvitationCompletePage />}
              />
              <Route path="invitations/guide/org/:invitationId" element={<InvitationGuide />} />
              <Route
                path="invitations/guide/candidate/:invitationId"
                element={<InvitationGuide />}
              />
              <Route path="invitations/:invitationId/results" element={<InvitationResults />} />
              <Route
                path="invitations/:invitationId/results/:type"
                element={<InvitationResults />}
              />
              <Route
                path="group-profiles/:groupProfileId/results"
                element={<InvitationResults />}
              />
              <Route path="sections" element={<SectionDashboard />} />
              <Route path="sections/:sectionUserId" element={<SectionDetails />} />
            </Route>

            <Route path="walkthrough" element={<WalkthroughPage />} />
          </Route>
        </SentryRoutes>
      </Router>
      <ToastContainer />
    </div>
  )
}

interface DefaultContainerProps {
  isOrgUser: boolean
}

const DefaultContainer = ({ isOrgUser }: DefaultContainerProps) => {
  const location = useLocation()
  const { pathname } = location
  const [isOpen, setIsOpen] = useLocalStorage('zinga:navOpen', true)
  const [useSideNav, setUseSideNav] = useState(true)

  useEffect(() => {
    let ignore = false
    const uuidPattern =
      '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}'
    const sectionsWithUuidPattern = new RegExp(`sections/${uuidPattern}`)
    if (!ignore && (sectionsWithUuidPattern.test(pathname) || pathname.includes('/welcome'))) {
      setUseSideNav(false)
    } else {
      setUseSideNav(true)
    }

    return () => {
      ignore = true
    }
  }, [pathname])

  const toggleSideNav = () => {
    setIsOpen((prevValue: boolean) => !prevValue)
  }

  const sideNavClass = classNames({
    'app--sideNavOpen': isOpen && isOrgUser,
    'app--sideNavClosed': !isOpen && isOrgUser,
    'app--noSideNav': !isOrgUser || !useSideNav,
  })

  return (
    <div className={sideNavClass}>
      <TopNav />
      {isOrgUser && <SideNav toggleOpen={toggleSideNav} />}
      <main>
        <Outlet />
      </main>
      <Footer />
    </div>
  )
}

export default Sentry.withProfiler(App)
