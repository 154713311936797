export enum CategoryType {
  Paragraph = 'PARAGRAPH',
  Spectrum = 'SPECTRUM',
  WordCloud = 'WORDCLOUD',
}

export type WordCloudData = {
  text: string
  value: number
}

type BaseCategory = {
  moduleTitle: string
  categoryTitle: string
  categoryDescription?: string
  categoryAbbreviation: string
}

export type ParagraphCategory = BaseCategory & {
  type: CategoryType.Paragraph
  organizationText?: string[]
  candidateText: string[]
  chart?: any
}

export type SpectrumConstruct = {
  title: string
  leftSpectrum: string
  rightSpectrum: string
  candidateScore: number
  organizationScore?: number
  scale: number
  organizationText?: string
  candidateText: string
}

export type SpectrumCategory = BaseCategory & {
  type: CategoryType.Spectrum
  constructs: SpectrumConstruct[]
}

export type WordCloudCategory = BaseCategory & {
  type: CategoryType.WordCloud
  candidateData: WordCloudData[]
  organizationData?: WordCloudData[]
}

export type ResultCategory = ParagraphCategory | SpectrumCategory | WordCloudCategory

export function isParagraphCategory(category: ResultCategory): category is ParagraphCategory {
  return category.type === CategoryType.Paragraph
}

export function isSpectrumCategory(category: ResultCategory): category is SpectrumCategory {
  return category.type === CategoryType.Spectrum
}

export function isWordCloudCategory(category: ResultCategory): category is WordCloudCategory {
  return category.type === CategoryType.WordCloud
}
