import classNames from 'classnames'
import './styles.scss'

// These padding sizes are the ones currently supported in the stylesheet
// If more sizes are needed, they can be added here
type PaddingSize = 0 | 1 | 2

type PageProps = {
  children: React.ReactNode
  extraClassNames?: string
  padding?: PaddingSize
  paddingTop?: PaddingSize
  paddingRight?: PaddingSize
  paddingBottom?: PaddingSize
  paddingLeft?: PaddingSize
  removeMobilePadding?: boolean
}

const PageWrapper = ({
  children,
  extraClassNames,
  padding = 1,
  paddingTop,
  paddingRight,
  paddingBottom,
  paddingLeft,
  removeMobilePadding,
}: PageProps) => {
  const conditionalClassNames = classNames({
    pageWrapper: true,
    [`${extraClassNames}`]: extraClassNames !== undefined,
    [`pageWrapper--${padding}`]: true,
    [`pageWrapper__top--${paddingTop}`]: paddingTop !== undefined && paddingTop,
    [`pageWrapper__right--${paddingRight}`]: paddingRight !== undefined && paddingRight,
    [`pageWrapper__bottom--${paddingBottom}`]: paddingBottom !== undefined && paddingBottom,
    [`pageWrapper__left--${paddingLeft}`]: paddingLeft !== undefined && paddingLeft,
    [`pageWrapper--noMobilePadding`]: removeMobilePadding,
  })

  return <div className={conditionalClassNames}>{children}</div>
}

export default PageWrapper
