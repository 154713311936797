import { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { handleError, InputField, SelectDropdown } from 'core'
import { Assessment, AssessmentSection, Invitation, AudienceType, useUserContext } from 'provider'
import { createInvitation } from '../../actions/invitations'

import { DropdownOption } from '../../models'
import { mapToOption, onSubmitForm, sortOptionByLabel } from '../../utils'
import Modal from '../Modal'
import OrgUsersDropdown, { OrgUsersDropdownProps } from '../OrgUsersDropdown'
import './styles.scss'
import { Button } from '@chakra-ui/react'

const mapAndSortSections = (sections: AssessmentSection[]) =>
  sections
    .map((section) => mapToOption(section, 'organizationText', 'sectionId'))
    .sort(sortOptionByLabel)

type InvitationForm = {
  section: DropdownOption | undefined
  user: DropdownOption | undefined
  label: string
}

type OrgInvitationCreateModalProps = {
  showModal: boolean
  onClose: () => void
  onSave?: () => void
  /**
   * The assessment to create the invitation for.
   * If not provided, the modal will show a dropdown to select the assessment from.
   *
   * @optional
   * @type {string}
   */
  assessmentId?: string
  orgUsersDropdownProps?: OrgUsersDropdownProps
  defaultSectionOption?: DropdownOption
  onCreateInvitation?: (invitation: Invitation) => void
}

const OrgInvitationCreateModal = ({
  showModal,
  onClose,
  onSave = onClose,
  orgUsersDropdownProps,
  defaultSectionOption,
  assessmentId,
  onCreateInvitation,
}: OrgInvitationCreateModalProps) => {
  const { userContext, isLoadingUserContext } = useUserContext()
  const [assessments, setAssessments] = useState<Assessment[]>()
  const [selectedAssessment, setSelectedAssessment] = useState<Assessment>()
  const [assessmentOptions, setAssessmentOptions] = useState<DropdownOption[]>([])
  const [sectionOptions, setSectionOptions] = useState<DropdownOption[]>([])
  const [isPosting, setIsPosting] = useState(false)
  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<InvitationForm>({
    defaultValues: {
      section: defaultSectionOption,
      label: '',
    },
  })

  const getAssesmentById = useCallback(
    (id: string) => {
      return assessments?.find((assessment) => assessment.id === id)
    },
    [assessments],
  )

  const getSectionById = useCallback(
    (id: string) => {
      const sections = assessments?.flatMap((assessment) => assessment.sectionSet)
      return sections?.find((section) => section.sectionId === id)
    },
    [assessments],
  )

  useEffect(() => {
    if (userContext?.tenant) {
      setAssessments(userContext.tenant.assessments)
      if (userContext.tenant.assessments) {
        setAssessmentOptions(
          userContext.tenant.assessments.map((assessment) =>
            mapToOption(assessment, 'title', 'id'),
          ),
        )
        setSectionOptions(
          userContext.tenant.assessments.flatMap((assessment) =>
            mapAndSortSections(assessment.sectionSet),
          ),
        )
      }
    }
  }, [userContext])

  const defaultAssessment = useMemo(() => {
    if (assessmentOptions.length === 1) {
      setSelectedAssessment(getAssesmentById(assessmentOptions[0].value))
      return assessmentOptions[0]
    } else {
      return undefined
    }
  }, [assessmentOptions, getAssesmentById])

  const submitInvitation = async ({ section, user, label }: InvitationForm) => {
    setIsPosting(true)
    let assessment
    if (assessmentId) {
      // If assessmentId is passed in, use that assessment
      assessment = getAssesmentById(assessmentId)
    } else {
      // Otherwise, use the selected assessment from the dropdown (shows up if assessmentId is not passed in)
      if (selectedAssessment) {
        assessment = selectedAssessment
      } else {
        // If no assessment is selected, throw an error
        handleError(undefined, {
          errorMessage: 'Could not create invitation. No assessment was selected.',
        })(new Error('Could not create invitation.'))
      }
    }
    if (assessment && user?.value && section?.value) {
      try {
        const newInvitation = await createInvitation({
          assessment: { id: assessment.id },
          user: { id: user.value },
          section: { id: section.value },
          audience: AudienceType.Organization,
          label,
        }).request
        onCreateInvitation?.(newInvitation)
        reset()
        onSave()
      } catch (err) {
        handleError(undefined, {
          errorMessage: 'Could not create invitation. An error occurred.',
        })(err)
      }
    }

    setIsPosting(false)
  }

  const generateTitle = useCallback(() => {
    if (defaultSectionOption) {
      const section = getSectionById(defaultSectionOption.value)
      return `${section?.organizationText}`
    }
    return ''
  }, [defaultSectionOption, getSectionById])

  const setAssessment = (assessment: DropdownOption | undefined) => {
    if (assessment) {
      const assessmentSections = getAssesmentById(assessment.value)?.sectionSet
      if (assessmentSections) {
        setSectionOptions(mapAndSortSections(assessmentSections))
      }
      setSelectedAssessment(getAssesmentById(assessment.value))
    }
  }

  return (
    <Modal showModal={showModal} onClose={onClose} dataCy="orgInviteCreateModal">
      <form onSubmit={onSubmitForm(handleSubmit(submitInvitation))} className="orgInvitationModal">
        <div className="header">
          Create Company Invitation
          {generateTitle() ? (
            <>
              <br /> <span className="header__subtext">{generateTitle()}</span>
            </>
          ) : null}
        </div>
        <OrgUsersDropdown
          className="orgInvitationModal__field"
          {...orgUsersDropdownProps}
          isCreateMemberOnly={true}
          name="user"
          control={control}
          rules={{ validate: (option) => !!option?.value || 'Required field' }}
          placeholder="Select or Create Company User..."
          dataCy="orgUser"
        />
        {!assessmentId && (
          // This is only used for the + Create Invitation button. In the future it may be used elsewhere, but now it can select which assessment to filter sections by if no assessmentId is passed in.
          <SelectDropdown
            defaultValue={defaultAssessment}
            field={{
              onChange: setAssessment,
              onBlur: () => {},
              value: undefined,
              name: 'assessment',
              ref: () => {},
            }}
            fieldState={{
              invalid: false,
              isTouched: true,
              isDirty: false,
            }}
            options={assessmentOptions}
            placeholder="Choose Assessment..."
          />
        )}

        {!defaultSectionOption?.label && (
          <Controller
            name="section"
            render={({ field, fieldState }) => (
              <SelectDropdown
                field={field}
                fieldState={fieldState}
                defaultValue={defaultSectionOption}
                options={sectionOptions}
                isLoading={isLoadingUserContext}
                placeholder="Choose Comparison Section..."
                dataCy="section"
              />
            )}
            control={control}
            rules={{ validate: (option) => !!option?.value || 'Required field' }}
          />
        )}
        <InputField
          placeholder="Comparison Title"
          registerFields={register('label', { required: 'Required field' })}
          getValue={() => watch('label')}
          errorMessage={errors.label?.message}
          className="orgInvitationModal__field"
          dataCy="tagline"
        />
        <div className="orgInvitationModal__actions">
          <Button variant="danger" className="orgInvitationModal__cancelBtn" onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="brandPrimary"
            className="orgInvitationModal__saveBtn"
            isLoading={isPosting}
            isDisabled={isPosting}
            data-cy="saveInvite">
            Send
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default OrgInvitationCreateModal
