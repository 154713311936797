import { Assessment } from './Assessment'
import { Id, InvitationFields, OptionalExceptFor } from './mixins'
import { SectionUser } from './SectionUser'
import { Status, WorkflowStatusType } from './Status'
import { User, AudienceType } from './User'

export enum InvitationKind {
  STANDARD = 'STANDARD',
  ALIGNED = 'ALIGNED',
}

export type Invitation = Id &
  InvitationFields & {
    currentStatus: Status
    user: User
    assessment: OptionalExceptFor<Assessment, 'id' | 'title'> | string
    jobPosition?: { id: string; positionTitle: string; currentStatusId: WorkflowStatusType }
    sectionUsers: SectionUser[]
    audience: AudienceType
    overallMatch?: number
    kind: InvitationKind
  }
