import { JSX } from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons'

import Modal from '../Modal'
import './styles.scss'
import { Button } from '@chakra-ui/react'

type ConfirmationModalType = {
  icon?: IconProp
  textHeader: string
  textBody: JSX.Element
  onSuccess: () => void
  onCancel: () => void
  onClose: () => void
  showModal: boolean
}
const ConfirmationModal = ({
  icon = faCircleQuestion,
  textHeader,
  textBody,
  onSuccess,
  onCancel,
  onClose,
  showModal,
}: ConfirmationModalType) => {
  return (
    <Modal showModal={showModal} onClose={onClose} dataCy="confirmationModal">
      <div className="confirmationModal">
        <div className="confirmationModal__header">
          <FontAwesomeIcon icon={icon} size={'3x'} className="confirmationModal__header__icon" />

          <div className="confirmationModal__header__text">{textHeader}</div>
        </div>
        <div className="confirmationModal__body">{textBody}</div>
        <div className="confirmationModal__buttons">
          <Button
            type="reset"
            colorScheme="grey"
            onClick={() => onCancel()}
            className="confirmationModal__button__cancel"
            data-cy="cancelInvite">
            Cancel
          </Button>
          <Button
            type="submit"
            colorScheme="brand"
            onClick={() => onSuccess()}
            className="confirmationModal__button__continue"
            data-cy="saveInvite">
            Continue
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmationModal
