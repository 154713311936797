import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { EMAIL_REGEX, handleError, InputField } from 'core'
import { forgotPassword } from '../../actions/signIn'
import { onSubmitForm } from '../../utils'
import { CommonFormProps, SignInState } from './common'
import './styles.scss'
import { Button } from '@chakra-ui/react'

type ForgotPasswordForm = {
  email: string
}

const ForgotPassword = ({ setState }: CommonFormProps) => {
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm<ForgotPasswordForm>({
    defaultValues: {
      email: '',
    },
  })
  const [isPosting, setIsPosting] = useState(false)

  const onSubmit = async ({ email }: ForgotPasswordForm) => {
    setIsPosting(true)
    try {
      await forgotPassword({ email }).request
      setState(SignInState.ForgotPasswordSuccess)
      setIsPosting(false)
    } catch (err) {
      handleError(setIsPosting)(err)
    }
  }

  const dontIgnore: boolean = false

  return (
    <form onSubmit={onSubmitForm(handleSubmit(onSubmit))} className="signInForm">
      <div className="signInForm__input">
        <InputField
          type="email"
          placeholder="Email"
          ignore1Password={dontIgnore}
          registerFields={register('email', {
            required: 'Required field',
            pattern: {
              value: EMAIL_REGEX,
              message: 'Invalid email address',
            },
          })}
          getValue={() => watch('email')}
          errorMessage={errors.email?.message}
        />
      </div>

      <Button
        variant="brandPrimary"
        className="signInForm__submit"
        type="submit"
        isLoading={isPosting}>
        Submit
      </Button>
    </form>
  )
}

export default ForgotPassword
