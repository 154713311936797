import { Grid, GridItem } from '@chakra-ui/react'
import './styles.scss'

type ColumnsProps = {
  columns: {
    data: {
      [key: string]: string[]
    }
    description: string
    footer: string
    title: string
  }
}

const itemsToDisplay = (items) => {
  return items.map((i, idx) => <p key={idx}>{i}</p>)
}

const ColumnsSection = ({ columns }: ColumnsProps) => {
  const { data, description, footer, title } = columns

  return (
    <div className="section_columns">
      <div className="section_columns--description">{description}</div>
      <div className="section_columns--title">{title}</div>
      <Grid className="section_columns--table" templateColumns="repeat(3, 1fr)" gap={0}>
        <GridItem className="header" w="100%" h="10">
          Strong Agreement
        </GridItem>
        <GridItem className="header" w="100%" h="10">
          Moderate
        </GridItem>
        <GridItem className="header" w="100%" h="10">
          Weak
        </GridItem>
        <GridItem className="body" w="100%">
          {itemsToDisplay(data.strongAgreement)}
        </GridItem>
        <GridItem className="body" w="100%">
          {itemsToDisplay(data.moderate)}
        </GridItem>
        <GridItem className="body" w="100%">
          {itemsToDisplay(data.weak)}
        </GridItem>
      </Grid>
      <div className="section_columns--footer">{footer}</div>
    </div>
  )
}

export default ColumnsSection
