import classNames from 'classnames'
import { ControllerFieldState, Noop, RefCallBack } from 'react-hook-form'
import Select from 'react-select'
import InputWrapper, { InputClassNames, InputWrapperProps } from '../InputWrapper'
import './styles.scss'

export type DropdownOption = {
  label: string
  value: string
}

type SelectDropdownProps = InputWrapperProps & {
  field: {
    onChange: (...event: any[]) => void
    onBlur: Noop
    value: DropdownOption | undefined
    name: string
    ref: RefCallBack
  }
  fieldState: ControllerFieldState
  defaultValue?: DropdownOption
  options: DropdownOption[]
  isDisabled?: boolean
  isLoading?: boolean
  placeholder?: string
  wrapperClassName?: string
}

const SelectDropdown = ({
  field,
  fieldState: { error },
  defaultValue,
  options,
  isDisabled,
  isLoading,
  placeholder,
  className: extraClassName = '',
  wrapperClassName = '',
  dataCy,
  ...inputWrapperProps
}: SelectDropdownProps) => {
  const errorMessage = error?.message
  const conditionalClassNames = classNames({
    [InputClassNames.Filled]: !!field.value && !errorMessage,
    [InputClassNames.Error]: !!errorMessage,
    'inputField__input--disabled': !!isDisabled,
  })

  return (
    <InputWrapper
      {...inputWrapperProps}
      className={wrapperClassName}
      errorMessage={errorMessage}
      dataCy={dataCy || 'select'}>
      <Select
        className={`${InputClassNames.Default} ${conditionalClassNames} selectDropdown ${extraClassName}`}
        classNamePrefix="selectDropdown"
        styles={{
          control: (provided) => ({
            ...provided,
            border: 'none',
          }),
          menu: (provided) => ({
            ...provided,
            zIndex: '1002',
          }),
          option: (provided, { isSelected }) => ({
            ...provided,
            ':hover': {
              ...provided[':hover'],
              fontWeight: 600,
            },
            fontWeight: isSelected ? 600 : 400,
          }),
          placeholder: (provided) => ({
            ...provided,
            color: '#aaa',
          }),
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: '#f3f0fe',
            primary: '#3f2c7f',
          },
        })}
        defaultValue={defaultValue}
        options={options}
        isLoading={isLoading}
        placeholder={placeholder}
        isDisabled={isDisabled}
        {...field}
      />
    </InputWrapper>
  )
}

export default SelectDropdown
