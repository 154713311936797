import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  FormControl,
  FormLabel,
} from '@chakra-ui/react'
import { Group } from 'provider'
import { RefObject, useEffect, useRef, useState } from 'react'
import { PrivateGroupSwitch } from '../PrivateGroupSwitch'

export type CopyGroupModalProps = {
  group?: Group
  onClose: () => void
  onSubmit: (groupId: string, name: string, isPrivate: boolean) => Promise<any>
  isOpen: boolean
}

export const DuplicateGroupModal = ({ group, isOpen, onClose, onSubmit }: CopyGroupModalProps) => {
  const isMounted = useRef(true)
  const [isLoading, setIsLoading] = useState(false)

  // form params
  const [name, setName] = useState(group?.title + ' (copy)')
  const [isPrivate, setIsPrivate] = useState(!group?.isPublic!)

  const initialRef = useRef() as RefObject<HTMLInputElement>

  useEffect(() => {
    isMounted.current = true

    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (group) {
      setName(group?.title + ' (copy)')
      setIsPrivate(!group?.isPublic!)
    }
  }, [group])

  const handleFocus = (event: any) => event.target.select()

  return (
    <Modal
      motionPreset="scale"
      onClose={onClose}
      isOpen={isOpen}
      isCentered={true}
      initialFocusRef={initialRef}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Duplicate {group?.title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl isRequired>
            <FormLabel htmlFor="title">Name</FormLabel>
            <Input
              id="title"
              value={name}
              onChange={(e) => {
                setName(e.target.value)
              }}
              ref={initialRef}
              onFocus={handleFocus}
              maxLength={48}></Input>
          </FormControl>
          <FormControl mt={4}>
            <FormLabel htmlFor="isPrivate">Privacy Settings</FormLabel>
            <PrivateGroupSwitch
              isPrivate={isPrivate}
              onChange={(isPrivate) => {
                setIsPrivate(!isPrivate)
              }}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} disabled={isLoading} variant="danger">
            Cancel
          </Button>
          <Button
            variant="brandPrimary"
            ml={3}
            onClick={() => {
              if (!isMounted.current) return
              setIsLoading(true)
              onSubmit(group?.id!, name, isPrivate).then(() => {
                if (!isMounted.current) return
                setIsLoading(false)
                onClose()
              })
            }}
            disabled={isLoading}
            isLoading={isLoading}>
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
