import { useEffect, useMemo, useState, useCallback, JSX } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  FilterTable,
  formatDate,
  handleError,
  DebouncedInput,
  Color,
  ActionDropdown,
  ActionItem,
  AlignType,
  Tag,
  TagGroup,
} from 'core'
import { CancellableRequest, SectionUser, Result, StatusKind, WorkflowStatusType } from 'provider'
import { InviteType, TabPage } from './common'
import {
  getSectionUsers,
  inactivateOrgComparison,
  reactivateOrgComparison,
} from '../../../actions/assessments'
import { cancelInvitation } from '../../../actions/invitations'
import { useLoadingStates, useRefreshData, useDocumentTitle } from '../../../hooks'
import UpdateSectionUserTitle from '../../../components/UpdateSectionUserTitle'
import OrgInvitationCreateModal from '../../../components/OrgInvitationCreateModal'
import { faPencil, faRectangleXmark } from '@fortawesome/pro-solid-svg-icons'
import { faBoxArchive, faBoxOpen, faEnvelope } from '@fortawesome/pro-solid-svg-icons'
import { faSquareEllipsis } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@chakra-ui/react'
import { commonColumnData } from '../../Sections/Dashboard/section_user_extra_columns'

let isRendered = true

const OrganizationInvitations = ({ tab }: { tab: TabPage }) => {
  useDocumentTitle('Company Invitations')
  const navigate = useNavigate()
  const { counter, refreshData } = useRefreshData()
  const { toggleLoadingStateForKey } = useLoadingStates()
  const [showModal, setShowModal] = useState(false)
  const [editSectionUser, setEditSectionUserId] = useState({ id: '', label: '' })

  const [isLoadingData, setIsLoadingData] = useState(true)
  const [tableData, setTableData] = useState<User[]>([])
  const [searchParams, setSearchParams] = useState<string>('')
  const [isCreateOrgInvitation, setIsCreateOrgInvitation] = useState(false)

  // when component is unmounted, `isRendered` is set to false
  // this is then used when getting responses to async requests to see if data is still needed
  useEffect(() => {
    isRendered = true
    return () => {
      isRendered = false
    }
  }, [])

  const onSectionUserId = useCallback((data: SectionUser) => {
    setEditSectionUserId({ id: data.id, label: data.label })
    setShowModal(true)
  }, [])

  const triggerCallback = useCallback(
    ({ id, callback }: { id: string; callback: (id: string) => CancellableRequest<Result> }) => {
      toggleLoadingStateForKey(id)
      callback(id)
        .request.then(() => isRendered && refreshData())
        .catch(handleError())
        .finally(() => isRendered && toggleLoadingStateForKey(id))
    },
    [refreshData, toggleLoadingStateForKey],
  )

  const renderOrgInviteActions = useCallback(
    (row: SectionUser): JSX.Element => {
      const actions: ActionItem[] = [
        {
          label: 'Edit',
          icon: faPencil,
          iconColor: Color.Purple,
          labelColor: Color.Purple,
          iconSize: 'xl',
          isDisabled: row.terminatedAt === null || row.terminatedAt === undefined ? false : true,
          onClick: () => {
            onSectionUserId(row)
          },
        },
      ]

      switch (true) {
        case row.invitationCurrentStatus.code === WorkflowStatusType.Invited ||
          row.invitationCurrentStatus.code === WorkflowStatusType.Pending:
          actions.push({
            label: 'Cancel',
            icon: faRectangleXmark,
            iconSize: 'xl',
            iconColor: Color.Red,
            labelColor: Color.Red,
            onClick: () => {
              if (row.invitation) {
                triggerCallback({ id: row.invitation, callback: cancelInvitation })
              }
            },
          })
          break
        case row.terminatedAt !== null && row.currentStatus.code !== WorkflowStatusType.Canceled:
          actions.push({
            label: 'Unarchive',
            icon: faBoxOpen,
            iconSize: 'xl',
            iconColor: Color.Green,
            labelColor: Color.Green,
            onClick: () => {
              triggerCallback({ id: row.id, callback: reactivateOrgComparison })
            },
          })
          break
        case row.terminatedAt === null || row.terminatedAt === undefined:
          actions.push({
            label: 'Archive',
            icon: faBoxArchive,
            iconSize: 'xl',
            iconColor: Color.Red,
            labelColor: Color.Red,
            onClick: () => {
              triggerCallback({ id: row.id, callback: inactivateOrgComparison })
            },
          })
          break
      }

      return (
        <>
          {actions && actions.length ? (
            <ActionDropdown
              actions={actions}
              alignButton={'right'}
              variant={'actionGhost'}
              icon={faSquareEllipsis}
              iconColor={'#3F2C7F'}
              iconSize={'2xl'}
            />
          ) : (
            ''
          )}
        </>
      )
    },
    [onSectionUserId, triggerCallback],
  )

  const tabs: Record<string, Record<string, Record<string, unknown>>> = useMemo(
    () => ({
      [TabPage.Active]: {
        filter: {
          statusKind: StatusKind.Final,
          type: InviteType.Organization,
        },
      },
      [TabPage.Inactive]: {
        filter: {
          showInactive: true,
          statusKind: StatusKind.Final,
          type: InviteType.Organization,
        },
      },
      [TabPage.Pending]: {
        filter: {
          statusKind: StatusKind.Final,
          excludeStatusKind: true,
          type: InviteType.Organization,
        },
      },
      [TabPage.OpenPositions]: {
        filter: {
          openPosition: true,
          type: InviteType.Organization,
        },
      },
      [TabPage.Last30Days]: {
        filter: {
          last30Days: true,
          type: InviteType.Organization,
        },
      },
    }),
    [],
  )

  const tags: Tag[] = useMemo(
    () => [
      {
        text: 'Pending',
        active: tab === TabPage.Pending,
        onActive: () => navigate(`../${TabPage.Pending}`),
      },
      {
        text: 'Active',
        active: tab === TabPage.Active,
        onActive: () => navigate(`../${TabPage.Active}`),
      },
      {
        text: 'Inactive',
        active: tab === TabPage.Inactive,
        onActive: () => navigate(`../${TabPage.Inactive}`),
      },
      {
        text: 'Open Jobs',
        active: tab === TabPage.OpenPositions,
        onActive: () => navigate(`../${TabPage.OpenPositions}`),
      },
      {
        text: 'Last 30 Days',
        active: tab === TabPage.Last30Days,
        onActive: () => navigate(`../${TabPage.Last30Days}`),
      },
      {
        text: 'My Sections',
        active: false,
        onActive: () => navigate(`/sections`),
      },
    ],
    [tab, navigate],
  )

  const fetchDataParams = useMemo(() => {
    const { filter } = tabs[tab]
    return {
      ...filter,
      pager: 'none',
      search: searchParams,
    }
  }, [searchParams, tabs, tab])

  const getTableData = useCallback(() => {
    setIsLoadingData(true)
    const resolve = getSectionUsers({
      ...fetchDataParams,
    })

    resolve.request
      .then((response) => {
        setIsLoadingData(false)
        setTableData(response)
      })
      .catch(handleError(setIsLoadingData))

    return resolve.cancelRequest
  }, [fetchDataParams])

  useEffect(getTableData, [getTableData, counter])

  const columns = useMemo<ColumnDef<Invitation>[]>(() => {
    const schemaColumns = [
      ...commonColumnData(tab),
      {
        header: 'User',
        accessorFn: ({ user }) => user.fullName,
      },
      {
        header: 'Section',
        accessorFn: ({ section }) => section.organizationText,
      },
      {
        header: 'Comparison Name',
        accessorKey: 'label',
      },
      {
        header: 'Invited By',
        accessorKey: 'invitedBy',
      },
      {
        header: 'Invitation Date',
        accessorKey: 'invitedAt',
        meta: {
          align: AlignType.center,
        },
        cell: (info) => formatDate(info.getValue()),
      },
      {
        header: 'Status',
        accessorFn: ({ invitationCurrentStatus }) => invitationCurrentStatus.title,
        cell: ({ row }) => row.original.invitationCurrentStatus?.title,
      },
      {
        header: 'Status Date',
        accessorKey: 'statusAt',
        meta: {
          align: AlignType.center,
        },
        cell: (info) => formatDate(info.getValue()),
      },
    ]

    if (tab === TabPage.Inactive) {
      schemaColumns.push({
        header: 'Inactive Date',
        accessorKey: 'terminatedAt',
        cell: (info) => formatDate(info.getValue()),
      })
    }

    schemaColumns.push({
      id: 'actions',
      header: '',
      enableSorting: false,
      cell: ({ row }) => renderOrgInviteActions(row.original),
    })

    return schemaColumns
  }, [tab, renderOrgInviteActions])

  return (
    <>
      <div className="manageInvitations__actions">
        <TagGroup tags={tags} isManagedByActiveTags={true} label="Filter" />
        <Button
          type="button"
          data-cy="createInvitation"
          variant="brandPrimary"
          onClick={() => setIsCreateOrgInvitation(true)}
          leftIcon={<FontAwesomeIcon icon={faEnvelope} />}>
          Send Section Invitation
        </Button>
      </div>
      <div className="filterInput">
        <DebouncedInput
          value={''}
          onChange={(value) => setSearchParams(String(value))}
          className="inputField__input fa-font"
          placeholder="Find..."
        />
      </div>
      <FilterTable schema={columns} data={tableData} isLoading={isLoadingData} />
      <UpdateSectionUserTitle
        showModal={showModal}
        onClose={() => {
          setShowModal(false)
        }}
        onSave={() => {
          setShowModal(false)
          refreshData()
        }}
        editSectionUser={editSectionUser}
      />
      <OrgInvitationCreateModal
        showModal={isCreateOrgInvitation}
        onClose={() => {
          setIsCreateOrgInvitation(false)
        }}
        onSave={() => {
          setIsCreateOrgInvitation(false)
          if (tab !== TabPage.Pending) {
            navigate(`../${TabPage.Pending}`)
          } else {
            refreshData()
          }
        }}
      />
    </>
  )
}

export default OrganizationInvitations
