import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'

let logRocketInitialized = false
let logRocketAppId: string | undefined = undefined
export const LRTools = {
  setAppId: (appId: string) => {
    logRocketAppId = appId
  },
  init: () => {
    if (logRocketAppId) {
      LogRocket.init(logRocketAppId, {
        network: {
          requestSanitizer: (request) => {
            if (request.url.toLowerCase().indexOf('api/v1/token') !== -1) {
              if (typeof request.body === 'string') {
                if (request.body?.includes('password')) {
                  let body = JSON.parse(request.body)
                  body.password = '***redacted***'
                  request.body = JSON.stringify(body)
                }
                if (request.body?.includes('auth_challenge')) {
                  let body = JSON.parse(request.body)
                  body.auth_challenge = '***redacted***'
                  request.body = JSON.stringify(body)
                }
                if (request.body?.includes('surrogate')) {
                  let body = JSON.parse(request.body)
                  body.surrogate = '***redacted***'
                  request.body = JSON.stringify(body)
                }
                if (request.body?.includes('token')) {
                  let body = JSON.parse(request.body)
                  body.token = '***redacted***'
                  request.body = JSON.stringify(body)
                }
              }
            }
            return request
          },
          responseSanitizer: (response) => {
            if (typeof response.body === 'string') {
              if (response.body?.includes('access')) {
                let body = JSON.parse(response.body)
                body.access = '***redacted***'
                response.body = JSON.stringify(body)
              }
              if (response.body?.includes('refresh')) {
                let body = JSON.parse(response.body)
                body.refresh = '***redacted***'
                response.body = JSON.stringify(body)
              }
            }
            return response
          },
        },
      })
      logRocketInitialized = true
      setupLogRocketReact(LogRocket)
    } else {
      console.warn('LogRocket App Id is not set.')
    }
  },
  identify: (user: any) => {
    if (logRocketAppId) {
      if (!logRocketInitialized) {
        LRTools.init()
      }
      if (!user || !user.email) {
        return
      }
      LogRocket.identify(user.email, { email: user.email, name: user.fullName ?? user.email })
    }
  },
  identifyAnonymous: () => {
    if (logRocketAppId) {
      if (!logRocketInitialized) {
        LRTools.init()
      }
      LogRocket.identify('anonymous')
    }
  },
  getSessionURL: (callback: (sessionURL: string) => void) => {
    if (logRocketAppId) {
      if (!logRocketInitialized) {
        LRTools.init()
      }
      return LogRocket.getSessionURL(callback)
    }
  },
}
