import { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { handleError, PageWrapper } from 'core'
import { CancellableRequest, MarkdownResponse } from 'provider'
import './styles.scss'

type BaseLegalPageProps = {
  getMarkdown: () => CancellableRequest<MarkdownResponse>
}

const BaseLegalPage = ({ getMarkdown }: BaseLegalPageProps) => {
  const [terms, setTerms] = useState('')

  useEffect(() => {
    const cancellableRequest = getMarkdown()

    cancellableRequest.request
      .then((res) => {
        setTerms(res.content)
      })
      .catch(handleError())

    return cancellableRequest.cancelRequest
  }, [getMarkdown])

  return (
    <PageWrapper extraClassNames="terms">
      <ReactMarkdown children={terms} />
    </PageWrapper>
  )
}

export default BaseLegalPage
