import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useUserContext } from 'provider'

import './styles.scss'

const WalkthroughPage = () => {
  const { userContext } = useUserContext()
  const iframe_src =
    (userContext?.allowJobAlignment ?? false)
      ? 'https://capture.navattic.com/clsyyg6k000ph0fjzdezvhuik'
      : 'https://capture.navattic.com/clsz5jh3t001l0fi96pqn8rom'

  return (
    <div className="walkthrough">
      <div className="walkthrough--banner">
        <span className="banner-title">App Walkthrough</span>
        <span className="close-button">
          <a href="/">
            <FontAwesomeIcon icon={faCircleXmark} size="lg" className="close-banner--icon" />
          </a>
        </span>
      </div>
      <iframe
        title="Walkthrough Demo"
        src={iframe_src}
        style={{
          border: 'none',
          width: '100vw',
          height: '100vh',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        allow="fullscreen"></iframe>
    </div>
  )
}

export default WalkthroughPage
