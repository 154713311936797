import { Slide, ToastOptions } from 'react-toastify'

const TOAST_OPTIONS: ToastOptions = {
  autoClose: 5000,
  position: 'bottom-right',
  transition: Slide,
  hideProgressBar: true,
}

export { TOAST_OPTIONS }
