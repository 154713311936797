import {
  api,
  CreatePosition,
  GetPositions,
  PatchPosition,
  PatchPositionEdit,
  PatchPositionSection,
} from 'provider'

export const getPosition = (positionId: string) => api.getPosition(positionId)

export const getPositions = (props?: GetPositions) => api.getPositions(props)

export const createPositions = (payload: CreatePosition) => api.createPosition(payload)

export const closePosition = (positionId: string) => api.closePosition(positionId)

export const reopenPosition = (positionId: string) =>
  api.postPositionStatus({ positionId, statusPayload: { statusId: 'REOPEN' } })

export const patchPosition = (payload: PatchPosition) => api.patchPosition(payload)

export const patchPositionEdit = (payload: PatchPositionEdit) => api.patchPositionEdit(payload)

export const patchPositionSection = (payload: PatchPositionSection) =>
  api.patchPositionSection(payload)

export const getPositionStatus = (positionId: string) => api.getPositionStatus(positionId)
