import {
  api,
  BasicLogin,
  ForgotPassword,
  MagicLink,
  ResetPassword,
  UserContextChoice,
} from 'provider'

export const basicLogin = (payload: BasicLogin) => api.basicLogin(payload)

export const logout = () => api.logout()

export const resetPassword = (payload: ResetPassword) => api.resetPassword(payload)

export const forgotPassword = (payload: ForgotPassword) => api.forgotPassword(payload)

export const getMagicLink = (params: MagicLink) => api.getMagicLink(params)

export const postMagicLink = async (payload: MagicLink, userContextChoice: UserContextChoice) => {
  const response = await api.postMagicLink(payload).request
  await api.createUserContext(userContextChoice).request
  return response
}

export const createUserContext = (userContextChoice: UserContextChoice) => {
  let userContext
  if (!userContextChoice.invitationId && !userContextChoice.membershipId) {
    userContext = {}
  } else {
    userContext = userContextChoice.invitationId
      ? { invitationId: userContextChoice.invitationId }
      : { membershipId: userContextChoice.membershipId }
  }
  return api.createUserContext(userContext)
}
