import { useRef, useState, useEffect } from 'react'
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  defaults,
} from 'chart.js'
import { Bubble } from 'react-chartjs-2'
import annotationPlugin from 'chartjs-plugin-annotation'
import './styles.scss'

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend, annotationPlugin)

interface QuadrantChartProps {
  apiData: any
}

const QuadrantChart = ({ apiData }: QuadrantChartProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
  const [coordinateData, setCoordinateData] = useState(apiData.datasets)
  const [chartData, setChartData] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  // global styling for the chart
  defaults.font.family = 'Istok Web'
  defaults.font.size = 13

  const pointScale = apiData.scale
  // set labels for annotation plugin
  const labelsObject = {}
  apiData.labels.forEach((label: any) => {
    const k = Object.keys(label)
    const quadrant = k[0]
    const title = label[quadrant]

    labelsObject[quadrant] = title
  })

  const chartRef = useRef<ChartJS>(null)

  // Need to sort the data so that Org will come first and match the order of display.
  const revSortOrder = (a, b) => {
    // "kind" will be either "ORGANIZATION" or "CANDIDATE"
    return b.kind.localeCompare(a.kind)
  }

  useEffect(() => {
    const { datasets } = apiData
    const chartDatasets = datasets.sort(revSortOrder).map((data) => {
      return {
        label: data.label,
        data: [
          {
            x: data.x,
            y: data.y,
            r: 11,
          },
        ],
        borderColor: '#f2f2f2',
        borderWidth: 0.5,
        ...getPointStyle(data.kind),
      }
    })

    const newChartData = {
      datasets: chartDatasets,
    }
    setChartData(newChartData)
    setIsLoading(false)
  }, [apiData])

  const getPointStyle = (kind) => {
    let backgroundColor: string = ''
    let pointStyle: string = ''
    if (kind === 'ORGANIZATION') {
      backgroundColor = '#55bbe4'
      pointStyle = 'triangle'
    } else {
      backgroundColor = '#a7398b'
      pointStyle = 'circle'
    }
    return {
      backgroundColor,
      pointStyle,
    }
  }

  const scaleOffset = 6
  const xOffset = 1
  const yOffset = 3
  const chartScale = pointScale + scaleOffset

  const options: any = {
    animation: {
      duration: 1,
    },
    aspectRatio: 1.3,
    scales: {
      y: {
        grid: {
          drawTicks: false,
          offset: true,
        },
        max: chartScale,
        min: -chartScale,
        ticks: {
          precision: 0,
          count: chartScale + 1,
          display: false,
        },
      },
      x: {
        grid: {
          drawTicks: false,
          offset: true,
        },
        max: chartScale,
        min: -chartScale,
        ticks: {
          precision: 1,
          count: chartScale + 1,
          display: false,
        },
      },
    },
    plugins: {
      quadrants: {
        topLeft: '#f2c6ca',
        topRight: '#553ba9',
        bottomRight: '#b3c7f1',
        bottomLeft: '#f3f0fe',
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      annotation: {
        annotations: {
          label1: {
            type: 'label',
            xValue: chartScale - xOffset,
            yValue: chartScale - yOffset,
            position: { x: 'end', y: 'center' },
            content: labelsObject.topRight,
            font: {
              size: 16,
            },
            backgroundColor: 'white',
            borderRadius: 4,
          },
          label2: {
            type: 'label',
            xValue: -chartScale + xOffset,
            yValue: -chartScale + yOffset,
            position: { x: 'start', y: 'center' },
            content: labelsObject.bottomLeft,
            font: {
              size: 16,
            },
            backgroundColor: 'white',
            borderRadius: 4,
          },
          label3: {
            type: 'label',
            xValue: -chartScale + xOffset,
            yValue: chartScale - yOffset,
            position: { x: 'start', y: 'center' },
            content: labelsObject.topLeft,
            font: {
              size: 16,
            },
            backgroundColor: 'white',
            borderRadius: 4,
          },
          label4: {
            type: 'label',
            xValue: chartScale - xOffset,
            yValue: -chartScale + yOffset,
            position: { x: 'end', y: 'center' },
            content: labelsObject.bottomRight,
            font: {
              size: 16,
            },
            backgroundColor: 'white',
            borderRadius: 4,
          },
        },
      },
    },
  }

  // quadrants plugin
  // this will display the background color in each chart quadrant
  const quadrants: any = [
    {
      id: 'quadrants',
      beforeDraw(chart: any, args: any, options: any) {
        const {
          ctx,
          chartArea: { left, top, right, bottom },
          scales: { x, y },
        } = chart
        const midX = x.getPixelForValue(0)
        const midY = y.getPixelForValue(0)
        ctx.save()

        const topLeftGradient = ctx.createLinearGradient(midX, midY, left, top)
        topLeftGradient.addColorStop(0, 'rgba(242, 198, 202, .1)')
        topLeftGradient.addColorStop(1, options.topLeft)
        ctx.fillStyle = topLeftGradient
        ctx.fillRect(left, top, midX - left, midY - top)

        const topRightGradient = ctx.createLinearGradient(midX, midY, right, top)
        topRightGradient.addColorStop(0, 'rgba(85, 59, 169, .1)')
        topRightGradient.addColorStop(1, options.topRight)
        ctx.fillStyle = topRightGradient
        ctx.fillRect(midX, top, right - midX, midY - top)

        const bottomRightGradient = ctx.createLinearGradient(midX, midY, right, bottom)
        bottomRightGradient.addColorStop(0, 'rgba(179, 199, 241, .1)')
        bottomRightGradient.addColorStop(1, options.bottomRight)
        ctx.fillStyle = bottomRightGradient
        ctx.fillRect(midX, midY, right - midX, bottom - midY)

        const bottomLeftGradient = ctx.createLinearGradient(midX, midY, left, bottom)
        bottomLeftGradient.addColorStop(0, 'rgba(243, 240, 254, .1)')
        bottomLeftGradient.addColorStop(1, options.bottomLeft)
        ctx.fillStyle = bottomLeftGradient
        ctx.fillRect(left, midY, midX - left, bottom - midY)
        ctx.restore()
      },
    },
  ]

  return (
    !isLoading && (
      <div className="quadrantChart">
        <Bubble options={options} plugins={quadrants} data={chartData} ref={chartRef} />
      </div>
    )
  )
}

export default QuadrantChart
