export enum SignInState {
  SignIn = 'signIn',
  ResetPassword = 'resetPassword',
  ResetPasswordSuccess = 'resetPasswordSuccess',
  ForgotPassword = 'forgotPassword',
  ForgotPasswordSuccess = 'forgotPasswordSuccess',
  CandidateAccess = 'candidateAccess',
}

export type CommonFormProps = {
  setState: (value: SignInState) => void
}
