import { useEffect, useRef, useState } from 'react'
import { Box } from '@chakra-ui/react'

import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  defaults,
} from 'chart.js'
import { color } from 'chart.js/helpers'
import { camelCase } from 'lodash'
import { MatrixController, MatrixElement } from 'chartjs-chart-matrix'
import { Chart } from 'react-chartjs-2'
import { Toast } from 'core'
import { postScore } from '../../../actions/profile'
import './styles.scss'

ChartJS.register(
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  MatrixController,
  MatrixElement,
)

interface MatrixChartProps {
  apiData: any
  groupProfileId: string
  passSelectedPointTotal: Function
  passPointTotal: Function
  callPoints: boolean
  allCanFinalize: Array<boolean>
  setAllCanFinalize: () => void
  chartIndex: number
}

const MatrixChart = ({
  apiData,
  groupProfileId,
  passSelectedPointTotal,
  passPointTotal,
  // eslint-disable-next-line unused-imports/no-unused-vars
  callPoints,
  allCanFinalize,
  setAllCanFinalize,
  chartIndex,
}: MatrixChartProps) => {
  // global styling for the chart
  defaults.font.family = 'Istok Web'
  defaults.font.size = 13

  const [yLabels, setYlabels] = useState<Array<any>>([])
  const [yLabelsArray, setYlabelsArray] = useState<Array<string>>([])
  const [chartData, setChartData] = useState<Array<any>>([])
  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const [selectedPointTotal, setSelectedPointTotal] = useState<number>(0)
  const [pointTotal, setPointTotal] = useState<number>(0)
  const [cellsToOutline, setCellsToOutline] = useState<Array<any>>([])

  const chartRef = useRef<ChartJS>(null)

  const loadData = () => {
    const tempYlabels: any = []
    const tempYlabelsArray: any = []
    const tempChartDataArray: any = []

    apiData.datasets.forEach((data: any) => {
      tempYlabels.push({
        title: data.title,
        profileScore: data.profileScore === null ? 0 : data.profileScore,
        constructId: data.id,
      })
      tempYlabelsArray.push(data.title)
      data.data.forEach((d: any, index: any) => {
        const constructedObject = { x: apiData.labels[index], y: data.title, v: d }
        tempChartDataArray.push(constructedObject)
      })
    })

    setYlabels(tempYlabels)
    setYlabelsArray(tempYlabelsArray)
    setChartData(tempChartDataArray)
    setIsDataLoaded(true)
    calculatePointSum(tempYlabels)
  }

  // this will determine which borders have been selected(have points)
  const addCellBorder = (chartData: any) => {
    const selectedCells: any = []
    chartData.forEach((data: any) => {
      for (const property in apiData.bucketMap) {
        const doesInclude = apiData.bucketMap[property].includes(data.profileScore)
        if (doesInclude) {
          selectedCells.push({ xTitle: property, yTitle: data.title })
        }
      }
    })
    setCellsToOutline(selectedCells)
  }

  useEffect(() => {
    addCellBorder(apiData.datasets)
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // sum up points user has selected.
  // we cannot post to the api if selected points is too low
  const calculatePointSum = (yLabels: any) => {
    const { current: chart } = chartRef

    let tempTotalPoints = 0
    yLabels.forEach((label: any) => {
      tempTotalPoints = tempTotalPoints + label.profileScore
    })
    setPointTotal(apiData.pointTotal)
    passPointTotal(apiData.pointTotal)
    passSelectedPointTotal(tempTotalPoints)
    setSelectedPointTotal(tempTotalPoints)
    addCellBorder(yLabels)
    if (pointTotal === tempTotalPoints && pointTotal !== 0) {
      postPoints()
    }
    if (!chart) {
      return
    }
    chart.update()
  }

  const data = {
    datasets: [
      {
        data: chartData,
        backgroundColor(context: any) {
          const value = context.dataset.data[context.dataIndex].v
          const alpha = value / 8
          return color('purple').alpha(alpha).rgbString()
        },
        borderColor(context: any) {
          // set default border
          // if a border with points matches, then make it a different color
          // to show it has been selected with points
          const xCoord = camelCase(context.dataset.data[context.dataIndex].x)
          const yCoord = camelCase(context.dataset.data[context.dataIndex].y)
          let borderColor = 'rgba(111,111,111,0.2)'
          cellsToOutline.forEach((cell: any) => {
            if (camelCase(cell.xTitle) === xCoord && camelCase(cell.yTitle) === yCoord) {
              borderColor = color('#017ac6').rgbString()
            }
          })
          return borderColor
        },
        borderWidth: 2,
        width: ({ chart }: any) => (chart.chartArea || {}).width / 4 - 2,
        height: ({ chart }: any) => (chart.chartArea || {}).height / yLabels.length - 2,
      },
    ],
  }

  const config: any = {
    onClick: (evt) => {
      const { current: chart } = chartRef

      if (!chart) {
        return
      }
      const points = chart.getElementsAtEventForMode(evt, 'nearest', { intersect: true }, true)

      if (points.length) {
        const firstPoint = points[0]
        const value = chart.data.datasets[firstPoint.datasetIndex].data[firstPoint.index]
        const valueX = camelCase(value.x)
        const newPoints = apiData.bucketMap[valueX][0]

        const tempLabels = yLabels.map((l: any) => {
          if (l.title === value.y) {
            l.profileScore = newPoints
          }
          calculatePointSum(yLabels)
          return l
        })

        setYlabels(tempLabels)
      }
    },
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        type: 'category',
        ticks: {
          display: true,
          // method to make x axis main labels and the sub labels under it
          // if padding not applied it will be cut off by the chart
          callback: function (label: any, index: any) {
            // match sub labels by main label index
            const subLabels = apiData.bucketMap[camelCase(apiData.labels[index])]
            // subLabels need to be (0-2) not (0,1,2)
            return [
              [apiData.labels[index]],
              '(' + subLabels[0] + '-' + subLabels[subLabels.length - 1] + ') points',
            ]
          },
          font: {
            size: 12,
          },
          padding: 12,
        },
        grid: {
          display: false,
        },
      },
      y: {
        type: 'category',
        labels: yLabelsArray.slice(0).reverse(),
        offset: true,
        ticks: {
          display: true,
        },
        grid: {
          display: false,
        },
      },
    },
  }

  // add and subtract point will figure out which row they clicked on
  // and take or add a point
  const addPoint = (label: any) => {
    const tempLabels = yLabels.map((l: any) => {
      if (l.title === label.title) {
        if (l.profileScore < apiData.pointScale) {
          l.profileScore = l.profileScore + 1
          calculatePointSum(yLabels)
          return l
        } else {
          Toast.error(` Cannot be greater than ${apiData.pointScale}`)
          return l
        }
      } else {
        return l
      }
    })
    setYlabels(tempLabels)
  }

  const subtractPoint = (label: any) => {
    const tempLabels = yLabels.map((l: any) => {
      if (l.title === label.title) {
        if (l.profileScore > 0) {
          l.profileScore = l.profileScore - 1
          calculatePointSum(yLabels)
          return l
        } else {
          return l
        }
      } else {
        return l
      }
    })
    setYlabels(tempLabels)
  }

  // construct labels for api call
  const constructLabels = (yLabels: any) => {
    yLabels.forEach((label: any) => {
      label['score'] = label.profileScore
    })
    return yLabels
  }

  const postPoints = () => {
    if (apiData.isStatusLocked) {
      return Toast.error('Score is locked and cannot be edited.')
    }
    const payLoad = constructLabels(yLabels)
    const request = postScore(groupProfileId, payLoad)
    request.request
      .then((response) => {
        if (response.hasValidScore) {
          const finalize = allCanFinalize
          finalize[chartIndex] = true
          setAllCanFinalize(finalize)
        }
      })
      .catch((err) => {
        Toast.error(err)
      })
    const { current: chart } = chartRef
    if (!chart) {
      return
    }
  }

  return (
    <div className="matrixChart">
      <div className="matrixTitle">{apiData.categoryChartTitle}</div>
      {isDataLoaded && (
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td>
                <Chart type="matrix" options={config} data={data} ref={chartRef} />
              </td>
              <td style={{ verticalAlign: 'top' }}>
                <div className="buttonTable">
                  <table style={{ height: '210px' }}>
                    <tbody>
                      {yLabels.map((label: any, index: number) => {
                        return (
                          <tr
                            key={'label_' + index}
                            style={{ fontSize: '15px', borderBottom: '1px solid transparent' }}>
                            <td>
                              <button key={label} onClick={() => subtractPoint(label)}>
                                -
                              </button>
                            </td>
                            <td>
                              <span key={label}>{label.profileScore}</span>
                            </td>
                            <td>
                              <button key={label} onClick={() => addPoint(label)}>
                                +
                              </button>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      )}
      <br />
      <Box align="right">
        <span
          className={
            selectedPointTotal === pointTotal ? 'selectedPointTotalGreen' : 'selectedPointTotalRed'
          }>
          {selectedPointTotal} of {pointTotal} available points assigned
        </span>
      </Box>
    </div>
  )
}

export default MatrixChart
