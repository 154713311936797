import { Choice } from 'provider'
import MultiSelectAnswer from './MultiSelectAnswer'
import cx from 'classnames'

type MultiSelectProps = {
  choices: Choice[]
  selectedChoices: string[]
  setSelectedChoices: (choices: string[]) => void
  numberOfSelectionsNeeded?: number
  isDisabled?: boolean
}

const MultiSelect = ({
  choices,
  selectedChoices,
  setSelectedChoices,
  numberOfSelectionsNeeded = 1,
  isDisabled,
}: MultiSelectProps) => {
  const isSingleSelect = numberOfSelectionsNeeded === 1
  const questionClasses = cx('multiselect', {
    isSingleSelect,
    isDisabled,
  })

  const renderChoice = (choice: Choice) => {
    if (isSingleSelect) {
      return (
        <MultiSelectAnswer
          {...choice}
          isSelected={selectedChoices.includes(choice.id)}
          onSelect={(id) => {
            setSelectedChoices(selectedChoices.includes(choice.id) ? [] : [id])
          }}
        />
      )
    } else {
      return (
        <MultiSelectAnswer
          {...choice}
          isSelected={selectedChoices.includes(choice.id)}
          onSelect={(id) => {
            const matchingChoiceIndex = selectedChoices.findIndex((id) => id === choice.id)
            if (matchingChoiceIndex !== -1) {
              selectedChoices.splice(matchingChoiceIndex, 1)
              setSelectedChoices([...selectedChoices])
            } else {
              setSelectedChoices([...selectedChoices, id])
            }
          }}
        />
      )
    }
  }

  return (
    <div className={questionClasses}>
      {choices
        .sort((a, b) => a.sequence - b.sequence)
        .map((choice, idx) => (
          <div key={idx} data-cy={`answer-${idx}`} className="multiselect__answer">
            {renderChoice(choice)}
          </div>
        ))}
    </div>
  )
}

export default MultiSelect
