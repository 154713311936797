import { useEffect, useMemo, useState, useCallback } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  FilterTable,
  formatDate,
  handleError,
  ActionDropdown,
  DebouncedInput,
  Color,
  AlignType,
  Tag,
  TagGroup,
  Toast,
} from 'core'
import {
  Invitation,
  StatusKind,
  WorkflowStatusType,
  api,
  TagDetail,
  useAuth,
  useUserContext,
} from 'provider'
import { commonColumnData, InviteType, TabPage } from './common'
import { cancelInvitation, getInvitations } from '../../../actions/invitations'
import { useLoadingStates, useRefreshData, useDocumentTitle } from '../../../hooks'
import UserInvitationCreateModal from '../../../components/UserInvitationCreateModal'
import EditTagsModal, { TagForm } from '../../../components/EditTagsModal'
import { DropdownOption } from '../../../models'
import { mapToOption, sortOptionByLabel, getTextColor } from '../../../utils'
import { faEnvelope, faRectangleXmark, faTags } from '@fortawesome/pro-solid-svg-icons'
import { faSquareEllipsis } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@chakra-ui/react'

let isRendered = true

const mapAndSortTags = (tags: TagDetail[]) =>
  tags.map((tag) => mapToOption(tag, 'title', 'title')).sort(sortOptionByLabel)

const CandidateInvitations = ({ tab }: { tab: TabPage }) => {
  useDocumentTitle('Manage Assessments')
  const navigate = useNavigate()

  const { counter, refreshData } = useRefreshData()
  const { toggleLoadingStateForKey } = useLoadingStates()

  const [isLoadingData, setIsLoadingData] = useState(true)
  const [tableData, setTableData] = useState<Invitation[]>([])
  const [searchParams, setSearchParams] = useState<string>('')
  const [isCreateUserInvitation, setIsCreateUserInvitation] = useState(false)
  const [tenantTags, setTenantTags] = useState<DropdownOption[]>([])
  const [isLoadingTags, setIsLoadingTags] = useState(true)
  const [editingTags, setEditingTags] = useState<TagForm>()
  const [columnVisibility, setColumnVisibility] = useState({})
  const { user } = useAuth()
  const { userContext } = useUserContext()

  const tabs: Record<string, Record<string, Record<string, unknown>>> = useMemo(
    () => ({
      [TabPage.Active]: {
        filter: {
          statusKind: StatusKind.Final,
          type: InviteType.Candidate,
        },
      },
      [TabPage.Pending]: {
        filter: {
          statusKind: StatusKind.Final,
          excludeStatusKind: true,
          type: InviteType.Candidate,
        },
      },
      [TabPage.OpenPositions]: {
        filter: {
          openPosition: true,
          type: InviteType.Candidate,
        },
      },
      [TabPage.Last30Days]: {
        filter: {
          last30Days: true,
          type: InviteType.Candidate,
        },
      },
    }),
    [],
  )

  const tags: Tag[] = useMemo(() => {
    const filters = [
      {
        text: 'Pending',
        active: tab === TabPage.Pending,
        onActive: () => navigate(`../${TabPage.Pending}`),
      },
      {
        text: 'Completed',
        active: tab === TabPage.Active,
        onActive: () => navigate(`../${TabPage.Active}`),
      },
      {
        text: 'Last 30 Days',
        active: tab === TabPage.Last30Days,
        onActive: () => navigate(`../${TabPage.Last30Days}`),
      },
    ]

    if (userContext?.allowJobAlignment ?? false) {
      filters.push({
        text: 'Open Jobs',
        active: tab === TabPage.OpenPositions,
        onActive: () => navigate(`../${TabPage.OpenPositions}`),
      })
    }

    return filters
  }, [user, tab, navigate])

  const fetchDataParams = useMemo(() => {
    const { filter } = tabs[tab]
    return {
      ...filter,
      pager: 'none',
      search: searchParams,
    }
  }, [searchParams, tabs, tab])

  const getTableData = useCallback(() => {
    setIsLoadingData(true)
    const resolve = getInvitations({
      ...fetchDataParams,
    })

    resolve.request
      .then((response) => {
        setIsLoadingData(false)
        setTableData(response)
      })
      .catch(handleError(setIsLoadingData))

    return resolve.cancelRequest
  }, [fetchDataParams])

  useEffect(getTableData, [getTableData, counter])

  // when component is unmounted, `isRendered` is set to false
  // this is then used when getting responses to async requests to see if data is still needed
  useEffect(() => {
    isRendered = true
    return () => {
      isRendered = false
    }
  }, [])

  const cancelInvite = useCallback(
    ({ id }: Invitation) => {
      toggleLoadingStateForKey(id)
      cancelInvitation(id)
        .request.then(() => isRendered && refreshData())
        .catch(handleError())
        .finally(() => isRendered && toggleLoadingStateForKey(id))
    },
    [refreshData, toggleLoadingStateForKey],
  )

  useEffect(() => {
    const tagsRequest = api.getTags()
    tagsRequest.request
      .then((data) => {
        const processedTags = mapAndSortTags(data)
        setTenantTags(processedTags)
        setIsLoadingTags(false)
      })
      .catch(handleError(setIsLoadingTags))

    return tagsRequest.cancelRequest
  }, [counter])

  const onEditTags = (row: Invitation) => {
    setEditingTags({
      userName: row.user.fullName,
      objectId: row.id,
      currentTags: row.tags,
    })
  }

  const onSaveTags = async (form: TagForm) => {
    const payloadTags = form.tags.map((tag) => ({ title: tag.value }))
    try {
      const payload = {
        tags: payloadTags,
        appLabel: 'assessments',
        model: 'invitation',
        objectId: form.objectId,
        mode: 'reconcile',
      }
      await api.postTags(payload).request
      Toast.success(`Tags updated successfully.`)
      refreshData()
    } catch (err) {
      handleError(undefined, { errorMessage: `Tags may not have updated properly.` })(err)
    }
    setEditingTags(undefined)
  }

  useEffect(() => {
    const newColumnVisibility = {
      jobPosition: userContext?.allowJobAlignment ?? false,
      overallMatch: userContext?.allowJobAlignment ?? false,
    }
    setColumnVisibility(newColumnVisibility)
  }, [])

  const columns = useMemo<ColumnDef<Invitation>[]>(
    () => [
      ...commonColumnData(tab),
      {
        header: 'Sent To',
        accessorFn: ({ user }) => user.fullName,
      },
      {
        header: 'Email',
        accessorFn: ({ user }) => user.email,
      },
      {
        id: 'jobPosition',
        header: 'Job',
        accessorFn: ({ jobPosition }) => jobPosition?.positionTitle,
        cell: ({ row }) => {
          const { original } = row
          return (
            <>
              <Link to={`/positions/${original.jobPosition?.id}`} className="">
                {original.jobPosition?.positionTitle ?? ''}
              </Link>
              {original.jobPosition?.currentStatusId === WorkflowStatusType.Closed && (
                <div className="statusBadge badgeInfo">{original.jobPosition?.currentStatusId}</div>
              )}
            </>
          )
        },
      },
      {
        header: 'Assessment',
        accessorKey: 'assessment',
        cell: ({ row }) => {
          const { original } = row
          return typeof original.assessment === 'string'
            ? original.assessment
            : original.assessment.title
        },
      },
      {
        header: 'Invited By',
        accessorKey: 'invitedBy',
      },
      {
        header: 'Invitation Date',
        accessorKey: 'invitedAt',
        meta: {
          align: AlignType.center,
        },
        cell: ({ row }) => formatDate(row.original.invitedAt),
      },
      {
        header: 'Status',
        accessorFn: (row) => row.currentStatus.title,
      },
      {
        header: 'Status Date',
        accessorKey: 'statusAt',
        meta: {
          align: AlignType.center,
        },
        cell: ({ row }) => formatDate(row.original.statusAt),
      },
      {
        header: 'Tags',
        enableSorting: false,
        cell: ({ row }) => {
          const { tags } = row.original
          const display = tags.map((tag) => {
            const backgroundColor = tag.kind.color || Color.MediumLightGray
            const textColor = getTextColor(backgroundColor)

            return (
              <span
                key={tag.id}
                className="item-tags"
                style={{ backgroundColor: backgroundColor, color: textColor }}>
                {tag.title}
              </span>
            )
          })
          return display
        },
      },
      {
        id: 'actions',
        header: '',
        cell: ({ row }) => {
          const statusCodes = [WorkflowStatusType.Pending, WorkflowStatusType.Invited]
          const isAllowed = statusCodes.includes(row.original.currentStatus.code)
          return (
            <ActionDropdown
              actions={[
                {
                  label: 'Manage Tags',
                  icon: faTags,
                  isDisabled: false,
                  iconSize: 'xl',
                  onClick: () => {
                    onEditTags(row.original)
                  },
                  iconColor: Color.Purple,
                  labelColor: Color.Purple,
                },
                {
                  label: 'Cancel Invitation',
                  icon: faRectangleXmark,
                  isDisabled: !isAllowed,
                  iconSize: 'xl',
                  onClick: () => {
                    cancelInvite(row.original)
                  },
                  iconColor: Color.Red,
                  labelColor: Color.Red,
                },
              ]}
              alignButton={'right'}
              variant={'actionGhost'}
              icon={faSquareEllipsis}
              iconColor={Color.Purple}
              iconSize={'2xl'}
            />
          )
        },
      },
    ],
    [tab, cancelInvite, user],
  )

  return (
    <>
      <div className="manageInvitations__actions">
        <TagGroup tags={tags} isManagedByActiveTags={true} label="Filter" />
        <Button
          type="button"
          data-cy="createInvitation"
          variant="brandPrimary"
          onClick={() => setIsCreateUserInvitation(true)}
          leftIcon={<FontAwesomeIcon icon={faEnvelope} fixedWidth={true} />}>
          Send Invitation
        </Button>
      </div>
      <div className="filterInput">
        <DebouncedInput
          value={''}
          onChange={(value) => setSearchParams(String(value))}
          className="inputField__input fa-font"
          placeholder="Find..."
        />
      </div>
      <FilterTable
        schema={columns}
        data={tableData}
        isLoading={isLoadingData}
        columnVisibilityState={columnVisibility}
      />
      <UserInvitationCreateModal
        showModal={isCreateUserInvitation}
        onClose={() => {
          setIsCreateUserInvitation(false)
        }}
        onSave={() => {
          setIsCreateUserInvitation(false)
          if (tab !== TabPage.Pending) {
            navigate(`../${TabPage.Pending}`)
          } else {
            refreshData()
          }
        }}
        tenantTags={tenantTags}
        isLoadingTags={isLoadingTags}
      />
      <EditTagsModal
        editingTags={editingTags}
        saveTags={onSaveTags}
        tenantTags={tenantTags}
        isLoadingTags={isLoadingTags}
        onClose={() => setEditingTags(undefined)}
      />
    </>
  )
}

export default CandidateInvitations
