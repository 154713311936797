export type EnvVars = ImportMetaEnv & {
  VITE_LOGROCKET_APP_ID?: string
  VITE_SENTRY_DSN?: string
  VITE_SENTRY_ENVIRONMENT?: string
  VITE_SENTRY_SAMPLE_RATE?: string
  VITE_SERVER_URL?: string
  VITE_BILLING_URL?: string
  /**
   * Feature flag to enable groups
   *
   * TODO: Remove this and dependant checks once groups are fully released
   *
   * @type {string}
   */
  VITE_FEATURE_ENABLE_GROUPS?: 'true'
}

const {
  VITE_LOGROCKET_APP_ID,
  VITE_SENTRY_DSN,
  VITE_SENTRY_ENVIRONMENT,
  VITE_SENTRY_SAMPLE_RATE,
  VITE_FEATURE_ENABLE_GROUPS,
  VITE_SERVER_URL,
  VITE_BILLING_URL,
}: EnvVars = import.meta.env

export const env = {
  VITE_LOGROCKET_APP_ID,
  VITE_SENTRY_DSN,
  VITE_SENTRY_ENVIRONMENT,
  VITE_SENTRY_SAMPLE_RATE,
  VITE_FEATURE_ENABLE_GROUPS,
  VITE_SERVER_URL,
  VITE_BILLING_URL,
}
