import { useEffect, useState } from 'react'
import { Input, InputGroup, InputLeftAddon } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import './styles.scss'

export function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value, debounce, onChange])

  return (
    <InputGroup>
      <InputLeftAddon>
        <FontAwesomeIcon icon={faSearch} />
      </InputLeftAddon>
      <Input {...props} value={value} onChange={(e) => setValue(e.target.value)} />
    </InputGroup>
  )
}
