import './styles.scss'

type ProgressBarOptions = {
  percentage: number
  showPercent?: boolean
  total: number
  showTotal?: boolean
  className?: string
  classNameModifier?: string
}

const ProgressBar = ({
  percentage,
  showPercent = true,
  total,
  showTotal = true,
  className,
  classNameModifier = 'primary',
}: ProgressBarOptions) => {
  const modifier = classNameModifier ? `progressBar__indicator--${classNameModifier}` : ''
  const intPercent = Math.floor(percentage)

  const currentValue = Math.round((total * percentage) / 100) + 1

  return (
    <div className={`progressBar ${className}`}>
      {showTotal && (
        <div className="progressBar__total">
          {currentValue} / {total}
        </div>
      )}
      <div className="progressBar__background">
        <div className={`progressBar__indicator ${modifier}`} style={{ width: `${percentage}%` }}>
          {intPercent && showPercent ? (
            <div className="progressBar__percent">{intPercent}%</div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default ProgressBar
