import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import classNames from 'classnames'
import { Path, useController, UseControllerProps } from 'react-hook-form'
import { SingleValue } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { handleError, InputWrapper, InputClassNames } from 'core'

import { UpsertMemberPayload, isOrgPowerUser, Member } from 'provider'
import { createMember } from '../../actions/members'
import { useMembers } from '../../hooks'
import { DropdownOption } from '../../models'
import { mapToOption, sortOptionByLabel } from '../../utils'
import MemberEditModal, { MemberForm } from '../MemberEditModal'

import './styles.scss'
import { Button } from '@chakra-ui/react'
import { KeyOfStrings } from '../SectionUsersDropdown'

const mapUserToOption = (member: Member, idField: KeyOfStrings<Member>) =>
  mapToOption(member, 'fullName', idField)

const mapAndSortUsers = (members: Member[], idField: KeyOfStrings<Member>) =>
  members.map((member) => mapUserToOption(member, idField)).sort(sortOptionByLabel)

export type OrgUsersDropdownProps = {
  placeholder?: string
  label?: string
  instructions?: string
  className?: string
  isCreateMemberOnly?: boolean
  isReadonly?: boolean
  filterByPowerUsers?: boolean
  defaultValue?: DropdownOption
  dataCy?: string
  formUpdates?: Dispatch<SetStateAction<boolean>>
  idField?: KeyOfStrings<Member>
}

type Props<T, U extends Path<T>> = UseControllerProps<T, U> & OrgUsersDropdownProps

function OrgUsersDropdown<T, U extends Path<T>>({
  placeholder,
  label,
  instructions,
  className = '',
  isCreateMemberOnly = false,
  isReadonly = false,
  filterByPowerUsers = false,
  defaultValue,
  dataCy,
  formUpdates,
  // send 'id' to the `idField` if you need to map the membershipId to the dropdown option
  idField = 'userId',
  ...controllerProps
}: Props<T, U>) {
  const { members, isLoadingMembers, addMember, roles, isLoadingRoles } = useMembers()
  const [selectedOption, setSelectedOption] = useState<DropdownOption | undefined>(defaultValue)
  const [options, setOptions] = useState<DropdownOption[]>([])
  const [newMember, setNewMember] = useState<MemberForm>()
  const [isPosting, setIsPosting] = useState(false)

  const {
    field,
    fieldState: { error },
  } = useController(controllerProps)

  useEffect(() => {
    if (members) {
      const availableUsers = filterByPowerUsers ? members.filter(isOrgPowerUser) : members
      setOptions(mapAndSortUsers(availableUsers, idField))
    }
  }, [members, filterByPowerUsers])

  const onCreateOption = (userFullName: string) => {
    const splitName = userFullName.split(' ')
    const firstName = splitName.shift()
    const lastName = splitName.join(' ')

    setNewMember({
      firstName: firstName ?? ' ',
      lastName: lastName,
      email: '',
    })
  }

  const onChange = (newValue: SingleValue<DropdownOption>) => {
    const value = newValue as DropdownOption
    setSelectedOption(value)
    field.onChange(value)
    if (formUpdates) {
      formUpdates(true)
    }
  }

  const onSaveMember = async (member: UpsertMemberPayload) => {
    setIsPosting(true)
    try {
      const createdMember = await createMember(member).request
      addMember(createdMember)
      const newOption = mapUserToOption(createdMember, idField)
      setOptions((oldOptions) => [...oldOptions, newOption])
      field.onChange(newOption)
      setSelectedOption(newOption)
      if (formUpdates) {
        formUpdates(true)
      }
    } catch (err) {
      handleError()(err)
    }
    setNewMember(undefined)
    setIsPosting(false)
  }

  const closeModal = () => {
    setNewMember(undefined)
  }

  const errorMessage = error?.message
  const conditionalClassNames = classNames({
    [InputClassNames.Filled]: !!selectedOption && !errorMessage,
    [InputClassNames.Error]: !!errorMessage,
    'inputField__input--disabled': isReadonly,
  })

  return (
    <>
      <InputWrapper
        errorMessage={errorMessage}
        className={className}
        label={label}
        instructions={instructions}
        hasDefaultWidthRange={false}
        dataCy={dataCy || 'orgUsersDropdown'}>
        <CreatableSelect
          className={`${InputClassNames.Default} ${conditionalClassNames} orgUsersDropdown`}
          classNamePrefix="selectDropdown"
          styles={{
            control: (provided) => ({
              ...provided,
              border: 'none',
            }),
            menu: (provided) => ({
              ...provided,
              zIndex: '1002',
            }),
            option: (provided, { isSelected }) => ({
              ...provided,
              ':hover': {
                ...provided[':hover'],
                fontWeight: 600,
              },
              fontWeight: isSelected ? 600 : 400,
            }),
            placeholder: (provided) => ({
              ...provided,
              color: '#aaa',
            }),
          }}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: '#f3f0fe',
              primary: '#3f2c7f',
            },
          })}
          isDisabled={isPosting || isReadonly}
          isLoading={isLoadingMembers}
          defaultValue={defaultValue}
          options={options}
          value={selectedOption}
          placeholder={placeholder}
          onCreateOption={onCreateOption}
          isValidNewOption={() => true}
          formatCreateLabel={(inputValue) => (
            <Button children={`Create ${inputValue}`} colorScheme="brand" variant="brandPrimary" />
          )}
          onChange={onChange}
          ref={field.ref}
          onBlur={field.onBlur}
        />
      </InputWrapper>
      <MemberEditModal
        editingMember={newMember}
        saveMember={onSaveMember}
        roles={isCreateMemberOnly ? [] : roles}
        isLoadingRoles={isLoadingRoles}
        onClose={closeModal}
      />
    </>
  )
}

export default OrgUsersDropdown
