import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  VisibilityState,
  FilterFn,
  RowData,
  Header,
  Table,
  FilterFnOption,
  Row as RowType,
} from '@tanstack/react-table'

import * as Sentry from '@sentry/react'
import { LoadingIndicator } from 'core'
import { CSSProperties, useCallback, useState, useEffect } from 'react'
import { Color } from '../../constants'
import './styles.scss'
import { Column, Row } from './types'
import { faSortDown, faSortUp } from '@fortawesome/pro-duotone-svg-icons'
import { faSort } from '@fortawesome/pro-regular-svg-icons'

export type FilterTableProps<DataType> = {
  schema: Column<DataType>[]
  data: DataType[]
  title?: string
  className?: string
  isLoading: boolean
  columnVisibilityState?: VisibilityState
  emptyTableMessage?: string
  /**
   * These three props are ONLY for when setting up client-side filtering.
   */
  clientSideFilterFns?: { [name: string]: FilterFn<RowData> }
  clientSideGlobalFilterFn?: FilterFnOption<DataType>
  clientSideGlobalFilterValue?: string
  /**
   * Function to be called when one (or more) items have been selected/changed.
   *
   * the current selected items are passed as an array of items
   *
   */
  onRowSelection?: (row: RowType<DataType>[]) => void
  onRowClicked?: (row: any) => void
}

export const FilterTable = <DataType extends Row>({
  schema,
  data,
  title,
  className,
  isLoading,
  columnVisibilityState = {},
  emptyTableMessage = 'No results to show. Please refine your search or add new entries.',
  clientSideFilterFns = {},
  clientSideGlobalFilterFn = undefined,
  clientSideGlobalFilterValue = '',
  onRowSelection,
  onRowClicked = () => {},
}: FilterTableProps<DataType>) => {
  const [rowSelection, setRowSelection] = useState({})

  const table = useReactTable({
    data,
    columns: schema,
    state: {
      rowSelection,
      columnVisibility: columnVisibilityState,
      globalFilter: clientSideGlobalFilterValue,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    onRowSelectionChange: setRowSelection,
    initialState: {
      pagination: {
        pageSize: 50,
      },
      columnVisibility: columnVisibilityState,
    },
    debugTable: false,
    filterFns: clientSideFilterFns,
    globalFilterFn: clientSideGlobalFilterFn,
  })

  useEffect(() => {
    if (onRowSelection && typeof onRowSelection === 'function') {
      onRowSelection(table.getSelectedRowModel().flatRows)
    }
  }, [onRowSelection, rowSelection, table])

  const renderSortDir = useCallback((header: Header<DataType, unknown>) => {
    const sortDir = header.column.getIsSorted()
    const isSortable = header.column.getCanSort()

    const sortIcons = {
      asc: <FontAwesomeIcon icon={faSortUp} style={{ color: Color.DarkRed } as CSSProperties} />,
      desc: <FontAwesomeIcon icon={faSortDown} style={{ color: Color.DarkRed } as CSSProperties} />,
    }

    if (isSortable) {
      if (sortDir) {
        return sortIcons[sortDir]
      } else {
        return (
          <FontAwesomeIcon icon={faSort} style={{ color: Color.DarkPurple } as CSSProperties} />
        )
      }
    } else {
      return null
    }
  }, [])

  if (isLoading) {
    return <LoadingIndicator fullWidth={true} />
  }

  const getPageIndex = (table: Table<DataType>) => {
    const pageIndex = table.getState().pagination.pageIndex
    const pageCount = table.getPageCount()
    if (pageCount > 0) {
      return (
        <>
          <div>Page</div>
          <div>
            <strong>
              {pageIndex + 1} of {pageCount}
            </strong>
          </div>
        </>
      )
    }

    return <></>
  }

  return (
    <div className="filterTable__wrapper">
      <Sentry.ErrorBoundary fallback={<>Something went wrong while rendering the table.</>}>
        <div className="filterTable">
          <table>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <th key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder ? null : (
                          <>
                            <div
                              {...{
                                className: header.column.getCanSort()
                                  ? 'cursor-pointer select-none'
                                  : '',
                                onClick: header.column.getToggleSortingHandler(),
                                align: (header.column.columnDef.meta as any)?.align,
                              }}>
                              {flexRender(header.column.columnDef.header, header.getContext())}
                              {renderSortDir(header)}
                            </div>
                          </>
                        )}
                      </th>
                    )
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.length > 0 ? (
                table.getRowModel().rows.map((row) => (
                  <tr key={row.id} onClick={() => onRowClicked(row.original)}>
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id} align={(cell.column.columnDef.meta as any)?.align}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="filterTable--emptyTable" colSpan={100}>
                    {emptyTableMessage}
                  </td>
                  {/* Adjust the colSpan according to your table's columns */}
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Sentry.ErrorBoundary>
      <div className="controls">
        <button
          className="controls__btn"
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}>
          {'<<'}
        </button>
        <button
          className="controls__btn"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}>
          {'<'}
        </button>
        <div className="controls__info">{getPageIndex(table)}</div>
        <button
          className="controls__btn"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}>
          {'>'}
        </button>
        <button
          className="controls__btn"
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}>
          {'>>'}
        </button>
      </div>
    </div>
  )
}

FilterTable.displayName = 'DataTable'
