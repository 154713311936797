import { DropdownOption } from '../models'

export type KeyOfStrings<T> = {
  [K in keyof T]: T[K] extends string ? K : never
}[keyof T]

const mapToOption = <
  T extends Record<U, string> & Record<V, unknown>,
  U extends KeyOfStrings<T>,
  V extends string | number | symbol,
>(
  entity: T,
  labelFieldName: U,
  valueFieldName: U,
): DropdownOption => ({
  label: entity[labelFieldName] ?? '',
  value: entity[valueFieldName] ?? '',
})

export default mapToOption
