import { useCallback, useState } from 'react'

export const useLoadingStates = (initialState: Record<string, boolean> = {}) => {
  const [loadingStates, setLoadingStates] = useState<Record<string, boolean>>(initialState)

  const isKeyLoading = useCallback(
    (key: string) => key in loadingStates && loadingStates[key],
    [loadingStates],
  )

  const toggleLoadingStateForKey = useCallback(
    (key: string) =>
      setLoadingStates((states) => {
        return {
          ...states,
          [key]: key in states ? !states[key] : true,
        }
      }),
    [],
  )

  return { isKeyLoading, toggleLoadingStateForKey }
}
