import { useMemo, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { handleError } from 'core'

import { Position, api, TagDetail, useUserContext } from 'provider'
import { createPositions } from '../../../actions/positions'

import { onSubmitForm } from '../../../utils'
import CommonFormInputs, { PositionForm } from '../CommonFormInputs'

import './styles.scss'
import { useNavigate } from 'react-router-dom'
import { Button } from '@chakra-ui/react'
import { useDocumentTitle } from '../../../hooks'

type CreatePositionFormProps = {
  setPosition: (value: Position) => void
}

const CreatePositionForm = ({ setPosition }: CreatePositionFormProps) => {
  useDocumentTitle('Create Job')
  const {
    control,
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm<PositionForm>({
    defaultValues: {
      title: '',
      referenceNumber: '',
    },
  })

  const { userContext } = useUserContext()
  const [isLoading, setIsLoading] = useState(true)
  const [isPosting, setIsPosting] = useState(false)
  const [tagOptions, setTagOptions] = useState<TagDetail[]>()
  const [formHasUpdates, setFormHasUpdates] = useState(false)
  const [isMounted, setIsMounted] = useState(true)

  useEffect(() => {
    setIsMounted(true)

    return () => {
      setIsMounted(false)
    }
  }, [])

  useEffect(() => {
    let isMounted = true // Flag to track mount status
    const tagList = api.getTags()
    tagList.request.then((data) => {
      if (isMounted) {
        // Only update state if component is still mounted
        setTagOptions(data)
        setIsLoading(false)
      }
    })

    return () => {
      isMounted = false // Set flag to false when component unmounts
    }
  }, [])

  const navigate = useNavigate()
  const assessments = useMemo(() => userContext?.tenant?.assessments ?? [], [userContext])

  const onSubmit = async ({
    assessment,
    assignedRecruiter,
    title,
    referenceNumber,
  }: // tags,
  PositionForm) => {
    if (!isMounted) return
    setIsPosting(true)
    try {
      const assessmentId = assessments.length === 1 ? assessments[0].id : assessment.value
      const newPosition = await createPositions({
        assessment: { id: assessmentId },
        positionTitle: title,
        referenceNumber: referenceNumber,
        recruiter: { id: assignedRecruiter.value },
      }).request

      if (!isMounted) return

      // await api.postTags({
      //   appLabel: 'organizations',
      //   model: 'jobrequisition',
      //   objectId: newPosition.id,
      //   mode: 'reconcile',
      //   tags: tags.map((tag) => ({ title: tag.label })),
      // }).request

      setPosition(newPosition)
      // setIsPosting(false)
      navigate(`/positions/${newPosition.id}`, { replace: true })
    } catch (err) {
      if (!isMounted) return
      handleError(setIsPosting, { errorMessage: 'Could not create position' })(err)
    }
  }

  return (
    !isLoading &&
    (assessments.length > 0 ? (
      <form onSubmit={onSubmitForm(handleSubmit(onSubmit))} className="createPosition__form">
        <CommonFormInputs
          isCreate={true}
          control={control}
          register={register}
          watch={watch}
          errors={errors}
          tags={tagOptions}
          formUpdates={setFormHasUpdates}
        />
        <Button
          className="createPosition__saveBtn"
          type="submit"
          colorScheme="brand"
          variant="brandPrimary"
          isLoading={isPosting}
          isFullWidth={false}>
          Save Job
        </Button>
      </form>
    ) : (
      <div>
        There are no assessments active on your account. Please contact{' '}
        <a href="mailto:support@workzinga.com">support@workzinga.com</a>
      </div>
    ))
  )
}

export default CreatePositionForm
