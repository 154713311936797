import { ColumnDef } from '@tanstack/react-table'
import * as Sentry from '@sentry/react'
import {
  PageWrapper,
  LoadingIndicator,
  FilterTable,
  ActionDropdown,
  Color,
  TagGroup,
  DebouncedInput,
  handleError,
  Toast,
  AlignType,
  formatDate,
} from 'core'
import {
  CancellableRequest,
  Group,
  GroupMember,
  GroupProfileDetail,
  WorkflowStatusType,
} from 'provider'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { api } from 'provider'
import { useDocumentTitle } from '../../../hooks'
import GroupManagePanel from '../components/GroupManagePanel'
import { Button, Tooltip, useDisclosure, UseDisclosureReturn } from '@chakra-ui/react'
import { GroupManageMemberModal } from '../components/GroupManageMembersModal'
import { CreateGroupProfileModal } from '../components/CreateGroupProfileModal'

import './styles.scss'
import { faTrashCan, faPencil, faBoxArchive } from '@fortawesome/pro-solid-svg-icons'
import { faFileChartColumn, faSquareEllipsis } from '@fortawesome/pro-duotone-svg-icons'
import { RemoveMemberModal } from '../components/RemoveMemberModal'
import { MemberKind } from '../components/GroupManageMembersModal/types'
import UpdateGroupProfileTitle from '../components/UpdateGroupProfileTitle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getGroupProfiles } from '../../../actions/profile'

enum GROUP_PAGE {
  edit = 'group-members',
  admin = 'group-admins',
  profiles = 'group-scores',
}

export const GroupEditPage = ({ path }: { path: string }) => {
  useDocumentTitle('Edit Group')
  const { groupId } = useParams()

  const [group, setGroup] = useState<Group>()
  const [groupProfiles, setGroupProfiles] = useState<GroupProfileDetail[]>()
  const [canViewAdmin, setCanViewAdmin] = useState(false)
  const [cacheDateTime, setCacheDateTime] = useState(new Date())
  const [loading, setLoading] = useState(true)
  const [tab, setTab] = useState<string>(GROUP_PAGE[path as keyof typeof GROUP_PAGE])

  const [groupMembers, setGroupMembers] = useState<GroupMember[]>([])
  const [searchParams, setSearchParams] = useState<string>('')
  const [isLoadingData, setIsLoadingData] = useState(true)

  const [tableSchema, setTableSchema] = useState<ColumnDef<GroupMember | GroupProfileDetail>[]>([])
  const [tableData, setTableData] = useState<GroupMember[] | GroupProfileDetail[]>([])

  const removeMemberModalDisclosure = useDisclosure()
  const removeAdminModalDisclosure = useDisclosure()
  const manageGroupModal = useDisclosure()
  const manageGroupAdminModal = useDisclosure()
  const manageCreateGroupProfileModal = useDisclosure()
  const [memberToRemove, setMemberToRemove] = useState<GroupMember | undefined>()
  const [showTitleModal, setShowTitleModal] = useState(false)
  const [editGroupProfile, setEditGroupProfile] = useState({ id: '', title: '' })
  const isMounted = useRef(true)

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  const navigate = useNavigate()

  const tags = useMemo(
    () => [
      {
        text: 'Group Members',
        active: tab === 'group-members',
        onActive: () => navigate(`/groups/${groupId}`),
      },
      {
        text: 'Group Admins',
        active: tab === 'group-admins',
        onActive: () => navigate(`/groups/${groupId}/admin`),
      },
      {
        text: 'Group Profiles',
        active: tab === 'group-scores',
        onActive: () => navigate(`/groups/${groupId}/profiles`),
      },
    ],
    [groupId, tab, navigate],
  )

  const memberParams = useMemo(() => {
    return {
      pager: 'none',
      kind: path === 'admin' ? 'admin' : ('member' as 'member' | 'admin'),
      searchQuery: searchParams,
    }
  }, [searchParams, path])

  const profileParams = useMemo(() => {
    return {
      pager: 'none',
      search: searchParams,
    }
  }, [searchParams])

  useEffect(() => {
    let fetchGroupProfiles: CancellableRequest<GroupProfileDetail[]> | undefined

    if (groupId && path.includes('profiles')) {
      setLoading(true)

      try {
        fetchGroupProfiles = getGroupProfiles({ group: groupId, ...profileParams })
        fetchGroupProfiles.request
          .then((response) => {
            if (isMounted.current) {
              setGroupProfiles(response)
              setTableData(response)
            }
          })
          .catch((error) => {
            if (!isMounted.current) return
            handleError(setIsLoadingData, {
              errorMessage: 'Could not retrieve group profiles. An error occurred.',
            })(error)
          })
      } finally {
        if (isMounted.current) {
          setLoading(false)
        }
      }
    }
    return () => {
      fetchGroupProfiles?.cancelRequest()
    }
  }, [groupId, cacheDateTime, profileParams, path])

  useEffect(() => {
    let fetchGroup: CancellableRequest<Group> | undefined
    if (groupId) {
      setLoading(true)
      fetchGroup = api.getGroup(groupId)
      fetchGroup.request
        .then((response) => {
          if (isMounted.current) {
            setGroup(response)
            setCanViewAdmin(response.isGroupAdmin)
          }
        })
        .catch((error) => {
          if (!isMounted.current) return
          handleError(setIsLoadingData, {
            errorMessage: 'Could not retrieve group. An error occurred.',
          })(error)
        })
        .finally(() => {
          if (isMounted.current) {
            setLoading(false)
          }
        })
    }
    return () => {
      fetchGroup?.cancelRequest()
    }
  }, [groupId, cacheDateTime])

  useEffect(() => {
    setIsLoadingData(true)
    const resolve = api.searchGroupUsers({ groupId: groupId as string, ...memberParams })

    if (['edit', 'admin'].some((substring) => path.includes(substring))) {
      resolve.request
        .then((response) => {
          if (isMounted.current) {
            setIsLoadingData(false)
            response.sort((a, b) => {
              if (a.userLastName < b.userLastName) {
                return -1
              }
              if (a.userLastName > b.userLastName) {
                return 1
              }
              return 0
            })
            setGroupMembers(response)
            setTableData(response)
          }
        })
        .catch((error) => {
          if (!isMounted.current) return
          handleError(setIsLoadingData, {
            errorMessage: 'Could not retrieve users. An error occurred.',
          })(error)
        })
    }

    return () => {
      resolve?.cancelRequest()
    }
  }, [groupId, memberParams, cacheDateTime, path])

  const bustGroupCache = useCallback(() => {
    setCacheDateTime(new Date())
  }, [])

  const onGroupProfileId = useCallback(
    (data: GroupProfileDetail) => {
      setEditGroupProfile({ id: data.id, title: data.title })
      setShowTitleModal(true)
      bustGroupCache()
    },
    [bustGroupCache],
  )

  const archiveProfile = useCallback(
    async (row: GroupProfileDetail) => {
      if (!groupId) return
      try {
        await api.archiveGroupProfile(row.id).request

        if (!isMounted.current) return
      } catch (e: Error | any) {
        if (!isMounted.current) return
        handleError(e?.message)(e)
      }

      bustGroupCache()
    },
    [bustGroupCache, groupId],
  )

  const memberColumns = useMemo<ColumnDef<GroupMember>[]>(
    () => [
      {
        header: 'First Name',
        accessorKey: 'userFirstName',
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: 'Last Name',
        accessorKey: 'userLastName',
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        id: 'actions',
        header: '',
        enableSorting: false,
        accessorFn: () => ({}),
        cell: ({ row }) => {
          if (canViewAdmin) {
            return (
              <ActionDropdown
                actions={[
                  {
                    label: `Remove ${path === 'edit' ? 'Member' : 'Admin'}`,
                    icon: faTrashCan,
                    iconSize: 'xl',
                    iconColor: Color.Red,
                    labelColor: Color.Red,
                    onClick: () => {
                      if (path === 'admin') {
                        setMemberToRemove(row.original)
                        removeAdminModalDisclosure.onOpen()
                      } else {
                        setMemberToRemove(row.original)
                        removeMemberModalDisclosure.onOpen()
                      }
                    },
                  },
                ]}
                alignButton={'right'}
                variant={'actionGhost'}
                icon={faSquareEllipsis}
                iconColor={'#3F2C7F'}
                iconSize={'2xl'}
              />
            )
          }
          return <></>
        },
      },
    ],
    [removeMemberModalDisclosure, removeAdminModalDisclosure, canViewAdmin, path],
  )

  const scoreColumns = useMemo<ColumnDef<GroupProfileDetail>[]>(
    () => [
      {
        header: 'Reports',
        accessorFn: () => ({}),
        enableSorting: false,
        meta: { align: AlignType.left },
        cell: ({ row }) => {
          const isDraft = row.original.currentStatus.code === WorkflowStatusType.Draft
          return (
            <div className="report-container">
              {!isDraft && (
                <div className="report-link">
                  <Tooltip
                    hasArrow
                    bg="brand.500"
                    color="white"
                    placement="right"
                    label={'Profile'}>
                    <Link to={`/group-profiles/${row.original.id}/results`}>
                      <FontAwesomeIcon icon={faFileChartColumn} size="2x" color="#3f2c7f" />
                    </Link>
                  </Tooltip>
                </div>
              )}
            </div>
          )
        },
      },
      {
        header: 'Profile',
        accessorKey: 'title',
        cell: (info) =>
          info.row.original.sampleSize ? (
            <Link to={`/scores/${info.row.original.id}`}>{info.getValue() as string}</Link>
          ) : (
            info.getValue()
          ),
      },
      {
        header: 'Assessment Title',
        accessorKey: 'assessment.title',
        cell: (info) => info.getValue(),
      },
      {
        header: 'Section Title',
        accessorKey: 'section.organizationText',
        cell: (info) => info.getValue(),
      },
      {
        header: 'Sample Size',
        accessorKey: 'sampleSize',
        meta: { align: AlignType.center },
        cell: (info) => info.getValue(),
      },
      {
        header: 'Status',
        accessorKey: 'currentStatus.title',
        cell: (info) => info.getValue(),
      },
      {
        header: 'Status Date',
        accessorKey: 'statusAt',
        meta: {
          align: AlignType.center,
        },
        cell: (info) => formatDate(info.getValue() as string),
      },
      {
        header: 'Creator',
        accessorKey: 'createdByName',
        cell: (info) => info.getValue(),
      },
      {
        id: 'id',
        header: '',
        accessorFn: () => ({}),
        enableSorting: false,
        cell: ({ row }) => {
          if (canViewAdmin) {
            return (
              <ActionDropdown
                actions={[
                  {
                    label: 'Edit Profile Name',
                    icon: faPencil,
                    iconSize: 'xl',
                    iconColor: Color.Purple,
                    labelColor: Color.Purple,
                    onClick: () => {
                      onGroupProfileId(row.original)
                    },
                  },
                  {
                    label: 'Archive Profile',
                    icon: faBoxArchive,
                    iconSize: 'xl',
                    iconColor: Color.Red,
                    labelColor: Color.Red,
                    onClick: () => {
                      archiveProfile(row.original)
                    },
                  },
                ]}
                alignButton={'right'}
                variant={'actionGhost'}
                icon={faSquareEllipsis}
                iconColor={'#3F2C7F'}
                iconSize={'2xl'}
              />
            )
          }
          return <></>
        },
      },
    ],
    [canViewAdmin, archiveProfile, onGroupProfileId],
  )

  useEffect(() => {
    /*
     * This exposes an issue in TanStack when using a wrapper component.
     * See open discussion on GitHub for more details:
     * https://github.com/TanStack/table/issues/4382
     */
    if (path === 'profiles') {
      setTableSchema(scoreColumns)
    } else {
      setTableSchema(memberColumns)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, canViewAdmin])

  const addMembers = useCallback(
    async (members: string[]) => {
      if (!groupId) return
      try {
        await api.addGroupMembers(groupId, members).request
      } catch (e: Error | any) {
        handleError(e?.message)(e)
      }

      bustGroupCache()
    },
    [bustGroupCache, groupId],
  )

  const removeMembers = useCallback(
    async (members: string[]) => {
      if (!groupId) return
      try {
        await api.archiveGroupMembers(groupId, members).request
      } catch (e: Error | any) {
        handleError(e?.message)(e)
      }
      bustGroupCache()
    },
    [bustGroupCache, groupId],
  )

  const addAdminMembers = useCallback(
    async (members: string[]) => {
      if (!groupId) return
      try {
        const admins = members
        await api.addGroupAdmins(groupId, admins).request
      } catch (e: Error | any) {
        handleError(e?.message)(e)
      }

      bustGroupCache()
    },
    [bustGroupCache, groupId],
  )

  const removeAdminMembers = useCallback(
    async (members: string[]) => {
      if (!groupId) return
      try {
        const admins = members
        await api.archiveGroupAdmins(groupId, admins).request
      } catch (e: Error | any) {
        handleError(e?.message)(e)
      }
      bustGroupCache()
    },
    [bustGroupCache, groupId],
  )

  // const [selectedRows, setSelectedRows] = useState<Row<Group>[]>([])

  const renderLoadingIndicator = useCallback(() => {
    if (loading) {
      return <LoadingIndicator fullWidth={true} fullHeight={true} />
    }
    return null
  }, [loading])

  const onCloseCreateGroup = () => {
    manageCreateGroupProfileModal.onClose()
    bustGroupCache()
  }

  const onSubmitCreateGroup = (id: string) => {
    if (id) {
      navigate(`/scores/${id}`)
    } else {
      // TODO: Maybe an error message?
      manageCreateGroupProfileModal.onClose()
      bustGroupCache()
    }
  }

  const checkGroupMemberCount = (modal: UseDisclosureReturn) => {
    if (group && group.memberCount >= 2) {
      modal.onOpen()
    } else {
      Toast.error('To create a new profile, there must be 2 or more active members in the group.')
    }
  }

  const renderContent = useCallback(() => {
    if (!groupId) {
      return <div>Group ID not specified</div>
    }

    if (groupId && group && groupMembers) {
      return (
        <>
          {renderLoadingIndicator()}
          <RemoveMemberModal
            isOpen={removeMemberModalDisclosure.isOpen}
            onClose={removeMemberModalDisclosure.onClose}
            onSubmit={async (memberId: string) => {
              await removeMembers([memberId])
              setMemberToRemove(undefined)
              setTab('group-members')
              removeMemberModalDisclosure.onClose()
            }}
            member={memberToRemove}
            kind={MemberKind.Member}
          />
          <RemoveMemberModal
            isOpen={removeAdminModalDisclosure.isOpen}
            onClose={removeAdminModalDisclosure.onClose}
            onSubmit={async (memberId: string) => {
              await removeAdminMembers([memberId])
              setMemberToRemove(undefined)
              setTab('group-admins')
              removeAdminModalDisclosure.onClose()
            }}
            member={memberToRemove}
            kind={MemberKind.Admin}
          />
          <UpdateGroupProfileTitle
            showModal={showTitleModal}
            onClose={() => {
              setShowTitleModal(false)
              bustGroupCache()
            }}
            onSave={() => {
              setShowTitleModal(false)
              bustGroupCache()
            }}
            editGroupProfile={editGroupProfile}
          />
          <Sentry.ErrorBoundary
            fallback={<>Something went wrong... If this issue persists please contact support.</>}>
            <div className="content">
              <GroupManagePanel
                group={group}
                isAdmin={canViewAdmin}
                onEdit={(updatedGroup) => setGroup(updatedGroup)}
              />
              {/* Group Data:
              <pre>{JSON.stringify(group, null, 2)}</pre> */}
              <br />
              <div className="add-button">
                <Button
                  colorScheme="brand"
                  variant="brandPrimary"
                  size="md"
                  style={{ marginLeft: '1rem' }}
                  onClick={() => checkGroupMemberCount(manageCreateGroupProfileModal)}>
                  Create Group Profile
                </Button>
                {canViewAdmin === true && (
                  <>
                    <Button
                      colorScheme="brand"
                      variant="brandPrimary"
                      size="md"
                      style={{ marginLeft: '1rem' }}
                      onClick={manageGroupModal.onOpen}>
                      Manage Members
                    </Button>
                    <Button
                      colorScheme="brand"
                      variant="brandPrimary"
                      size="md"
                      style={{ marginLeft: '1rem' }}
                      onClick={manageGroupAdminModal.onOpen}>
                      Manage Admins
                    </Button>
                  </>
                )}
              </div>
              <br />
              <div className="tags">
                <TagGroup tags={tags} isManagedByActiveTags={true} label="View" />
              </div>
              <div className="filterInput">
                <DebouncedInput
                  value={''}
                  onChange={(value) => setSearchParams(String(value))}
                  className="inputField__input fa-font"
                  placeholder="Find..."
                />
              </div>
              {tableData && tableSchema && (
                <FilterTable
                  key={tableSchema}
                  schema={tableSchema}
                  data={tableData}
                  isLoadingData={isLoadingData}
                />
              )}
            </div>

            <div className="modals">
              <CreateGroupProfileModal
                groupId={groupId}
                isOpen={manageCreateGroupProfileModal.isOpen}
                onClose={onCloseCreateGroup}
                onSubmit={onSubmitCreateGroup}
              />
              <GroupManageMemberModal
                groupId={groupId}
                groupMembers={group.currentMembers}
                isOpen={manageGroupModal.isOpen}
                onClose={manageGroupModal.onClose}
                onSubmit={() => {
                  manageGroupModal.onClose()
                  navigate(`/groups/${groupId}`)
                }}
                onMemberAdd={addMembers}
                onMemberRemove={removeMembers}
              />
              <GroupManageMemberModal
                groupId={groupId}
                groupMembers={group.currentAdmins}
                kind={MemberKind.Admin}
                isOpen={manageGroupAdminModal.isOpen}
                onClose={manageGroupAdminModal.onClose}
                onSubmit={() => {
                  manageGroupAdminModal.onClose()
                  navigate(`/groups/${groupId}/admin`)
                }}
                onMemberAdd={addAdminMembers}
                onMemberRemove={removeAdminMembers}
              />
            </div>
          </Sentry.ErrorBoundary>
        </>
      )
    } else if (groupId && !group) {
      return (
        <>
          <div>Loading group...</div>
          {renderLoadingIndicator()}
        </>
      )
    } else {
      return <div>Group not found</div>
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    addMembers,
    bustGroupCache,
    memberColumns,
    groupProfiles,
    group,
    groupId,
    manageGroupModal.isOpen,
    manageGroupModal.onClose,
    manageGroupModal.onOpen,
    memberToRemove,
    removeMemberModalDisclosure,
    removeMembers,
    renderLoadingIndicator,
    tab,
    path,
    tags,
  ])

  return <PageWrapper extraClassNames="groupEditPage">{renderContent()}</PageWrapper>
}
