import { api, CreateInvitation, GetInvitations } from 'provider'

export const createInvitation = (payload: CreateInvitation) => {
  if ('email' in payload.user) {
    return api.createInvitation({
      ...payload,
      user: { ...payload.user, email: payload.user.email.toLowerCase() },
    })
  } else {
    return api.createInvitation(payload)
  }
}

export const getInvitations = (props: GetInvitations = {}) => api.getInvitations(props)

export const getInvitation = (invitationId: string) => api.getInvitation(invitationId)

export const cancelInvitation = (invitationId: string) => api.cancelInvitation(invitationId)

export const getInvitationWelcome = (invitationId: string) => api.getInvitationWelcome(invitationId)

export const getInvitationComplete = (invitationId: string) =>
  api.getInvitationComplete(invitationId)

export const getInvitationResults = (invitationId: string) => api.getInvitationResults(invitationId)
export const getGroupProfileResults = (groupProfileId: string) =>
  api.getGroupProfileResults(groupProfileId)

export const getInvitationGuide = (invitationId: string) => api.getInvitationGuide(invitationId)
