import { InvitationKind } from './Invitation'
import { ResultCategory } from './ResultCategory'
import { AudienceType } from './User'

export enum SampleTier {
  neutral = 'neutral',
  high = 'high',
  low = 'low',
}

export enum ResultType {
  OrganizationMatch = 'organization_match',
  CandidateOnly = 'candidate_only',
  CandidateMatch = 'candidate_match',
  Standard = 'standard',
  Empty = '',
}

export enum SampleType {
  CultureProfile = 'CULTURE_PROFILE',
  SelfCultureProfile = 'SELF_CULTURE_PROFILE',
  CompanyValues = 'COMPANY_VALUES',
  LeadershipPreferences = 'LEADERSHIP_PREFERENCES',
  JobPersonality = 'JOB_PERSONALITY',
}

export type Result = {
  type: ResultType | string
  invitationAudience: AudienceType | string
  invitationKind: InvitationKind | string
  organizationName?: string
  candidateName: string
  completedOn: string
  logoUrl: string
  positionTitle?: string
  reportTitle: string
  overallMatchPercent?: number
  overviewDescription?: string
  audience: AudienceType
  categories: ResultCategory[]
}
