import { getCookie } from 'provider'

export const logout = () => {
  window.location.href = '/login?autoLogout=true'
}

const checkExpiryCookie = () => {
  const sessionExpiry = getCookie('workzinga-session-expiry')
  if (!sessionExpiry) {
    if (!window.location.pathname.includes('/login')) {
      logout()
    }
    return null
  } else {
    return sessionExpiry
  }
}

const checkSession = () => {
  const sessionExpiry = checkExpiryCookie()
  if (!sessionExpiry) {
    return
  }

  const expirationDate = new Date(sessionExpiry)
  const now = new Date()

  const timeRemaining = expirationDate.getTime() - now.getTime() + 1000
  if (timeRemaining <= 0) {
    logout()
  } else if (timeRemaining <= 2 * 60 * 1000) {
    // 2 minutes warning
    const expirationTimeStr = expirationDate.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    })
    alert(
      `Your session will expire after ${expirationTimeStr}. Please save your work or use the application to continue your session.`,
    )
    checkExpiryCookie()
  }
}

export const startSessionChecker = () => {
  setInterval(checkSession, 60 * 1000) // Check every minute
}
