import { useState, useEffect } from 'react'
import {
  faArrowRightFromBracket,
  faArrowRightToBracket,
  faBuilding,
  faListCheck,
  faBuildingUser,
  faPeopleGroup,
  faPersonWalking,
  faAnglesRight,
  faAnglesLeft,
} from '@fortawesome/pro-solid-svg-icons'
import { faEnvelope, faFileInvoiceDollar } from '@fortawesome/pro-regular-svg-icons'
import { faIdBadge, faCreditCard } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { api, useAuth, useUserContext, UserContext } from 'provider'
import { NavLink, useNavigate } from 'react-router-dom'
import { useSupportModal } from '../../hooks'

import './styles.scss'
import {
  Tooltip,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react'

interface SideNavProps {
  toggleOpen: () => void
}

const SideNav = ({ toggleOpen }: SideNavProps) => {
  // const userGuideLink =
  //   'https://media.workzinga.com/user-guides/Culture%20Fit%20Assessment%20User%20Guide.pdf'
  const navigate = useNavigate()

  const { isAuthenticated } = useAuth()
  const { userContext, isLoadingUserContext } = useUserContext()
  const { supportModal, setShowSupportModal } = useSupportModal()
  const [navState, setNavState] = useState({
    isOrgMember: false,
    isOrgPowerUser: false,
    isCandidateUser: false,
    isBillingAdmin: false,
    isTenantUser: false,
    allowJobAlignment: false,
  })

  const updateNavState = (userContext: UserContext) => {
    setNavState({
      isOrgMember: userContext?.isOrgMember ?? false,
      isOrgPowerUser: userContext?.isOrgPowerUser ?? false,
      isCandidateUser: userContext?.isCandidate ?? false,
      isBillingAdmin: userContext?.isBillingAdmin ?? false,
      isTenantUser: userContext?.isOrgUser ?? false,
      allowJobAlignment: userContext?.allowJobAlignment ?? false,
    })
  }

  const authIcon = isAuthenticated ? faArrowRightFromBracket : faArrowRightToBracket
  const linkText = isAuthenticated ? 'Logout' : 'Login'

  useEffect(() => {
    if (userContext) {
      updateNavState(userContext)
    } else {
      setNavState({
        isOrgMember: false,
        isOrgPowerUser: false,
        isCandidateUser: false,
        isBillingAdmin: false,
        isTenantUser: false,
        allowJobAlignment: false,
      })
    }
  }, [userContext])

  const [isOrgAccordionOpen, setIsOrgAccordionOpen] = useState(-1)
  // useEffect(() => {
  //   let ignore = false
  //   // if (
  //   //   !ignore &&
  //   //   (pathname.includes('invitations/manage/organization') || pathname.includes('sections'))
  //   // ) {
  //   //   setIsOrgAccordionOpen(0)
  //   // } else {
  //   //   setIsOrgAccordionOpen(-1)
  //   // }

  //   return () => {
  //     ignore = true
  //   }
  // }, [pathname])

  const [isCandidateAccordionOpen, setIsCandidateAccordionOpen] = useState(-1)
  // useEffect(() => {
  //   let ignore = false
  //   // if (!ignore && pathname.includes('invitations/manage/candidate')) {
  //   //   setIsCandidateAccordionOpen(0)
  //   // } else {
  //   //   setIsCandidateAccordionOpen(-1)
  //   // }

  //   return () => {
  //     ignore = true
  //   }
  // }, [pathname])

  if (!navState.isTenantUser) {
    return null
  }

  if (isLoadingUserContext) {
    return null
  }

  const onAuthButtonClicked = () => {
    navigate(isAuthenticated ? '/logout' : '/login')
  }

  const activeClassFn = ({ isActive }: { isActive: boolean }) => {
    return isActive ? 'isActive sideNav__item' : 'sideNav__item'
  }

  // send current URL path to stripe.
  // when done with stripe you will be redirected to currentUrl
  const renderBillingPage = () => {
    const currentUrlPath = window.location.href
    api.getBillingRedirect(currentUrlPath).request.then((response) => {
      window.location.replace(response.url)
    })
  }

  return (
    <div className="sideNav">
      <div className="sideNav__item sideNav__item--right" onClick={toggleOpen}>
        <Tooltip label="Collapse Side Navigation" placement="top">
          <FontAwesomeIcon
            className="sideNav__icon sideNav__icon--open"
            icon={faAnglesLeft}
            size="1x"
            fixedWidth={true}
          />
        </Tooltip>
        <Tooltip label="Expand Side Navigation" placement="top">
          <FontAwesomeIcon
            className="sideNav__icon sideNav__icon--closed"
            icon={faAnglesRight}
            size="1x"
            fixedWidth={true}
          />
        </Tooltip>
      </div>
      <div className="sideNav__break sideNav__header">{userContext?.tenant?.title}</div>
      {navState.isOrgMember && (
        <NavLink end={true} to="/sections" className={activeClassFn}>
          <Tooltip label="My Sections" placement="top">
            <FontAwesomeIcon
              className="sideNav__icon"
              icon={faListCheck}
              size="1x"
              fixedWidth={true}
            />
          </Tooltip>
          <span className="sideNav__text">My Sections</span>
        </NavLink>
      )}
      {navState.isOrgPowerUser && (
        <>
          <Accordion
            className="sideNav__accordion"
            allowToggle
            index={isCandidateAccordionOpen}
            defaultIndex={isCandidateAccordionOpen}
            onChange={setIsCandidateAccordionOpen}>
            <AccordionItem className="sideNav__accordion--container" border="none">
              <div className="sideNav__accordion--item">
                <NavLink to="/invitations/manage/candidate/pending" className="sideNav__item">
                  <Tooltip label="Pending Assessments" placement="top">
                    <FontAwesomeIcon
                      className="sideNav__icon"
                      icon={faListCheck}
                      size="1x"
                      fixedWidth={true}
                    />
                  </Tooltip>
                  <span className="sideNav__text">Assessments</span>
                </NavLink>
                <AccordionButton>
                  <AccordionIcon />
                </AccordionButton>
              </div>
              <AccordionPanel pl={5}>
                <NavLink to="/invitations/manage/candidate/pending" className={activeClassFn}>
                  <span className="sideNav__text">Pending</span>
                </NavLink>
                <NavLink to="/invitations/manage/candidate/active" className={activeClassFn}>
                  <span className="sideNav__text">Completed</span>
                </NavLink>
                <NavLink to="/invitations/manage/candidate/last_30_days" className={activeClassFn}>
                  <span className="sideNav__text">Last 30 Days</span>
                </NavLink>
                {navState.allowJobAlignment && (
                  <NavLink
                    to="/invitations/manage/candidate/open_positions"
                    className={activeClassFn}>
                    <span className="sideNav__text">Open Jobs</span>
                  </NavLink>
                )}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
          <div className="sideNav__break sideNav__header">
            Alignment
            {!navState.allowJobAlignment && (
              <Tooltip
                hasArrow
                label="Contact us to upgrade your subscription to include our Alignment Index Feature!"
                placement="right">
                <div className="badge sideNav__badge">UPGRADE</div>
              </Tooltip>
            )}
          </div>
          <NavLink
            to="/positions/manage"
            className={(navData) =>
              `${activeClassFn(navData)} ${navState.allowJobAlignment ? '' : 'requireUpgrade'}`
            }>
            <Tooltip label="Open Jobs" placement="top">
              <FontAwesomeIcon
                className="sideNav__icon"
                icon={faIdBadge}
                size="1x"
                fixedWidth={true}
              />
            </Tooltip>
            <span className="sideNav__text">Jobs</span>
          </NavLink>

          <Accordion
            className="sideNav__accordion"
            allowToggle
            index={isOrgAccordionOpen}
            defaultIndex={isOrgAccordionOpen}
            onChange={setIsOrgAccordionOpen}>
            <AccordionItem
              className="sideNav__accordion--container"
              border="none"
              isDisabled={!navState.allowJobAlignment}>
              <div className="sideNav__accordion--item">
                <NavLink
                  to="/invitations/manage/organization/pending"
                  className={`sideNav__item ${navState.allowJobAlignment ? '' : 'requireUpgrade'}`}>
                  <Tooltip label="Pending Company Sections" placement="top">
                    <FontAwesomeIcon
                      className="sideNav__icon"
                      icon={faBuilding}
                      size="1x"
                      fixedWidth={true}
                    />
                  </Tooltip>
                  <span className="sideNav__text">Company Sections</span>
                </NavLink>
                <AccordionButton>
                  <AccordionIcon />
                </AccordionButton>
              </div>
              <AccordionPanel pl={5}>
                <NavLink
                  to="/invitations/manage/organization/pending"
                  className={(navData) =>
                    `${activeClassFn(navData)} ${
                      navState.allowJobAlignment ? '' : 'requireUpgrade'
                    }`
                  }>
                  <span className="sideNav__text">Pending</span>
                </NavLink>
                <NavLink
                  to="/invitations/manage/organization/active"
                  className={(navData) =>
                    `${activeClassFn(navData)} ${
                      navState.allowJobAlignment ? '' : 'requireUpgrade'
                    }`
                  }>
                  <span className="sideNav__text">Active</span>
                </NavLink>
                <NavLink
                  to="/invitations/manage/organization/inactive"
                  className={(navData) =>
                    `${activeClassFn(navData)} ${
                      navState.allowJobAlignment ? '' : 'requireUpgrade'
                    }`
                  }>
                  <span className="sideNav__text">Inactive</span>
                </NavLink>
                <NavLink
                  to="/invitations/manage/organization/open_positions"
                  className={(navData) =>
                    `${activeClassFn(navData)} ${
                      navState.allowJobAlignment ? '' : 'requireUpgrade'
                    }`
                  }>
                  <span className="sideNav__text">Open Jobs</span>
                </NavLink>
                <NavLink
                  to="/invitations/manage/organization/last_30_days"
                  className={(navData) =>
                    `${activeClassFn(navData)} ${
                      navState.allowJobAlignment ? '' : 'requireUpgrade'
                    }`
                  }>
                  <span className="sideNav__text">Last 30 Days</span>
                </NavLink>

                <NavLink
                  to="/sections"
                  className={(navData) =>
                    `${activeClassFn(navData)} ${
                      navState.allowJobAlignment ? '' : 'requireUpgrade'
                    }`
                  }>
                  <span className="sideNav__text">My Sections</span>
                </NavLink>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
          <NavLink
            end={true}
            to="/groups"
            className={(navData) =>
              `${activeClassFn(navData)} ${navState.allowJobAlignment ? '' : 'requireUpgrade'}`
            }>
            <Tooltip label="User Groups" placement="top">
              <FontAwesomeIcon
                className="sideNav__icon"
                icon={faPeopleGroup}
                size="1x"
                fixedWidth={true}
              />
            </Tooltip>
            <span className="sideNav__text">User Groups</span>
          </NavLink>
        </>
      )}

      <div className="sideNav__item sideNav__item--expand" onClick={toggleOpen}></div>
      {(navState.isOrgPowerUser || navState.isBillingAdmin) && (
        <div className="sideNav__break sideNav__header">Administration</div>
      )}
      {navState.isOrgPowerUser && (
        <NavLink to="/users/manage" className={activeClassFn}>
          <Tooltip label="Manage System Users" placement="top">
            <FontAwesomeIcon
              className="sideNav__icon"
              icon={faBuildingUser}
              size="1x"
              fixedWidth={true}
            />
          </Tooltip>
          <span className="sideNav__text">Users</span>
        </NavLink>
      )}

      {navState.isBillingAdmin && (
        <div
          className="sideNav__item sideNav__item"
          onClick={() => {
            renderBillingPage()
          }}>
          <Tooltip label="Manage Payments" placement="top">
            <FontAwesomeIcon
              className="sideNav__icon"
              icon={faCreditCard}
              size="1x"
              fixedWidth={true}
            />
          </Tooltip>
          <span className="sideNav__text">Payments</span>
        </div>
      )}
      {navState.isBillingAdmin && import.meta.env.VITE_BILLING_URL && (
        <NavLink to={import.meta.env.VITE_BILLING_URL} className={activeClassFn}>
          <Tooltip label="Billing Portal" placement="top">
            <FontAwesomeIcon
              className="sideNav__icon"
              icon={faFileInvoiceDollar}
              size="1x"
              fixedWidth={true}
            />
          </Tooltip>
          <span className="sideNav__text">Billing Portal</span>
        </NavLink>
      )}
      {/* <a
        className="sideNav__item sideNav__item"
        target="_blank"
        rel="noreferrer"
        href={userGuideLink}>
        <Tooltip label="User Guide">
          <FontAwesomeIcon
            className="sideNav__icon"
            icon={faMemoCircleInfo}
            size="1x"
            fixedWidth={true}
          />
        </Tooltip>
        <span className="sideNav__text">User Guide</span>
      </a> */}
      <div className="sideNav__break sideNav__header">Support</div>
      <a className="sideNav__item sideNav__item" href="/walkthrough">
        <Tooltip label="Walkthrough" placement="top">
          <FontAwesomeIcon
            className="sideNav__icon"
            icon={faPersonWalking}
            size="1x"
            fixedWidth={true}
          />
        </Tooltip>
        <span className="sideNav__text">Walkthrough</span>
      </a>
      <div
        className="sideNav__item sideNav__item"
        onClick={() => {
          setShowSupportModal(true)
        }}>
        <Tooltip label="Support" placement="top">
          <FontAwesomeIcon
            className="sideNav__icon"
            icon={faEnvelope}
            size="1x"
            fixedWidth={true}
          />
        </Tooltip>
        <span className="sideNav__text">Contact Us</span>
      </div>
      <div className="sideNav__item sideNav__item--last" onClick={onAuthButtonClicked}>
        <Tooltip label="Logout" placement="top">
          <FontAwesomeIcon className="sideNav__icon" icon={authIcon} size="1x" fixedWidth={true} />
        </Tooltip>
        <span className="sideNav__text">{linkText}</span>
      </div>
      {supportModal}
    </div>
  )
}

export default SideNav
