import { useMemo, useEffect, useState, Dispatch, SetStateAction } from 'react'
import { Control, Controller, FieldErrors, UseFormRegister, UseFormWatch } from 'react-hook-form'
import { InputField, SelectDropdown, CreatableMultiInput } from 'core'
import { Assessment, TagDetail, useUserContext } from 'provider'
import OrgUsersDropdown from '../../../components/OrgUsersDropdown'
import { ComparisonOptionSections } from '../../../components/ComparisonProfileField'
import { DropdownOption } from '../../../models'
import { mapToOption, sortOptionByLabel } from '../../../utils'
import './styles.scss'

const mapAssessments = (assessments: Assessment[]) =>
  assessments.map((assessment) => mapToOption(assessment, 'title', 'id'))

const mapAndSortTags = (tags: TagDetail[]) =>
  tags.map((tag) => mapToOption(tag, 'title', 'title')).sort(sortOptionByLabel)

export type PositionForm = {
  title: string
  referenceNumber: string
  assignedRecruiter: DropdownOption
  assessment: DropdownOption
  sectionUsers: Record<string, ComparisonOptionSections | undefined>
  tags: DropdownOption[]
}

type CommonFormInputsProps = {
  control: Control<PositionForm, object>
  register: UseFormRegister<PositionForm>
  watch: UseFormWatch<PositionForm>
  errors: FieldErrors<PositionForm>
  isReadonly?: boolean
  isInputFieldReadonly?: boolean
  defaultOrgUser?: DropdownOption
  defaultTagOption?: DropdownOption[]
  isCreate: boolean
  tags?: TagDetail[]
  formUpdates: Dispatch<SetStateAction<boolean>>
}

const CommonFormInputs = ({
  control,
  register,
  watch,
  errors,
  isReadonly,
  isInputFieldReadonly,
  defaultOrgUser,
  defaultTagOption,
  isCreate,
  tags,
  formUpdates,
}: CommonFormInputsProps) => {
  const { userContext, isLoadingUserContext } = useUserContext()
  const [selectedTags, setSelectedTags] = useState<DropdownOption[] | undefined>(defaultTagOption)
  const [tagOptions, setTagOptions] = useState<DropdownOption[]>([])
  const assessments = useMemo(() => userContext?.tenant?.assessments ?? [], [userContext?.tenant])
  const assessmentOptions = useMemo(() => mapAssessments(assessments), [assessments])

  useEffect(() => {
    if (tags?.length) {
      const processedTags = mapAndSortTags(tags)
      setTagOptions(processedTags)
    }
    setSelectedTags(defaultTagOption)
  }, [tags, defaultTagOption])

  const defaultAssessment = useMemo(() => {
    if (assessments.length === 1) {
      return mapToOption(assessments[0], 'title', 'id')
    } else {
      return undefined
    }
  }, [assessments])

  return (
    <div className="editPositionForm--common">
      <div className="positionFormInput">
        <InputField
          registerFields={register('title', {
            required: 'Required field',
            onChange: () => formUpdates(true),
          })}
          getValue={() => watch('title')}
          errorMessage={errors.title?.message}
          isReadonly={isReadonly || isInputFieldReadonly}
          instructions="Required. This will be the name used to identify the position that the organization sees and is also referenced in communication to Candidates."
          label="Title"
          hasDefaultMargin={false}
          hasDefaultWidthRange={false}
        />
      </div>
      <div className="positionFormInput">
        <InputField
          registerFields={register('referenceNumber', { onChange: () => formUpdates(true) })}
          getValue={() => watch('referenceNumber')}
          errorMessage={errors.referenceNumber?.message}
          isReadonly={isReadonly}
          label="Reference ID"
          instructions="Optional. Any value you may want to use to cross reference systems or find this position."
          hasDefaultMargin={false}
          hasDefaultWidthRange={false}
        />
      </div>
      <div className="positionFormInput">
        <OrgUsersDropdown
          defaultValue={defaultOrgUser}
          name="assignedRecruiter"
          control={control}
          rules={{ validate: (option) => !!option?.value || 'Required field' }}
          filterByPowerUsers={true}
          isReadonly={isReadonly}
          label="Assigned To"
          placeholder=""
          instructions="Required. Who is the point of contact for this Job?"
          formUpdates={formUpdates}
        />
      </div>

      {!isLoadingUserContext && (
        <div className="positionFormInput">
          <Controller
            name="assessment"
            defaultValue={defaultAssessment}
            render={({ field, fieldState }) => (
              <SelectDropdown
                defaultValue={defaultAssessment ?? undefined}
                placeholder=""
                field={{
                  ...field,
                  onChange: (e) => {
                    field.onChange(e)
                    formUpdates(true)
                  },
                }}
                fieldState={fieldState}
                options={assessmentOptions}
                isDisabled={!isCreate}
                label="Assessment"
                instructions="Required. Which Assessment should be sent?"
                hasDefaultMargin={false}
                hasDefaultWidthRange={false}
              />
            )}
            control={control}
            rules={{ validate: (option) => !!option?.value || 'Required field' }}
          />
        </div>
      )}

      {!isCreate && (
        <div className="positionFormInput">
          <label className="positionFormInput__label"></label>
          <Controller
            name="tags"
            render={({ field, fieldState }) => (
              <CreatableMultiInput
                defaultValue={selectedTags}
                field={{
                  ...field,
                  onChange: (e) => {
                    field.onChange(e)
                    formUpdates(true)
                  },
                }}
                fieldState={fieldState}
                options={tagOptions}
                label="Tags"
                placeholder=""
                instructions="Optional. Enter as many tags as you need to keep organized."
                hasDefaultWidthRange={false}
              />
            )}
            control={control}
          />
        </div>
      )}
    </div>
  )
}

export default CommonFormInputs
