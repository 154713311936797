import React from 'react'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import ContextCardsContainer from './ContextCardsContainer'
import { useAuth, useUserContext } from 'provider'

type SwitchContextModalProps = {
  isOpen: boolean
  onClose: () => void
}

const SwitchContextModal: React.FC<SwitchContextModalProps> = ({
  isOpen,
  onClose,
}): React.ReactElement | null => {
  const { isLoading: isAuthLoading, contextModalTitle } = useAuth()
  const { isLoadingUserContext } = useUserContext()

  if (isAuthLoading || isLoadingUserContext) {
    return null
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={false} size="3xl" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{contextModalTitle}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ContextCardsContainer />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default SwitchContextModal
