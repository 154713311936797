import { useEffect, useState, useMemo, JSX } from 'react'
import { useParams } from 'react-router-dom'
import { formatDate, handleError } from 'core'
import {
  InterviewGuide,
  AudienceType,
  useAuth,
  InvitationKind,
  SampleTier,
  useUserContext,
} from 'provider'
import { getInvitationGuide } from '../../../actions/invitations'
import { isEmpty } from '../../../utils'
import classNames from 'classnames'
import GuideSections from './GuideSections'
import { OrgIntro, CandidateIntro } from './Intros'
import AlignmentChart from '../SharedComponents/AlignmentChart'

import './styles.scss'

type InvitationGuideParamsType = {
  invitationId?: string
  viewerAudience?: AudienceType
  invitationKind?: InvitationKind
  sampleTier?: SampleTier
  key?: string
}

const InvitationGuide = () => {
  const params = useParams<InvitationGuideParamsType>()

  const invitationId = params?.invitationId
  const viewerAudience = params?.viewerAudience?.toUpperCase()
  const invitationKind = params?.invitationKind?.toUpperCase()
  const sampleTier = params?.sampleTier
  // techdebt: later can extract this key to env variable to invalidate shared links
  const isRedacted =
    params?.key && params?.key !== '178a64ca-b2a1-4cf0-84d4-0e6f6e8fa86c' ? true : false

  const { user } = useAuth()
  const { userContext } = useUserContext()
  const isCandidate = userContext?.isCandidate || viewerAudience === AudienceType.Candidate

  const [isLoading, setIsLoading] = useState(true)
  const [result, setResult] = useState<InterviewGuide>()
  const [chartData, setChartData] = useState<Array<number>>()
  const [chartLabels, setChartLabels] = useState<Array<string>>()

  const conditionalClassNames = classNames({
    candidateGuide: isCandidate,
    'page-sample': isRedacted,
  })

  function parseResults(data: InterviewGuide) {
    setResult(data)

    if (data?.data) {
      setChartData(data.data)
    }

    if (data?.labels) {
      const newLabels = [...data.labels]
      const overall = data.labels[0]
      newLabels[0] = overall.split(' ')
      setChartLabels(newLabels)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    if (!invitationId || !user?.id) {
      // Could be looking for the unprotected sample pages.
      if (viewerAudience && invitationKind && sampleTier) {
        import('./results.mock').then((mockModule) => {
          // Get sample data based on path params or default to Org/High match.
          const dummyData = mockModule.sampleData[viewerAudience][invitationKind][sampleTier]
          parseResults(dummyData)
        })
      }
      return
    }

    const resultsRequest = getInvitationGuide(invitationId)
    resultsRequest.request
      .then((data) => {
        parseResults(data)
      })
      .catch(handleError(setIsLoading))

    return resultsRequest.cancelRequest
  }, [invitationId, isCandidate, user?.id, viewerAudience, invitationKind, sampleTier])

  const { paragraphs } = useMemo(() => {
    if (result === undefined || isEmpty(result)) {
      return { paragraphs: [] }
    }

    const paragraphs: JSX.Element[] = []
    const guideColors = ['#15BDE8', '#B4288E', '#230C3B', '#FF7376', '#1b998b']

    Object.entries(result.sections).forEach(([id, section], idx) => {
      if (isEmpty(section)) {
        // filter out empty categories
        return
      }
      const paragraph = (
        <GuideSections
          key={id}
          section={section}
          color={guideColors[idx]}
          isOrganization={!isCandidate}
        />
      )
      paragraphs.push(paragraph)
    })
    return { paragraphs }
  }, [result, isCandidate])

  if (isLoading) {
    return <div className="loading-message">Loading...</div>
  }

  if (result === undefined || isEmpty(result)) {
    return <div className="loading-message">Could not load results</div>
  }

  return (
    <div className={`guidePage ${conditionalClassNames}`}>
      <div className="guidePage__sectionWrapper guidePage__sectionWrapper--gray">
        <div className="guidePage__section guideHeader">
          <div className="guideHeader__section">
            <div className="guideHeader__heading">
              {isCandidate ? result.organizationName : result.candidateName}
            </div>
            <div className="guideHeader__subheading">
              {!isCandidate ? result.assessmentTitle + ' ' : ''}
              {result.reportTitle}
            </div>
            <div className="guideHeader__subheading--position">{result.positionTitle}</div>
            <div className="guideHeader__subheading--completion-date">
              Assessment Completed {formatDate(result.completedOn)}
            </div>
          </div>
          <div className="guideHeader__section">
            <div className="guidePage__div resultLogo">
              <img className="guidePage__logo" src="/logo-purple.svg" alt="Workzinga logo" />
            </div>
          </div>
        </div>
      </div>
      <div className="guidePage__sectionWrapper">
        {isCandidate ? (
          <CandidateIntro companyName={result.organizationName} />
        ) : (
          <OrgIntro candidateName={result.candidateName} />
        )}
        {!isCandidate && chartData && chartLabels && (
          <div className="guidePage__section barChart">
            <AlignmentChart chartData={chartData} labels={chartLabels} background={undefined} />
          </div>
        )}

        <div className="guidePage__section">
          <div className="guideSection guideSection__grid">{paragraphs}</div>
          <div className="pagebreak"> </div>
        </div>
      </div>
    </div>
  )
}

export default InvitationGuide
