import { Button } from '@chakra-ui/react'
import { InputField, PageWrapper } from 'core'
import { api } from 'provider'
import { useState } from 'react'
import { useDocumentTitle } from '../../../hooks'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import './styles.scss'
import { PrivateGroupSwitch } from '../components/PrivateGroupSwitch'

type NewGroupInputs = {
  title: string
  isPrivate: boolean
}

export const NewGroupPage = () => {
  useDocumentTitle('Create Group')
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<NewGroupInputs>()

  const [loading, setLoading] = useState(false)
  const [isPrivate, setIsPrivate] = useState<boolean>(false)

  const navigate = useNavigate()

  const createGroup: SubmitHandler<NewGroupInputs> = (data: NewGroupInputs) => {
    setLoading(true)

    const groupData = {
      title: data.title,
      isPublic: !isPrivate,
    }
    api.createGroup(groupData).request.then((res) => {
      setLoading(false)
      navigate(`/groups/${res.id}`, { replace: true })
    })
  }

  function changePrivacy(privacy: boolean) {
    setIsPrivate(!privacy)
  }

  return (
    <PageWrapper extraClassNames="newGroupPage">
      <div className="newGroupPage__header">Create Group</div>
      <div className="newGroupPage__content">
        <form onSubmit={handleSubmit(createGroup)}>
          <InputField
            label="Name"
            placeholder="What do you want to name your group?"
            registerFields={register('title', { required: 'Name is a required field' })}
            getValue={() => watch('title')}
            errorMessage={errors.title?.message}
            maxLength={48}
          />

          <div className="newGroupPage__toggle_title">Privacy Settings</div>
          <div className="switchContainer">
            <PrivateGroupSwitch isPrivate={isPrivate} onChange={changePrivacy} />
          </div>

          <div className="newGroupPage__actions">
            <Button
              type="submit"
              isDisabled={loading}
              variant="brandPrimary"
              loadingText="Creating..."
              isLoading={loading}>
              Save
            </Button>
          </div>
        </form>
      </div>
    </PageWrapper>
  )
}
