import {
  faArrowRightFromBracket,
  faArrowsRotate,
  faBuilding,
  faUser,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { User, useAuth, useUserContext } from 'provider'
import { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import './styles.scss'

type ProfileCardProps = {
  user: User
  className?: string
  removeProfileCard: () => void
  extraRef?: React.MutableRefObject<HTMLDivElement | null>
  setIsContextModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const ProfileCard = ({
  user,
  removeProfileCard,
  extraRef,
  className,
  setIsContextModalOpen,
}: ProfileCardProps) => {
  const navigate = useNavigate()
  const { userContext } = useUserContext()
  const { user: currentUser } = useAuth()
  const cardRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const isClickInsideComponent = cardRef.current?.contains(event.target as Element)
      const isClickInsideExtraComponent = extraRef?.current?.contains(event.target as Element)

      if (!isClickInsideComponent && !isClickInsideExtraComponent) {
        removeProfileCard()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [cardRef, extraRef, removeProfileCard])

  return (
    <div className={`profileCard ${className}`} ref={cardRef}>
      <div className="profileCard__profile">
        {userContext?.tenant?.brandLogo ? (
          <img
            src={userContext.tenant.brandLogo}
            alt="Brand Logo"
            className="profileCard__profile__logo"
          />
        ) : (
          <FontAwesomeIcon
            className="profileCard__profile__icon"
            icon={userContext?.isCandidate ? faUser : faBuilding}
            size="4x"
          />
        )}
      </div>
      <div className="profileCard__text profileCard__text--companyName">
        {userContext?.tenant?.title}
      </div>
      <div className="profileCard__text profileCard__text--name">{user.fullName}</div>
      <div className="profileCard__text profileCard__text--email">{user.email}</div>
      {currentUser?.userContexts && currentUser.userContexts.length > 1 && (
        <>
          <div className="profileCard__divider" />
          <div className="profileCard__action" onClick={() => setIsContextModalOpen(true)}>
            <FontAwesomeIcon className="profileCard__icon" icon={faArrowsRotate} size="1x" />
            <span>Switch Context</span>
          </div>
        </>
      )}
      <div className="profileCard__divider" />
      <div className="profileCard__action" onClick={() => navigate('/logout')}>
        <FontAwesomeIcon className="profileCard__icon" icon={faArrowRightFromBracket} size="1x" />
        <span>Logout</span>
      </div>
    </div>
  )
}

export default ProfileCard
