import classNames from 'classnames'
import { WordCloudCategory, WordCloudData } from 'provider'
import WordCloudWrapper from '../../../../components/WordCloud'
import './styles.scss'

type WordCloudCategoryProps = {
  category: WordCloudCategory
  organizationName?: string
  candidateName: string
  color: string
}

type WordCloudProps = {
  title?: string
  data: WordCloudData[]
  isOrganization?: boolean
}

const WordCloud = ({ title, data, isOrganization = false }: WordCloudProps) => {
  const conditionalClassNames = classNames({
    'wordCloud__title--secondary': !isOrganization,
  })
  return (
    <div className="wordCloud">
      <div className={`wordCloud__title ${conditionalClassNames}`}>{title}</div>
      <WordCloudWrapper data={data} />
    </div>
  )
}

const WordCloudCategoryComponent = ({
  category: {
    moduleTitle,
    categoryTitle,
    categoryDescription,
    organizationData,
    candidateData,
    categoryAbbreviation,
  },
  organizationName,
  candidateName,
  color,
}: WordCloudCategoryProps) => {
  const title = moduleTitle !== categoryTitle ? `${moduleTitle}: ${categoryTitle}` : categoryTitle
  return (
    <div className="wordCloudCategory">
      <div className="header">
        <div className="section_header--abbreviation-wrapper" style={{ backgroundColor: color }}>
          <div className="abbreviation">{categoryAbbreviation}</div>
        </div>
        <div className="headerTitle">{title}</div>
      </div>
      {categoryDescription && <div className="description">{categoryDescription}</div>}
      <div className="wordClouds">
        {organizationData && (
          <WordCloud title={organizationName} data={organizationData} isOrganization={true} />
        )}
        <WordCloud title={candidateName} data={candidateData} isOrganization={false} />
      </div>
    </div>
  )
}

export default WordCloudCategoryComponent
