const akaTerms: Record<string, string> = {
  position: 'requisition',
  positionId: 'requisitionId',
  positionSection: 'requisitionModule',
  positionSectionUser: 'requisitionModuleUser',
  jobPositionId: 'jobRequisitionId',
  jobPosition: 'jobRequisition',
  section: 'module',
  sectionId: 'moduleId',
  sections: 'modules',
  sectionSet: 'moduleSet',
  sectionUser: 'moduleUser',
  sectionProfile: 'moduleProfile',
  sectionUserId: 'moduleUserId',
  sectionUsers: 'moduleUsers',
  sectionUserCurrentStatusId: 'moduleUserCurrentStatusId',
  sectionUserProgress: 'moduleUserProgress',
  sectionUserQuestionCount: 'moduleUserQuestionCount',
  sectionText: 'moduleText',
}

const innerRenameKeys = (data: unknown, incoming: boolean) => {
  if (data && typeof data === 'object') {
    Object.entries(akaTerms).forEach(([key, value]) => {
      const initialTerm = incoming ? value : key
      const updatedTerm = incoming ? key : value

      if (initialTerm in data) {
        type DataKeys = keyof typeof data
        data[updatedTerm as DataKeys] = data[initialTerm as DataKeys]
        delete data[initialTerm as DataKeys]
      }
    })
  }
}

export const renameKeys = (data: unknown, incoming: boolean) => {
  if (data && typeof data === 'object') {
    if (Array.isArray(data)) {
      data.forEach((value) => {
        renameKeys(value, incoming)
      })
    } else {
      // first level
      innerRenameKeys(data, incoming)
      // nested values
      Object.values(data).forEach((value) => {
        renameKeys(value, incoming)
      })
    }
  }
}

export default renameKeys
