import { Alert, AlertIcon, Button, Input, InputGroup, InputRightElement } from '@chakra-ui/react'
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-regular-svg-icons'
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { api, GroupMember, NormalizedGroupMember, normalizeGroupMembers } from 'provider'
import { useEffect, useMemo, useState } from 'react'
import { useDebounce } from 'react-use'
import { MemberKind } from '../types'
import './styles.scss'
import { handleError } from 'core'

export type ManageMembersProps = {
  groupId: string
  kind?: MemberKind
  groupMembers?: GroupMember[]
  onUsersToBeAddedChange: (users: NormalizedGroupMember[]) => void
  onUsersToBeRemovedChange: (users: NormalizedGroupMember[]) => void
}

export const ManageMembers = ({
  groupId,
  kind = MemberKind.Member,
  groupMembers,
  onUsersToBeAddedChange,
  onUsersToBeRemovedChange,
}: ManageMembersProps) => {
  const availableUserLanguage = useMemo(() => {
    switch (kind) {
      case MemberKind.Member:
        return 'Available Users'
      case MemberKind.Admin:
        return 'Available Admins'
    }
  }, [kind])

  const membershipLanguage = useMemo(() => {
    switch (kind) {
      case MemberKind.Member:
        return 'Group Members'
      case MemberKind.Admin:
        return 'Group Admins'
    }
  }, [kind])

  // Queries (search text)

  // this is not already debounced, so we can use it to set the input value and debounce it separately.
  const [userSearchQuery, setUserSearchQuery] = useState('')
  const [memberSearchQuery, setMemberSearchQuery] = useState('')

  // Debounced Queries (to prevent spamming the API)

  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('')

  // debounce the search query so we don't make a request on every keystroke.
  useDebounce(() => setDebouncedSearchQuery(userSearchQuery), 250, [userSearchQuery])

  // Results (search results)
  const [userResults, setUserResults] = useState<NormalizedGroupMember[]>([])
  const [memberResults, setMemberResults] = useState<NormalizedGroupMember[]>([])

  /**
   * Simple fuzzy search to filter members by name and email
   *
   * if the search query is empty, return all members
   */
  const filteredMemberResults: NormalizedGroupMember[] = useMemo(() => {
    if (memberSearchQuery) {
      return memberResults.filter((member) => {
        return (
          member.firstName.toLowerCase().includes(memberSearchQuery.toLowerCase()) ||
          member.lastName.toLowerCase().includes(memberSearchQuery.toLowerCase()) ||
          member.email.toLowerCase().includes(memberSearchQuery.toLowerCase())
        )
      })
    } else {
      return memberResults
    }
  }, [memberSearchQuery, memberResults])

  // All users (a list to hold all users, both in the group, but also results from the search)
  // We will need this to look up users by their id and show users added and removed prior to saving.
  const [allUsers, setAllUsers] = useState<NormalizedGroupMember[]>([])

  useEffect(() => {
    // Combine userResults and memberResults
    const combinedResults = [...userResults, ...memberResults]

    // Remove duplicates
    const uniqueUsers = combinedResults.reduce<NormalizedGroupMember[]>((acc, currentUser) => {
      if (!acc.find((user) => user.id === currentUser.id)) {
        acc.push(currentUser)
      }
      return acc
    }, [])

    // Update allUsers state
    setAllUsers(uniqueUsers)

    return () => {
      // cleanup
    }
  }, [userResults, memberResults])

  function findUserById(
    allUsers: NormalizedGroupMember[],
    id: string,
  ): NormalizedGroupMember | undefined {
    return allUsers.find((user) => user.id === id)
  }

  const [usersToBeAdded, setUsersToBeAdded] = useState<NormalizedGroupMember[]>([])
  const [usersToBeRemoved, setUsersToBeRemoved] = useState<NormalizedGroupMember[]>([])

  // Selected
  const [selectedUsers, setSelectedUsers] = useState<string[]>([])
  const [selectedMembers, setSelectedMembers] = useState<string[]>([])

  // Cache (to force re-render/refresh api calls)
  const [cacheDateTime, setCacheDateTime] = useState(new Date())

  // Pending Actions
  const [isLoadingUserResults, setIsLoadingUserResults] = useState(false)
  const [pendingAdd, setPendingAdd] = useState<boolean>(false)
  const [pendingRemove, setPendingRemove] = useState<boolean>(false)

  const alphaSort = (a: NormalizedGroupMember, b: NormalizedGroupMember) => {
    if (a.fullName < b.fullName) {
      return -1
    }
    if (a.fullName > b.fullName) {
      return 1
    }
    return 0
  }

  const finalAvailableUsers: NormalizedGroupMember[] = useMemo(() => {
    const availableUsers = [...userResults]

    usersToBeRemoved.forEach((user) => {
      if (!availableUsers.find((u) => u.id === user.id)) {
        availableUsers.push(user)
      }
    })

    return availableUsers
      .filter((user) => {
        return !usersToBeAdded.find((u) => u.id === user.id)
      })
      .sort(alphaSort)
  }, [userResults, usersToBeAdded, usersToBeRemoved])

  const [finalGroupMembers, setFinalGroupMembers] = useState<Array<NormalizedGroupMember>>([])

  useEffect(() => {
    const groupMembers = [...filteredMemberResults]
    usersToBeAdded.forEach((user) => {
      if (!groupMembers.find((u) => u.id === user.id)) {
        groupMembers.push(user)
      }
    })
    const filteredGroupMembers = groupMembers.filter((user) => {
      return !usersToBeRemoved.find((u) => u.id === user.id)
    })
    setFinalGroupMembers(filteredGroupMembers.sort(alphaSort))
  }, [filteredMemberResults, usersToBeAdded, usersToBeRemoved])

  useEffect(() => {
    // make a request and populate userSearchquery with results from api.searchNonGroupUsers
    // also, if the cache is updated, we want to make a new request.
    // we may not need that now though, as a cache-busted result is only needed when changes are made to the group memberships.
    // TODO ( @AlexSwensen ): investigate this further.
    let isMounted = true
    const searchQueryParams = {
      groupId: groupId,
      kind: kind,
      query: debouncedSearchQuery,
    }

    const request = api.searchNonGroupUsers(
      searchQueryParams.groupId,
      searchQueryParams.kind,
      searchQueryParams.query,
    )

    setIsLoadingUserResults(true)
    request.request
      .then((res) => {
        if (isMounted) {
          const resultUsers = normalizeGroupMembers(res.results)
          setUserResults(resultUsers)
        }
      })
      .catch((error) => {
        if (isMounted)
          handleError(setIsLoadingUserResults, { errorMessage: 'Trouble loading users' })(error)
      })
      .finally(() => {
        if (isMounted) {
          setIsLoadingUserResults(false)
        }
      })
    return () => {
      isMounted = false
      request?.cancelRequest()
    }
  }, [debouncedSearchQuery, groupId, kind, groupMembers, cacheDateTime])

  useEffect(() => {
    if (groupMembers) {
      const normalizedGroupMembers = normalizeGroupMembers(groupMembers)
      setMemberResults(normalizedGroupMembers)
    }
  }, [groupMembers])

  // useeffect to set users to add and remove to an exported prop.
  useEffect(() => {
    if (onUsersToBeAddedChange) {
      onUsersToBeAddedChange(usersToBeAdded)
    }
    if (onUsersToBeRemovedChange) {
      onUsersToBeRemovedChange(usersToBeRemoved)
    }
  }, [onUsersToBeAddedChange, onUsersToBeRemovedChange, usersToBeAdded, usersToBeRemoved])

  const bustAvailableUsersCache = () => {
    setCacheDateTime(new Date())
  }

  const addMembers = async () => {
    setPendingAdd(true)

    // check if the user is in the remove list, if so reconcile,
    // otherwise add them to the add list.
    const usersToIterate = selectedUsers
    const newUsersToBeAdded = [...usersToBeAdded]
    const newUsersToBeRemoved = [...usersToBeRemoved]

    usersToIterate.forEach((id) => {
      const user = findUserById(allUsers, id)

      if (user) {
        const userInRemoveList = newUsersToBeRemoved.findIndex((u) => u.id === user.id)
        if (userInRemoveList !== -1) {
          newUsersToBeRemoved.splice(userInRemoveList, 1)
        }

        if (newUsersToBeAdded.findIndex((u) => u.id === user.id) === -1) {
          newUsersToBeAdded.push(user)
        }
      }
    })
    setUsersToBeAdded(newUsersToBeAdded)
    setUsersToBeRemoved(newUsersToBeRemoved)

    setSelectedUsers([])
    setSelectedMembers([])
    bustAvailableUsersCache()
    setPendingAdd(false)
  }

  const removeMembers = async () => {
    // check if the user is in the add list, if so reconcile,
    // otherwise add them to the remove list.
    setPendingRemove(true)
    const membersToIterate = selectedMembers
    const newUsersToBeAdded = [...usersToBeAdded]
    const newUsersToBeRemoved = [...usersToBeRemoved]

    membersToIterate.forEach((id) => {
      const user = findUserById(allUsers, id)

      if (user) {
        const userInAddList = newUsersToBeAdded.findIndex((u) => u.id === user.id)
        if (userInAddList !== -1) {
          newUsersToBeAdded.splice(userInAddList, 1)
        }

        if (newUsersToBeRemoved.findIndex((u) => u.id === user.id) === -1) {
          newUsersToBeRemoved.push(user)
        }
      }
    })
    setUsersToBeAdded(newUsersToBeAdded)
    setUsersToBeRemoved(newUsersToBeRemoved)

    setSelectedUsers([])
    setSelectedMembers([])
    bustAvailableUsersCache()
    setPendingRemove(false)
  }

  return (
    <>
      <div className="manageMembers">
        <div className="manageMembers__content">
          <div className="manageMembers__content__left">
            <h3>{availableUserLanguage}</h3>
            <InputGroup size="md">
              <Input
                type="text"
                placeholder="Search for a user by name or email"
                value={userSearchQuery}
                onChange={(e) => setUserSearchQuery(e.target.value)}
              />
              {isLoadingUserResults && (
                <InputRightElement width="3rem">
                  <FontAwesomeIcon icon={faCircleNotch} spin />
                </InputRightElement>
              )}
            </InputGroup>
            <div className="results">
              <select
                multiple
                value={selectedUsers}
                onChange={(e) =>
                  setSelectedUsers(Array.from(e.target.selectedOptions, (option) => option.value))
                }>
                {finalAvailableUsers.map((result) => (
                  <option key={result.id} value={result.id}>
                    {result.fullName + ' | ' + result.email}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="manageMembers__content__middle">
            <Button
              className="manageMembers__content__middle__button"
              onClick={addMembers}
              disabled={pendingAdd}
              isLoading={pendingAdd}
              rightIcon={<FontAwesomeIcon icon={faArrowRight} />}>
              Add
            </Button>
            <Button
              className="manageMembers__content__middle__button"
              onClick={removeMembers}
              disabled={pendingRemove}
              isLoading={pendingRemove}
              leftIcon={<FontAwesomeIcon icon={faArrowLeft} />}>
              Remove
            </Button>
          </div>
          <div className="manageMembers__content__right">
            <h3>{membershipLanguage}</h3>
            <InputGroup size="md">
              <Input
                type="text"
                placeholder="Search for a user by name or email"
                value={memberSearchQuery}
                onChange={(e) => setMemberSearchQuery(e.target.value)}
              />
              {/* {isLoading && (
                <InputRightElement width="3rem">
                  <FontAwesomeIcon icon={faCircleNotch} spin />
                </InputRightElement>
              )} */}
            </InputGroup>
            <div className="results">
              <select
                multiple
                value={selectedMembers}
                onChange={(e) =>
                  setSelectedMembers(Array.from(e.target.selectedOptions, (option) => option.value))
                }>
                {finalGroupMembers.map((result) => (
                  <option key={result.id} value={result.id}>
                    {result.firstName + ' ' + result.lastName + ' | ' + result.email}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="helperText">
          <Alert status="info">
            <AlertIcon />
            Hold down the Ctrl (windows) or Command (Mac) key to select multiple options at once.
          </Alert>
        </div>
      </div>
    </>
  )
}
