import { isErrorResponse } from 'provider'

type ErrorDetails = {
  messages: string[]
}

const isErrorDetail = (errorDetail: unknown): errorDetail is ErrorDetails => {
  return !!errorDetail && typeof errorDetail === 'object' && 'messages' in errorDetail
}

const getErrorDetails = (error: unknown) => {
  return isErrorResponse(error) && isErrorDetail(error.data?.errors)
    ? error.data.errors.messages
    : []
}

export default getErrorDetails
