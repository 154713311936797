import { useRef, MouseEvent, useState } from 'react'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  defaults,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { postScore } from '../../../actions/profile'
import { Toast } from 'core'
import { camelCase } from 'lodash'
import './styles.scss'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

interface BarChartProps {
  headLabel: string
  description: string
  apiData: Array<number>
  apiLabels: Array<string> | undefined
  groupProfileId: string
  constructId: string
  bucketMap: any
  construct: any
  isStatusLocked: boolean
  allCanFinalize: Array<boolean>
  setAllCanFinalize: () => void
  chartIndex: number
}

const BarChart = ({
  headLabel,
  description,
  apiData,
  apiLabels,
  groupProfileId,
  constructId,
  bucketMap,
  construct,
  isStatusLocked,
  allCanFinalize,
  setAllCanFinalize,
  chartIndex,
}: BarChartProps) => {
  // global styling for the chart
  defaults.font.family = 'Istok Web'
  defaults.font.size = 13
  const [borderClickColor, setBorderClickColor] = useState([
    'rgba(0, 0, 0, 0)',
    'rgba(0, 0, 0, 0)',
    'rgba(0, 0, 0, 0)',
    'rgba(0, 0, 0, 0)',
  ])
  const [barBackgroundColor, setBarBackgroundColor] = useState([
    '#3f2c7f',
    '#3f2c7f',
    '#3f2c7f',
    '#3f2c7f',
  ])
  const [ticksColor, setTicksColor] = useState(['gray', 'gray', 'gray', 'gray'])
  const [verticalHeight, setVerticalHeight] = useState(0)
  const [chartData, setChartData] = useState<Array<number>>([])

  const chartRef = useRef<ChartJS>(null)

  // this will grab the x value when the click in the chart
  // we can take the x value, use it as the index in the label array
  // and get the label that they clicked
  const getClickedCoordinates = (chart: any, event: any) => {
    const xTop = chart.chartArea.left
    const xBottom = chart.chartArea.right
    const xMin = chart.scales['x'].min
    const xMax = chart.scales['x'].max
    let newX = 0
    if (event.nativeEvent.offsetX <= xBottom && event.nativeEvent.offsetX >= xTop) {
      newX = Math.abs((event.nativeEvent.offsetX - xTop) / (xBottom - xTop))
      newX = newX * Math.abs(xMax - xMin) + xMin
    }

    newX = parseInt(newX.toFixed())

    return newX
  }

  // this will adjust the border for the clicked bar.
  // if clicked it will have a blue border...else it will turn it invisible
  const printElementAtEvent = (clickedIndex: number) => {
    const newBorderColorList: any = []
    const newTickColorList: any = []
    const newBarBackgroundColor: any = []

    // set new border for bar clicked then and reset the rest to invivisble state
    borderClickColor.forEach((color, i) => {
      if (i !== clickedIndex) {
        newBorderColorList.push('rgba(0, 0, 0, 0)')
        newTickColorList.push('gray')
        newBarBackgroundColor.push('#3f2c7f')
      } else {
        newBorderColorList.push('#55bbe4')
        newTickColorList.push('#55bbe4')
        newBarBackgroundColor.push('#a7398b')
      }
    })
    setBorderClickColor(newBorderColorList)
    setTicksColor(newTickColorList)
    setBarBackgroundColor(newBarBackgroundColor)
    if (!data.labels) {
      return
    }
    // make the api call to send out the clicked value
    postSelectedLabel(data.labels[clickedIndex])
  }

  // make the api call with the selected value(rounded up)
  // reset contruct data with the updated response construct
  // update the data to reflect the new profile score
  const postSelectedLabel = (selectedLabel: string) => {
    if (isStatusLocked) {
      return Toast.error('Score is locked and cannot be edited.')
    }
    const selectedValue = Math.max(...bucketMap[camelCase(selectedLabel)])
    const request = postScore(groupProfileId, { constructId: constructId, score: selectedValue })
    request.request
      .then((resp) => {
        if (resp.hasValidScore) {
          const finalize = allCanFinalize
          finalize[chartIndex] = true
          setAllCanFinalize(finalize)
        }
        resp.datasets.forEach((data: any) => {
          if (constructId === data.id) {
            setChartData(data.data)
            construct = data
          }
        })
      })
      .catch(() => {
        Toast.error('An error occured')
      })
  }

  // when a bar is clicked on the chart
  const onClick = (event: MouseEvent<HTMLCanvasElement>) => {
    const { current: chart } = chartRef

    if (!chart) {
      return
    }

    const clickedIndex = getClickedCoordinates(chart, event)
    printElementAtEvent(clickedIndex)
    chart.update()
  }

  // find if there is an existing profile score on page load
  // set the correct border and tick color if so
  const updateScoreBar = (data: any) => {
    const newDataset = data
    if (construct.profileScore !== null) {
      labels?.forEach((label, index) => {
        if (label === construct.bucket) {
          borderClickColor[index] = '#55bbe4'
          ticksColor[index] = '#55bbe4'
          barBackgroundColor[index] = '#a7398b'
        }
      })
    }
    setVerticalHeight(Math.max(...newDataset) + 1)
    setChartData(newDataset)
  }

  const beforeInit: any = [
    {
      id: 'beforeInitPlugin',
      beforeInit: () => {
        updateScoreBar(apiData)
      },
    },
  ]

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      id: 'custom_canvas_background_color',
    },
    scales: {
      y: {
        ticks: {
          stepSize: 1,
        },
        max: verticalHeight,
      },
      x: {
        ticks: {
          color: ticksColor,
        },
      },
    },
  }

  const labels = apiLabels

  const data = {
    labels,
    datasets: [
      {
        data: chartData,
        backgroundColor: barBackgroundColor,
        borderColor: borderClickColor,
        borderWidth: 3,
        borderRadius: 2,
        hoverBackgroundColor: '#a7398b',
        hoverBorderColor: '#55bbe4',
        hoverBorderWidth: 3,
        hoverBorderRadius: 2,
      },
    ],
  }

  return (
    <div className="barChart">
      <div className="barTitle__wrapper">
        <span className="barTitle__title">{headLabel}</span>
        <Popover trigger="hover" className="popoverInfo">
          <PopoverTrigger>
            <FontAwesomeIcon icon={faCircleInfo} />
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverBody>{description}</PopoverBody>
          </PopoverContent>
        </Popover>
      </div>
      <Bar
        ref={chartRef}
        plugins={beforeInit}
        options={options}
        datasetIdKey="id"
        data={data}
        onClick={onClick}
      />
    </div>
  )
}

export default BarChart
