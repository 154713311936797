import { useAuth, useUserContext } from 'provider'
import { useNavigate } from 'react-router-dom'
import { Avatar } from 'core'

import './styles.scss'
import ProfileCard from '../ProfileCard'
import { useRef, useState } from 'react'
import SwitchContextModal from '../SwitchContextModal'

const TopNav = () => {
  const navigate = useNavigate()
  const { user } = useAuth()
  const { userContext } = useUserContext()
  const profileWrapperRef = useRef<HTMLDivElement | null>(null)
  const isOrgUser = userContext?.isOrgUser ?? false
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const [isProfileVisible, setIsProfileVisible] = useState(false)

  const goLogoClicked = () => {
    if (isOrgUser) {
      navigate('/')
    }
  }

  return (
    <nav className="topnav">
      <img
        src="/logo-white.svg"
        alt="logo"
        className={`topnav__logo ${!isOrgUser ? 'topnav__logo--disabled' : ''}`}
        onClick={goLogoClicked}
      />
      {user && (
        <div className="topnav__profileWrapper" ref={profileWrapperRef}>
          <Avatar
            className="topnav__avatar"
            fullName={user.fullName}
            onClick={() => setIsProfileVisible((prev) => !prev)}
          />
          {isProfileVisible && (
            <ProfileCard
              className="topnav__profile"
              user={user}
              extraRef={profileWrapperRef}
              removeProfileCard={() => setIsProfileVisible(false)}
              setIsContextModalOpen={setIsModalOpen}
            />
          )}
        </div>
      )}
      <SwitchContextModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </nav>
  )
}

export default TopNav
