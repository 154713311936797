import { JSX } from 'react'
import { handleError } from 'core'
import { CancellableRequest, PaginationResponse, Role, Member } from 'provider'
import { createContext, useContext, useEffect, useState } from 'react'
import { getRoles, getMembers } from '../actions/members'

type MemberProps = {
  members: Member[]
  usersCount: number
  isLoadingMembers: boolean
  addMember: (newMember: Member) => void
  roles: Role[]
  isLoadingRoles: boolean
  isInProvider: boolean
}

const useMembersWithoutContext = ({
  shouldLoad,
  includeRoles,
}: {
  shouldLoad: boolean
  includeRoles: boolean
}) => {
  const [members, setMembers] = useState<Member[]>([])
  const [usersCount, setUsersCount] = useState(0)
  const [isLoadingMembers, setIsLoadingMembers] = useState(true)
  const [roles, setRoles] = useState<Role[]>([])
  const [isLoadingRoles, setIsLoadingRoles] = useState(includeRoles)

  const addMember = (newMember: Member) => {
    setMembers((currMembers) => [...currMembers, newMember])
  }

  useEffect(() => {
    let rolesRequest: CancellableRequest<PaginationResponse<Role[]>> | undefined
    let membersRequest: CancellableRequest<PaginationResponse<Member[]>> | undefined

    if (shouldLoad) {
      // TODO: create better way of managing dropdowns with pagination
      // for now, just use an absurd page size so its effectively not paginated
      membersRequest = getMembers({ pageSize: 1000, ordering: 'first_name,last_name' })
      membersRequest.request
        .then((data) => {
          setMembers(data.results)
          setUsersCount(data.count)
          setIsLoadingMembers(false)
        })
        .catch(handleError(setIsLoadingMembers, { errorMessage: 'Could not load users' }))

      if (includeRoles) {
        // TODO: create better way of managing dropdowns with pagination
        // for now, just use an absurd page size so its effectively not paginated
        rolesRequest = getRoles({ pageSize: 1000, ordering: 'title' })
        rolesRequest.request
          .then((data) => {
            setRoles(data.results)
            setIsLoadingRoles(false)
          })
          .catch(handleError(setIsLoadingRoles, { errorMessage: 'Could not load roles' }))
      }
    }

    return () => {
      membersRequest?.cancelRequest()
      rolesRequest?.cancelRequest()
    }
  }, [includeRoles, shouldLoad])

  return { members, usersCount, isLoadingMembers, addMember, roles, isLoadingRoles }
}

const MembersContext = createContext<MemberProps>({
  members: [],
  usersCount: 0,
  isLoadingMembers: true,
  addMember: () => {},
  roles: [],
  isLoadingRoles: true,
  isInProvider: false,
})
export const useMembers = (includeRoles: boolean = true) => {
  const { isInProvider, ...context } = useContext(MembersContext)
  const membersWithoutContext = useMembersWithoutContext({
    shouldLoad: !isInProvider,
    includeRoles,
  })

  return isInProvider ? context : membersWithoutContext
}

export const MembersProvider = ({
  children,
  includeRoles = true,
}: {
  children: JSX.Element
  includeRoles?: boolean
}) => {
  const membersWithoutContext = useMembersWithoutContext({ shouldLoad: true, includeRoles })
  return (
    <MembersContext.Provider value={{ ...membersWithoutContext, isInProvider: true }}>
      {children}
    </MembersContext.Provider>
  )
}
