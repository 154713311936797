import { useState } from 'react'
import { Choice } from 'provider'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import './styles.scss'

type LikertProps = {
  choices: Choice[]
  selectedChoices: string[]
  setSelectedChoices: (choices: string[]) => void
  isDisabled?: boolean
}
const Likert = ({ choices, isDisabled, setSelectedChoices }: LikertProps) => {
  const [sliderValue, setSliderValue] = useState<number>()

  if (choices.length < 2) {
    return <>Could not render choices!</>
  }

  const leftText = choices[0].text
  const rightText = choices[choices.length - 1].text

  const choicesSpacing = (1 / (choices.length - 1)) * 100
  const marks = sliderValue === undefined ? { 50: <></> } : {}
  // if there's an even number of choices then 50 is not a valid value to select.
  const isMiddleInvalid = choices.length % 2 === 0

  const onAfterChange = (value: number | number[]) => {
    if (value === 50 && isMiddleInvalid) {
      return
    }
    const singleValue = Array.isArray(value) ? value[0] : value
    setSliderValue(singleValue)
    const choiceIndex = Math.round((singleValue / 100) * (choices.length - 1))
    setSelectedChoices([choices[choiceIndex].id])
  }

  return (
    <div className="likert likert__mobile">
      <div className="likert__mobile__text">
        <div className="likert__text likert__text--left"> {leftText} </div>
        <div className="likert__text likert__text--right"> {rightText} </div>
      </div>
      <div className="likert__text likert__text--left likert__mobile__hide"> {leftText} </div>
      <div className="likert__sliderWrapper">
        <Slider
          dots
          marks={marks}
          step={choicesSpacing}
          defaultValue={50}
          onAfterChange={onAfterChange}
          disabled={isDisabled}
          dotStyle={(value) => {
            if (value === 50 && isMiddleInvalid) {
              return { display: 'none' }
            } else {
              return {}
            }
          }}
        />
      </div>
      <div className="likert__text likert__text--right likert__mobile__hide"> {rightText} </div>
    </div>
  )
}

export default Likert
