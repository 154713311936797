import {
  Editable,
  EditableInput,
  EditablePreview,
  Spinner,
  useEditableControls,
} from '@chakra-ui/react'
import { faPencil, faPeopleGroup } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ActionDropdown, ActionItem, Toast, formatDate } from 'core'
import { api, Group, GroupUpdateParams } from 'provider'
import { useState } from 'react'
import { PrivateGroupSwitch } from '../PrivateGroupSwitch'
import './styles.scss'

export type GroupManagePanelProps = {
  group: Group
  isAdmin: boolean
  actions?: ActionItem[]
  onEdit?: (resp: Group) => void
}
function GroupManagePanel({ group, isAdmin, actions, onEdit }: GroupManagePanelProps) {
  const [isLoading, setIsLoading] = useState(false)

  async function changeTitle(title: string) {
    const params: GroupUpdateParams = {
      title,
    }
    await updateGroupProps(params)
  }

  async function changePrivacy(isPublic: boolean) {
    const params: GroupUpdateParams = {
      isPublic,
    }
    await updateGroupProps(params)
  }

  const updateGroupProps = ({
    title = group.title,
    isPublic = !group.isPublic,
  }: GroupUpdateParams) => {
    setIsLoading(true)
    const request = api.updateGroup(group.id, { title: title, isPublic: isPublic })
    request.request
      .then((resp) => {
        emitEdit(resp)
      })
      .catch((err) => {
        Toast.error(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const emitEdit = (resp: Group) => {
    if (onEdit) {
      onEdit(resp)
    }
  }

  function EditableGroupTitle() {
    function EditableControls() {
      const { getEditButtonProps } = useEditableControls()
      return <FontAwesomeIcon icon={faPencil} color="black" size="sm" {...getEditButtonProps()} />
    }

    return (
      <Editable defaultValue={group.title} onSubmit={changeTitle} className="editableGroupTitle">
        <EditablePreview />
        <EditableInput maxLength={48} />
        <EditableControls />
      </Editable>
    )
  }

  function GroupTitleElement(props) {
    const isAdmin = props.isAdmin
    if (isAdmin) {
      return <EditableGroupTitle />
    }
    return <div>{group.title}</div>
  }

  return (
    <div className="groupManagePanel">
      <div className="groupManagePanel__header">
        <div>
          <div className="groupManagePanel__header__title">
            <FontAwesomeIcon icon={faPeopleGroup} color="black" />
            <GroupTitleElement isAdmin={isAdmin} />
          </div>
          <div className="groupManagePanel__header__subtitle">
            <div className="updatedAt">
              Updated {formatDate(group.updatedAt)} by {group.updatedByName}
            </div>
          </div>
        </div>
        {isLoading && (
          <div>
            <Spinner size="md" marginRight={3} />
          </div>
        )}
        {isAdmin && (
          <div>
            <div className="groupManagePanel__toggle_title">Privacy Settings</div>
            <div className="switchContainer">
              <PrivateGroupSwitch isPrivate={!group.isPublic} onChange={changePrivacy} />
            </div>
          </div>
        )}
        {actions && (
          <div className="groupManagePanel__header__action_dropdown">
            <ActionDropdown actions={actions} alignButton={'right'} size="sm" />
          </div>
        )}
      </div>
      {isAdmin && (
        <div className="groupManagePanel__body">
          <div className="bodyCount">
            <div className="bodyCount__left">
              <h5>Group Members</h5>
              <div>
                {group.currentMembers.length}{' '}
                {group.currentMembers.length >= 1 ? 'members' : 'member'}
              </div>
            </div>
            <div className="bodyCount__right">
              <h5>Group Admins</h5>
              <div className="bodyCount__right__admins">
                {group.currentAdmins.length > 0 && (
                  <>
                    <div>
                      {`${group.currentAdmins[0].userFirstName} ${group.currentAdmins[0].userLastName}`}
                      {group.currentAdmins.length > 1 && (
                        <div>
                          and {group.currentAdmins.length - 1}{' '}
                          {group.currentAdmins.length - 1 > 1 ? 'others' : 'other'}
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

GroupManagePanel.displayName = 'GroupManagePanel'

export default GroupManagePanel
