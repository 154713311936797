import { useRef, useState } from 'react'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons'
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  defaults,
} from 'chart.js'
import { Bubble } from 'react-chartjs-2'
import annotationPlugin from 'chartjs-plugin-annotation'
import { postScore } from '../../../actions/profile'
import { Toast } from 'core'
import './styles.scss'

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend, annotationPlugin)

interface ScatterChartProps {
  apiData: any
  groupProfileId: string
  allCanFinalize: Array<boolean>
  setAllCanFinalize: () => void
  chartIndex: number
}

const ScatterChart = ({
  apiData,
  groupProfileId,
  allCanFinalize,
  setAllCanFinalize,
  chartIndex,
}: ScatterChartProps) => {
  const [coordinateData, setCoordinateData] = useState(apiData.datasets)
  const [pointBackgroundColor, setPointBackgroundColor] = useState<Array<string>>([])
  const [pointShape, setPointShape] = useState<Array<string>>([])

  // global styling for the chart
  defaults.font.family = 'Istok Web'
  defaults.font.size = 13
  // set labels for annotation plugin
  const labelsObject = {}
  const labelsDescriptions = apiData.labels.map((label: any) => {
    const k = Object.keys(label)
    const quadrant = k[0]
    const title = label[quadrant]
    const { description } = label

    labelsObject[quadrant] = title
    return {
      title,
      description,
    }
  })

  const chartRef = useRef<ChartJS>(null)
  const pointScale = apiData.pointScale % 2 === 1 ? apiData.pointScale + 1 : apiData.pointScale

  // this will get clicked coordinates on the chart by looking at the chart area
  // and doing some math where their mouse click happened
  const getClickedCoordinates = (chart: any, event: any) => {
    const yTop = chart.chartArea.top
    const yBottom = chart.chartArea.bottom

    const yMin = chart.scales.y.min
    const yMax = chart.scales.y.max
    let newY = 0

    if (event.nativeEvent.offsetY <= yBottom && event.nativeEvent.offsetY >= yTop) {
      newY = Math.abs((event.nativeEvent.offsetY - yTop) / (yBottom - yTop))
      newY = (newY - 1) * -1
      newY = newY * Math.abs(yMax - yMin) + yMin
    }

    const xTop = chart.chartArea.left
    const xBottom = chart.chartArea.right
    const xMin = chart.scales['x'].min
    const xMax = chart.scales['x'].max
    let newX = 0
    if (event.nativeEvent.offsetX <= xBottom && event.nativeEvent.offsetX >= xTop) {
      newX = Math.abs((event.nativeEvent.offsetX - xTop) / (xBottom - xTop))
      newX = newX * Math.abs(xMax - xMin) + xMin
    }

    newX = parseInt(newX.toFixed())
    newY = parseInt(newY.toFixed())
    newX = Math.ceil(newX) | 1
    newY = Math.ceil(newY) | 1

    const scoreProfile = { x: newX, y: newY, r: 9, profileScore: true }
    return scoreProfile
  }

  const checkIfProfileScoreExists = (coordinates: any) => {
    const doesExist = coordinateData.some((d: any) => d.profileScore === true)
    if (!doesExist) {
      coordinateData.push(coordinates)
    } else {
      replaceProfileScore(coordinates)
    }
  }

  const replaceProfileScore = (coordinates: any) => {
    const newCoordinates = coordinateData.map((d: any) => {
      if (d.profileScore === true) {
        return (d = coordinates)
      } else {
        return d
      }
    })
    setCoordinateData(newCoordinates)
  }

  // set shapes and colors for points on the chart
  const getPointStyles = () => {
    const newPointColorArray: Array<string> = []
    const newPointShapeArray: Array<string> = []
    coordinateData.forEach((data: any) => {
      if (data.profileScore) {
        newPointColorArray.push('#a7398b')
        newPointShapeArray.push('triangle')
        data['r'] = data?.occurrence + 8 || 9
      } else {
        newPointColorArray.push('#1b998b')
        newPointShapeArray.push('circle')
        data['r'] = data.occurrence * 5
      }
    })
    setPointBackgroundColor(newPointColorArray)
    setPointShape(newPointShapeArray)
  }

  // these methods will determine where they clicked in the chart
  // and it will start distributing points to the temp point bucket
  // once all points have been distributed we can make the api call
  const bothPositive = (
    a: number,
    b: number,
    coordLg: number,
    coordX: number,
    modulous: number,
    tempPointBucket: any,
  ) => {
    if (coordLg === Math.abs(coordX)) {
      tempPointBucket.topRight = a
      tempPointBucket.bottomRight = b
    } else {
      tempPointBucket.topRight = a
      tempPointBucket.topLeft = b
    }

    if (modulous === 2) {
      tempPointBucket.topLeft = tempPointBucket.topLeft + 1
      tempPointBucket.bottomRight = tempPointBucket.bottomRight + 1
    }
  }

  const bothNegative = (
    coordX: number,
    coordLg: number,
    a: number,
    b: number,
    modulous: number,
    tempPointBucket: any,
  ) => {
    if (coordLg === Math.abs(coordX)) {
      tempPointBucket.bottomLeft = a
      tempPointBucket.topLeft = b
    } else {
      tempPointBucket.bottomLeft = a
      tempPointBucket.bottomRight = b
    }

    if (modulous === 2) {
      tempPointBucket.topLeft = tempPointBucket.topLeft + 1
      tempPointBucket.bottomRight = tempPointBucket.bottomRight + 1
    }
  }

  const xPositiveYnegative = (
    coordX: number,
    coordLg: number,
    a: number,
    b: number,
    modulous: number,
    tempPointBucket: any,
  ) => {
    if (coordLg === Math.abs(coordX)) {
      tempPointBucket.bottomRight = a
      tempPointBucket.topRight = b
    } else {
      tempPointBucket.bottomRight = a
      tempPointBucket.bottomLeft = b
    }

    if (modulous === 2) {
      tempPointBucket.topRight = tempPointBucket.topRight + 1
      tempPointBucket.bottomLeft = tempPointBucket.bottomLeft + 1
    }
  }

  const xNegativeYpositive = (
    coordX: number,
    coordLg: number,
    a: number,
    b: number,
    modulous: number,
    tempPointBucket: any,
  ) => {
    if (coordLg === Math.abs(coordX)) {
      tempPointBucket.topLeft = a
      tempPointBucket.bottomLeft = b
    } else {
      tempPointBucket.topLeft = a
      tempPointBucket.topRight = b
    }

    if (modulous === 2) {
      tempPointBucket.topRight = tempPointBucket.topRight + 1
      tempPointBucket.bottomLeft = tempPointBucket.bottomLeft + 1
    }
  }

  // add final points based on how many there are left from total questions
  const addFinalPoints = (tempPointBucket: any, pointsLeft: number) => {
    const pointsToGive = pointsLeft / 4
    tempPointBucket.topRight = tempPointBucket.topRight + pointsToGive
    tempPointBucket.topLeft = tempPointBucket.topLeft + pointsToGive
    tempPointBucket.bottomLeft = tempPointBucket.bottomLeft + pointsToGive
    tempPointBucket.bottomRight = tempPointBucket.bottomRight + pointsToGive
  }

  // api payload construction
  const constructPayload = (tempPointBucket: any) => {
    const payload: any = []
    apiData.labels.forEach((label: any) => {
      Object.keys(label).forEach((lKey) => {
        Object.keys(tempPointBucket).forEach((pKey) => {
          if (pKey === lKey) {
            const payloadObject = { constructId: label.constructId, score: tempPointBucket[pKey] }
            payload.push(payloadObject)
          }
        })
      })
    })
    return payload
  }

  const postPoints = (tempPointBucket: any) => {
    if (apiData.isStatusLocked) {
      return Toast.error('Score is locked and cannot be edited.')
    }
    const payload = constructPayload(tempPointBucket)
    const request = postScore(groupProfileId, payload)
    request.request
      .then((response) => {
        if (response.hasValidScore) {
          const finalize = allCanFinalize
          finalize[chartIndex] = true
          setAllCanFinalize(finalize)
        }
      })
      .catch(() => {
        Toast.error('An error occured')
      })
    const { current: chart } = chartRef
    if (!chart) {
      return
    }
  }

  const calculateQuadrantScores = (clickedCoordinates: any) => {
    // temp bucket to place points
    const tempPointBucket = { topRight: 0, bottomRight: 0, topLeft: 0, bottomLeft: 0 }
    // points left
    let pointsLeft = apiData.pointScale

    // get both the coordinates clicked on
    const coordX = clickedCoordinates.x
    const coordY = clickedCoordinates.y

    // the largest and smallest ABSOLUTE of the 2 coordinates
    const coordLg = Math.max(Math.abs(coordX), Math.abs(coordY))
    const coordSm = Math.min(Math.abs(coordX), Math.abs(coordY))

    // plot first two points
    const a = (coordLg + coordSm) / 2
    const b = (coordLg - coordSm) / 2

    pointsLeft = pointsLeft - (a + b)
    const modulous = pointsLeft % 4
    pointsLeft = pointsLeft - modulous

    // if user clicked top right quadrant
    if (Math.sign(coordX) === 1 && Math.sign(coordY) === 1) {
      bothPositive(a, b, coordLg, coordX, modulous, tempPointBucket)
    }

    // if user clicked bottom left quadrant
    if (Math.sign(coordX) === -1 && Math.sign(coordY) === -1) {
      bothNegative(coordX, coordLg, a, b, modulous, tempPointBucket)
    }

    // if user clicked bottom right quadrant
    if (Math.sign(coordX) === 1 && Math.sign(coordY) === -1) {
      xPositiveYnegative(coordX, coordLg, a, b, modulous, tempPointBucket)
    }

    // if user clicked top left quadrant
    if (Math.sign(coordX) === -1 && Math.sign(coordY) === 1) {
      xNegativeYpositive(coordX, coordLg, a, b, modulous, tempPointBucket)
    }

    // if no points left, then we are done!
    if (pointsLeft > 0) {
      addFinalPoints(tempPointBucket, pointsLeft)
    }

    postPoints(tempPointBucket)
  }

  const onClick = (event: any) => {
    const { current: chart } = chartRef

    if (!chart) {
      return
    }

    // do some math and get the coordinates that were clicked on
    const clickedCoordinates = getClickedCoordinates(chart, event)

    // check to see if a profile score has already been plotted
    if (clickedCoordinates.x > apiData.pointScale || clickedCoordinates.y > apiData.pointScale) {
      return Toast.error('This score is out of range')
    }

    checkIfProfileScoreExists(clickedCoordinates)

    // update color and shape of points
    getPointStyles()

    // do the math to convert coordinates into a score then post to api
    calculateQuadrantScores(clickedCoordinates)

    chart.update()
  }

  const options: any = {
    animation: {
      duration: 1,
    },
    aspectRatio: 1.3,
    scales: {
      y: {
        grid: {
          drawTicks: false,
        },
        max: pointScale,
        min: -pointScale,
        ticks: {
          precision: 0,
          count: pointScale + 1,
          display: false,
        },
      },
      x: {
        grid: {
          drawTicks: false,
        },
        max: pointScale,
        min: -pointScale,
        ticks: {
          precision: 0,
          count: pointScale + 1,
          display: false,
        },
      },
    },
    plugins: {
      quadrants: {
        topLeft: '#f2c6ca',
        topRight: '#553ba9',
        bottomRight: '#b3c7f1',
        bottomLeft: '#f3f0fe',
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      annotation: {
        annotations: {
          label1: {
            type: 'label',
            xValue: pointScale - 1,
            yValue: pointScale - 2,
            position: { x: 'end', y: 'center' },
            content: labelsObject.topRight,
            font: {
              size: 18,
            },
            backgroundColor: 'white',
            borderRadius: 4,
          },
          label2: {
            type: 'label',
            xValue: -pointScale + 1,
            yValue: -pointScale + 2,
            position: { x: 'start', y: 'center' },
            content: labelsObject.bottomLeft,
            font: {
              size: 18,
            },
            backgroundColor: 'white',
            borderRadius: 4,
          },
          label3: {
            type: 'label',
            xValue: -pointScale + 1,
            yValue: pointScale - 2,
            position: { x: 'start', y: 'center' },
            content: labelsObject.topLeft,
            font: {
              size: 18,
            },
            backgroundColor: 'white',
            borderRadius: 4,
          },
          label4: {
            type: 'label',
            xValue: pointScale - 1,
            yValue: -pointScale + 2,
            position: { x: 'end', y: 'center' },
            content: labelsObject.bottomRight,
            font: {
              size: 18,
            },
            backgroundColor: 'white',
            borderRadius: 4,
          },
        },
      },
    },
  }

  // quadrants plugin
  // this will display the background color in each chart quadrant
  const quadrants: any = [
    {
      id: 'quadrants',
      beforeDraw(chart: any, args: any, options: any) {
        getPointStyles()
        const {
          ctx,
          chartArea: { left, top, right, bottom },
          scales: { x, y },
        } = chart
        const midX = x.getPixelForValue(0)
        const midY = y.getPixelForValue(0)
        ctx.save()

        const topLeftGradient = ctx.createLinearGradient(midX, midY, left, top)
        topLeftGradient.addColorStop(0, 'rgba(242, 198, 202, .1)')
        topLeftGradient.addColorStop(1, options.topLeft)
        ctx.fillStyle = topLeftGradient
        ctx.fillRect(left, top, midX - left, midY - top)

        const topRightGradient = ctx.createLinearGradient(midX, midY, right, top)
        topRightGradient.addColorStop(0, 'rgba(85, 59, 169, .1)')
        topRightGradient.addColorStop(1, options.topRight)
        ctx.fillStyle = topRightGradient
        ctx.fillRect(midX, top, right - midX, midY - top)

        const bottomRightGradient = ctx.createLinearGradient(midX, midY, right, bottom)
        bottomRightGradient.addColorStop(0, 'rgba(179, 199, 241, .1)')
        bottomRightGradient.addColorStop(1, options.bottomRight)
        ctx.fillStyle = bottomRightGradient
        ctx.fillRect(midX, midY, right - midX, bottom - midY)

        const bottomLeftGradient = ctx.createLinearGradient(midX, midY, left, bottom)
        bottomLeftGradient.addColorStop(0, 'rgba(243, 240, 254, .1)')
        bottomLeftGradient.addColorStop(1, options.bottomLeft)
        ctx.fillStyle = bottomLeftGradient
        ctx.fillRect(left, midY, midX - left, bottom - midY)
        ctx.restore()
      },
    },
  ]

  const data: any = {
    datasets: [
      {
        data: coordinateData,
        backgroundColor: pointBackgroundColor,
        borderColor: '#f2f2f2',
        borderWidth: 0.5,
        pointStyle: pointShape,
      },
    ],
  }

  return (
    <div className="scatterChart">
      <div className="scatterTitle__wrapper">
        <span className="scatterTitle__title">{apiData.categoryTitle}</span>
        <Popover trigger="hover" className="popoverInfo">
          <PopoverTrigger>
            <FontAwesomeIcon icon={faCircleInfo} />
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverHeader>{apiData.categoryChartTitle}:</PopoverHeader>
            <PopoverBody>
              <ul>
                {labelsDescriptions.map((desc) => {
                  return (
                    <li key={desc.title}>
                      <span className="popoverInfo__title">{desc.title}: </span>
                      <span className="popoverInfo__description">{desc.description}.</span>
                    </li>
                  )
                })}
              </ul>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </div>
      <Bubble options={options} plugins={quadrants} data={data} ref={chartRef} onClick={onClick} />
    </div>
  )
}

export default ScatterChart
