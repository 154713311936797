import { useParams } from 'react-router-dom'
import { PageWrapper } from 'core'
import CandidateInvitations from './CandidateInvitations'
import { InviteType, TabPage } from './common'
import OrganizationInvitations from './OrganizationInvitations'
import './styles.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faListCheck } from '@fortawesome/pro-solid-svg-icons'

const InvitationManagementPage = () => {
  const { type = InviteType.Organization, tab = TabPage.Active } = useParams<{
    type: InviteType
    tab: TabPage
  }>()

  return (
    <PageWrapper extraClassNames="manageInvitations">
      <div className="header">
        {type === InviteType.Organization ? (
          <FontAwesomeIcon className="page-icon" icon={faBuilding} fixedWidth={true} />
        ) : (
          <FontAwesomeIcon className="page-icon" icon={faListCheck} fixedWidth={true} />
        )}
        Manage {type === InviteType.Organization ? 'Company Sections' : 'Assessments'}
      </div>
      {type === InviteType.Organization ? (
        <OrganizationInvitations tab={tab} />
      ) : (
        <CandidateInvitations tab={tab} />
      )}
    </PageWrapper>
  )
}

export default InvitationManagementPage
