import { useEffect, useMemo, useState, useCallback } from 'react'

import {
  FilterTable,
  formatDate,
  handleError,
  PageWrapper,
  Tag,
  TagGroup,
  Toast,
  DebouncedInput,
  ActionDropdown,
  Color,
  AlignType,
} from 'core'
import { Role, UpsertMemberPayload, User, UserRole, Member, useUserContext } from 'provider'
import {
  createMember,
  getRoles,
  getMembers,
  inactivateMember,
  reactivateMember,
  updateMember,
} from '../../../actions/members'
import MemberEditModal, { MemberForm } from '../../../components/MemberEditModal'
import { useLoadingStates, useRefreshData, useDocumentTitle } from '../../../hooks'
import { mapToOption } from '../../../utils'
import {
  faPencil,
  faPlus,
  faToggleOff,
  faToggleOn,
  faBuildingUser,
} from '@fortawesome/pro-solid-svg-icons'
import { faSquareEllipsis } from '@fortawesome/pro-duotone-svg-icons'
import './styles.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@chakra-ui/react'

enum ActivityStatus {
  Active = 'active',
  Inactive = 'inactive',
}

const UserManagementPage = () => {
  useDocumentTitle('Manage Company Users')
  const { userContext } = useUserContext()
  const [editingMember, setEditingMember] = useState<MemberForm>()
  const [roles, setRoles] = useState<Role[]>([])
  const [isLoadingData, setIsLoadingData] = useState(true)
  const [isLoadingRoles, setIsLoadingRoles] = useState(true)
  const [activeStatus, setActiveStatus] = useState<ActivityStatus>(ActivityStatus.Active)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { counter, refreshData } = useRefreshData()
  const { toggleLoadingStateForKey } = useLoadingStates()
  const [tableData, setTableData] = useState<User[]>([])
  const [searchParams, setSearchParams] = useState<string>('')

  const fetchDataParams = useMemo(
    () => ({
      isActive: activeStatus === ActivityStatus.Active,
      pager: 'none',
      search: searchParams,
    }),
    [activeStatus, searchParams],
  )

  useEffect(() => {
    setIsLoadingData(true)
    const resolve = getMembers({
      ...fetchDataParams,
    })

    resolve.request
      .then((response) => {
        setIsLoadingData(false)
        setTableData(response)
      })
      .catch(handleError(setIsLoadingData))

    return resolve.cancelRequest
  }, [fetchDataParams, counter])

  const tags: Tag[] = [
    {
      text: 'Active',
      active: true,
      onActive: () => {
        switchActiveStatus(ActivityStatus.Active)
      },
    },
    {
      text: 'Inactive',
      active: false,
      onActive: () => {
        switchActiveStatus(ActivityStatus.Inactive)
      },
    },
  ]

  useEffect(() => {
    // TODO: create better way of managing dropdowns with pagination
    // for now, just use an absurd page size so its effectively not paginated
    const rolesRequest = getRoles({ pageSize: 1000, ordering: 'title' })
    rolesRequest.request
      .then((roles) => {
        setRoles(roles.results)
        setIsLoadingRoles(false)
      })
      .catch(handleError(setIsLoadingRoles))

    return rolesRequest.cancelRequest
  }, [])

  const onEditMember = (member: Member) => {
    setEditingMember({
      id: member.id,
      firstName: member.firstName,
      lastName: member.lastName,
      email: member.email,
      role: mapToOption(member, 'roleTitle', 'role'),
    })
  }

  const onNewMember = () => {
    setEditingMember({ firstName: '', lastName: '', email: '' })
  }

  const onSaveMember = async (payload: UpsertMemberPayload) => {
    let action = 'created'
    try {
      if (payload.id) {
        action = 'updated'
        await updateMember(payload).request
      } else {
        await createMember(payload).request
      }
      Toast.success(`User ${action} successfully`)
      refreshData()
    } catch (err) {
      handleError(undefined, { errorMessage: `User could not be ${action}` })(err)
    }
    setEditingMember(undefined)
  }

  const changeStatus = useCallback(
    async (member: Member, status: string) => {
      toggleLoadingStateForKey(member.id)
      const userRole = member.role
      let action = 'reactivated'
      try {
        if (status === ActivityStatus.Active) {
          await reactivateMember(member.id, member.email, userRole).request
        } else {
          action = 'inactivated'
          await inactivateMember(member.id, member.email, userRole).request
        }
        refreshData()
      } catch (err) {
        handleError(undefined, { errorMessage: `User could not be ${action}` })(err)
      }
      toggleLoadingStateForKey(member.id)
    },
    [refreshData, toggleLoadingStateForKey],
  )

  const switchActiveStatus = (type: ActivityStatus) => {
    if (type === activeStatus) {
      return
    }

    setActiveStatus(type)
  }

  const renderUserActions = useCallback(
    (row: Member) => {
      const disallowEditOwner =
        row.role === UserRole.Owner && userContext?.userRole !== UserRole.Owner
      const isCurrentUser = row.id === userContext?.membershipId
      const isDisabled = disallowEditOwner || isCurrentUser

      const actions =
        activeStatus === ActivityStatus.Active
          ? [
              {
                label: 'Edit',
                icon: faPencil,
                iconSize: 'xl',
                iconColor: Color.Purple,
                labelColor: Color.Purple,
                isDisabled: isDisabled,
                onClick: () => onEditMember(row),
              },
              {
                label: 'Deactivate',
                icon: faToggleOff,
                iconSize: 'xl',
                iconColor: Color.Red,
                labelColor: Color.Red,
                isDisabled: isDisabled,
                onClick: () => changeStatus(row, ActivityStatus.Inactive),
              },
            ]
          : [
              {
                label: 'Reactivate',
                icon: faToggleOn,
                iconSize: 'xl',
                iconColor: Color.Green,
                labelColor: Color.Green,
                isDisabled: isDisabled,
                onClick: () => changeStatus(row, ActivityStatus.Active),
              },
            ]

      return (
        <ActionDropdown
          actions={actions}
          alignButton={'right'}
          variant={'actionGhost'}
          icon={faSquareEllipsis}
          iconColor={'#3F2C7F'}
          iconSize={'2xl'}
        />
      )
    },
    [changeStatus, activeStatus, userContext],
  )

  const columns = useMemo<ColumnDef<Group>[]>(
    () => [
      {
        header: 'First Name',
        accessorKey: 'firstName',
        footer: (props) => props.column.id,
      },
      {
        header: 'Last Name',
        accessorKey: 'lastName',
        footer: (props) => props.column.id,
      },
      {
        header: 'Email',
        accessorKey: 'email',
        footer: (props) => props.column.id,
      },
      {
        header: 'Role',
        accessorFn: (row) => row.roleTitle,
        cell: ({ row }) => {
          const { original } = row
          return original.roleTitle
        },
        footer: (props) => props.column.id,
      },
      {
        header: 'Status',
        accessorFn: (row) => row.currentStatusTitle,
        cell: ({ row }) => {
          const { original } = row
          return original.currentStatusTitle
        },
        footer: (props) => props.column.id,
      },
      {
        header: 'Status Date',
        accessorFn: (row) => row.statusAt,
        meta: {
          align: AlignType.center,
        },
        cell: ({ row }) => {
          const { original } = row
          return formatDate(original.statusAt)
        },
        footer: (props) => props.column.id,
      },
      {
        id: 'actions',
        header: '',
        enableSorting: false,
        cell: ({ row }) => renderUserActions(row.original),
      },
    ],
    [renderUserActions],
  )

  return (
    <PageWrapper extraClassNames="userManage">
      <div className="header">
        <FontAwesomeIcon className="page-icon" icon={faBuildingUser} fixedWidth={true} />
        Manage Company Users
      </div>
      <div className="userManage__actions">
        <TagGroup tags={tags} label="Filter" />
        <Button
          type="button"
          variant="brandPrimary"
          onClick={onNewMember}
          leftIcon={<FontAwesomeIcon icon={faPlus} />}>
          Create User
        </Button>
      </div>
      <div className="filterInput">
        <DebouncedInput
          value={''}
          onChange={(value) => setSearchParams(String(value))}
          className="inputField__input fa-font"
          placeholder="Find..."
        />
      </div>
      <FilterTable schema={columns} data={tableData} isLoading={isLoadingData} />
      <MemberEditModal
        editingMember={editingMember}
        saveMember={onSaveMember}
        roles={roles}
        isLoadingRoles={isLoadingRoles}
        onClose={() => setEditingMember(undefined)}
      />
    </PageWrapper>
  )
}

export default UserManagementPage
