import { IconProps } from './IconProps'
import { Color } from '../../constants'

export default function Organization({ className, height = 20, width = 20 }: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0 L20 0 L10 20 Z" style={{ fill: Color.OrgBlue }} />
    </svg>
  )
}
