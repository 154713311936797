import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { CreatableMultiInput } from 'core'
import { TagDetail } from 'provider'
import Modal from '../Modal'
import { DropdownOption } from '../../models'
import { mapToOption, onSubmitForm, sortOptionByLabel } from '../../utils'
import { Button } from '@chakra-ui/react'

import './styles.scss'

export type TagForm = {
  userName: string
  objectId: string
  currentTags?: TagDetail[]
}

type TagEditProps = {
  onClose: () => void
  tenantTags: DropdownOption[]
  isLoadingTags: boolean
  saveTags: (tags: TagDetail[]) => Promise<void>
  editingTags?: TagForm
}

const mapAndSortTags = (tags: TagDetail[]) =>
  tags.map((tag) => mapToOption(tag, 'title', 'title')).sort(sortOptionByLabel)

const EditTagsModal = ({
  onClose,
  isLoadingTags,
  editingTags,
  saveTags,
  tenantTags,
}: TagEditProps) => {
  const [isPosting, setIsPosting] = useState(false)
  const [selectedTags, setSelectedTags] = useState<DropdownOption[]>([])

  const {
    control,
    handleSubmit,
    reset,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
    formState: { errors },
  } = useForm<TagForm>({
    defaultValues: {
      tags: selectedTags,
    },
  })

  useEffect(() => {
    if (editingTags) {
      setIsPosting(false)
      setSelectedTags(mapAndSortTags(editingTags.currentTags))
      reset(editingTags)
    }
  }, [editingTags, reset])

  const submitTags = async (data: TagsForm) => {
    setIsPosting(true)
    await saveTags(data)
    setIsPosting(false)
  }

  return (
    <Modal showModal={!!editingTags} onClose={onClose}>
      <form onSubmit={onSubmitForm(handleSubmit(submitTags))} className="tagEditModal__form">
        <h2 className="header">{editingTags?.userName}</h2>
        <div className="tagFormInput">
          <label className="tagFormInput__label"></label>
          <Controller
            name="tags"
            render={({ field, fieldState }) => (
              <CreatableMultiInput
                defaultValue={selectedTags}
                field={field}
                fieldState={fieldState}
                options={tenantTags}
                label="Tags"
                placeholder=""
                hasDefaultWidthRange={false}
                isLoading={isLoadingTags}
              />
            )}
            control={control}
          />
        </div>
        <div className="tagEditModal__actions">
          <Button
            type="button"
            className="tagEditModal__cancelBtn"
            onClick={onClose}
            variant="danger">
            Cancel
          </Button>
          <Button
            type="submit"
            isLoading={isPosting}
            className="tagEditModal__saveBtn"
            variant="brandPrimary">
            Save
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default EditTagsModal
