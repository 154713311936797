const CandidateIntro = ({ companyName }: { companyName: string }) => {
  return (
    <div className="guidePage__intro-wrapper">
      <div className="guidePage__section">
        <div className="guidePage__description">
          Welcome to Workzinga's Question Guide, custom generated for you if you have an interview
          with {companyName}. We're here to help in the conversation by providing questions and
          conversation starters based on the Culture Alignment Assessment you completed and which
          the company completed as well. Remember, all interviews should be a two-sided
          conversation; you are interviewing the company too! We encourage you to talk with the
          company about the type of atmosphere you're looking for, what kind of leadership gets the
          best out of you, and what workplace values are most important to you. Ask probing
          questions about the culture and leadership style you should expect to encounter if the
          company extends you an employment offer.
        </div>
      </div>
      <div className="guidePage__section">
        <div className="header">Interview Basics</div>

        <div className="guidePage__div">Preparation Is Important</div>
        <div className="guidePage__div">
          <ol>
            <li>
              Do your homework. You are asking for the company to hire you, so demonstrate your
              interest by knowing what they do. What do they produce or what services do they
              provide? Asking the interviewer, "What does your company do?”, tells them you are not
              prepared.
            </li>
            <li>
              Read and re-read the job posting so you are clear about the exact position you're
              being interviewed for; be ready with your own questions about the day-to-day
              responsibilities of the job. Ask about goals and expectations. If you want to do this
              job every day, you need to know what that really entails.
            </li>
            <li>
              Be ready to answer questions about your background and education. Know what your
              resume contains and be able to speak in detail about the jobs you have held - what
              were your responsibilities, what were your goals, and did you meet them, and what were
              your challenges at that company?
            </li>
          </ol>
        </div>

        <div className="guidePage__div">
          Now let's go over a few starter questions you can ask the interviewer. Be prepared to ask
          questions about the job description, the company, and anything about the position that
          would inform your decision to accept a job position at this company. We've provided a few
          below; feel free to adapt or change the questions as you see fit.
        </div>

        <div className="guidePage__div">
          <ol>
            <li>
              Is this a new position or a position that recently became vacant? Why is it new? Why
              is it vacant?
            </li>
            <li>What do you foresee to be the main challenges in this position?</li>
            <li>Where do you see the company in 3 to 5 years? Where are we going?</li>
            <li>
              How would you characterize the level of employee satisfaction and engagement here?
            </li>
            <li>When people choose to leave here, what are their main reasons?</li>
          </ol>
        </div>
      </div>
    </div>
  )
}

export default CandidateIntro
