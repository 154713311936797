import { useState, JSX } from 'react'
import ConfirmationModal from '../components/ConfirmationModal'

type Props = {
  textHeader?: string
  textBody: JSX.Element
  onSuccess?: () => void
}

export const useConfirmationModal = ({
  textHeader = 'Are you sure',
  textBody,
  onSuccess,
}: Props) => {
  const [showModal, setShowModal] = useState(false)

  const closeModal = () => {
    setShowModal(false)
  }
  const confirmationModal = (
    <ConfirmationModal
      textHeader={textHeader}
      textBody={textBody}
      onSuccess={onSuccess ?? closeModal}
      onCancel={closeModal}
      onClose={closeModal}
      showModal={showModal}
    />
  )
  return { confirmationModal, setShowModal }
}
