import { Tooltip } from '@chakra-ui/react'
import { faFileChartColumn, faClipboardList } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Column } from 'packages/core/src/components/FilterTable/types'
import { AlignType } from 'core'
import { Invitation, InvitationKind, WorkflowStatusType } from 'provider'
import { Link } from 'react-router-dom'

export enum InviteType {
  Organization = 'organization',
  Candidate = 'candidate',
}

export enum TabPage {
  Active = 'active',
  Inactive = 'inactive',
  Pending = 'pending',
  OpenPositions = 'open_positions',
  Last30Days = 'last_30_days',
  NotSet = '',
}

export function commonColumnData<T extends Invitation>(activeTab: TabPage): Column<T>[] {
  return [
    ...(activeTab !== TabPage.Pending
      ? [
          {
            header: 'Reports',
            accessorFn: () => ({}),
            enableSorting: false,
            meta: {
              align: AlignType.left,
            },
            cell: ({ row }: { row: any }) => {
              const { original } = row

              const isCompleted = original.currentStatus.code === WorkflowStatusType.Completed
              const isStandardKind = original.kind === InvitationKind.STANDARD

              return (
                <div className="report-container">
                  {isCompleted && (
                    <>
                      <div className="report-link">
                        <Tooltip
                          hasArrow
                          bg="brand.500"
                          color="white"
                          placement="right"
                          label={'Workplace Culture Profile'}>
                          <Link to={`/invitations/${original.id}/results`}>
                            <FontAwesomeIcon icon={faFileChartColumn} size="2x" color="#3f2c7f" />
                          </Link>
                        </Tooltip>
                      </div>

                      {isStandardKind ? null : (
                        <div className="report-link">
                          <Tooltip
                            hasArrow
                            bg="brand.500"
                            color="white"
                            placement="right"
                            label={'Interview Guide'}>
                            <Link to={`/invitations/guide/org/${original.id}`}>
                              <FontAwesomeIcon icon={faClipboardList} size="2x" color="#3f2c7f" />
                            </Link>
                          </Tooltip>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )
            },
          },
          {
            header: '% Alignment',
            accessorKey: 'overallMatch',
            meta: {
              align: AlignType.center,
            },
          },
        ]
      : []),
  ]
}
