import * as Sentry from '@sentry/react'
import { ErrorResponse, isAbortError, isErrorResponse } from 'provider'
import { Toast } from '../components'

type CallbackProps = {
  errorMessage?: string
  isSentryEnabled?: boolean
  shouldDisplayToast?: boolean
}

const sentryEnvEnabled = import.meta.env.VITE_SENTRY_DSN !== undefined

export const handleError =
  (
    setLoadingState?: (value: boolean) => void,
    {
      errorMessage,
      isSentryEnabled = sentryEnvEnabled,
      shouldDisplayToast = true,
    }: CallbackProps = {},
  ) =>
  (err: unknown) => {
    // if this is an abort error, then the component has simply been unmounted
    // so no need to do anything else
    if (isAbortError(err)) {
      return
    }

    // if errorMessage is not provided, then attempt to get statusText
    // from error and use that as the toast message
    let message = errorMessage
    let toastMessages: Array<string> | undefined = undefined
    let response: ErrorResponse | undefined = undefined
    if (isErrorResponse(err) && !message) {
      toastMessages = err.data?.errors?.messages
      message = err.statusText
      response = err
    }

    // if sentry is enabled, record error in logs
    if (isSentryEnabled) {
      if (err instanceof Error) {
        Sentry.captureException(err)
      } else {
        const sentryReport = new Error(message, { cause: err })
        Sentry.captureException(sentryReport)
      }
    }

    // if toast should be displayed, then display it
    if (shouldDisplayToast) {
      if (toastMessages) {
        toastMessages.map((e) => Toast.error(e))
      } else {
        Toast.error(message ?? 'An error occurred')
      }
    }

    // when provided, this is used to update the loading state for the component
    setLoadingState?.(false)

    return response
  }
