import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Assessment,
  AudienceType,
  CancellableRequest,
  GroupProfileDetail,
  SectionUser,
  Status,
} from 'provider'
import { DebouncedInput, FilterTable, AlignType, formatDate, handleError } from 'core'
import { getSectionUsers } from '../../../actions/assessments'
import { getSectionProfiles } from '../../../actions/profile'
import StatusTag from '../../../components/StatusTag'
import { ComparisonOption, SectionType } from '../../ComparisonProfileField'
import './styles.scss'
import { ColumnDef } from '@tanstack/react-table'

export type ComparisonProfileListProps = {
  sectionId: string
  isGroup: boolean
  assessment: Assessment
  onChange: (changed: ComparisonOption) => void
}

export const ComparisonProfileList = ({
  sectionId,
  isGroup,
  assessment,
  onChange,
}: ComparisonProfileListProps) => {
  // Results (search results)
  const [tableData, setTableData] = useState<SectionUser[] | GroupProfileDetail[]>([])
  const [searchParams, setSearchParams] = useState<string | undefined>(undefined)
  const [sectionIdState] = useState<string>(sectionId)

  // Pending Actions
  const [isLoadingData, setIsLoadingData] = useState(false)

  const columns = useMemo<ColumnDef<SectionUser | GroupProfileDetail>[]>(
    () => [
      {
        header: isGroup ? 'Group Profile' : 'Comparison Title',
        accessorFn: (row) => (isGroup ? row?.groupProfile : row?.label),
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableColumnFilter: true,
      },
      {
        header: isGroup ? 'Group' : 'User',
        accessorFn: isGroup
          ? (row: GroupProfileDetail) => row?.group
          : (row: SectionUser) => row?.user?.fullName,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableColumnFilter: true,
      },
      {
        header: 'Status Date',
        accessorKey: 'statusAt',
        meta: {
          align: AlignType.center,
        },
        cell: (info) => formatDate(info.getValue() as string),
        footer: (props) => props.column.id,
      },
      {
        header: 'Status',
        accessorFn: (row) => row.currentStatus?.title,
        meta: {
          align: AlignType.center,
        },
        cell: (info) => (
          <StatusTag status={(info.row.original.currentStatus as Status) ?? undefined} />
        ),
        footer: (props) => props.column.id,
        enableColumnFilter: true,
      },
    ],
    [isGroup],
  )

  const fetchDataParams = useMemo(() => {
    return {
      pager: 'none',
      search: searchParams,
    }
  }, [searchParams])

  const getTableData = useCallback(() => {
    setIsLoadingData(true)
    let tableRequest:
      | CancellableRequest<SectionUser[]>
      | CancellableRequest<GroupProfileDetail[]>
      | undefined

    if (!isGroup) {
      tableRequest = getSectionUsers({
        sectionId: sectionId,
        type: AudienceType.Organization,
        ...fetchDataParams,
      })
    } else {
      tableRequest = getSectionProfiles({ section: sectionIdState, ...fetchDataParams })
    }

    tableRequest?.request
      .then((response) => {
        setTableData(response)
        setIsLoadingData(false)
      })
      .catch((error) => {
        handleError(setIsLoadingData, { errorMessage: 'Trouble retrieving section profiles' })(
          error,
        )
      })

    return tableRequest.cancelRequest
  }, [fetchDataParams, sectionId, isGroup, assessment])

  useEffect(getTableData, [getTableData])

  const rowClick = (row: any) => {
    const { id, user: userDict, group, label, groupProfile, statusAt, currentStatus } = row
    const user = isGroup ? group : userDict.fullName
    const title = isGroup ? groupProfile : label

    const changed: ComparisonOption = {
      sectionType: isGroup ? SectionType.GROUP : SectionType.USER,
      id,
      title,
      user,
      date: statusAt,
      status: currentStatus,
    }
    onChange(changed)
  }

  return (
    <>
      <div className="comparisonProfileList">
        <div className="comparisonProfileList__content">
          <div className="filterInput">
            <DebouncedInput
              value={''}
              onChange={(value) => setSearchParams(String(value) || undefined)}
              className="inputField__input fa-font"
              placeholder="Find..."
            />
          </div>
          <FilterTable
            schema={columns}
            data={tableData}
            isLoading={isLoadingData}
            onRowClicked={rowClick}
          />
        </div>
      </div>
    </>
  )
}
