import classNames from 'classnames'
import { HTMLInputTypeAttribute } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'
import InputWrapper, { InputClassNames, InputWrapperProps } from '../InputWrapper'

type InputFieldProps = InputWrapperProps & {
  registerFields: UseFormRegisterReturn
  getValue: () => string
  placeholder?: string
  type?: HTMLInputTypeAttribute
  isAutoComplete?: boolean
  isReadonly?: boolean
  dataCy?: string
  maxLength?: number
  ignore1Password?: boolean
}

const InputField = ({
  registerFields,
  getValue,
  placeholder,
  type = 'text',
  isAutoComplete = false,
  isReadonly = false,
  dataCy,
  maxLength,
  ignore1Password = true,
  ...inputWrapperProps
}: InputFieldProps) => {
  const { errorMessage } = inputWrapperProps
  const conditionalClassNames = classNames({
    [InputClassNames.Filled]: !!getValue() && !errorMessage,
    [InputClassNames.Error]: !!errorMessage,
    [InputClassNames.Readonly]: isReadonly,
  })

  return (
    <InputWrapper {...inputWrapperProps}>
      <input
        className={`${InputClassNames.Default} ${conditionalClassNames}`}
        type={type}
        autoComplete={isAutoComplete ? 'on' : 'off'}
        readOnly={isReadonly}
        disabled={isReadonly}
        placeholder={placeholder}
        data-cy={dataCy || 'input'}
        maxLength={maxLength || undefined}
        {...(ignore1Password ? { 'data-1p-ignore': true } : {})}
        {...registerFields}
      />
    </InputWrapper>
  )
}

export default InputField
