const OrgIntro = ({ candidateName }: { candidateName: string }) => {
  return (
    <div className="guidePage__intro-wrapper">
      <div className="guidePage__section">
        <div className="guidePage__description">
          Welcome to Workzinga's Interview Guide, custom generated for a possible interview with{' '}
          {candidateName}. We're here to help in the conversation by providing questions and
          conversation starters based on the Culture Alignment Assessment of both yourself and the
          candidate. The interview should be two-sided, like a conversation. We encourage you to
          engage the candidate on what they're looking for in a future employer and what your
          organization offers. You'll want the candidate to leave the interview fully aware of the
          culture and leadership they should expect to encounter should you extend an offer.
        </div>
      </div>
      <div className="guidePage__section">
        <div className="header">Good starter questions</div>
        <div className="guidePage__description">
          We've provided some general questions below; we recommend that you be consistent with
          which questions you pick to help compare candidates across similar areas; however,
          remember to dive deep into their answers and ask plenty of follow-up questions.
          <ol>
            <li>What interests you about working for our company?</li>
            <li>What drew your interest to this position or type of work?</li>
            <li>What will you bring to this job that is unique to you?</li>
            <li>How will you measure your success and satisfaction in this position?</li>
            <li>
              When we consider all our candidates for this position, what do you hope we remember
              most about you?
            </li>
          </ol>
        </div>
      </div>
    </div>
  )
}

export default OrgIntro
