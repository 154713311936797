import { JSX } from 'react'
import { Outlet } from 'react-router-dom'
import LoadingIndicator from '../LoadingIndicator'

type RoleBasedRouteProps = {
  isAllowed: boolean
  isLoadingUser: boolean
  children?: JSX.Element
  notAllowedPage: JSX.Element
}

const RoleBasedRoute = ({
  isAllowed,
  isLoadingUser,
  children,
  notAllowedPage,
}: RoleBasedRouteProps) => {
  if (isLoadingUser) {
    return (
      <div>
        <LoadingIndicator fullWidth={true} fullHeight={true} />
      </div>
    )
  }

  if (!isAllowed) {
    return notAllowedPage
  }

  return children ? children : <Outlet />
}

export default RoleBasedRoute
