import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
  JSX,
} from 'react'
import type { GlobalError } from '../models'

type ErrorProviderProps = {
  children: JSX.Element
}

const ErrorContext = createContext<{
  globalErrors: GlobalError | undefined
  setGlobalErrors: Dispatch<SetStateAction<GlobalError | undefined>>
}>({
  globalErrors: undefined,
  setGlobalErrors: () => {},
})

export const useErrorContext = () => useContext(ErrorContext)

const ErrorProvider = ({ children }: ErrorProviderProps) => {
  const [globalErrors, setGlobalErrors] = useState<GlobalError | undefined>(undefined)

  const value = useMemo(
    () => ({
      globalErrors,
      setGlobalErrors,
    }),
    [globalErrors],
  )
  useEffect(() => {
    return () => {}
  }, [])

  return <ErrorContext.Provider value={value}>{children}</ErrorContext.Provider>
}

export default ErrorProvider
