import { faBuilding, faStar, faEnvelope, faCheck, faXmark } from '@fortawesome/pro-solid-svg-icons'
import { faBarsProgress, faHourglassHalf } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UserContextChoice, UserContextKind, useUserContext } from 'provider'
import './styles.scss'
import { createUserContext } from '../../../actions/signIn'
import React, { useMemo } from 'react'
import { Color } from 'core/src/constants'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

type ContextCardProps = {
  userContextChoice: UserContextChoice
}

const ContextCard: React.FC<ContextCardProps> = ({ userContextChoice }) => {
  const { userContext, setUserContext } = useUserContext()
  let content
  let invitationIcon: IconProp = faEnvelope
  let invitationIconColor: string = Color.Blue

  if (userContextChoice?.kind === UserContextKind.Invitation) {
    if (userContextChoice?.currentStatusId === 'WIP') {
      invitationIcon = faBarsProgress
      invitationIconColor = Color.Warning
    } else if (userContextChoice?.currentStatusId === 'COMPLT') {
      invitationIcon = faCheck
      invitationIconColor = Color.Green
    } else if (userContextChoice?.currentStatusId === 'WAITRES') {
      invitationIcon = faHourglassHalf
      invitationIconColor = Color.Warning
    } else if (
      userContextChoice?.currentStatusId &&
      ['CANCEL', 'UNDELVBL'].includes(userContextChoice.currentStatusId)
    ) {
      invitationIcon = faXmark
      invitationIconColor = Color.Red
    }
  }
  const isActiveContext = useMemo(() => {
    if (!userContext) {
      return false
    }
    if (userContextChoice.kind === UserContextKind.Membership) {
      return userContextChoice?.membershipId === userContext?.membershipId
    }
    if (userContextChoice.kind === UserContextKind.Invitation) {
      return userContextChoice?.invitationId === userContext?.invitationId
    }
    return false
  }, [userContextChoice, userContext])

  const conditionalClasses = isActiveContext ? 'active' : ''

  const handleClick = async () => {
    const response = await createUserContext(userContextChoice).request
    if (response) {
      setUserContext(response)
      window.location.href = '/'
    }
  }

  if (userContextChoice?.kind === UserContextKind.Membership) {
    content = (
      <div className="contextCard__tenantDetails">
        {isActiveContext && (
          <FontAwesomeIcon
            icon={faStar}
            size="1x"
            className="contextCard__starIcon"></FontAwesomeIcon>
        )}
        {userContextChoice?.tenantLogo ? (
          <>
            <div className="contextCard__companyName">{userContextChoice?.tenantTitle}</div>
            <img
              src={userContextChoice.tenantLogo}
              alt={`${userContextChoice.tenantTitle} Logo`}
              className="contextCard__logo"
            />
          </>
        ) : (
          <>
            <div className="contextCard__companyName">{userContextChoice?.tenantTitle}</div>
            <div className="contextCard__icon">
              <FontAwesomeIcon icon={faBuilding} size="3x"></FontAwesomeIcon>
            </div>
          </>
        )}
      </div>
    )
  } else if (userContextChoice?.kind === UserContextKind.Invitation) {
    content = (
      <>
        {isActiveContext && (
          <FontAwesomeIcon
            icon={faStar}
            size="1x"
            className="contextCard__starIcon"></FontAwesomeIcon>
        )}
        <div className="contextCard__assessmentDetails">
          <div className="contextCard__assessmentDetails__title">
            {userContextChoice?.assessment} Assessment
          </div>
          {userContextChoice?.tenantTitle && (
            <div className="contextCard__assessmentDetails__companyName">
              {userContextChoice?.tenantTitle}
            </div>
          )}
          {userContextChoice?.positionTitle && (
            <div className="contextCard__assessmentDetails__positionTitle">
              {userContextChoice?.positionTitle}
            </div>
          )}
          {/* {userContextChoice?.invitedAt && (
            <div className="contextCard__assessmentDetails__date">
              {new Date(userContextChoice.invitedAt).toLocaleDateString()}
            </div>
          )} */}
        </div>
        <div
          className={
            'contextCard__statusDetails ' + userContextChoice?.currentStatusId?.toLowerCase()
          }>
          <div className="contextCard__statusDetails__currentStatus">
            {userContextChoice?.currentStatusTitle?.toUpperCase()}
          </div>
          <div>
            <FontAwesomeIcon
              icon={invitationIcon}
              size="2x"
              color={invitationIconColor}></FontAwesomeIcon>
          </div>
          {userContextChoice?.statusAt && (
            <div className="contextCard__statusDetails__date">
              {new Date(userContextChoice.statusAt).toLocaleDateString()}
            </div>
          )}
        </div>
      </>
    )
  }

  return (
    <div
      className={`contextCard ${
        userContextChoice?.kind === UserContextKind.Membership ? 'cardMember' : 'cardInvitation'
      } ${conditionalClasses}`}
      onClick={isActiveContext ? () => {} : handleClick}>
      {content}
    </div>
  )
}

export default ContextCard
