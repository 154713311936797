import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { LoginResponse, User } from '../models'
import { isSessionAvailable } from './authUtils'
import api from '../api'
import { basicLogin } from 'disco/src/actions/signIn'
import { LRTools } from '../services'
import * as Sentry from '@sentry/react'
import EventEmitter from 'events'
import { UserProvider } from './UserContext'

type AuthContextType = {
  user: User | undefined
  isAuthenticated: boolean
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>
  isLoading: boolean
  signIn: (email: string, password: string) => Promise<LoginResponse | undefined>
  signOut: () => void
  contextModalTitle: string
  userContextsInfo: {
    hasMembership: boolean
    hasInvitation: boolean
    hasBoth: boolean
  }
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

const authEventEmitter = new EventEmitter()

export const onAuthChange = (listener: any) => {
  authEventEmitter.on('authChange', listener)
  return () => authEventEmitter.removeListener('authChange', listener)
}

type AuthProviderProps = {
  children?: React.ReactNode
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | undefined>(undefined)
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [contextModalTitle, setContextModalTitle] = useState<string>(
    'Click on a card below to set your login context',
  )

  useEffect(() => {
    setIsLoading(isAuthenticated && !user)
    authEventEmitter.emit('authChange', user)
  }, [user, isAuthenticated])

  useEffect(() => {
    const loadUser = async () => {
      if (isSessionAvailable()) {
        try {
          // console.log('Loading User')
          const userData = await api.getCurrentUser().request
          setUser(userData)
          setIsAuthenticated(true)
          Sentry.setUser({ email: userData?.email })
          LRTools.identify(userData)
        } catch (error) {
          // console.log('Error loading user', error)
          setUser(undefined)
          setIsAuthenticated(false)
        }
      } else {
        setUser(undefined)
        setIsAuthenticated(false)
      }
    }
    // console.log('loadUser called')
    loadUser()
    // console.log('loadUser exited')
  }, [])

  const signIn = async (email: string, password: string): Promise<LoginResponse | undefined> => {
    try {
      const loginResponse = await basicLogin({ username: email, password: password }).request
      const userData = await api.getCurrentUser().request
      setUser(userData)
      setIsAuthenticated(true)
      return loginResponse
    } catch (error) {
      setUser(undefined)
      setIsAuthenticated(false)
      throw error
    }
  }

  const signOut = async () => {
    if (isSessionAvailable()) {
      // clear session cookies
      await api.logout().request
    }
    setUser(undefined)
    setIsAuthenticated(false)
    // console.log('User signed out')
  }

  const userContextsInfo = useMemo(() => {
    const hasMembership =
      user?.userContexts?.some((context) => context.kind === 'membership') || false
    const hasInvitation =
      user?.userContexts?.some((context) => context.kind === 'invitation') || false
    const info = {
      hasMembership,
      hasInvitation,
      hasBoth: hasMembership && hasInvitation,
    }
    if (!info.hasBoth && info.hasMembership) {
      setContextModalTitle('Click on the Company you wish to login as')
    } else if (!info.hasBoth && info.hasInvitation) {
      setContextModalTitle('Click on the Invitation you wish to access')
    } else {
      setContextModalTitle('Click on a card below to set your login context')
    }
    return info
  }, [user])

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
        setIsAuthenticated,
        isLoading,
        signIn,
        signOut,
        userContextsInfo,
        contextModalTitle,
      }}>
      <UserProvider>{children}</UserProvider>
    </AuthContext.Provider>
  )
}

// Hook to use auth context
export const useAuth = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}
