import styles from './styles.module.scss'
import { useDocumentTitle } from '../../hooks'
import TopNav from '../../components/TopNav'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse } from '@fortawesome/pro-solid-svg-icons'

const NotPermittedPage = () => {
  useDocumentTitle('Not Permitted')
  return (
    <>
      <TopNav />
      <div className={styles.fourohthree}>
        <h1>403</h1>
        <p>⛔ Sorry, you are not authorized to access the requested page.</p>
        <div className={styles.container}>
          <NavLink to="/" className={styles.centeredButton}>
            <FontAwesomeIcon icon={faHouse} size="1x" fixedWidth={true} />
            <span>Go Home</span>
          </NavLink>
        </div>
      </div>
    </>
  )
}

export default NotPermittedPage
