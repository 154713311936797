import { useAuth } from 'provider'
import ContextCard from '../ContextCard'
import './styles.scss'

const ContextCardsContainer: React.FC = () => {
  const { user } = useAuth()

  if (!user || !user.userContexts.length) {
    return <div>No contexts available.</div>
  }

  return (
    <div className="contextCardsContainer">
      {user.userContexts.map((context, index) => (
        <ContextCard key={index} userContextChoice={context} />
      ))}
    </div>
  )
}

export default ContextCardsContainer
