import { JSX } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/pro-solid-svg-icons'
import ReactModal from 'react-modal'

import './styles.scss'

interface ModalProps {
  showModal: boolean
  onClose: () => void
  className?: string
  children: JSX.Element
  contentLabel?: string
  dataCy?: string
}

const Modal = ({ showModal, onClose, className = '', children, dataCy, ...props }: ModalProps) => {
  return (
    <ReactModal
      overlayClassName="wzModal__overlay"
      preventScroll={true}
      isOpen={showModal}
      ariaHideApp={false}
      onRequestClose={onClose}
      className={'wzModal ' + className}
      {...props}>
      <div className="wzModal__contents" data-cy={dataCy || 'modal'}>
        <span className="wzModal__close" onClick={onClose}>
          <FontAwesomeIcon icon={faClose} size="lg" />
        </span>
        {children}
      </div>
    </ReactModal>
  )
}

ReactModal.setAppElement('#root')

export default Modal
