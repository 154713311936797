import { UserRole, UserContext, Member } from 'provider'

export const checkUserPermissions = (
  userContext: UserContext | undefined,
  perm: string | Array<string>,
): boolean => {
  if (!perm) return false

  if (!userContext || !userContext.permissions) return false

  const { permissions } = userContext
  let granted = false

  if (Array.isArray(perm)) {
    // If perm is an array, check if at least one permission is granted
    granted = perm.some((p) => permissions.includes(p))
  } else {
    // If perm is a single string, check if it is in permissions
    granted = permissions.includes(perm)
  }

  return granted
}

export const isSessionAvailable = (): boolean => {
  const sessionExpiry = getCookie('workzinga-session-expiry')
  if (!sessionExpiry) {
    return false
  }

  const expirationDate = new Date(sessionExpiry)
  const now = new Date()

  return expirationDate.getTime() > now.getTime()
}

export const getCookie = (cookieName: string): string | null => {
  const cookies = document.cookie.split('; ')
  const expiryCookie = cookies.find((cookie) => cookie.startsWith(cookieName + '='))
  if (!expiryCookie) {
    return null
  }

  return expiryCookie.split('=')[1]
}

function isUserContext(user: UserContext | Member | undefined): user is UserContext {
  return (user as UserContext).userRole !== undefined
}

export const hasRole = (user: UserContext | Member | undefined, roles: UserRole[]): boolean => {
  if (!roles.length) {
    return true
  }
  if (!user) {
    return false
  }

  const userRole = isUserContext(user) ? user.userRole : user.role
  if (userRole) {
    return roles.includes(userRole)
  }
  return false
}

export const isOrgMember = (user: Member | undefined) => hasRole(user, [UserRole.Member])
export const isOrgPowerUser = (user: Member | undefined) =>
  hasRole(user, [UserRole.Recruiter, UserRole.Owner])

export function extractInvitationId(url: string | null): string | undefined {
  if (url) {
    const match = url.match(/\/invitations\/([^/]+)(\/.*)?/)
    if (match) {
      return match[1]
    }
  }
  return undefined
}
