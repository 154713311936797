import classNames from 'classnames'
import { TailSpin } from 'react-loader-spinner'
import './styles.scss'

type LoadingIndicatorProps = {
  fullWidth?: boolean
  fullHeight?: boolean
}

const LoadingIndicator = ({ fullWidth = false, fullHeight }: LoadingIndicatorProps) => {
  return (
    <div
      className={classNames('loadingIndicator', {
        'loadingIndicator--fullWidth': fullWidth,
        'loadingIndicator--fullHeight': fullHeight,
      })}>
      <TailSpin color="grey" ariaLabel="loading" />
    </div>
  )
}

export default LoadingIndicator
