import getQueryParams from './getQueryParams'
import getStringIfDefined from './getStringIfDefined'

export type CommonParams = {
  page?: number
  pageSize?: number
  ordering?: string
  pager?: string
  search?: string
}

function getCommonParams({
  page,
  pageSize,
  ordering,
  pager,
  search,
  existingParams,
}: CommonParams & { existingParams?: string }) {
  const params = {
    page: getStringIfDefined(page),
    pager: getStringIfDefined(pager),
    page_size: getStringIfDefined(pageSize),
    search: getStringIfDefined(search),
    ordering,
  }
  return getQueryParams({ params, existingParams })
}

export default getCommonParams
