import { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
  handleError,
  LoadingIndicator,
  PageWrapper,
  ProgressBar,
  Question as QuestionComponent,
} from 'core'
import { CancellableRequest, ignoreAbortError, Question } from 'provider'
import { getSectionUserQuestion, saveAnswer } from '../../../actions/assessments'
import { KnownError } from '../../../constants'
import { getErrorDetails } from '../../../utils'
import './styles.scss'

const SectionDetails = () => {
  const { sectionUserId } = useParams<{ sectionUserId: string }>()
  const searchParams = useSearchParams()[0]
  const navigate = useNavigate()

  const [isComplete, setIsComplete] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [question, setQuestion] = useState<Question>()
  const [nextModule, setNextModule] = useState<string>()

  useEffect(() => {
    let isRendered = true
    let questionRequest: CancellableRequest<Question> | undefined = undefined

    if (sectionUserId) {
      setIsLoading(true)
      questionRequest = getSectionUserQuestion(sectionUserId)
      questionRequest.request
        .then(setQuestion)
        .catch(ignoreAbortError)
        .catch((err: unknown) => {
          if (isRendered) {
            const details = getErrorDetails(err)
            setIsComplete(details.includes(KnownError.SectionUserComplete))
          }
        })
        .finally(() => isRendered && setIsLoading(false))
    }

    return () => {
      isRendered = false
      questionRequest?.cancelRequest()
    }
  }, [sectionUserId])

  useEffect(() => {
    if (isComplete) {
      const { nextModuleUserId, id, currentStatus } = nextModule
      let navTarget = ''

      if (nextModuleUserId) {
        navTarget = `/sections/${nextModuleUserId}`
      }

      if (id && currentStatus) {
        navTarget = `/invitations/${id}/${currentStatus?.isFinal ? 'complete' : 'welcome'}`
      }

      const redirectParam = searchParams.get('redirect')

      if (navTarget) {
        navigate(`${navTarget}?${redirectParam}`)
      } else {
        navigate(redirectParam)
      }
    }
  }, [isComplete, searchParams, navigate, nextModule])

  const handleOnNext = (answers: string[]) => {
    if (!sectionUserId) {
      return
    }

    setIsSaving(true)
    saveAnswer(sectionUserId, answers)
      .request.then((nextQuestion) => {
        if (!nextQuestion || !('questionText' in nextQuestion)) {
          setNextModule(nextQuestion)
          setIsComplete(true)
          setQuestion(undefined)
        } else {
          setQuestion(nextQuestion)
        }
      })
      .catch((err) => {
        setQuestion(undefined)
        handleError()(err)
      })
      .finally(() => {
        setIsSaving(false)
      })
  }

  const renderNoQuestion = () => {
    if (isLoading) {
      return <LoadingIndicator fullWidth={true} />
    }
    if (isComplete) {
      return <div className="loading-message">You've completed this section!</div>
    }
    return (
      <div className="loading-message">
        We apologize, something went wrong.
        <br />
        Please try refreshing page or contacting support@workzinga.com
      </div>
    )
  }

  if (!(sectionUserId && question) || isLoading) {
    return renderNoQuestion()
  }

  return (
    <PageWrapper extraClassNames="details">
      <div className="details__header--wrapper">
        <div className="details__header">
          <div className="header details__title">{question.sectionText}</div>
        </div>
        <ProgressBar
          className="details__progressBar"
          percentage={question.sectionUserProgress}
          total={question.sectionUserQuestionCount}
        />
      </div>
      <QuestionComponent
        kind={question.questionKind}
        text={question.questionText}
        helperText={question.questionHelperText}
        numberOfSelectionsNeeded={question.questionExpectedReplies}
        choices={question.choices}
        isSaving={isSaving}
        onNext={handleOnNext}
      />
    </PageWrapper>
  )
}

export default SectionDetails
