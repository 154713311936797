import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { EMAIL_REGEX, InputField, SelectDropdown } from 'core'
import { Role, UpsertMemberPayload, UserRole } from 'provider'
import Modal from '../Modal'
import { DropdownOption } from '../../models'
import { mapToOption, onSubmitForm } from '../../utils'
import './styles.scss'
import { Button } from '@chakra-ui/react'

type MemberEditProps = {
  onClose: () => void
  roles: Role[]
  isLoadingRoles: boolean
  saveMember: (member: UpsertMemberPayload) => Promise<void>
  editingMember?: MemberForm
}

export type MemberForm = {
  id?: string
  firstName: string
  lastName: string
  email: string
  role?: DropdownOption
}

const MemberEditModal = ({
  onClose,
  roles,
  isLoadingRoles,
  editingMember,
  saveMember,
}: MemberEditProps) => {
  const [isPosting, setIsPosting] = useState(false)

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<MemberForm>({
    defaultValues: {
      id: '',
      firstName: '',
      lastName: '',
      email: '',
    },
  })

  useEffect(() => {
    if (editingMember) {
      setIsPosting(false)
      reset(editingMember)
    }
  }, [editingMember, reset])

  const submitUser = async (data: MemberForm) => {
    setIsPosting(true)
    await saveMember({
      ...data,
      role: data.role?.value ?? UserRole.Member,
    })
    setIsPosting(false)
  }

  return (
    <Modal showModal={!!editingMember} onClose={onClose}>
      <form onSubmit={onSubmitForm(handleSubmit(submitUser))} className="userEditModal__form">
        <h2 className="header">Company User</h2>
        <table>
          <tbody>
            <tr>
              <td>
                <InputField
                  registerFields={register('firstName', { required: 'Required field' })}
                  getValue={() => watch('firstName')}
                  label="First Name"
                  errorMessage={errors.firstName?.message}
                  hasDefaultMargin={false}
                />
              </td>
            </tr>
            <tr>
              <td>
                <InputField
                  registerFields={register('lastName', { required: 'Required field' })}
                  getValue={() => watch('lastName')}
                  label="Last Name"
                  errorMessage={errors.lastName?.message}
                  hasDefaultMargin={false}
                />
              </td>
            </tr>
            <tr>
              <td>
                <InputField
                  registerFields={register('email', {
                    required: 'Required field',
                    pattern: {
                      value: EMAIL_REGEX,
                      message: 'Invalid email address',
                    },
                  })}
                  getValue={() => watch('email')}
                  label="Email"
                  errorMessage={errors.email?.message}
                  isReadonly={!!editingMember?.id}
                  hasDefaultMargin={false}
                />
              </td>
            </tr>
            {roles.length > 0 && (
              <tr>
                <td>
                  <Controller
                    name="role"
                    render={({ field, fieldState }) => (
                      <>
                        <label className="inputField__label">Role</label>
                        <SelectDropdown
                          field={field}
                          fieldState={fieldState}
                          defaultValue={editingMember?.role}
                          options={roles.map((role) => mapToOption(role, 'title', 'code'))}
                          placeholder="Choose One"
                          isLoading={isLoadingRoles}
                        />
                      </>
                    )}
                    control={control}
                    rules={{ validate: (option) => !!option?.value || 'Required field' }}
                  />
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="userEditModal__actions">
          <Button
            type="button"
            className="userEditModal__cancelBtn"
            onClick={onClose}
            variant="danger">
            Cancel
          </Button>
          <Button
            type="submit"
            isLoading={isPosting}
            className="userEditModal__saveBtn"
            variant="brandPrimary">
            Save
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default MemberEditModal
