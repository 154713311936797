import { ErrorResponse } from '../api'

function isErrorResponse(error: unknown): error is ErrorResponse {
  return (
    !!error &&
    typeof error === 'object' &&
    'status' in error &&
    'statusText' in error &&
    'data' in error
  )
}

export default isErrorResponse
