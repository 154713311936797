import { useEffect, useState, useMemo, JSX } from 'react'
import { useParams } from 'react-router-dom'
import { formatDate, handleError } from 'core'
import {
  isParagraphCategory,
  isSpectrumCategory,
  Result,
  ResultType,
  AudienceType,
  useAuth,
  isWordCloudCategory,
  InvitationKind,
  SampleTier,
  useUserContext,
  CancellableRequest,
} from 'provider'
import classNames from 'classnames'
import { getGroupProfileResults, getInvitationResults } from '../../../actions/invitations'
import { isEmpty } from '../../../utils'
import ParagraphCategory from './ParagraphCategory'
import SpectrumCategory from './SpectrumCategory'
import WordCloudCategory from './WordCloudCategory'
import AlignmentChart from '../SharedComponents/AlignmentChart'

import './styles.scss'

type InvitationResultsParamsType = {
  invitationId?: string
  groupProfileId?: string
  sampleType?: string
  viewerAudience?: AudienceType
  invitationKind?: InvitationKind
  sampleTier?: SampleTier
  key?: string
}

const InvitationResults = () => {
  const params = useParams<InvitationResultsParamsType>()
  const groupProfileId = params?.groupProfileId ?? ''
  const invitationId = params?.invitationId
  const sampleType = params?.sampleType?.replace(/-/g, '_').toUpperCase()
  const viewerAudience = params?.viewerAudience?.toUpperCase()
  const [invitationKind, setInvitationKind] = useState<InvitationKind | string | undefined>(
    params?.invitationKind?.toUpperCase(),
  )
  const sampleTier = params?.sampleTier
  // techdebt: later can extract this key to env variable to invalidate shared links
  const isRedacted =
    params?.key && params?.key !== '178a64ca-b2a1-4cf0-84d4-0e6f6e8fa86c' ? true : false

  const { user } = useAuth()
  const { userContext } = useUserContext()
  const isCandidateAudience =
    userContext?.userAudience === AudienceType.Candidate ||
    viewerAudience === AudienceType.Candidate
  const [isLoading, setIsLoading] = useState(true)
  const [result, setResult] = useState<Result>()
  const [overviewChartData, setOverviewChartData] = useState<Array<number>>()
  const [overviewChartLabels, setOverviewChartLabels] = useState<Array<string>>()
  const [matchPercentColor, setMatchPercentColor] = useState<string>('red')
  const [overviewDescription, setOverviewDescription] = useState<Array<string>>()
  const [resultPageType, setResultPageType] = useState<ResultType>(ResultType.Empty)
  const [invitationAudience, setInvitationAudience] = useState<AudienceType | undefined>()

  const conditionalClassNames = classNames({
    'page-sample': isRedacted,
  })

  function parseResults(data: any) {
    setResult(data)
    setResultPageType(data.type)
    setInvitationAudience(data.invitationAudience)
    setInvitationKind(data.invitationKind)
    if (data?.overview?.chart) {
      setOverviewChartData(data.overview.chart.data)
      const newLabels = data.overview.chart.labels.map((label: string) => {
        return label.split(' ')
      })
      setOverviewChartLabels(newLabels)
    }

    if (data?.overview?.description) {
      const description = data.overview.description.split('\n').reduce(
        (acc: string[], curr: string, idx: number) => {
          if (idx <= 1) {
            acc[idx] = curr
          } else {
            acc[1] = [acc[1], curr].join('\n')
          }
          return acc
        },
        ['', ''],
      )
      setOverviewDescription(description)
    }

    if (data.overallMatchPercent > 70) {
      setMatchPercentColor('green')
    } else if (data.overallMatchPercent >= 50) {
      setMatchPercentColor('orange')
    }

    setIsLoading(false)
  }

  useEffect(() => {
    let isMounted = true
    let resultsRequest: CancellableRequest<Result>

    const fetchData = async () => {
      if (!groupProfileId) {
        if (!invitationId || !user?.id) {
          if (viewerAudience && invitationKind && sampleTier && sampleType) {
            try {
              const mockModule = await import('./results.mock')
              const dummyData =
                mockModule.sampleData[viewerAudience][sampleType][invitationKind][sampleTier]
              if (isMounted) {
                parseResults(dummyData)
              }
            } catch (error) {
              if (isMounted) {
                handleError(setIsLoading)(error)
              }
            }
          }
          return
        }
      }

      resultsRequest = invitationId
        ? getInvitationResults(invitationId)
        : getGroupProfileResults(groupProfileId)

      resultsRequest.request
        .then((data) => {
          if (isMounted) {
            parseResults(data)
          }
        })
        .catch((error) => {
          if (isMounted) {
            handleError(setIsLoading, error)
          }
        })
    }

    fetchData()

    return () => {
      isMounted = false
      if (resultsRequest && resultsRequest.cancelRequest) {
        resultsRequest.cancelRequest()
      }
    }
  }, [])

  const { paragraphs } = useMemo(() => {
    if (result === undefined || isEmpty(result)) {
      return { paragraphs: [] }
    }

    const paragraphs: JSX.Element[] = []
    const guideColors = ['#15BDE8', '#B4288E', '#230C3B', '#FF7376', '#1b998b', '#5530a8']

    result.categories.forEach((category, idx) => {
      if (isEmpty(category)) {
        // filter out empty categories
        return
      }
      if (isParagraphCategory(category)) {
        const paragraph = (
          <ParagraphCategory
            key={`${category.categoryTitle + idx}`}
            category={category}
            organizationName={result.organizationName}
            candidateName={result.candidateName}
            chart={category.chart}
            color={guideColors[idx]}
          />
        )

        paragraphs.push(paragraph)
      } else if (isSpectrumCategory(category)) {
        const spectrum = (
          <SpectrumCategory
            key={idx}
            category={category}
            organizationName={result.organizationName}
            candidateName={result.candidateName}
            color={guideColors[idx]}
            type={resultPageType}
          />
        )

        paragraphs.push(spectrum)
      } else if (isWordCloudCategory(category)) {
        const cloud = (
          <WordCloudCategory
            key={idx}
            category={category}
            organizationName={result.organizationName}
            candidateName={result.candidateName}
            color={guideColors[idx]}
          />
        )

        paragraphs.push(cloud)
      }
    })

    return { paragraphs }
  }, [result, resultPageType])

  if (isLoading) {
    return <div className="loading-message">Loading...</div>
  }

  if (result === undefined || isEmpty(result)) {
    return <div className="loading-message">Could not load results</div>
  }

  return (
    <div className={`resultPage ${resultPageType}-page ${conditionalClassNames}`}>
      <div className="resultPage__sectionWrapper resultPage__sectionWrapper--gray">
        <div className="resultPage__section resultHeader">
          <div className="resultHeader__section">
            {result.logoUrl && (
              <div>
                <img src={result.logoUrl} alt="Organization Logo" className="brand-logo" />
              </div>
            )}

            <div className="resultHeader__heading">
              {isCandidateAudience || invitationAudience === AudienceType.Organization
                ? result.organizationName
                : result.candidateName}
            </div>
            <div className="resultHeader__subheading">{result.reportTitle}</div>
            <div className="resultHeader__subheading--position">{result.positionTitle}</div>
            <div className="resultHeader__subheading--completion-date">
              Assessment Completed {formatDate(result.completedOn)}
            </div>
          </div>
          <div className="resultHeader__section">
            <div className="resultPage__div resultLogo">
              <img className="resultPage__logo" src="/logo-purple.svg" alt="Workzinga logo" />
            </div>
            {result.overallMatchPercent !== undefined && (
              <div className="match-circle">
                <div className="single-chart">
                  <svg viewBox="0 0 36 36" className={`circular-chart ${matchPercentColor}`}>
                    <path
                      className="circle-bg"
                      d="M18 2.0845
                        a 15.9155 15.9155 0 0 1 0 31.831
                        a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                    <path
                      className="circle"
                      strokeDasharray={`${result.overallMatchPercent}, 100`}
                      d="M18 2.0845
                        a 15.9155 15.9155 0 0 1 0 31.831
                        a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                    <text x="18" y="22" className={`percentage ${matchPercentColor}`}>
                      {result.overallMatchPercent}%
                    </text>
                  </svg>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="resultPage__sectionWrapper resultPage__resultWrapper">
        <div className="resultPage__overview">
          <div className="resultPage__overview--header header">Overview</div>
          <div className="resultPage__overview--description">{overviewDescription?.[0]}</div>
          {overviewChartData && overviewChartLabels && (
            <div className="resultPage__overview--chart">
              <AlignmentChart
                chartData={overviewChartData}
                labels={overviewChartLabels}
                background="#F9E5F5"
              />
            </div>
          )}
          <div className="resultPage__overview--footer">{overviewDescription?.[1]}</div>
        </div>
        <div className="resultPage__section">
          {paragraphs.map((paragraph) => (
            <div key={paragraph.key} className="resultCategory">
              {paragraph}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default InvitationResults
