import { createContext, useContext, useEffect, useState } from 'react'
import { useAuth } from './AuthContext'
import { UserContext, UserContextChoice, UserContextKind, UserRole } from '../models/User'
import { CreateUserContext } from '../api/payload-models/CreateUserContext'
import { isSessionAvailable, hasRole, checkUserPermissions } from './authUtils'
import api from '../api'

type SessionContextType = {
  userContext: UserContext | undefined
  isLoadingUserContext: boolean
  createUserContext: (userContextChoice: UserContextChoice) => Promise<void>
  getUserContext: () => Promise<UserContext | undefined>
  setUserContext: React.Dispatch<React.SetStateAction<UserContext | undefined>>
}

const SessionContext = createContext<SessionContextType | undefined>(undefined)

type UserProviderProps = {
  children?: React.ReactNode
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const { user, isLoading: isLoadingAuth, signOut } = useAuth()
  const [userContext, setUserContext] = useState<UserContext | undefined>(undefined)
  const [isLoadingUserContext, setIsLoadingUserContext] = useState<boolean>(true)

  useEffect(() => {
    let isMounted = true

    const fetchUserContext = async () => {
      if (!user || isLoadingAuth) {
        setIsLoadingUserContext(false)
        return
      }
      setIsLoadingUserContext(true)
      try {
        if (isSessionAvailable()) {
          // console.log('Session Available, fetching user context')
          const currentContext = await getUserContext()
          if (!isMounted) {
            return
          }
          if (!currentContext) {
            if (user && user.userContexts.length > 1) {
              // context modal will be shown
            } else if (user && user.userContexts.length === 1) {
              const userContextChoice: UserContextChoice = user.userContexts[0]
              await createUserContext(userContextChoice)
            } else if (user && !user.userContexts.length) {
              // no user contexts, log out user
              setUserContext(undefined)
              await signOut()
              setIsLoadingUserContext(false)
              window.location.href = '/login?autoLogout=true'
            }
          }
        } else {
          setUserContext(undefined)
          // console.log('No session available, unsetting user context')
        }
      } catch (error) {
        throw error
      } finally {
        // console.log('UserContext finished loading')
        setIsLoadingUserContext(false)
      }
    }

    fetchUserContext()

    return () => {
      isMounted = false
    }
  }, [user, isLoadingAuth])

  const isEmptyObject = (obj: object) => Object.keys(obj).length === 0

  const setAuthorizationAttrs = (userContext: UserContext): UserContext => {
    userContext.isOrgMember = hasRole(userContext, [UserRole.Member])
    userContext.isOrgPowerUser = hasRole(userContext, [UserRole.Recruiter, UserRole.Owner])
    userContext.isCandidate = hasRole(userContext, [UserRole.Candidate])
    userContext.isOrgUser = !!userContext?.tenant
    userContext.allowJobAlignment = checkUserPermissions(userContext, 'allow_job_alignment')

    return userContext
  }

  const createUserContext = async (userContextChoice: UserContextChoice) => {
    setIsLoadingUserContext(true)
    let payload: CreateUserContext
    if (userContextChoice.kind === UserContextKind.Membership) {
      payload = { membershipId: userContextChoice.membershipId }
    } else {
      payload = { invitationId: userContextChoice.invitationId }
    }
    let response = await api.createUserContext(payload).request
    if (!isEmptyObject(response)) {
      response = setAuthorizationAttrs(response)
      setUserContext(response)
      // console.log('UserContext Set...', response)
    } else {
      // console.log('createUserContext: UserContext is empty and not set...')
      setUserContext(undefined)
    }
    setIsLoadingUserContext(false)
  }

  const getUserContext = async (): Promise<UserContext | undefined> => {
    let returnContext: UserContext | undefined = undefined
    try {
      setIsLoadingUserContext(true)
      if (isSessionAvailable()) {
        let userContext = await api.getUserContext().request
        if (!isEmptyObject(userContext)) {
          userContext = setAuthorizationAttrs(userContext)
          setUserContext(userContext)
          returnContext = userContext
        } else {
          // console.log('getUserContext: UserContext is empty and not set...')
          setUserContext(undefined)
        }
      }
    } catch (error) {
      setUserContext(undefined)
    }
    setIsLoadingUserContext(false)
    return returnContext
  }

  return (
    <SessionContext.Provider
      value={{
        userContext,
        isLoadingUserContext,
        createUserContext,
        getUserContext,
        setUserContext,
      }}>
      {children}
    </SessionContext.Provider>
  )
}

// Hook to use auth context
export const useUserContext = () => {
  const context = useContext(SessionContext)
  if (context === undefined) {
    throw new Error('useUserContext must be used within an UserProvider')
  }
  return context
}
