import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { EMAIL_REGEX, handleError, InputField } from 'core'
import { onSubmitForm } from '../../utils'
import { CommonFormProps, SignInState } from './common'
import './styles.scss'

import { isSessionAvailable, useAuth, useErrorContext, UserStorage } from 'provider'
import { Button } from '@chakra-ui/react'

export const TOKEN_QUERY_PARAM = 'token'
export const AUTH_CHALLENGE_QUERY_PARAM = 'auth_challenge'
export const SURROGATE_QUERY_PARAM = 'surrogate'

type BasicSignInForm = {
  email: string
  password: string
}

type BasicSignInProps = CommonFormProps & {
  redirectOnSignIn?: string
}

const BasicSignIn = ({ redirectOnSignIn, setState }: BasicSignInProps) => {
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm<BasicSignInForm>({
    defaultValues: {
      email: '',
      password: '',
    },
  })
  const [isPosting, setIsPosting] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const [globalErrorMessages, setGlobalErrorMessages] = useState<string[]>([])

  const { globalErrors } = useErrorContext()
  const navigate = useNavigate()

  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const autoLogout = queryParams.get('autoLogout')
  const { signIn } = useAuth()

  const isMounted = useRef(true)

  // need to deprecate old storage items
  UserStorage.deprecate()

  useEffect(() => {
    if (isSessionAvailable()) {
      navigate(redirectOnSignIn ?? '/')
    }
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (globalErrors && globalErrors.errors?.detail?.length) {
      setGlobalErrorMessages(globalErrors.errors.detail)
    }
  }, [globalErrors])

  const onSubmit = async ({ email, password }: BasicSignInForm) => {
    setIsPosting(true)
    try {
      const loginResponse = await signIn(email, password)
      if (!isMounted.current) return
      if (loginResponse?.forceNewPassword) {
        navigate(`/login?state=resetPassword&redirect=${redirectOnSignIn}`)
        setIsPosting(false)
      } else {
        if (redirectOnSignIn) {
          navigate(redirectOnSignIn)
        }
      }
    } catch (err) {
      if (!isMounted.current) return
      setIsPosting(false)
      const errorResponse = handleError(setIsPosting, { shouldDisplayToast: false })(err)
      switch (errorResponse?.status) {
        case 401:
          setErrorMessage('Incorrect email or password')
          break
        default:
          setErrorMessage('Failed to sign in')
      }
    }
  }

  const renderGlobalErrors = () => {
    if (globalErrorMessages.length) {
      return (
        <div className="signInMessage signInMessage--error">
          There were problems logging you in: <br />
          <ul style={{ listStyleType: 'disc', listStylePosition: 'inside' }}>
            {globalErrorMessages.map((message, index) => (
              <li key={index}>{message}</li>
            ))}
          </ul>
        </div>
      )
    } else if (globalErrors) {
      return (
        <div className="signInMessage signInMessage--error" style={{ textAlign: 'left' }}>
          Unable to log you in because
          <ul style={{ listStyleType: 'disc', listStylePosition: 'inside' }}>
            {globalErrors.errors.messages.map((message, index) => (
              <li key={index}>{message}</li>
            ))}
          </ul>
        </div>
      )
    } else if (autoLogout) {
      return (
        <div className="signInMessage signInMessage--error" style={{ textAlign: 'center' }}>
          You have been logged out. <br /> Please sign in again.
        </div>
      )
    } else {
      return null
    }
  }

  const dontIgnore: boolean = false

  return (
    <>
      <form onSubmit={onSubmitForm(handleSubmit(onSubmit))} className="signInForm">
        <div className="signInMessage signInMessage--error">{errorMessage}</div>
        {renderGlobalErrors()}
        <div className="signInForm__input">
          <InputField
            type="email"
            placeholder="Email"
            registerFields={register('email', {
              required: 'Required field',
              pattern: {
                value: EMAIL_REGEX,
                message: 'Invalid email address',
              },
            })}
            getValue={() => watch('email')}
            errorMessage={errors.email?.message}
            dataCy="signInEmail"
            ignore1Password={dontIgnore}
          />
        </div>
        <div className="signInForm__input">
          <InputField
            type="password"
            placeholder="Password"
            registerFields={register('password', { required: 'Required field' })}
            getValue={() => watch('password')}
            errorMessage={errors.password?.message}
            dataCy="password"
            ignore1Password={dontIgnore}
          />
        </div>

        <Button
          className="signInForm__submit"
          colorScheme="brand"
          variant="brandPrimary"
          type="submit"
          isLoading={isPosting}
          loadingText="Signing In..."
          data-cy="sign_in_button">
          Sign In
        </Button>
      </form>
      <div className="signInLinks">
        <button className="forgotPasswordLink" onClick={() => setState(SignInState.ForgotPassword)}>
          Reset Password
        </button>
        <button
          className="forgotPasswordLink"
          onClick={() => setState(SignInState.CandidateAccess)}>
          Get Magic Link
        </button>
      </div>
    </>
  )
}

export default BasicSignIn
