import isErrorResponse from './isErrorResponse'

function isAbortError(error: unknown) {
  return isErrorResponse(error) && error.isAbort
}

export function ignoreAbortError(error: unknown) {
  // if error is abort error, then its safe to ignore
  // otherwise re-throw the error
  if (!isAbortError(error)) {
    throw error
  }
}

export default isAbortError
