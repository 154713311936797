import { Link, useNavigate } from 'react-router-dom'
import {
  AlignType,
  formatDate,
  LoadingIndicator,
  PageWrapper,
  DebouncedInput,
  FilterTable,
  handleError,
  Tag,
  TagGroup,
} from 'core'
import { TabPage } from '../../Management/Invitations/common'
import { SectionUser, AudienceType, useUserContext, useAuth } from 'provider'
import { getSectionUsers } from '../../../actions/assessments'
import { useMemo, useEffect, useState } from 'react'
import { useDocumentTitle } from '../../../hooks'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faListCheck } from '@fortawesome/pro-regular-svg-icons'
import { commonColumnData } from './section_user_extra_columns'

const SectionDashboard = () => {
  useDocumentTitle('My Invitations')
  const { user } = useAuth()
  const { userContext } = useUserContext()
  const navigate = useNavigate()
  const [isLoadingData, setIsLoadingData] = useState(true)
  const [tableData, setTableData] = useState<SectionUser[]>([])
  const [searchParams, setSearchParams] = useState<string>('')
  const canSeeFilterBar = userContext?.isOrgPowerUser ?? false

  const tags: Tag[] = useMemo(() => {
    const filters = canSeeFilterBar
      ? [
          {
            text: 'Pending',
            active: false,
            onActive: () => navigate(`/invitations/manage/organization/${TabPage.Pending}`),
          },
          {
            text: 'Active',
            active: false,
            onActive: () => navigate(`/invitations/manage/organization/${TabPage.Active}`),
          },
          {
            text: 'Inactive',
            active: false,
            onActive: () => navigate(`/invitations/manage/organization/${TabPage.Inactive}`),
          },
          {
            text: 'Open Positions',
            active: false,
            onActive: () => navigate(`/invitations/manage/organization/${TabPage.OpenPositions}`),
          },
          {
            text: 'Last 30 Days',
            active: false,
            onActive: () => navigate(`/invitations/manage/organization/${TabPage.Last30Days}`),
          },
          {
            text: 'My Sections',
            active: true,
            onActive: () => navigate(`/sections`),
          },
        ]
      : []

    return filters
  }, [navigate, canSeeFilterBar])

  const fetchDataParams = useMemo(
    () => ({
      userId: user?.id,
      pager: 'none',
      search: searchParams,
    }),
    [user?.id, searchParams],
  )

  useEffect(() => {
    setIsLoadingData(true)
    let inviteRequest: CancellableRequest<SectionUser> | undefined
    if (user?.id) {
      inviteRequest = getSectionUsers({
        ...fetchDataParams,
      })
      inviteRequest.request
        .then((response) => {
          setTableData(response)
          setIsLoadingData(false)
        })
        .catch(handleError(setIsLoadingData))
    }

    return inviteRequest?.cancelRequest
  }, [fetchDataParams, user?.id])

  const columns = useMemo<ColumnDef<SectionUser>[]>(
    () => [
      ...commonColumnData(),
      {
        header: 'Assessment',
        accessorFn: (row) => row.assessment.title,
      },
      {
        header: 'Section',
        accessorFn: (row) =>
          row.kind === AudienceType.Organization
            ? row.section.organizationText
            : row.section.candidateText,
      },
      {
        header: 'Comparison Name',
        accessorKey: 'label',
      },
      {
        header: 'Time Estimate',
        accessorFn: (row) => row.section.timeEstimate,
        cell: ({ row }) => `${row.original.section.timeEstimate} minutes`,
        meta: {
          align: AlignType.center,
        },
      },
      {
        header: 'Progress',
        cell: ({ row }) =>
          `${Math.round((row.original.questionCount * row.original.progress) / 100)} out of ${
            row.original.questionCount
          }`,
        meta: {
          align: AlignType.center,
        },
      },
      {
        header: 'Invited By',
        accessorKey: 'invitedBy',
      },
      {
        header: 'Invitation Date',
        accessorFn: (row) => row.invitedAt,
        cell: ({ row }) => <>{formatDate(row.original.invitedAt)}</>,
        meta: {
          align: AlignType.center,
        },
      },
      {
        header: 'Status',
        accessorFn: (row) => row.currentStatus.title,
      },
      {
        id: 'actions',
        header: '',
        cell: ({ row }) => {
          if (row.original.completedAt) {
            return <>{formatDate(row.original.completedAt)}</>
          }
          if (row.original.progress === 0 && row.original.invitation) {
            return (
              <Link data-cy="take-section" to={`/invitations/${row.original.invitation}/welcome`}>
                Take Section
              </Link>
            )
          }
          if (row.original.progress !== 100 && row.original.invitation) {
            return (
              <Link data-cy="take-section" to={`/invitations/${row.original.invitation}/welcome`}>
                Finish Section
              </Link>
            )
          }
          return <></>
        },
      },
    ],
    [],
  )

  if (!user) {
    return <LoadingIndicator fullWidth={true} />
  }

  return (
    <PageWrapper>
      <div className="header" style={{ marginBottom: '5px' }}>
        <FontAwesomeIcon className="page-icon" icon={faListCheck} fixedWidth={true} />
        My Sections
      </div>
      <div className="manageInvitations__actions">
        {canSeeFilterBar && <TagGroup tags={tags} isManagedByActiveTags={true} label="Filter" />}
      </div>
      <div className="filterInput">
        <DebouncedInput
          value={''}
          onChange={(value) => setSearchParams(String(value))}
          className="inputField__input fa-font"
          placeholder="Find..."
        />
      </div>
      <FilterTable schema={columns} data={tableData} isLoadingData={isLoadingData} />
    </PageWrapper>
  )
}

export default SectionDashboard
