import { LoadingIndicator } from 'core'
import { useAuth } from 'provider'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const LogoutPage = () => {
  const { signOut } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    const handleSignOutAndNavigate = async () => {
      await signOut()
      navigate('/login')
    }
    handleSignOutAndNavigate()
  }, [])

  return <LoadingIndicator fullWidth={true} />
}

export default LogoutPage
