import { useRef } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  defaults,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import annotationPlugin from 'chartjs-plugin-annotation'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, annotationPlugin)

interface BarChartProps {
  chartData: Array<number>
  labels: Array<string> | undefined
  background: string | undefined
}

const AlignmentChart = ({ chartData, labels, background }: BarChartProps) => {
  // global styling for the chart
  defaults.font.family = 'Istok Web'
  defaults.font.size = 13

  const chartRef = useRef<ChartJS>(null)

  const guideColors = ['', '#15BDE8', '#B4288E', '#230C3B', '#FF7376', '#1b998b']

  const data = {
    labels: labels,
    datasets: [
      {
        data: chartData,
        backgroundColor: '#5530A8',
        borderColor: '#5530A8',
        borderWidth: 1,
      },
    ],
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (context) {
            return ' ' + context.parsed.y + '%'
          },
          // techdebt: this needs to be done better
          title: function (context) {
            const title = context[0].label
            if (title.split(',')[0] === 'Overall') {
              return 'Overall Alignment'
            }
            if (title === 'A') {
              return 'Atmosphere'
            }
            if (title === 'P') {
              return 'Principles'
            }
            if (title === 'G') {
              return 'Guidance'
            }
            if (title === 'M') {
              return 'Motivators'
            }
            if (title === 'J') {
              return 'Job Personality'
            }
            return title
          },
        },
      },
      annotation: {
        annotations: [
          {
            type: 'line',
            yMin: 70,
            yMax: 70,
            borderColor: '#FF7376',
            borderWidth: 2,
          },
          {
            type: 'line',
            yMin: 50,
            yMax: 50,
            borderColor: '#FF7376',
            borderWidth: 2,
          },
        ],
      },
      customCanvasBackgroundColor: {
        color: background,
      },
    },
    scales: {
      x: {
        border: {
          width: 3,
        },
        grid: {
          display: false,
        },
        ticks: {
          font: {
            weight: 600,
            size: (t) => {
              if (t.index === 0) {
                return 13
              }
              return 18
            },
          },
          color: ['black', 'white', 'white', 'white', 'white', 'white'],
        },
      },
      y: {
        beginAtZero: true,
        max: 100,
        border: {
          width: 3,
        },
        grid: {
          lineWidth: 2,
          display: false,
        },
        ticks: {
          stepSize: 5,
          display: true,
          callback: (value) => {
            if (value === 25) {
              return 'Weak'
            } else if (value === 60) {
              return 'Moderate'
            } else if (value === 85) {
              return 'Strong'
            }
          },
        },
      },
    },
  }

  const beforeDraw: any = [
    {
      id: 'customCanvasBackgroundColor',
      beforeDraw: (chart, args, options) => {
        const { ctx } = chart
        ctx.save()
        ctx.globalCompositeOperation = 'destination-over'
        ctx.fillStyle = options.color || '#ffffff'
        ctx.fillRect(0, 0, chart.width, chart.height)
        ctx.restore()
      },
    },
    {
      id: 'circularXAxisLabels',
      beforeDraw: (chart) => {
        const {
          ctx,
          data,
          scales: { x },
        } = chart

        ctx.save()

        ctx.lineWidth = 2

        data.labels.forEach((label, idx) => {
          if (idx === 0) {
            return
          }

          const centerX = x.getPixelForValue(idx)
          const centerY = x.top + 20
          const color = guideColors[idx]

          ctx.beginPath()
          ctx.fillStyle = color
          ctx.strokeStyle = color
          ctx.arc(centerX, centerY, 15, 0, 2 * Math.PI, false)
          ctx.fill()
          ctx.stroke()
        })
      },
    },
  ]

  return <Bar ref={chartRef} plugins={beforeDraw} options={options} datasetIdKey="id" data={data} />
}

export default AlignmentChart
