import { JSX } from 'react'
import classNames from 'classnames'
import './styles.scss'

export enum TagColors {
  Gray = 'gray',
  Red = 'red',
  Yellow = 'yellow',
  Green = 'green',
  Blue = 'blue',
  Custom = 'custom',
}

export type TagProps = {
  children: JSX.Element | string
  onClick?: () => void
  color?: TagColors
  filled?: boolean
  active?: boolean
  disabled?: boolean
}

const Tag = ({
  children,
  onClick,
  color,
  filled = true,
  active = true,
  disabled = false,
}: TagProps) => {
  const conditionalClassNames = classNames({
    tag: true,
    'tag--clickable': !!onClick,
    'tag--filled': filled,
    'tag--disabled': disabled,
    'tag--inactive': !active,
    [`tag--${color}`]: color && color !== TagColors.Custom,
  })

  return (
    <div onClick={onClick} className={conditionalClassNames} data-cy={`tag-${active}`}>
      {children}
    </div>
  )
}

export default Tag
