import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
} from '@chakra-ui/react'
import { Group } from 'provider'
import { RefObject, useEffect, useMemo, useRef, useState } from 'react'
import './styles.scss'

export type MergeGroupModalProps = {
  groups?: Group[]
  onClose: () => void
  onSubmit: (groupIds: string[], title: string) => Promise<any>
  isOpen: boolean
}

export const MergeGroupModal = ({ groups, isOpen, onClose, onSubmit }: MergeGroupModalProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isMounted, setIsMounted] = useState(true)

  useEffect(() => {
    setIsMounted(true)
    return () => {
      setIsMounted(false)
    }
  }, [])

  // form params
  const [name, setName] = useState<string>('')

  const initialRef = useRef() as RefObject<HTMLInputElement>

  const handleFocus = () => {
    if (initialRef.current) {
      initialRef.current.focus()
    }
  }

  const defaultTitle = useMemo(() => {
    if (groups) {
      const titles = groups.map((group) => group.title)
      const title = titles.join(' & ')
      if (title.length > 48) {
        return title.slice(0, 48)
      } else {
        return title
      }
    }
    return ''
  }, [groups])

  useEffect(() => {
    setName(defaultTitle)
    return () => {}
  }, [defaultTitle, groups])

  return (
    <Modal
      motionPreset="scale"
      onClose={onClose}
      isOpen={isOpen}
      isCentered={true}
      initialFocusRef={initialRef}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Merge Groups</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          The following groups will be merged together into a new group:
          <ul className="groupList">
            {groups?.map((group) => <li key={group.id}>{group.title}</li>)}
          </ul>
          <div className="groupSettings">
            <h5>Group Settings:</h5>
            <FormControl isRequired>
              <FormLabel htmlFor="title">Title</FormLabel>
              <Input
                id="title"
                value={name}
                onChange={(e) => {
                  setName(e.target.value)
                }}
                ref={initialRef}
                onFocus={handleFocus}
                maxLength={48}></Input>
            </FormControl>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} disabled={isLoading}>
            Cancel
          </Button>
          <Button
            colorScheme="brand"
            ml={3}
            onClick={() => {
              setIsLoading(true)
              const groupIds = groups?.map((group) => group.id)
              onSubmit(groupIds!, name).then(() => {
                if (!isMounted) return
                setIsLoading(false)
                onClose()
              })
            }}
            disabled={isLoading}
            isLoading={isLoading}>
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
