import { ReactNode } from 'react'
import { useAsync } from 'react-use'
import { ErrorResponse } from '../api'
import { postMagicLink } from 'disco/src/actions/signIn'
import { MagicLink, extractInvitationId } from 'provider'
import { useErrorContext } from '../services/ErrorProvider'
import type { GlobalError, UserContextChoice } from '../models'

export const TOKEN_QUERY_PARAM = 'token'
export const AUTH_CHALLENGE_QUERY_PARAM = 'auth_challenge'
export const SURROGATE_QUERY_PARAM = 'surrogate'

interface MagicLinkProviderProps {
  children: ReactNode
  loader?: ReactNode
}

export function MagicLinkProvider({ children, loader = <></> }: MagicLinkProviderProps) {
  const { setGlobalErrors } = useErrorContext()
  const magicLinkHandler = useAsync(async () => {
    const searchParams = new URLSearchParams(window.location.search)
    const token = searchParams.get(TOKEN_QUERY_PARAM) ?? undefined
    const authChallenge = searchParams.get(AUTH_CHALLENGE_QUERY_PARAM) ?? undefined
    const surrogate = searchParams.get(SURROGATE_QUERY_PARAM) ?? undefined
    const membershipId = searchParams.get('context') ?? undefined

    if (!(token || authChallenge || surrogate)) {
      return
    }

    try {
      const redirectURL = searchParams.get('redirect')
      const invitationId = extractInvitationId(redirectURL)
      const payload: MagicLink = { token, authChallenge, surrogate }
      const userContextChoice: UserContextChoice = { invitationId, membershipId }
      const loginResponse = await postMagicLink(payload, userContextChoice)
      // noting those that need to be redirected to reset password
      // if the response contains forceNewPassword as true, redirect to password reset, otherwise treat it as a magic link
      if (loginResponse?.forceNewPassword) {
        window.location.href = '/login?state=resetPassword'
      } else {
        // if you have a redirect url in the query params, go there
        if (redirectURL) {
          window.location.href = redirectURL
        } else if (loginResponse?.redirectTo) {
          // otherwise if you have a redirect url in the response, go there
          window.location.href = loginResponse.redirectTo
        }
      }
    } catch (error) {
      if (error instanceof ErrorResponse) {
        const errorData = error.data as unknown as GlobalError
        setGlobalErrors(errorData)
      }
    }

    searchParams.delete(TOKEN_QUERY_PARAM)
    searchParams.delete(AUTH_CHALLENGE_QUERY_PARAM)
    searchParams.delete(SURROGATE_QUERY_PARAM)
  }, [])

  if (magicLinkHandler.loading) {
    return <>{loader}</>
  }

  return <>{children}</>
}

export default MagicLinkProvider
