import { Choice } from 'provider'

type MultiSelectAnswerProps = Choice & {
  isSelected: boolean
  onSelect: (id: string) => void
}

const MultiSelectAnswer = ({ id, text, isSelected, onSelect }: MultiSelectAnswerProps) => {
  return (
    <label className={`answer ${isSelected ? 'isSelected' : ''}`} onClick={() => onSelect(id)}>
      {text}
    </label>
  )
}

export default MultiSelectAnswer
