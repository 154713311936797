export enum SectionDisplayType {
  Questions = 'questions',
  Columns = 'columns',
}

type BaseSection = {
  moduleTitle: string
  title: string
  subtitle?: string
  titleAbbreviation?: string
}

export type QuestionsSection = BaseSection & {
  displayType: SectionDisplayType.Questions
  questions: string[]
}

export type ColumnsDataConstruct = {
  [key: string]: string[]
}

export type ColumnsConstruct = {
  data: ColumnsDataConstruct[]
}

export type ColumnsSection = BaseSection & {
  displayType: SectionDisplayType.Columns
  columns: ColumnsConstruct
}

export type InterviewGuideSections = {
  [key: string]: QuestionsSection | ColumnsSection
}

export function hasQuestionsSection(section: GuideSection): section is QuestionsCategory {
  return section.displayType === SectionDisplayType.Questions || section.questions?.length > 0
}

export function isColumnsSection(section: GuideSection): section is ColumnsCategory {
  return section.displayType === SectionDisplayType.Columns
}
