import { useCallback, useState } from 'react'

export const useRefreshData = () => {
  const [counter, setCounter] = useState(0)
  const refreshData = useCallback(() => {
    setCounter((count) => count + 1)
  }, [])

  return { counter, refreshData }
}
