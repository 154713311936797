import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Menu, MenuItem, MenuButton, MenuList, Button, IconButton } from '@chakra-ui/react'
import { faChevronDown, faEllipsisH } from '@fortawesome/pro-solid-svg-icons'
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core'
import './styles.scss'
import { CSSProperties, useMemo, JSX } from 'react'
import { ActionItem } from './types'

export type ActionDropdownProps = {
  /**
   * The actions to be shown in the dropdown
   *
   * @type {ActionItem[]}
   */
  actions: ActionItem[]
  /**
   * The label to be shown for the dropdown.
   *
   * Default is `undefined` which will show the ellipsis icon
   *
   * @default undefined
   * @type {string}
   */
  label?: string
  /**
   * The icon to be shown for the dropdown
   * If not provided, the label will be shown
   */
  icon?: IconProp
  /**
   *
   *
   * @type {string}
   */
  iconColor?: string
  /**
   * Font Awesome size for icon.
   *
   * @type {string}
   */
  iconSize?: SizeProp
  /**
   * If the caret should be shown.
   *
   * @default false
   * @type {boolean}
   */
  showCaret?: boolean
  /**
   * bootstrap variant class to apply to the toggle button
   *
   * @type {string}
   * @default 'light'
   */
  variant?: string
  /**
   * Color scheme to apply to the toggle button from chakra-ui
   *
   * @type {string}
   * @default undefined
   */
  colorScheme?: string
  /**
   * float the button to the left or right
   *
   * This is useful when the button is in a table, to align it to the left or right of the table cell.
   *
   * @type {('left' | 'right')}
   */
  alignButton?: 'left' | 'right' | undefined
  size?: 'sm' | 'md' | 'lg'
}

/**
 * A dropdown menu that can be used to display a list of actions
 *
 * By default, the dropdown will show an ellipsis icon (configurable).
 *
 * If a label is provided, the icon will be hidden and the label will be shown instead.
 *
 * If you want to show a label instead, pass in the `label` prop.
 *
 * If you want to show the caret, pass in the `showCaret` prop.
 *
 *
 * @export
 * @param {ActionDropdownProps} {
 *   actions,
 *   label,
 *   icon = faEllipsisH,
 *   showCaret = false,
 *   variant = 'outline',
 *   colorScheme = 'transparent',
 *   alignButton,
 * }
 * @return {*}  {JSX.Element}
 */
export function ActionDropdown({
  actions,
  label,
  icon = faEllipsisH,
  iconColor = 'black',
  iconSize = 'lg',
  showCaret = false,
  variant = 'outline',
  colorScheme = undefined,
  alignButton,
  size = 'md',
}: ActionDropdownProps): JSX.Element {
  const alignStyles = useMemo(() => {
    if (alignButton === 'left') {
      return { float: 'left' } as CSSProperties
    }
    if (alignButton === 'right') {
      return { float: 'right' } as CSSProperties
    }
    return undefined
  }, [alignButton])

  const buttonType = useMemo(() => {
    if (icon && !label) {
      return IconButton
    }
    return Button
  }, [icon, label])

  const buttonIconProps = useMemo(() => {
    if (!label) {
      return null
    } else if (label) {
      if (showCaret) {
        return { rightIcon: <FontAwesomeIcon icon={faChevronDown} /> }
      }
    }
    return null
  }, [label, showCaret])

  return (
    <div className="actionDropdown" style={alignStyles}>
      <Menu>
        <MenuButton
          as={buttonType}
          aria-label={label}
          variant={variant}
          colorScheme={colorScheme}
          size={size}
          {...buttonIconProps}>
          {label ? label : <FontAwesomeIcon icon={icon} color={iconColor} size={iconSize} />}
        </MenuButton>
        <MenuList>
          {actions.map((action, index) => (
            <MenuItem
              key={index}
              isDisabled={action.isDisabled}
              icon={
                <FontAwesomeIcon
                  icon={action.icon!}
                  color={action.iconColor}
                  size={action.iconSize || undefined}
                />
              }
              onClick={action.onClick}>
              <div style={{ color: action.labelColor }}>{action.label}</div>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </div>
  )
}

ActionDropdown.displayName = 'ActionDropdown'

export * from './utils'
export * from './types'
