import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { handleError, InputField } from 'core'
import { Section, OptionalExceptFor, api } from 'provider'
import { onSubmitForm } from '../../../../utils'
import Modal from '../../../../components/Modal'
import './styles.scss'
import { Button } from '@chakra-ui/react'

export type SectionWithId = OptionalExceptFor<Section, 'id'>

type UpdateGroupProfileForm = {
  title: string
}

type UpdateGroupProfileTitleProps = {
  showModal: boolean
  onClose: () => void
  onSave?: () => void
  editGroupProfile: { id: string; title: string }
}

const UpdateGroupProfileTitle = ({
  showModal,
  onClose,
  onSave = onClose,
  editGroupProfile,
}: UpdateGroupProfileTitleProps) => {
  const [isPosting, setIsPosting] = useState(false)
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<UpdateGroupProfileForm>({
    defaultValues: { title: editGroupProfile.title },
  })
  const submitTitleEdit = ({ title }: UpdateGroupProfileForm) => {
    setIsPosting(true)
    api
      .patchGroupProfile(editGroupProfile.id, { title })
      .request.then(() => {
        setIsPosting(false)
        reset()
        onSave()
      })
      .catch(handleError(setIsPosting, { errorMessage: 'Could not update profile name.' }))
  }
  const closeModal = () => {
    reset()
    onClose()
  }
  return (
    <Modal showModal={showModal} onClose={closeModal}>
      <form
        onSubmit={onSubmitForm(handleSubmit(submitTitleEdit))}
        className="updateGroupProfileTitle">
        <div className="header">Update Group Profile</div>
        <InputField
          placeholder="New Title"
          registerFields={register('title', { required: 'Required field' })}
          getValue={() => watch('title')}
          errorMessage={errors.title?.message}
          className="updateGroupProfileTitle__field"
        />
        <div className="updateGroupProfileTitle__actions">
          <Button
            variant="danger"
            className="updateGroupProfileTitle__cancelBtn"
            onClick={closeModal}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="brandPrimary"
            isLoading={isPosting}
            className="updateGroupProfileTitle__saveBtn">
            Save
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default UpdateGroupProfileTitle
