import { JSX } from 'react'
import { Color } from '../../constants'

export type IconProps = {
  /** Class names to be passed through to the icon */
  className?: string
  /** Color to be used for the icon */
  color?: string
  /** Secondary color to be used for the icon if needed */
  secondColor?: string
  /** The degree to which the background behind the element is overlaid where 1 is opaque and 0 is transparent */
  opacity?: number
  /** Height in pixels of the icon */
  height?: number
  /** Width in pixels of the icon */
  width?: number
  /** Unique identifier used to avoid collisions with svg defs */
  uniqueId?: string
}

export const defaultColor = Color.Black

export type IconComponent = (props: IconProps) => JSX.Element
