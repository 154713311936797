import { useEffect, useRef } from 'react'
import type { HTMLProps } from 'react'
import './checkbox.scss'

export default function Checkbox({
  indeterminate,
  className = '',
  label,
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null!)

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate
    }
  }, [ref, indeterminate, rest.checked])

  return (
    <>
      <input type="checkbox" ref={ref} className={className + ' cursor-pointer'} {...rest} />
      {typeof label === 'string' && <label htmlFor={rest.id}>{label}</label>}
    </>
  )
}
