import { JSX } from 'react'
import WordCloud from 'react-d3-cloud'
import { Color } from 'core'
import './styles.scss'

export interface IWordCloudData {
  text: string
  value: number
}

export default function WordCloudWrapper({ data }: { data: Array<IWordCloudData> }): JSX.Element {
  // Should be ordered from greatest to least, and multiply the value by 100 to match font weight.

  if (!data) {
    return <div className="wordCloud__empty">No data to display. This is most likely an error.</div>
  }

  const transformedData = data
    .sort((a, b) => b.value - a.value)
    .map(({ text, value }) => {
      return { text: text.toUpperCase(), value: value * 100 }
    })

  const rotateFn = () => {
    return 0
  }

  const colorFn = () => {
    return Color.Purple
  }

  const placementFn = (): number => {
    return 0.5
  }

  const fontWeightFn = (item: IWordCloudData): string | number => {
    switch (item.value) {
      case 100:
        return 200
      case 200:
        return 300
      case 300:
        return 400
      case 400:
        return 500
      case 500:
        return 600
      default:
        return 'normal'
    }
  }

  return (
    <div
      className="word-cloud-wrapper"
      style={
        {
          // backgroundImage: `url(${Cloud})`,
          // backgroundRepeat: 'no-repeat',
          // backgroundSize: 'contain',
          // backgroundPosition: 'center',
        }
      }>
      <div className="word-cloud-wrapper__container">
        <WordCloud
          data={transformedData}
          font="Istok Web"
          padding={1}
          spiral="archimedean"
          rotate={rotateFn}
          fill={colorFn}
          height={200}
          width={500}
          fontWeight={fontWeightFn}
          random={placementFn}
        />
      </div>
    </div>
  )
}
