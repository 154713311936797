import { useCallback, useEffect, useState, useRef, Dispatch, SetStateAction } from 'react'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { Button } from '@chakra-ui/react'
import classNames from 'classnames'
import {
  AudienceType,
  api,
  Assessment,
  CancellableRequest,
  Group,
  Invitation,
  GroupDetail,
  Member,
  Id,
} from 'provider'
import { handleError, InputField, SelectDropdown } from 'core'
import { onSubmitForm, mapToOption, sortOptionByLabel } from '../../utils'
import { DropdownOption } from '../../models'
import { ComparisonOption, SectionType, SectionWithId } from '../ComparisonProfileField'
import { ComparisonProfileList } from './ComparisonProfileList'
import './styles.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faUsers } from '@fortawesome/pro-solid-svg-icons'

type InvitationForm = {
  label: string
  section: SectionWithId
  user?: DropdownOption | undefined
  groupId?: string
  createFirstName?: string
  createLastName?: string
  createEmail?: string
}

type MinimumUserFields =
  | Id
  | {
      firstName: string
      lastName: string
      email: string
    }

export type ComparisonProfileModalProps = {
  assessment: Assessment
  section: SectionWithId | undefined
  // defaultSectionOption?: DropdownOption
  isGroup?: boolean
  onChange: (changed: ComparisonOption) => void
  formUpdates: Dispatch<SetStateAction<boolean>>
  isOpen: boolean
  onClose: () => void
  onSubmit?: () => void
}

/**
 * Modal for managing members or admins of a group.
 */
export const ComparisonProfileModal = ({
  assessment,
  section,
  // defaultSectionOption,
  isGroup = false,
  onChange,
  isOpen,
  onClose,
  // eslint-disable-next-line unused-imports/no-unused-vars, no-unused-vars
  onSubmit,
}: ComparisonProfileModalProps) => {
  const selectInputRef = useRef<any>(null)

  const [selectDropdownOptions, setSelectDropdownOptions] = useState<DropdownOption[]>()
  const [selectedGroupId, setSelectedGroupId] = useState('')
  const [selectedUserId, setSelectedUserId] = useState('')
  const [isPosting, setIsPosting] = useState(false)
  const [isLoadingDropdownOptions, setIsLoadingDropdownOptions] = useState(false)
  const [toggleFormHidden, setToggleFormHidden] = useState(true)
  const [isGroupState, setIsGroupState] = useState<boolean>(isGroup)

  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars, no-unused-vars
    control,
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
    setValue,
  } = useForm<InvitationForm>({
    defaultValues: {
      section: section,
      label: '',
    },
  })

  useEffect(() => {
    const fetchData = async () => {
      if (isOpen) {
        let selectDropdownData: CancellableRequest<Group[] | Member[]> | undefined
        try {
          if (isGroupState) {
            setIsLoadingDropdownOptions(true)
            selectDropdownData = api.listGroups()
            const data = await selectDropdownData.request
            setSelectDropdownOptions(
              (data as Group[]).map((group: Group) => mapToOption(group, 'title', 'id')),
            )
          } else {
            selectDropdownData = api.getMembers({ pager: 'none', ordering: 'first_name,last_name' })
            const data = await selectDropdownData.request
            const members = (data as Member[])
              .map((member: Member) => mapToOption(member, 'fullName', 'userId'))
              .sort(sortOptionByLabel)
            setSelectDropdownOptions(members)
          }
        } catch (error) {
          handleError(setIsLoadingDropdownOptions, {
            errorMessage: isGroupState
              ? 'Could not load groups for dropdown.'
              : 'Could not load users for dropdown.',
          })(error)
        } finally {
          setIsLoadingDropdownOptions(false)
        }

        return selectDropdownData?.cancelRequest
      }
    }

    fetchData()

    return () => {}
  }, [isGroupState, isOpen])

  const setGroupId = (groupOption: DropdownOption | undefined) => {
    if (groupOption) {
      const groupId = groupOption.value
      setSelectedGroupId(groupId)
      setSelectedUserId('')
    }
  }

  const setUserId = (userOption: DropdownOption | undefined) => {
    if (userOption) {
      const userId = userOption.value
      setSelectedUserId(userId)
      setSelectedGroupId('')
    }
  }

  const toggleIsGroup = () => {
    const currentGroupState = isGroupState
    setIsGroupState(!isGroupState)
    // if we are switching to group from users and
    // extended user form is not hidden, hide it
    if (!currentGroupState && !toggleFormHidden) {
      toggleFormHandler()
    }
  }

  const handleClose = () => {
    setToggleFormHidden(true)
    onClose()
  }

  const handleChange = (changed: ComparisonOption) => {
    onChange(changed)
    onClose()
  }

  function isGroupDetail(response: any): response is GroupDetail {
    return (
      response &&
      typeof response.group === 'object' &&
      typeof response.title === 'string' &&
      Array.isArray(response.categories)
    )
  }

  const submitInvitation = async (formValues: InvitationForm) => {
    setIsPosting(true)
    const { label, createFirstName, createLastName, createEmail, section } = formValues
    try {
      let newInvitation: CancellableRequest<GroupDetail | Invitation> | undefined = undefined

      if (!assessment || !label) {
        throw new Error('No assessment selected or label created.')
      }

      if (isGroupState && selectedGroupId) {
        newInvitation = api.postGroupProfile({
          title: label,
          assessment: { id: assessment.id },
          section: { id: section.id || '' },
          group: { id: selectedGroupId },
        })
      }

      if (!isGroupState) {
        let userToSubmit: MinimumUserFields

        if (selectedUserId) {
          userToSubmit = { id: selectedUserId }
        } else {
          userToSubmit = {
            firstName: createFirstName ?? '',
            lastName: createLastName ?? '',
            email: createEmail ?? '',
          }
        }

        newInvitation = api.createInvitation({
          assessment: { id: assessment.id },
          section: { id: section.id },
          audience: AudienceType.Organization,
          user: userToSubmit,
          label,
        })
      }

      const response = await newInvitation?.request

      if (!response) {
        throw new Error('No response from server.')
      }

      const { id, statusAt, currentStatus } = response
      let user = ''
      let title = ''
      let sectionType: SectionType = SectionType.GROUP
      let valueId = id

      if (isGroupState && isGroupDetail(response)) {
        const { group, title: profile_title, categories } = response
        user = group.title
        title = profile_title
        const category = categories.find((cat) => cat.sectionId === section.id)
        if (category) {
          valueId = category.moduleProfileId
        }
      } else {
        if (!isGroupState && !isGroupDetail(response)) {
          const { user: userDict, sectionUsers } = response
          user = userDict.fullName
          const sectionUser = sectionUsers.find((item) => item.section.id === section.id)
          if (sectionUser) {
            valueId = sectionUser.id
            title = sectionUser.label
          }
          sectionType = SectionType.USER
        }
      }

      const newOption: ComparisonOption = {
        sectionType,
        id: valueId,
        user,
        title,
        date: statusAt,
        status: currentStatus,
      }

      onChange(newOption)
      reset()
      setIsPosting(false)
      handleClose()
    } catch (err) {
      handleError(setIsPosting, {
        errorMessage: err?.data?.errors?.messages?.[0] ?? 'Could not create profile.',
      })(err)
    }
  }

  const generateTitle = useCallback(() => {
    if (section) {
      return section.organizationText
    } else if (assessment) {
      return assessment.title
    }
    return ''
  }, [section, assessment])

  const generateSubTitle = useCallback(() => {
    if (isGroupState) {
      return 'Group'
    } else {
      return 'User'
    }
  }, [isGroupState])

  const toggleFormHandler = () => {
    const visibleState = !toggleFormHidden
    setToggleFormHidden((t) => !t)
    if (visibleState) {
      setValue('createFirstName', '')
      setValue('createLastName', '')
      setValue('createEmail', '')

      return
    }

    selectInputRef.current?.clearValue()
    setSelectedUserId('')
  }

  const conditionalClassNames = classNames({
    'form-hidden': toggleFormHidden,
  })

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      isCentered={true}
      size="5xl"
      scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent className="fullSizeModal">
        <ModalHeader>
          {generateTitle()
            ? `Choose a ${generateSubTitle()} Comparison for ${generateTitle()}`
            : 'Choose a Comparison'}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div className="toggle-button-group">
            <Button
              variant="brandPrimary"
              className="toggle-button"
              type="button"
              onClick={toggleIsGroup}
              isDisabled={!isGroupState}
              isLoading={isPosting}>
              <FontAwesomeIcon icon={faUser} className="button-icon" />
              Show Users
            </Button>
            <Button
              variant="brandPrimary"
              className="toggle-button"
              type="button"
              onClick={toggleIsGroup}
              isDisabled={isGroupState}
              isLoading={isPosting}>
              <FontAwesomeIcon icon={faUsers} className="button-icon" />
              Show Groups
            </Button>
          </div>
          <div className="modalInvitationForm">
            <form
              onSubmit={onSubmitForm(handleSubmit(submitInvitation))}
              className="modalInvitationForm__form">
              <div className="modalInvitationForm__defaultForm">
                <div className="header">
                  <span className="header__subtext">Create New</span>
                </div>
                <div className="modalInvitationForm__form-fields">
                  <div className="modalInvitationForm__defaultForm-form">
                    <InputField
                      className="modalInvitationForm__field--text-input"
                      placeholder={isGroupState ? 'Group Profile Name' : 'Comparison Title'}
                      registerFields={register('label', { required: 'Required field' })}
                      getValue={() => watch('label')}
                      errorMessage={errors.label?.message}
                      dataCy="tagline"
                      hasDefaultWidthRange={false}
                      isColumn={true}
                    />
                    {!isGroupState && (
                      <div className="modalInvitationForm__dropdown-with-link">
                        <SelectDropdown
                          wrapperClassName="modalInvitationForm__field"
                          placeholder="Select User..."
                          field={{
                            onChange: setUserId,
                            onBlur: () => {},
                            value: undefined,
                            name: 'user',
                            ref: selectInputRef,
                          }}
                          fieldState={{
                            invalid: false,
                            isTouched: true,
                            isDirty: false,
                            isValidating: false,
                          }}
                          options={selectDropdownOptions ?? ([] as DropdownOption[])}
                          isDisabled={!toggleFormHidden}
                        />
                        <Button variant="link" onClick={toggleFormHandler}>
                          Click here to {toggleFormHidden ? 'add a user' : 'select a user'}
                        </Button>
                      </div>
                    )}
                    {isGroupState && (
                      <SelectDropdown
                        wrapperClassName="modalInvitationForm__field"
                        placeholder="Select Group..."
                        field={{
                          onChange: setGroupId,
                          onBlur: () => {},
                          value: undefined,
                          name: 'groupId',
                          ref: () => {},
                        }}
                        fieldState={{
                          invalid: false,
                          isTouched: true,
                          isDirty: false,
                          isValidating: false,
                        }}
                        options={selectDropdownOptions ?? ([] as DropdownOption[])}
                        isDisabled={isLoadingDropdownOptions}
                      />
                    )}
                  </div>
                  <div className={`modalInvitationForm__createForm ${conditionalClassNames}`}>
                    <div className="modalInvitationForm__createForm--name-fields">
                      <InputField
                        className="modalInvitationForm__field--create-field"
                        placeholder="First Name"
                        registerFields={register('createFirstName')}
                        getValue={() => watch('createFirstName') || ''}
                        errorMessage={errors.createFirstName?.message}
                        dataCy="createFirstName"
                        hasDefaultWidthRange={false}
                        isColumn={true}
                        isReadonly={toggleFormHidden}
                      />
                      <InputField
                        className="modalInvitationForm__field--create-field modalInvitationForm__field--right-side"
                        placeholder="Last Name"
                        registerFields={register('createLastName')}
                        getValue={() => watch('createLastName') || ''}
                        errorMessage={errors.createLastName?.message}
                        dataCy="createLastName"
                        hasDefaultWidthRange={false}
                        isColumn={true}
                        isReadonly={toggleFormHidden}
                      />
                    </div>
                    <div className="modalInvitationForm__createForm--email">
                      <InputField
                        className="modalInvitationForm__field--create-email"
                        placeholder="Email"
                        registerFields={register('createEmail')}
                        getValue={() => watch('createEmail') || ''}
                        errorMessage={errors.createEmail?.message}
                        dataCy="createEmail"
                        hasDefaultWidthRange={false}
                        isColumn={true}
                        isReadonly={toggleFormHidden}
                      />
                    </div>
                  </div>
                </div>
                <div className="modalInvitationForm__actions">
                  <Button
                    type="submit"
                    variant="brandPrimary"
                    className="modalInvitationForm__saveBtn"
                    isLoading={isPosting}
                    isDisabled={isPosting}
                    data-cy="saveInvite">
                    {isGroupState ? 'Create' : 'Send'}
                  </Button>
                </div>
              </div>
            </form>
          </div>

          <ComparisonProfileList
            assessment={assessment}
            sectionId={section?.id ?? ''}
            isGroup={isGroupState}
            onChange={handleChange}
          />
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  )
}
