import axios, {
  AxiosRequestConfig,
  AxiosRequestTransformer,
  AxiosResponse,
  AxiosResponseTransformer,
} from 'axios'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import { renameKeys } from '../utilities/renameKeys'

import {
  Invitation,
  Product,
  Question,
  Tenant,
  User,
  UserContext,
  Member,
  Result,
  Role,
  Statistic,
  MarkdownResponse,
  PositionSection,
  SectionUser,
  Position,
  PositionStatus,
  LoginResponse,
  Group,
  GroupListParams,
  GroupCreateParams,
  GroupUpdateParams,
  UserSearchResult,
  GroupCopyParams,
  GroupMergePrams,
  Profile,
  GroupProfileParams,
  TagDetail,
  TagParams,
  InterviewGuide,
  GroupProfileDetail,
  GroupDetail,
  GroupMember,
} from '../models'
import {
  CommonParams,
  getCommonParams,
  getParsedJson,
  getQueryParams,
  getStatusParams,
} from '../utilities'
import {
  BasicLogin,
  CreateInvitation,
  CreatePosition,
  CreateSectionUser,
  ForgotPassword,
  GetGroupProfile,
  GetInvitations,
  GetPositions,
  GetSectionUsers,
  GetMembers,
  InactivateMember,
  InvitationComplete,
  InvitationWelcome,
  MagicLink,
  PatchPosition,
  PatchPositionEdit,
  PatchPositionSection,
  PatchSectionUser,
  PostPositionStatus,
  ReactivateMember,
  ResetPassword,
  SaveAnswer,
  UpsertMemberPayload,
} from './payload-models'
import { CreateUserContext } from './payload-models/CreateUserContext'
import { GetSectionProfile } from './payload-models/GetGroupProfile'
import { SectionProfiles } from 'src/models/Profile'

// Set up axios to include CSRF token in requests
axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.xsrfCookieName = 'workzinga-csrf'

type AxiosRequestProps = {
  config?: AxiosRequestConfig
  includePaginationMetadata?: boolean
}

type UpdateProps = AxiosRequestProps & {
  isPut?: boolean
}

export type PaginationResponse<T> = {
  count: number
  next: string | null
  previous: string | null
  results: T
}

export function isPaginationResponse<T>(
  response: T | PaginationResponse<T>,
): response is PaginationResponse<T> {
  return response && 'count' in response && 'results' in response
}

export type CancellableRequest<T> = {
  request: Promise<T>
  cancelRequest: () => void
}

export type ErrorResponseData = Record<string, unknown> & {
  errors?: {
    detail?: {
      [key: string]: unknown
    }
    messages?: string[]
  }
}

export class ErrorResponse {
  status = 500
  statusText = 'Unknown error'
  data: ErrorResponseData = {}
  isAbort: boolean

  constructor(errorResponse: Partial<ErrorResponse> = {}) {
    this.status = errorResponse.status || this.status
    this.statusText = errorResponse.statusText || this.statusText
    this.data = errorResponse.data || this.data
    this.isAbort = errorResponse.isAbort ?? false
  }
}

const transformResponse: AxiosResponseTransformer = (data) => {
  if (data && getParsedJson(data)) {
    const jsonData = camelcaseKeys(JSON.parse(data), { deep: true })
    renameKeys(jsonData, true)
    return jsonData
  }

  return data
}

const transformRequest: AxiosRequestTransformer = (data) => {
  if (data) {
    renameKeys(data, false)
    return JSON.stringify(snakecaseKeys(data, { deep: true }))
  }

  return data
}

function getErrorResponse(err: unknown): ErrorResponse {
  if (err instanceof ErrorResponse) {
    return err
  }

  let response = new ErrorResponse()

  if (axios.isAxiosError(err) && err.response) {
    const { messages } = err.response?.data?.errors || ['Unknown error.']
    response = new ErrorResponse({
      status: err.response.status,
      statusText: err.response.statusText || messages[0],
      data: err.response.data,
    })
  } else if (err instanceof Error) {
    const message = getParsedJson(err.message)
    if (message && message.response) {
      response = message.response
    } else {
      response.statusText = err.message
    }
  }

  return response
}

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_SERVER_URL,
  withCredentials: true,
  withXSRFToken: true,
})

axiosInstance.interceptors.response.use(
  async (response) => {
    return response
  },
  async (error) => {
    // console.log('error', error, error?.response)
    // console.log('error server code', error?.response?.data?.errors?.code)
    if (error?.response?.status === 403 && error?.response?.data?.errors?.code === 'invalid_link') {
      // Use history.replaceState to modify the URL without a re-render
      const urlWithoutQueryParams = window.location.pathname
      window.history.replaceState({}, '', urlWithoutQueryParams)
    }
    if (
      error?.response?.status === 401 &&
      error?.response?.data?.errors?.code === 'not_authenticated'
    ) {
      window.location.href = '/login?autoLogout=true'
    }
    return Promise.reject(error)
  },
)

const defaultConfig = {
  responseType: 'json' as const,
  transformResponse,
  transformRequest,
  headers: { 'Content-Type': 'application/json' },
}

const makeRequest = <T>(
  requestCallback: (config: AxiosRequestConfig) => Promise<AxiosResponse<T>>,
  { config, includePaginationMetadata = false }: AxiosRequestProps = {},
): CancellableRequest<T> => {
  const controller = new AbortController()
  return {
    request: requestCallback({ ...defaultConfig, ...config, signal: controller.signal })
      .then((response) => {
        if (response.status === 404) {
          throw new ErrorResponse({
            ...response,
            data: {
              details: {
                request: response,
              },
              errors: { messages: ['Resource not found'] },
            },
            statusText: 'Resource not found',
          })
        }

        return isPaginationResponse(response.data) && !includePaginationMetadata
          ? response.data.results
          : response.data
      })
      .catch((err) => {
        throw controller.signal.aborted
          ? new ErrorResponse({ isAbort: true })
          : getErrorResponse(err)
      }),
    cancelRequest: () => controller.abort(),
  }
}

function get<T>(url: string, requestProps?: AxiosRequestProps): CancellableRequest<T> {
  return makeRequest((config) => axiosInstance.get<T>(url, config), requestProps)
}

/**
 *
 * @param url {string} - url to send request to
 * @param object {object} - object to send in request body - optional
 * @param requestProps {object} - additional request properties - optional
 * @returns {CancellableRequest<U>} - promise that resolves to response data
 */

function post<T, U = T>(
  url: string,
  object?: T,
  requestProps?: AxiosRequestProps,
): CancellableRequest<U> {
  return makeRequest(
    (config) => axiosInstance.post<T, AxiosResponse<U>>(url, object, config),
    requestProps,
  )
}

function update<T, U = T>(
  url: string,
  object: T,
  { isPut = false, ...requestProps }: UpdateProps = {},
): CancellableRequest<U> {
  const updateMethod = isPut ? axiosInstance.put : axiosInstance.patch
  return makeRequest(
    (config) => updateMethod<T, AxiosResponse<U>>(url, object, config),
    requestProps,
  )
}
/** @type {*} */
const api = {
  get,
  post,
  update,

  getTenant: () => get<Tenant>('/v1/organizations/tenant/'),
  getRoles: (params: CommonParams = {}) =>
    get<PaginationResponse<Role[]>>(`/v1/organizations/roles/${getCommonParams(params)}`, {
      includePaginationMetadata: true,
    }),
  getProducts: (service?: string) =>
    get<Product[]>(`/v1/billing/products/${service ? `?service=${service}` : ''}`),
  getProductDetails: (productId: string) => get<Product>(`/v1/billing/products/${productId}/`),
  getCurrentUser: () => get<User>('/v1/profiles/me/'),
  getDashboardStatistics: () => get<Statistic[]>('/v1/organizations/dashboard/'),

  // Auth endpoints
  basicLogin: (payload: BasicLogin) => post<BasicLogin, LoginResponse>('/v1/login/', payload),
  logout: () => post('/v1/logout/'),
  resetPassword: (payload: ResetPassword) =>
    post<ResetPassword, LoginResponse>('/v1/password/reset/', payload),
  forgotPassword: (payload: ForgotPassword) =>
    post<ForgotPassword, unknown>('/v1/password/forgot/', payload),
  getMagicLink: (params: MagicLink) => get(`/v1/token/magic/${getQueryParams({ params })}`),
  postMagicLink: (payload: MagicLink) =>
    post<MagicLink, LoginResponse>('/v1/token/magic/', payload),
  createUserContext: (payload: CreateUserContext) =>
    post<CreateUserContext, UserContext>('/v1/user-context/', payload),
  getUserContext: () => get<UserContext>('/v1/user-context/'),

  // User endpoints
  getMembers: ({ isActive, ...commonParams }: GetMembers) => {
    const queryParams = getCommonParams({
      ...commonParams,
      existingParams: isActive === undefined ? '' : isActive ? '?is_active=1' : '?is_active=0',
    })
    return get<PaginationResponse<Member[]>>(`/v1/organizations/users/${queryParams}`, {
      includePaginationMetadata: true,
    })
  },
  createMember: (payload: UpsertMemberPayload) =>
    post<UpsertMemberPayload, Member>('/v1/organizations/users/', payload),
  reactivateMember: (payload: ReactivateMember) =>
    update<ReactivateMember, Member>(`/v1/organizations/users/${payload.id}/`, payload),
  inactivateMember: (payload: InactivateMember) =>
    update<InactivateMember, Member>(`/v1/organizations/users/${payload.id}/`, payload),
  patchUser: (payload: UpsertMemberPayload) =>
    update<UpsertMemberPayload, Member>(`/v1/organizations/users/${payload.id}/`, payload),

  // Position endpoints
  getPositions: ({
    page,
    pageSize,
    pager,
    ordering,
    search,
    ...statusParams
  }: GetPositions = {}) => {
    const queryParams = getCommonParams({
      page,
      pageSize,
      ordering,
      pager,
      search,
      existingParams: getStatusParams(statusParams),
    })
    return get<PaginationResponse<Position[]>>(
      `/v1/organizations/job-requisitions/${queryParams}`,
      {
        includePaginationMetadata: false,
      },
    )
  },
  getPosition: (positionId: string) =>
    get<Position>(`/v1/organizations/job-requisitions/${positionId}/`),
  createPosition: (payload: CreatePosition) =>
    post<CreatePosition, Position>('/v1/organizations/job-requisitions/', payload),
  closePosition: (positionId: string) =>
    post(`/v1/organizations/job-requisitions/${positionId}/close/`),
  patchPosition: (payload: PatchPosition) =>
    update<PatchPosition, Position>(`/v1/organizations/job-requisitions/${payload.id}/`, payload),
  patchPositionEdit: (payload: PatchPositionEdit) =>
    update<PatchPositionEdit, Position>(
      `/v1/organizations/job-requisitions/${payload.id}/edit/`,
      payload,
    ),
  patchPositionSection: (payload: PatchPositionSection) =>
    update<PatchPositionSection, PositionSection>(
      `/v1/organizations/job-requisition-modules/${payload.id}/`,
      payload,
    ),
  postPositionStatus: ({ positionId, statusPayload }: PostPositionStatus) =>
    post(`/v1/organizations/job-requisitions/${positionId}/status/`, statusPayload),
  getPositionStatus: (positionId: string) =>
    get<PositionStatus[]>(
      `/v1/workflows/status-events/organizations/jobrequisition/${positionId}/`,
    ),

  // Section-user endpoints
  getSectionUsers: ({
    sectionId: module,
    userId: user,
    type: audience,
    page,
    pageSize,
    pager,
    search,
    ordering,
    ...statusParams
  }: GetSectionUsers = {}) => {
    let queryParams = getCommonParams({
      page,
      pageSize,
      pager,
      search,
      ordering,
      existingParams: getStatusParams(statusParams),
    })
    queryParams = getQueryParams({
      params: { module, user, audience },
      existingParams: queryParams,
    })
    return get<SectionUser[]>(`/v1/organizations/module-users/${queryParams}`, {
      includePaginationMetadata: false,
    })
  },
  createSectionUser: (payload: CreateSectionUser) =>
    post<CreateSectionUser, SectionUser>(`/v1/organizations/module-users/`, payload),
  patchSectionUser: (sectionUserId: string, payload: PatchSectionUser) =>
    update<PatchSectionUser>(`v1/organizations/module-users/${sectionUserId}/`, payload),
  getNextQuestion: (sectionUserId: string) =>
    get<Question>(`/v1/assessments/module-users/${sectionUserId}/question/`),
  saveAnswer: (sectionUserId: string, payload: SaveAnswer) =>
    update<SaveAnswer, Question>(
      `/v1/assessments/module-users/${sectionUserId}/question/`,
      payload,
    ),

  // Invitation endpoints
  getInvitations: ({
    jobPositionId: job_requisition,
    userId: user,
    type: audience,
    page,
    pageSize,
    ordering,
    search,
    pager,
    ...statusParams
  }: GetInvitations = {}) => {
    let queryParams = getCommonParams({
      page,
      pageSize,
      ordering,
      search,
      pager,
      existingParams: getStatusParams(statusParams),
    })
    queryParams = getQueryParams({
      params: { job_requisition, user, audience },
      existingParams: queryParams,
    })
    return get<PaginationResponse<Invitation[]>>(`/v1/organizations/invitations/${queryParams}`, {
      includePaginationMetadata: false,
    })
  },
  getInvitation: (invitationId: string) =>
    get<Invitation>(`/v1/organizations/invitations/${invitationId}/`),
  createInvitation: (payload: CreateInvitation) =>
    post<CreateInvitation, Invitation>(`/v1/organizations/invitations/`, payload),
  cancelInvitation: (invitationId: string) =>
    post<Invitation>(`/v1/organizations/invitations/${invitationId}/cancel/`),
  inactivateOrgComparison: (sectionUserId: string) =>
    post<SectionUser>(`/v1/organizations/module-users/${sectionUserId}/inactivate/`),
  reactivateOrgComparison: (sectionUserId: string) =>
    post<SectionUser>(
      `/v1/organizations/module-users/${sectionUserId}/reactivate/?show_inactive=true`,
    ),
  getInvitationWelcome: (invitationId: string) =>
    get<InvitationWelcome>(`/v1/organizations/invitations/${invitationId}/welcome/`),
  getInvitationComplete: (invitationId: string) =>
    get<InvitationComplete>(`/v1/organizations/invitations/${invitationId}/complete/`),
  getInvitationResults: (invitationId: string) =>
    get<Result>(`/v1/assessments/reports/assessment_result/${invitationId}/`),
  getGroupProfileResults: (groupProfileId: string) =>
    get<Result>(`/v1/assessments/reports/group_profile_result/${groupProfileId}/`),
  getInvitationGuide: (invitationId: string) =>
    get<InterviewGuide>(`/v1/assessments/reports/interview_guide/${invitationId}/`),

  // terms ond conditions endpoints
  getTermsOfUse: () => get<MarkdownResponse>('/v1/terms-of-use/'),
  getCookiePolicy: () => get<MarkdownResponse>('/v1/cookies-policy/'),
  getPrivacyPolicy: () => get<MarkdownResponse>('/v1/privacy-policy/'),

  // Groups
  listGroups: (params: GroupListParams = {}) => {
    const queryParams = getCommonParams({
      ...params,
      existingParams: params?.isPublic !== undefined ? `?is_public=${params.isPublic}` : '',
    })

    return get<Group[]>(`/v1/organizations/groups/${queryParams}`, {
      includePaginationMetadata: false,
    })
  },
  getGroup: (groupId: string) => get<Group>(`/v1/organizations/groups/${groupId}/`),
  createGroup: (payload: GroupCreateParams) =>
    post<GroupCreateParams, Group>('/v1/organizations/groups/', payload),
  updateGroup: (groupId: string, payload: GroupUpdateParams) =>
    update<GroupUpdateParams, Group>(`/v1/organizations/groups/${groupId}/`, payload),
  archiveGroup: (groupId: string) => post(`/v1/organizations/groups/${groupId}/archive/`),
  copyGroup: (groupId: string, payload: GroupCopyParams) =>
    post<GroupCopyParams, Group>(`/v1/organizations/groups/${groupId}/copy/`, payload),
  mergeGroups: (payload: GroupMergePrams) =>
    post<GroupMergePrams, Group>('/v1/organizations/groups/merge/', payload),

  // Group Members & Admins
  addGroupMembers: (groupId: string, members: string[]) =>
    post(`/v1/organizations/groups/${groupId}/add_members/`, { members }),
  archiveGroupMembers: (groupId: string, members: string[]) =>
    post(`/v1/organizations/groups/${groupId}/archive_members/`, { members }),
  searchNonGroupUsers: (groupId: string, kind: 'member' | 'admin', searchQuery: string) =>
    get<PaginationResponse<UserSearchResult[]>>(
      `/v1/organizations/search-users/?page_size=500&group_id=${groupId}&type=exclude&kind=${kind}&q=${searchQuery}`,
      { includePaginationMetadata: true },
    ),
  searchGroupUsers: ({
    groupId,
    kind,
    searchQuery,
    pager,
  }: {
    groupId: string
    kind: 'member' | 'admin'
    searchQuery: string
    pager: string
  }) =>
    get<GroupMember[]>(
      `/v1/organizations/search-users/?pager=${pager}&group_id=${groupId}&kind=${kind}&q=${searchQuery}`,
      { includePaginationMetadata: false },
    ),
  addGroupAdmins: (groupId: string, admins: string[]) =>
    post(`/v1/organizations/groups/${groupId}/add_admins/`, { admins }),
  archiveGroupAdmins: (groupId: string, admins: string[]) =>
    post(`/v1/organizations/groups/${groupId}/archive_admins/`, { admins }),

  // Billing Endpoints
  getBillingRedirect: (currentUrlPath: string) =>
    get<Response>(`v1/billing/portal/?return_url=${currentUrlPath}`),

  // Score Profile Endpoints
  getGroupProfileDetail: (groupProfileId: string) =>
    get<GroupProfileDetail>(`/v1/organizations/group-profiles/${groupProfileId}/`),
  getGroupProfileCategory: (groupProfileId: string, categoryId: string) =>
    get<Profile>(`/v1/assessments/group-profile-category/${groupProfileId}/${categoryId}/`),
  getGroupProfiles: ({ group, assessment, section: module, ...params }: GetGroupProfile) => {
    if (!params.ordering) {
      params.ordering = 'title'
    }
    let queryParams = getCommonParams({
      ...params,
    })
    queryParams = getQueryParams({
      params: { group, assessment, module },
      existingParams: queryParams,
    })
    return get<GroupProfileDetail[]>(`/v1/organizations/group-profiles/${queryParams}`)
  },
  getSectionProfiles: ({ section: module, ...params }: GetSectionProfile) => {
    if (!params.ordering) {
      params.ordering = 'group_profile__title'
    }
    let queryParams = getCommonParams({
      ...params,
    })
    queryParams = getQueryParams({
      params: { module },
      existingParams: queryParams,
    })
    return get<SectionProfiles[]>(`/v1/organizations/module-profiles/${queryParams}`)
  },
  postScore: (groupProfileId: string, payload: any) =>
    post(`/v1/organizations/group-profiles/${groupProfileId}/record_score/`, payload),
  postGroupProfile: (payload: GroupProfileParams) =>
    post<GroupProfileParams, GroupDetail>(`/v1/organizations/group-profiles/`, payload),
  finalizeGroupProfile: (groupProfileId: string) =>
    post(`/v1/organizations/group-profiles/${groupProfileId}/finalize/`),
  archiveGroupProfile: (groupProfileId: string) =>
    post(`/v1/organizations/group-profiles/${groupProfileId}/archive/`),
  patchGroupProfile: (groupProfileId: string, payload: any) =>
    update(`/v1/organizations/group-profiles/${groupProfileId}/`, payload),

  // Tags
  getTags: () => get<TagDetail[]>('/v1/tagging/item-tags/'),
  postTags: (payload: TagParams) => post(`/v1/tagging/item-tags/`, payload),
}

export default api
