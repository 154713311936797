import { Link } from 'react-router-dom'
import { Tooltip } from '@chakra-ui/react'
import { useSupportModal } from '../../hooks'
import './styles.scss'

import * as gitHash from '../../_git_commit'

const Footer = () => {
  const { supportModal, setShowSupportModal } = useSupportModal()
  return (
    <footer className="footer">
      <div>
        <Tooltip
          fontSize="0.5rem"
          label={gitHash.default.commitSha.toString() || 'Commit hash will be here when deployed'}
          placement="top">
          <span>A Navorion Inc Project</span>
        </Tooltip>
      </div>
      <div>
        <a href="https://workzinga.com">workzinga.com</a>&nbsp;&bull;&nbsp;
        <Link to="/terms-of-use/">Terms</Link>&nbsp;&bull;&nbsp;
        <Link to="/privacy-policy/">Privacy</Link>&nbsp;&bull;&nbsp;
        <Link to="/cookies-policy/">Cookies</Link>&nbsp;&bull;&nbsp;
        <span className="footer__support" onClick={() => setShowSupportModal(true)}>
          Support
        </span>
      </div>
      <div>&copy; 2021 - {new Date().getFullYear()} Workzinga All Rights Reserved</div>
      {supportModal}
    </footer>
  )
}

export default Footer
