import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { PageWrapper } from 'core'
import BasicSignIn from './basicSignIn'
import { SignInState } from './common'
import ForgotPassword from './forgotPassword'
import ResetPassword from './resetPassword'
import './styles.scss'
import { createSearchParams, useSearchParams } from 'react-router-dom'
import { Button } from '@chakra-ui/react'

type SignInProps = {
  redirectOnSignIn?: string
  state?: SignInState
}

const SignIn = ({ redirectOnSignIn, state: initialState }: SignInProps) => {
  const [state, setState] = useState(initialState)
  const [message, setMessage] = useState('')

  const [searchParams, setSearchParams] = useSearchParams()
  const isMounted = useRef(true)

  useEffect(() => {
    // isMounted.current = true;
    return () => {
      isMounted.current = false
    }
  }, [])

  const handleStateChange = (newState: SignInState) => {
    if (isMounted.current) {
      setState(newState)
    }
  }

  const changeState = useCallback(
    (newState: SignInState) => {
      const removeAutoLogout = () => {
        const currentParams = searchParams
        if (currentParams.get('autoLogout')) {
          currentParams.delete('autoLogout')
          setSearchParams(createSearchParams({ ...currentParams }))
        }
      }
      removeAutoLogout()
      return setState(newState)
    },
    [searchParams, setSearchParams],
  )

  const renderForm = useMemo(() => {
    switch (state) {
      case SignInState.ForgotPassword:
        setMessage('Enter your email address')
        return <ForgotPassword setState={setState} />
      case SignInState.ForgotPasswordSuccess:
        setMessage('If your email address matches our system, you should receive an email shortly.')
        return (
          <div className="signInForm">
            <Button
              colorScheme="brand"
              className="signInForm__submit"
              onClick={() => changeState(SignInState.SignIn)}>
              Continue
            </Button>
          </div>
        )
      case SignInState.ResetPassword:
        setMessage('Enter a new password')
        return <ResetPassword setState={setState} />
      case SignInState.ResetPasswordSuccess:
        setMessage('Your password has been reset successfully.')
        return (
          <div className="signInForm">
            <Button
              colorScheme="brand"
              className="signInForm__submit"
              onClick={() => changeState(SignInState.SignIn)}>
              Continue to Sign In
            </Button>
          </div>
        )
      case SignInState.CandidateAccess:
        setMessage(
          'If you have received an Invitation from a company to take an Assessment or have purchased an Assessment, you are in the right place!  Fill out the form and we will send you an email with magic links to access your stuff.',
        )
        return <ForgotPassword setState={setState} />
      default:
        setMessage('Building Cultural Alignment')
        return <BasicSignIn redirectOnSignIn={redirectOnSignIn} setState={handleStateChange} />
    }
  }, [redirectOnSignIn, state, changeState])

  return (
    <PageWrapper extraClassNames="signInWrapper">
      <img src="/logo-purple.svg" alt="logo" />
      <div className="signInMessage">{message}</div>

      {renderForm}
    </PageWrapper>
  )
}

export default SignIn
