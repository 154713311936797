import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { GroupMember } from 'provider'
import { RefObject, useMemo, useRef, useState } from 'react'
import { MemberKind } from '../GroupManageMembersModal/types'

export type RemoveMemberModalProps = {
  isOpen: boolean
  onClose: () => void
  onSubmit: (memberId: string) => Promise<any>
  member?: GroupMember
  kind: MemberKind
}

export const RemoveMemberModal = ({
  isOpen,
  onClose,
  onSubmit,
  member,
  kind,
}: RemoveMemberModalProps) => {
  const [isLoading, setIsLoading] = useState(false)

  const initialRef = useRef(null) as RefObject<HTMLButtonElement>

  const textCopy = useMemo(() => {
    if (kind === MemberKind.Member) {
      return 'as a member from this group'
    } else if (kind === MemberKind.Admin) {
      return 'as an admin from this group'
    }
  }, [kind])

  return (
    <Modal
      motionPreset="scale"
      onClose={onClose}
      isOpen={isOpen}
      isCentered={true}
      initialFocusRef={initialRef}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Remove {kind === MemberKind.Member ? 'Member' : 'Admin'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <p>
            Are you sure you would like to remove {member?.userFirstName} {member?.userLastName}{' '}
            {textCopy}?
          </p>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} disabled={isLoading}>
            Cancel
          </Button>
          <Button
            ref={initialRef}
            colorScheme="brand"
            ml={3}
            onClick={() => {
              setIsLoading(true)
              onSubmit(member!.userId).then(() => {
                setIsLoading(false)
              })
            }}
            disabled={isLoading}
            isLoading={isLoading}>
            Remove
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
