import classNames from 'classnames'
import { ControllerFieldState, Noop, RefCallBack } from 'react-hook-form'
import CreatableSelect from 'react-select/creatable'
import InputWrapper, { InputClassNames, InputWrapperProps } from '../InputWrapper'
import './styles.scss'

export type DropdownOption = {
  label: string
  value: string
}

type SelectDropdownProps = InputWrapperProps & {
  field: {
    onChange: (...event: any[]) => void
    onBlur: Noop
    value: DropdownOption | undefined
    name: string
    ref: RefCallBack
  }
  fieldState: ControllerFieldState
  defaultValue?: DropdownOption
  options: DropdownOption[]
  isDisabled?: boolean
  isLoading?: boolean
  placeholder?: string
}

const CreatableMultiInput = ({
  field,
  fieldState: { error },
  defaultValue,
  options,
  isDisabled,
  isLoading,
  placeholder,
  className: extraClassName = '',
  dataCy,
  ...inputWrapperProps
}: SelectDropdownProps) => {
  const errorMessage = error?.message
  const conditionalClassNames = classNames({
    [InputClassNames.Filled]: !!field.value && !errorMessage,
    [InputClassNames.Error]: !!errorMessage,
  })

  return (
    <InputWrapper {...inputWrapperProps} errorMessage={errorMessage} dataCy={dataCy || 'select'}>
      <CreatableSelect
        {...field}
        closeMenuOnSelect={false}
        isMulti
        isClearable={false}
        className={`${InputClassNames.Default} ${conditionalClassNames} selectDropdown ${extraClassName}`}
        styles={{
          control: (provided) => ({
            ...provided,
            border: 'none',
          }),
          menu: (provided) => ({
            ...provided,
            zIndex: '1002',
          }),
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: '#f3f0fe',
            primary: '#3f2c7f',
          },
        })}
        defaultValue={defaultValue}
        options={options}
        isLoading={isLoading}
        placeholder={placeholder}
        isDisabled={isDisabled}
      />
    </InputWrapper>
  )
}

export default CreatableMultiInput
