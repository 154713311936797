import { useEffect, useMemo, useState } from 'react'
import { Choice, QuestionType } from 'provider'
import MultiSelect from './MultiSelect'
import { Button } from '@chakra-ui/react'
import LoadingIndicator from '../LoadingIndicator'
import './styles.scss'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import Likert from '../Likert'

type QuestionProps = {
  text: string
  helperText?: string
  kind: QuestionType
  numberOfSelectionsNeeded: number
  choices: Choice[]
  isSaving: boolean
  onNext: (selectedChoices: string[]) => void
  className?: string
}

const Question = ({
  text,
  helperText,
  kind,
  numberOfSelectionsNeeded,
  choices,
  isSaving,
  onNext,
  className,
}: QuestionProps) => {
  const [selectedChoices, setSelectedChoices] = useState<string[]>([])

  const isNextEnabled = useMemo(
    () => selectedChoices.length === numberOfSelectionsNeeded,
    [selectedChoices, numberOfSelectionsNeeded],
  )

  const icon = useMemo(() => {
    const isMultipleNeeded = numberOfSelectionsNeeded > 1
    if (isNextEnabled) {
      return (
        <div
          className={`question__header__icon--active ${
            isMultipleNeeded && 'question__header__icon--multiple'
          }`}>
          <FontAwesomeIcon
            className="question__header__icon__nextEnabled"
            icon={faCheck}
            size="3x"
          />
        </div>
      )
    }
    if (isMultipleNeeded) {
      return (
        <div className="question__header__needed">
          <div className="question__header__needed__count" data-cy="answers-needed-count">
            {selectedChoices.length}/{numberOfSelectionsNeeded}
          </div>
          <div>Selected</div>
        </div>
      )
    }
    return <div className="question__header__icon"></div>
  }, [selectedChoices, numberOfSelectionsNeeded, isNextEnabled])

  useEffect(() => {
    setSelectedChoices([])
  }, [choices])

  const conditionalClassNames = classNames({
    'question__container--nextEnabled': isNextEnabled,
    'question__container--saving': isSaving,
  })

  return (
    <div className={`question ${className}`}>
      <div className={`question__container ${conditionalClassNames}`}>
        {isSaving ? <LoadingIndicator fullWidth={true} /> : null}
        <div className="question__header">
          <div className="question__header__text">
            <div>{text}</div>
            <div className="question__header__text--helper">{helperText}</div>
          </div>
          {icon}
        </div>

        <div className="question__choices">
          {kind === QuestionType.Likert ? (
            <Likert
              key={text}
              choices={choices}
              selectedChoices={selectedChoices}
              setSelectedChoices={setSelectedChoices}
              isDisabled={isSaving}
            />
          ) : (
            <MultiSelect
              choices={choices}
              selectedChoices={selectedChoices}
              setSelectedChoices={setSelectedChoices}
              numberOfSelectionsNeeded={
                kind === QuestionType.MultipleChoice ? 1 : numberOfSelectionsNeeded
              }
              isDisabled={isSaving}
            />
          )}
        </div>
      </div>
      <Button
        className="question__button"
        colorScheme="brand"
        isDisabled={!isNextEnabled}
        isLoading={isSaving}
        onClick={() => isNextEnabled && onNext(selectedChoices)}>
        Next
      </Button>
    </div>
  )
}

export default Question
