import { useEffect, useMemo, useState, useCallback } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  FilterTable,
  ActionDropdown,
  ActionItem,
  TagGroup,
  formatDate,
  Tag,
  handleError,
  PageWrapper,
  DebouncedInput,
  Color,
  AlignType,
} from 'core'
import { Position, StatusKind } from 'provider'
import { closePosition, getPositions, reopenPosition } from '../../../actions/positions'
import {
  useConfirmationModal,
  useLoadingStates,
  useRefreshData,
  useDocumentTitle,
} from '../../../hooks'
import { getTextColor } from '../../../utils'

import './styles.scss'
import { faBoxArchive, faBoxOpen, faPlus } from '@fortawesome/pro-solid-svg-icons'
import { faSquareEllipsis } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faIdBadge } from '@fortawesome/pro-regular-svg-icons'
import { Button } from '@chakra-ui/react'

let isRendered = true

const PositionManagementPage = () => {
  useDocumentTitle('Manage Jobs')
  const navigate = useNavigate()
  const { counter, refreshData } = useRefreshData()
  const { toggleLoadingStateForKey } = useLoadingStates()
  const [shouldFilterByClosed, setShouldFilterByClosed] = useState(false)
  const [positionToClose, setPositionToClose] = useState<string>()
  const [isLoadingData, setIsLoadingData] = useState(true)
  const [tableData, setTableData] = useState<User[]>([])
  const [searchParams, setSearchParams] = useState<string>('')

  const fetchDataParams = useMemo(() => {
    return {
      statusKind: StatusKind.Final,
      excludeStatusKind: !shouldFilterByClosed,
      pager: 'none',
      search: searchParams,
    }
  }, [shouldFilterByClosed, searchParams])

  useEffect(() => {
    setIsLoadingData(true)
    const resolve = getPositions({
      ...fetchDataParams,
    })

    resolve.request
      .then((response) => {
        setIsLoadingData(false)
        setTableData(response)
      })
      .catch(handleError(setIsLoadingData))

    return resolve.cancelRequest
  }, [fetchDataParams, counter])

  const closeJobPosition = () => {
    if (positionToClose) {
      toggleLoadingStateForKey(positionToClose)
      closePosition(positionToClose)
        .request.then(() => isRendered && refreshData())
        .catch(handleError())
        .finally(() => isRendered && toggleLoadingStateForKey(positionToClose))
    }
    setPositionToClose(undefined)
    setShowModal(false)
  }

  const { confirmationModal, setShowModal } = useConfirmationModal({
    textBody: (
      <>
        Are you sure you would like to close this position?
        <br /> You can always undo this action by "Reopening" the position.
        <br /> Do you want to continue?
      </>
    ),
    onSuccess: closeJobPosition,
  })

  const openJobPosition = useCallback(
    (positionToOpen: string) => {
      toggleLoadingStateForKey(positionToOpen)
      reopenPosition(positionToOpen)
        .request.then(() => isRendered && refreshData())
        .catch(handleError())
        .finally(() => isRendered && toggleLoadingStateForKey(positionToOpen))
    },
    [refreshData, toggleLoadingStateForKey],
  )

  const toggleFilter = () => {
    setShouldFilterByClosed((currValue) => !currValue)
  }

  // when component is unmounted, `isRendered` is set to false
  // this is then used when getting responses to async requests to see if data is still needed
  useEffect(() => {
    isRendered = true
    return () => {
      isRendered = false
    }
  }, [])

  const tags: Tag[] = [
    {
      text: 'Open',
      onActive: toggleFilter,
      active: true,
    },
    {
      text: 'Closed',
      onActive: toggleFilter,
    },
  ]

  const onCloseBtnClicked = useCallback(
    (id: string) => {
      setPositionToClose(id)
      setShowModal(true)
    },
    [setShowModal],
  )

  const renderPositionAction = useCallback(
    (row: Position): ActionItem[] => {
      if (shouldFilterByClosed) {
        return [
          {
            label: 'Reopen Job',
            icon: faBoxOpen,
            iconSize: 'xl',
            onClick: () => {
              openJobPosition(row.id)
            },
            iconColor: Color.Green,
            labelColor: Color.Green,
          },
        ]
      }

      return [
        {
          label: 'Close Job',
          icon: faBoxArchive,
          iconSize: 'xl',
          iconColor: Color.Red,
          labelColor: Color.Red,
          onClick: () => {
            onCloseBtnClicked(row.id)
          },
        },
      ]
    },
    [shouldFilterByClosed, openJobPosition, onCloseBtnClicked],
  )

  const columns = useMemo<ColumnDef<Group>[]>(
    () => [
      {
        accessorKey: 'positionTitle',
        header: 'Title',
        cell: ({ row }) => (
          <Link to={`/positions/${row.original.id}`}>{row.original.positionTitle}</Link>
        ),
      },
      {
        accessorKey: 'referenceNumber',
        header: 'Reference ID',
      },
      {
        header: 'Assigned To',
        accessorKey: 'recruiter',
        cell: ({ row }) => {
          const { recruiter } = row.original
          if (typeof recruiter === 'string') {
            return recruiter
          }
          return recruiter.fullName
        },
      },
      {
        accessorKey: 'effectiveAt',
        header: 'Date Opened',
        meta: {
          align: AlignType.center,
        },
        cell: ({ row }) => formatDate(row.original.effectiveAt),
      },
      {
        accessorKey: 'currentStatus.title',
        header: 'Status',
      },
      {
        header: 'Invitations',
        accessorKey: 'invited',
        meta: {
          align: AlignType.center,
        },
      },
      {
        header: 'Completed',
        accessorKey: 'completed',
        meta: {
          align: AlignType.center,
        },
      },
      {
        header: 'Tags',
        enableSorting: false,
        cell: ({ row }) => {
          const { tags } = row.original
          const display = tags.map((tag) => {
            const backgroundColor = tag.kind.color || Color.MediumLightGray
            const textColor = getTextColor(backgroundColor)

            return (
              <span
                key={tag.id}
                className="position-tags"
                style={{ backgroundColor: backgroundColor, color: textColor }}>
                {tag.title}
              </span>
            )
          })
          return display
        },
      },
      {
        id: 'actions',
        header: '',
        enableSorting: false,
        cell: ({ row }) => (
          <ActionDropdown
            actions={renderPositionAction(row.original)}
            alignButton={'right'}
            variant={'actionGhost'}
            icon={faSquareEllipsis}
            iconColor={Color.Purple}
            iconSize={'2xl'}
          />
        ),
      },
    ],
    [renderPositionAction],
  )

  return (
    <PageWrapper extraClassNames="managePositions">
      <div className="header">
        <FontAwesomeIcon className="page-icon" icon={faIdBadge} fixedWidth={true} />
        Manage Jobs
      </div>
      <div className="managePositions__actions">
        <TagGroup tags={tags} label="Filter" />
        <Button
          type="button"
          variant="brandPrimary"
          onClick={() => navigate('/positions')}
          leftIcon={<FontAwesomeIcon icon={faPlus} />}>
          Create Job
        </Button>
      </div>
      <div className="filterInput">
        <DebouncedInput
          value={''}
          onChange={(value) => setSearchParams(String(value))}
          className="inputField__input fa-font"
          placeholder="Find..."
        />
      </div>
      <FilterTable schema={columns} data={tableData} isLoading={isLoadingData} />
      {confirmationModal}
    </PageWrapper>
  )
}

export default PositionManagementPage
