import { extendTheme } from '@chakra-ui/react'

// Setup overrides for chakra-ui
export const theme = extendTheme({
  initialColorMode: 'light',
  useSystemColorMode: false, // TODO( @AlexSwensen ): remove this when we have dark mode support
  shadows: {
    outline: '0 0 0 3px #aea0df', // replace with your desired color and opacity
  },
  styles: {
    global: {
      // styles for the `a`
      a: {
        color: 'blue.600',
        // _hover: {
        //   textDecoration: 'underline',
        // },
      },
      h1: {
        fontSize: '2xl',
        fontWeight: 'bold',
      },
      h2: {
        fontSize: 'xl',
        fontWeight: 'bold',
      },
      h3: {
        fontSize: 'lg',
        fontWeight: 'bold',
      },
      h4: {
        fontSize: 'md',
        fontWeight: 'bold',
      },
      h5: {
        fontSize: 'sm',
        fontWeight: 'bold',
      },
      h6: {
        fontSize: 'xs',
        fontWeight: 'bold',
      },
      _focusVisible: {
        outline: 'none',
      },
    },
  },
  fonts: {
    heading:
      '"Noto Sans Display","Istok Web", "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;',
    body: '"Istok Web", "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;',
  },
  colors: {
    // For additional colors, see https://chakra-ui.com/docs/theming/theme#colors.
    // The following colors override the default colors.
    brand: {
      50: '#f0ebff',
      100: '#cfc5ee',
      200: '#aea0df',
      300: '#8e7ad1',
      400: '#6e55c3',
      500: '#3f2c7f',
      600: '#422e85',
      700: '#2f2160',
      800: '#1c133b',
      900: '#090619',
    },
    darkBrand: {
      50: '#f3e9ff',
      100: '#d8bff2',
      200: '#bd96e7',
      300: '#a36cde',
      400: '#8a42d4',
      500: '#712abb',
      600: '#582091',
      700: '#3f1768',
      800: '#260d40',
      900: '#0f0318',
    },
    red: {
      50: '#ffe8ed',
      100: '#f2c3ca',
      200: '#e39da8',
      300: '#d57686',
      400: '#c85063',
      500: '#af374a',
      600: '#892a39',
      700: '#631c28',
      800: '#3d0f18',
      900: '#1c0106',
    },
    blue: {
      50: '#dcf7ff',
      100: '#aee2ff',
      200: '#7eceff',
      300: '#4dbafe',
      400: '#23a6fd',
      500: '#0f8de3',
      600: '#016db2',
      700: '#004e80',
      800: '#002f4f',
      900: '#00111f',
    },
    // TODO: ( @AlexSwensen ) Create a gray scale that matches the design
    grey: {
      50: '#f2f2f2',
      100: '#d9d9d9',
      200: '#bfbfbf',
      300: '#a6a6a6',
      400: '#8c8c8c',
      500: '#737373',
      600: '#595959',
      700: '#404040',
      800: '#262626',
      900: '#0d0d0d',
    },
    wzBrand: {
      green: '#1b998b',
      red: '#a63446',
      yellow: '#f1d900',
      orange: '#d58936',
      blue: '#017ac6',
      blue2: '#55bbe4',
      pink: '#b4288e',
      pink2: '#a7398b',
      darkPurple: '#230c3b',
      mediumPurple: '#3f2c7f',
      mediumLightPurple: '#8a42d4',
      lightPurple: '#f3e0ff',
      palePurple: '#f3f0fe',
      palestPurple: '#faf8fe',
    },
  },
  components: {
    Tooltip: {
      baseStyle: {
        bg: 'wzBrand.mediumPurple',
      },
    },
    Button: {
      baseStyle: {
        _focus: {
          ring: 2,
          ringColor: 'brand.200',
        },
      },
      variants: {
        brandPrimary: {
          background: 'wzBrand.mediumPurple',
          color: 'white',
          border: '1px solid',
          borderColor: 'wzBrand.mediumPurple',
          _hover: {
            backgroundColor: 'wzBrand.green',
          },
          _focus: {
            boxShadow: '0px 0px 0.5rem wzBrand.mediumLightPurple',
            ring: '2px solid wzBrand.mediumLightPurple',
          },
          _focusVisible: {
            boxShadow: '0px 0px 0.5rem wzBrand.mediumLightPurple',
            ring: '2px solid wzBrand.mediumLightPurple',
          },
          _loading: {
            backgroundColor: 'white',
            color: 'wzBrand.mediumPurple',
          },
        },
        danger: {
          background: 'white',
          color: 'wzBrand.red',
          border: '1px solid',
          borderColor: 'wzBrand.red',
          _hover: {
            backgroundColor: 'wzBrand.red',
            color: 'white',
          },
          _focus: {
            boxShadow: '0px 0px 0.5rem wzBrand.mediumLightPurple',
          },
          _loading: {
            color: 'white',
            backgroundColor: 'wzBrand.red',
          },
        },
        actionGhost: {
          background: 'none',
          border: 'none',
          boxShadow: '0 0 0 #fff',
          _focus: {
            boxShadow: '0 0 0 #fff',
            background: 'none',
            color: '#00ffff',
          },
          _hover: {
            background: 'none',
          },
          _active: {
            background: 'none',
          },
        },
      },
    },
    Select: {
      baseStyle: {
        field: {
          _disabled: {
            color: 'gray.900', // your desired color
          },
        },
      },
    },
    Menu: {
      baseStyle: {
        // define the part you're going to style
        list: {
          borderColor: 'gray.400',
        },
        item: {
          _hover: {
            bg: 'wzBrand.palePurple',
          },
          _focus: {
            bg: 'wzBrand.palePurple',
          },
        },
      },
    },
  },
})
