import './styles.scss'

type AvatarProps = {
  fullName: string
  className?: string
  onClick?: () => void
}

const Avatar = ({ fullName, onClick, className }: AvatarProps) => {
  const initials = fullName.split(' ').reduce((prev, curr) => {
    const name = curr.trim()
    return prev + (name.length > 0 ? name[0].toUpperCase() : '')
  }, '')

  return (
    <div className={`avatar ${className ?? ''}`} onClick={onClick}>
      <div className="avatar__initials">{initials}</div>
    </div>
  )
}

export default Avatar
