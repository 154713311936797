import { useEffect, useMemo, useState, useCallback } from 'react'
import { useParams, Link } from 'react-router-dom'
import { Tooltip } from '@chakra-ui/react'
import {
  handleError,
  PageWrapper,
  DebouncedInput,
  ActionDropdown,
  FilterTable,
  formatDate,
  Color,
  AlignType,
} from 'core'
import {
  CancellableRequest,
  Position,
  WorkflowStatusType,
  AudienceType,
  Invitation,
  PaginationResponse,
} from 'provider'
import { getPosition } from '../../actions/positions'
import { getInvitations, cancelInvitation } from '../../actions/invitations'
import { MembersProvider, useRefreshData } from '../../hooks'
import AddCandidateForm from './AddCandidateForm'
import CreatePositionForm from './CreatePositionForm'
import EditPositionForm from './EditPositionForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRectangleXmark, faUser } from '@fortawesome/pro-solid-svg-icons'
import { faIdBadge } from '@fortawesome/pro-regular-svg-icons'
import {
  faClipboardList,
  faFileChartColumn,
  faSquareEllipsis,
} from '@fortawesome/pro-duotone-svg-icons'

import './styles.scss'

const PositionPage = () => {
  const { positionId } = useParams<{ positionId?: string }>()
  const { counter, refreshData } = useRefreshData()
  const isNew = positionId === undefined
  const [isLoading, setIsLoading] = useState(!isNew)
  const [position, setPosition] = useState<Position>()
  const [isPositionEditable, setIsPositionEditable] = useState<boolean>(
    position ? position.isEditable : true,
  )
  const isPositionClosed = useMemo(
    () => position?.currentStatus.code === WorkflowStatusType.Closed,
    [position?.currentStatus.code],
  )
  const [invitesAdded, setInvitesAdded] = useState(0)
  const [positionSections, setPositionSections] = useState<Record<string, string | undefined>>({})

  const [isLoadingData, setIsLoadingData] = useState(true)
  const [tableData, setTableData] = useState<Invitation[]>([])
  const [searchParams, setSearchParams] = useState<string>('')

  const cancelInvite = useCallback(
    ({ id }: Invitation) => {
      cancelInvitation(id)
        .request.then(() => refreshData())
        .catch(handleError())
    },
    [refreshData],
  )

  const columns = useMemo<ColumnDef<Invitation>[]>(
    () => [
      {
        header: 'Reports',
        accessorFn: () => ({}),
        enableSorting: false,
        meta: {
          align: AlignType.center,
        },
        cell: ({ row }) => (
          <>
            {row.original.currentStatus.code === WorkflowStatusType.Completed ? (
              <div className="positionTable--reports">
                <Tooltip
                  hasArrow
                  bg="brand.500"
                  color="white"
                  placement="right"
                  label={'Culture Alignment Report'}>
                  <Link to={`/invitations/${row.original.id}/results`}>
                    <FontAwesomeIcon icon={faFileChartColumn} size="2x" color="#3f2c7f" />
                  </Link>
                </Tooltip>
                <Tooltip
                  hasArrow
                  bg="brand.500"
                  color="white"
                  placement="right"
                  label={'Interview Guide'}>
                  <Link to={`/invitations/guide/org/${row.original.id}`}>
                    <FontAwesomeIcon icon={faClipboardList} size="2x" color="#3f2c7f" />
                  </Link>
                </Tooltip>
              </div>
            ) : null}
          </>
        ),
      },
      {
        header: '% Alignment',
        accessorKey: 'overallMatch',
        meta: {
          align: AlignType.center,
        },
      },
      {
        header: 'Candidate Name',
        accessorFn: ({ user }) => `${user.firstName} ${user.lastName}`,
      },
      {
        header: 'Email',
        accessorFn: ({ user }) => user.email,
      },
      {
        header: 'Invited By',
        accessorKey: 'invitedBy',
      },
      {
        header: 'Invitation Date',
        accessorKey: 'invitedAt',
        cell: ({ row }) => formatDate(row.original.invitedAt),
        meta: {
          align: AlignType.center,
        },
      },
      {
        header: 'Status',
        accessorFn: (row) => row.currentStatus.title,
      },
      {
        header: 'Status Date',
        accessorKey: 'statusAt',
        cell: ({ row }) => formatDate(row.original.statusAt),
        meta: {
          align: AlignType.center,
        },
      },
      {
        id: 'actions',
        header: '',
        cell: ({ row }) => {
          const statusCodes = [WorkflowStatusType.Pending, WorkflowStatusType.Invited]
          if (statusCodes.includes(row.original.currentStatus.code)) {
            return (
              <ActionDropdown
                actions={[
                  {
                    label: 'Cancel Invitation',
                    icon: faRectangleXmark,
                    iconSize: 'xl',
                    onClick: () => cancelInvite(row.original),
                    iconColor: Color.Red,
                    labelColor: Color.Red,
                  },
                ]}
                alignButton={'right'}
                variant={'actionGhost'}
                icon={faSquareEllipsis}
                iconColor={'#3F2C7F'}
                iconSize={'2xl'}
              />
            )
          }

          return <></>
        },
      },
    ],
    [cancelInvite],
  )

  useEffect(() => {
    setPositionSections(
      position?.sections.reduce((prevValue, currentValue) => {
        return {
          ...prevValue,
          [currentValue.id]: currentValue.sectionUser?.id || currentValue.sectionProfile?.id,
        }
      }, {}) ?? {},
    )
  }, [position?.sections])

  useEffect(() => {
    let positionRequest: CancellableRequest<Position> | undefined
    let isMounted = true

    if (positionId) {
      positionRequest = getPosition(positionId)
      positionRequest.request
        .then((data) => {
          if (isMounted) {
            setPosition(data)
            setIsPositionEditable(data.isEditable)
            setIsLoading(false)
          }
        })
        .catch((error) => {
          if (isMounted) handleError(setIsLoading)(error)
        })
    }

    return () => {
      isMounted = false
      positionRequest?.cancelRequest()
    }
  }, [positionId, invitesAdded, counter])

  const fetchDataParams = useMemo(
    () => ({
      jobPositionId: positionId,
      type: AudienceType.Candidate,
      invitesAdded,
      pager: 'none',
      search: searchParams,
    }),
    [positionId, invitesAdded, searchParams],
  )

  useEffect(() => {
    let isMounted = true
    setIsLoadingData(true)
    let inviteRequest: CancellableRequest<PaginationResponse<Invitation[]>> | undefined

    if (positionId) {
      inviteRequest = getInvitations({
        ...fetchDataParams,
      })
      inviteRequest.request
        .then((response) => {
          if (isMounted) {
            setTableData(response)
            setIsLoadingData(false)
          }
        })
        .catch((error) => {
          if (isMounted) handleError(setIsLoadingData)(error)
        })
    }

    return () => {
      isMounted = false
      inviteRequest?.cancelRequest()
    }
  }, [fetchDataParams, positionId, counter])

  const assessmentId = useMemo(() => {
    if (position?.sections.length) {
      const assessment = position.sections[0].assessment
      return typeof assessment === 'string' ? assessment : assessment.id
    }
    return undefined
  }, [position])

  const candidateForm = useMemo(() => {
    const addNewInvitation = () => {
      setIsPositionEditable(false)
      setInvitesAdded(invitesAdded + 1)
    }

    const sectionUsersExist = Object.values(positionSections).every((value) => !!value)

    if (position?.id && (sectionUsersExist || tableData.length)) {
      if (assessmentId) {
        return (
          <div>
            {!isPositionClosed && (
              <div className="positionPage--candidate-form">
                <div className="positionPage__sectionHeader invitation-header">
                  <FontAwesomeIcon icon={faUser} />
                  Candidate Invitations
                </div>
                <AddCandidateForm
                  positionId={position.id}
                  assessmentId={assessmentId}
                  addNewInvitation={addNewInvitation}
                  // TODO(AlexSwensen): Remove confirmation modal functionality.
                  confirmationModalNeeded={false}
                />
              </div>
            )}
            <div className="filterInput">
              <DebouncedInput
                value={''}
                onChange={(value) => setSearchParams(String(value))}
                className="inputField__input fa-font"
                placeholder="Find..."
              />
            </div>
            <FilterTable schema={columns} data={tableData} isLoadingData={isLoadingData} />
          </div>
        )
      } else {
        return <div>Could not find position's associated assessment!</div>
      }
    } else {
      return null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position?.id, assessmentId, positionSections, invitesAdded, isPositionClosed, tableData])

  if (isLoading) {
    return <div className="loading-message">Loading...</div>
  }

  if (!isLoading && !isNew && !position) {
    return <div className="loading-message">Could not load position</div>
  }

  return (
    <MembersProvider>
      <PageWrapper extraClassNames="positionPage">
        <h1 className="position-header">
          <FontAwesomeIcon icon={faIdBadge} size="lg" />
          <span className="position-header--text">
            {isNew ? 'Create' : `Manage ${position.positionTitle}`} Job
          </span>
        </h1>

        {position ? (
          <EditPositionForm
            isGroupLoading={isLoading}
            position={position}
            isPositionEditable={isPositionEditable}
            isPositionClosed={isPositionClosed}
            updatePosition={setPosition}
            refreshData={refreshData}
            counter={counter}
          />
        ) : (
          <div className="create-position-wrapper">
            <CreatePositionForm setPosition={setPosition} />
          </div>
        )}

        {candidateForm}
      </PageWrapper>
    </MembersProvider>
  )
}

export default PositionPage
