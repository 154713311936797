import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { handleError, InputField } from 'core'
import { ErrorResponse, isErrorResponse, useAuth } from 'provider'
import { resetPassword } from '../../actions/signIn'
import { onSubmitForm } from '../../utils'
import { CommonFormProps, SignInState } from './common'
import './styles.scss'
import { Button } from '@chakra-ui/react'

type ResetPasswordForm = {
  password: string
  passwordConfirmation: string
}

const ResetPassword = ({ setState }: CommonFormProps) => {
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm<ResetPasswordForm>({
    defaultValues: {
      password: '',
      passwordConfirmation: '',
    },
  })
  const [isPosting, setIsPosting] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const { signOut } = useAuth()

  const deflateErrors = (err: ErrorResponse): Array<string> => {
    return Object.entries(err.data.errors.detail)
      .map(([, value]) => value)
      .reduce((a, c) => [...a, ...c], [])
      .join('\n')
  }

  const onSubmit = async ({ password, passwordConfirmation }: ResetPasswordForm) => {
    if (password !== passwordConfirmation) {
      setErrorMessage('Password does not match')
      return
    }

    setIsPosting(true)
    try {
      await resetPassword({ newPassword: password }).request
      setIsPosting(false)
      await signOut()
      setState(SignInState.ResetPasswordSuccess)
      // Remove ?state=resetPassword from the URL while keeping other query params
      const url = new URL(window.location.href)
      const params = url.searchParams
      if (params.get('state') === 'resetPassword') {
        params.delete('state') // Remove the specific query param
        window.history.replaceState({}, '', `${url.pathname}?${params.toString()}` + url.hash)
      }
    } catch (err) {
      setIsPosting(false)
      handleError(setIsPosting, { shouldDisplayToast: false })(err)

      if (isErrorResponse(err)) {
        setErrorMessage(deflateErrors(err))
      } else {
        setErrorMessage('Failed to reset password')
      }
    }
  }

  const dontIgnore: boolean = false

  return (
    <form onSubmit={onSubmitForm(handleSubmit(onSubmit))} className="signInForm">
      <div className="signInForm__instructions">
        <ul style={{ listStyle: 'disc' }}>
          <li>Your password must be at least 8 characters</li>
          <li>Your password cannot be similar to your name or email address</li>
          <li>Your password cannot be a common word</li>
          <li>Your password cannot be entirely numeric</li>
        </ul>
      </div>
      <div className="signInMessage signInMessage--error">
        <pre>{errorMessage}</pre>
      </div>
      <div className="signInForm__input">
        <InputField
          type="password"
          placeholder="Password"
          ignore1Password={dontIgnore}
          registerFields={register('password', {
            required: 'Required field',
          })}
          getValue={() => watch('password')}
          errorMessage={errors.password?.message}
        />
      </div>
      <div className="signInForm__input">
        <InputField
          type="password"
          placeholder="Confirm password"
          ignore1Password={dontIgnore}
          registerFields={register('passwordConfirmation', { required: 'Required field' })}
          getValue={() => watch('passwordConfirmation')}
          errorMessage={errors.passwordConfirmation?.message}
        />
      </div>

      <Button
        variant="brandPrimary"
        className="signInForm__submit"
        type="submit"
        isLoading={isPosting}>
        Submit
      </Button>
    </form>
  )
}

export default ResetPassword
