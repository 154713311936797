export enum Color {
  Black = '#000',
  Green = '#1b998b',
  Cerulean = '#017ac6',
  Warning = '#d58936',
  Red = '#a63446',
  Error = Red,
  DarkRed = '#B4288E',
  Magenta = DarkRed,
  Purple = '#3F2C7F',
  White = '#fff',
  Blue = Cerulean,
  DarkPurple = '#230C3B',
  Info = Blue,
  Gray = '#595959',
  LightPurple = '#f3f0fe',
  MediumLightGray = '#ced4da',
  OrgBlue = '#55bbe4',
  CandidatePurple = '#a7398b',
}
