import { Status } from './Status'
import { Tenant } from './Tenant'
import { Id } from './mixins'

export enum UserRole {
  'Owner' = 'OWNER',
  'Recruiter' = 'RECR',
  'Member' = 'MBR',
  'Candidate' = 'CAND',
  'Staff' = 'STAFF',
}

export enum AudienceType {
  Organization = 'ORGANIZATION',
  Candidate = 'CANDIDATE',
}

export enum UserContextKind {
  Membership = 'membership',
  Invitation = 'invitation',
}

export enum UserType {
  Partner = 'Partner',
  User = 'User',
}

export type UserContextChoice = {
  kind?: UserContextKind
  audienceType?: AudienceType
  membershipId?: string
  tenantId?: string
  tenantTitle?: string
  tenantLogo?: string
  role?: string
  roleTitle?: string
  invitationId?: string
  assessment?: string
  invitedAt?: string
  invitedBy?: string
  completedAt?: string
  currentStatusId?: string
  currentStatusTitle?: string
  statusAt?: string
  positionTitle?: string
  isPurchased?: boolean
}

export type UserContext = {
  authOnly?: boolean
  userAudience?: AudienceType
  userRole?: UserRole
  membershipId?: string
  invitationId?: string
  tenant?: Tenant
  isBillingAdmin?: boolean
  permissions?: string[]
  isOrgMember?: boolean | undefined
  isOrgPowerUser?: boolean | undefined
  isCandidate?: boolean | undefined
  isOrgUser?: boolean | undefined
  allowJobAlignment?: boolean | undefined
}

export type User = Id & {
  username: string
  email: string
  fullName: string
  firstName: string
  lastName: string
  isStaff: boolean
  isActive: boolean
  userContexts: UserContextChoice[]
}

export type Member = Id & {
  role: UserRole
  email: string
  username: string
  firstName: string
  lastName: string
  userId: string
  fullName: string
  roleTitle: string
  currentStatus: Status
  statusAt: string
  effectiveAt?: string
  terminatedAt?: string
  lastLogin: string
  isActive: boolean
  isStaff: boolean
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
}
