import { Choice } from './Choice'
import { WorkflowStatusType } from './Status'

export enum QuestionType {
  MultipleChoice = 'Multiple-Choice',
  MultiSelect = 'Multi-Select',
  Likert = 'Likert',
}

export type Question = {
  sectionUserId: string
  sectionUserCurrentStatusId: WorkflowStatusType
  sectionUserProgress: number
  sectionUserQuestionCount: number
  sectionText: string
  questionSequence: number
  questionExpectedReplies: number
  questionKind: QuestionType
  questionText: string
  questionHelperText?: string
  choices: Choice[]
}
