import classNames from 'classnames'
import { Status, WorkflowStatusType } from 'provider'

import './styles.scss'

const StatusTag = ({ status }: { status: Status | undefined }) => {
  if (status === undefined) {
    return null
  }

  const tagClassNames = classNames({
    statusTag: true,
    'statusTag--pending': status.code === WorkflowStatusType.Pending,
    'statusTag--invited': status.code === WorkflowStatusType.Invited,
    'statusTag--completed': status.code === WorkflowStatusType.Completed,
    'statusTag--active': status.code === WorkflowStatusType.Active,
    'statusTag--canceled': status.code === WorkflowStatusType.Canceled,
    'statusTag--error': status.code === WorkflowStatusType.Error,
    'statusTag--wip': status.code === WorkflowStatusType.InProgress,
    'statusTag--draft': status.code === WorkflowStatusType.Draft,
    'statusTag--archived': status.code === WorkflowStatusType.Archived,
  })

  return (
    <div className={tagClassNames} data-cy="statusTag">
      {status.title}
    </div>
  )
}

export default StatusTag
