import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useDocumentTitle } from '../../hooks'
import Footer from '../../components/Footer'
import SignIn from '../../components/SignIn'
import { SignInState } from '../../components/SignIn/common'

import './styles.scss'

const LoginPage = () => {
  useDocumentTitle('Login')

  const searchParams = useSearchParams()[0]
  const signInState = useMemo(() => {
    const stateParam = searchParams.get('state')
    switch (stateParam) {
      case SignInState.ForgotPassword:
      case SignInState.ForgotPasswordSuccess:
      case SignInState.ResetPassword:
        return stateParam as SignInState
      default:
        return SignInState.SignIn
    }
  }, [searchParams])

  const redirectRoute = useMemo(() => {
    const redirectParam = searchParams.get('redirect') ?? '/'
    return redirectParam.startsWith('/') ? redirectParam : `/${redirectParam}`
  }, [searchParams])

  return (
    <div className="loginPage">
      <SignIn redirectOnSignIn={redirectRoute} state={signInState} />
      <Footer />
    </div>
  )
}

export default LoginPage
