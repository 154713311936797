import classNames from 'classnames'
import { Candidate, Organization } from 'core'
import { SpectrumCategory, SpectrumConstruct, ResultType } from 'provider'
import Paragraphs from '../Paragraph'
import './styles.scss'

type SpectrumCategoryProps = {
  category: SpectrumCategory
  organizationName?: string
  candidateName: string
  color: string
  type: ResultType
}

type ConstructProps = {
  construct: SpectrumConstruct
  organizationName?: string
  candidateName: string
}

const Construct = ({
  construct: {
    title,
    leftSpectrum,
    rightSpectrum,
    scale,
    organizationScore,
    organizationText,
    candidateScore,
    candidateText,
  },
  organizationName,
  candidateName,
}: ConstructProps) => {
  const hasOrganizationScore = organizationScore !== undefined

  const orgIconClassNames = classNames({
    'spectrum__icon--max': organizationScore === scale,
  })

  const candidateIconClassNames = classNames({
    'spectrum__icon--max': candidateScore === scale,
  })

  const isStandardResult = classNames({
    'spectrum__icon--standard': !hasOrganizationScore,
  })

  return (
    <div className="spectrumConstruct">
      <div className="spectrumConstruct__title">{title}</div>
      <div className="spectrumConstruct__body">
        <div className="spectrum">
          <div className="spectrum__text">{leftSpectrum}</div>
          <div className="spectrum__line">
            {hasOrganizationScore && (
              <div
                className={`spectrum__icon ${orgIconClassNames}`}
                style={
                  { '--score': `${(organizationScore / scale) * 100}%` } as React.CSSProperties
                }>
                <Organization className="spectrum__icon--org" />
              </div>
            )}
            <div
              className={`spectrum__icon ${candidateIconClassNames}`}
              style={{ '--score': `${(candidateScore / scale) * 100}%` } as React.CSSProperties}>
              <Candidate className={`spectrum__icon--candidate ${isStandardResult}`} />
            </div>
          </div>
          <div className="spectrum__text">{rightSpectrum}</div>
        </div>
        <div className="spectrumConstruct__paragraphs">
          {organizationText && (
            <Paragraphs
              title={organizationName}
              paragraphs={[organizationText]}
              isOrganization={true}
            />
          )}
          <Paragraphs title={candidateName} paragraphs={[candidateText]} isOrganization={false} />
        </div>
      </div>
    </div>
  )
}

const SpectrumCategoryComponent = ({
  category: { moduleTitle, categoryTitle, categoryDescription, constructs, categoryAbbreviation },
  organizationName,
  candidateName,
  color,
  type,
}: SpectrumCategoryProps) => {
  const title = moduleTitle !== categoryTitle ? `${moduleTitle}: ${categoryTitle}` : categoryTitle
  return (
    <div className="spectrumCategory">
      <div className="header">
        <div className="section_header--abbreviation-wrapper" style={{ backgroundColor: color }}>
          <div className="abbreviation">{categoryAbbreviation}</div>
        </div>
        <div>
          <div className="headerTitle">{title}</div>
        </div>
      </div>
      <div>{categoryDescription && <div className="description">{categoryDescription}</div>}</div>
      <div>
        <ul className="spectrumConstruct--legend">
          {['candidate_match', 'organization_match'].includes(type) && (
            <li>
              <Organization />
              <span style={{ color: '#55bbe4' }}>{organizationName}</span>
            </li>
          )}
          <li>
            <Candidate />
            <span style={{ color: '#a7398b' }}>{candidateName}</span>
          </li>
        </ul>
      </div>
      {constructs.map((construct, idx) => (
        <Construct
          key={idx}
          construct={construct}
          organizationName={organizationName}
          candidateName={candidateName}
        />
      ))}
    </div>
  )
}

export default SpectrumCategoryComponent
