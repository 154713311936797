import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from '@chakra-ui/react'
import classNames from 'classnames'
import { handleError, relativeTime } from 'core'
import {
  Position,
  PositionStatus,
  api,
  WorkflowStatusType,
  Section,
  OptionalExceptFor,
  PatchPositionEdit,
} from 'provider'
import {
  patchPositionEdit,
  getPositionStatus,
  closePosition,
  reopenPosition,
} from '../../../actions/positions'
import ComparisonProfileField, {
  ComparisonOption,
  ComparisonOptionSections,
  SectionType,
} from '../../../components/ComparisonProfileField'
import { DropdownOption } from '../../../models'
import { useDocumentTitle } from '../../../hooks'
import { mapToOption } from '../../../utils'
import CommonFormInputs, { PositionForm } from '../CommonFormInputs'
import './styles.scss'
import { faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export type SectionWithId = OptionalExceptFor<Section, 'id'>

type EditPositionFormProps = {
  position: Position
  isPositionEditable: boolean
  isPositionClosed: boolean
  isGroupLoading: boolean
  updatePosition: (updatedPosition: Position) => void
  refreshData: () => void
  counter: number
}

const EditPositionForm = ({
  position,
  isPositionEditable,
  isPositionClosed,
  updatePosition,
  refreshData,
  counter,
}: EditPositionFormProps) => {
  useDocumentTitle('Edit Job')

  const initialSectionOptions: ComparisonOptionSections = useMemo(
    () =>
      position.sections.reduce((prevValue, currValue) => {
        let option
        if (currValue.sectionUser?.id) {
          option = {
            sectionType: SectionType.USER,
            id: currValue.sectionUser.id,
            title: currValue.sectionUser.label,
            user: currValue.sectionUser.user,
            date: currValue.sectionUser.statusAt,
            status: currValue.sectionUser.currentStatus,
          }
        } else if (currValue.sectionProfile?.id) {
          option = {
            sectionType: SectionType.GROUP,
            id: currValue.sectionProfile.id,
            title: currValue.sectionProfile.groupProfile,
            user: currValue.sectionProfile.group,
            date: currValue.sectionProfile.statusAt,
            status: currValue.sectionProfile.currentStatus,
          }
        }

        return {
          ...prevValue,
          [currValue.id]: option as ComparisonOption,
        }
      }, {} as ComparisonOptionSections),
    [position.sections],
  )

  const [isLoading, setIsLoading] = useState(true)
  const [positionStatusData, setPositionStatusData] = useState<PositionStatus[]>()
  const [tagOptions, setTagOptions] = useState<DropdownOption[]>()
  const [selectedTags, setSelectedTags] = useState<DropdownOption[]>()
  const [toggleTableText, setToggleTableText] = useState('More')
  const [toggleTableHidden, setToggleTableHidden] = useState(true)
  const [toggleIsExpanded, setToggleIsExpanded] = useState(false)
  const [formHasUpdates, setFormHasUpdates] = useState<boolean>(false)
  const [sectionOptions, setSectionOptions] =
    useState<ComparisonOptionSections>(initialSectionOptions)

  useEffect(() => {
    const positionStatusRequest = getPositionStatus(position.id)
    positionStatusRequest.request
      .then((data) => {
        setPositionStatusData(data)
      })
      .catch((error) => {
        handleError(undefined, { errorMessage: 'Problem loading status history' })(error)
      })

    return positionStatusRequest?.cancelRequest
  }, [position, counter])

  const recruiterOption = useMemo(
    () =>
      typeof position.recruiter !== 'string'
        ? mapToOption(position.recruiter, 'fullName', 'id')
        : undefined,
    [position.recruiter],
  )

  const assessmentOption = useMemo(
    () => (position.assessment ? mapToOption(position.assessment, 'title', 'id') : undefined),
    [position.assessment],
  )

  useEffect(() => {
    const tagList = api.getTags()
    tagList.request
      .then((data) => {
        setTagOptions(data)
        setIsLoading(false)
      })
      .catch((error) => {
        handleError(undefined, { errorMessage: 'Problem loading tag list' })(error)
      })
  }, [position])

  useEffect(() => {
    const { tags } = position
    let options = undefined
    if (tags && tags?.length > 0) {
      options = tags.map((tag) => mapToOption(tag, 'title', 'title'))
    }
    setSelectedTags(options)
  }, [position])

  const {
    control,
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm<PositionForm>({
    defaultValues: {
      title: position.positionTitle,
      referenceNumber: position.referenceNumber ?? '',
      assignedRecruiter: recruiterOption,
      sectionUsers: initialSectionOptions,
      assessment: assessmentOption,
      tags: selectedTags,
    },
  })
  const [isPosting, setIsPosting] = useState(false)

  const onSubmit = async ({ assignedRecruiter, title, referenceNumber, tags }: PositionForm) => {
    setIsPosting(true)

    try {
      await api.postTags({
        appLabel: 'organizations',
        model: 'jobrequisition',
        objectId: position.id,
        mode: 'reconcile',
        tags: tags?.length ? tags.map((tag) => ({ title: tag.label })) : [],
      }).request
    } catch (err) {
      handleError(undefined, { errorMessage: 'Tags may not have been updated properly.' })(err)
    }
    try {
      const payload: PatchPositionEdit = {
        id: position.id,
        positionTitle: title,
        referenceNumber: referenceNumber,
        recruiter: assignedRecruiter.value,
        sections: Object.entries(sectionOptions).map(([id, comparisonOption]) => ({
          id,
          sectionUser:
            comparisonOption?.sectionType === SectionType.USER ? comparisonOption?.id : null,
          sectionProfile:
            comparisonOption?.sectionType === SectionType.GROUP ? comparisonOption?.id : null,
        })),
      }
      const updatedPosition = await patchPositionEdit(payload).request
      updatePosition(updatedPosition)
      refreshData()
      setIsPosting(false)
      setFormHasUpdates(false)
    } catch (err) {
      handleError(setIsPosting, { errorMessage: 'Could not update position' })(err)
    }
  }

  const handleSave = handleSubmit(onSubmit)
  const handleSaveAndNew = handleSubmit((data) => {
    onSubmit(data).then(() => {
      window.location.href = '/positions'
    })
  })

  const closeJobPosition = () => {
    setIsLoading(true)
    closePosition(position.id)
      .request.then(() => {
        setIsLoading(false)
        setFormHasUpdates(false)
        refreshData()
      })
      .catch(handleError)
  }

  const reopenJobPosition = () => {
    setIsLoading(true)
    reopenPosition(position.id)
      .request.then(() => {
        setIsLoading(false)
        setFormHasUpdates(false)
        refreshData()
      })
      .catch(handleError)
  }

  const toggleTable = () => {
    setToggleTableText((old) => (old.toLowerCase() === 'less' ? 'More' : 'Less'))
    setToggleTableHidden((hidden) => !hidden)
    setToggleIsExpanded((expanded) => !expanded)
  }

  const conditionalClassNames = classNames({
    'row-hidden': toggleTableHidden,
  })

  return (
    <>
      {!isLoading && (
        <form onSubmit={handleSave} className="editPositionForm">
          <div className="editPositionForm--left">
            <div className="status-row">
              <div className="status badge-status">{position.currentStatus.title}</div>
              <span className="text-muted">{relativeTime(position.statusAt)}</span>
            </div>
            <CommonFormInputs
              isCreate={false}
              control={control}
              register={register}
              watch={watch}
              errors={errors}
              defaultOrgUser={recruiterOption}
              defaultTagOption={selectedTags}
              isInputFieldReadonly={!isPositionEditable}
              isReadonly={isPositionClosed}
              tags={tagOptions}
              formUpdates={setFormHasUpdates}
            />
          </div>

          <div className="editPositionForm--right">
            <div className="positionPage__sectionHeader">Set Comparisons</div>
            <div>
              {position.sections.map((positionSection) => {
                const section: SectionWithId =
                  typeof positionSection.section === 'string'
                    ? { id: positionSection.section }
                    : positionSection.section

                const isGroup = positionSection.sectionProfile ? true : false
                const comparisonOption = {
                  sectionType: isGroup ? SectionType.GROUP : SectionType.USER,
                  id: positionSection.sectionUser?.id ?? positionSection.sectionProfile?.id ?? '',
                  title:
                    positionSection.sectionUser?.label ??
                    positionSection.sectionProfile?.groupProfile ??
                    '',
                  user:
                    positionSection.sectionUser?.user ??
                    positionSection.sectionProfile?.group ??
                    '',
                  date:
                    positionSection.sectionUser?.statusAt ??
                    positionSection.sectionProfile?.statusAt ??
                    '',
                  status:
                    positionSection.sectionUser?.currentStatus ??
                    positionSection.sectionProfile?.currentStatus ??
                    undefined,
                }

                return (
                  <div key={positionSection.id} className="sectionUserForm">
                    <div className="sectionUserForm__dropdownWrapper">
                      <ComparisonProfileField
                        positionSectionId={positionSection.id}
                        assessment={position.assessment}
                        className="sectionUserForm__dropdown"
                        section={section}
                        name={`comparisionSection.${positionSection.id}`}
                        control={control}
                        isDisabled={!isPositionEditable || isPositionClosed}
                        comparisonOption={comparisonOption}
                        label={section?.organizationText}
                        instructions={section?.organizationDescription}
                        statusTag={
                          positionSection.sectionUser?.currentStatus ||
                          positionSection.sectionProfile?.currentStatus
                        }
                        formUpdates={setFormHasUpdates}
                        updateSectionOptions={setSectionOptions}
                        isGroup={isGroup}
                      />
                    </div>
                  </div>
                )
              })}
            </div>
            <div className="calloutBox calloutBox--fixed-bottom calloutBox--light-border">
              <div className="positionStatusTable">
                <div className="positionStatusTable--title">
                  <div className="calloutTitle">Status Events</div>
                  <Button
                    className="showMoreLink"
                    variant="link"
                    padding={1}
                    colorScheme="brand"
                    fontSize={14}
                    onClick={toggleTable}>
                    <FontAwesomeIcon size="sm" icon={toggleIsExpanded ? faMinus : faPlus} />
                    &nbsp;Show {toggleTableText}
                  </Button>
                </div>
                {positionStatusData?.statusEvents && (
                  <table className="table table-condensed small text-muted infoTable">
                    <tbody>
                      {positionStatusData?.statusEvents.map((statusEvent, idx) => (
                        <tr
                          className={`${idx !== 0 ? conditionalClassNames : ''} text-muted`}
                          key={statusEvent.changeAt}>
                          <td>
                            {new Date(statusEvent.changeAt).toLocaleString('en-us', {
                              weekday: 'short',
                              year: 'numeric',
                              month: 'short',
                              day: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                              hour12: true,
                            })}
                          </td>
                          <td>{statusEvent.toStatus.title}</td>
                          <td>{statusEvent.createdByName}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
          <div className="editPositionForm--button-container">
            {formHasUpdates && (
              <div className="editPositionForm--button-container--unsaved">
                <span>Unsaved Changes</span>
              </div>
            )}
            {position.currentStatus.code === WorkflowStatusType.Closed ? (
              <Button
                className="editPositionForm__closeBtn"
                isLoading={isPosting}
                onClick={() => reopenJobPosition()}
                variant="danger">
                Reopen Job
              </Button>
            ) : (
              <Button
                className="editPositionForm__closeBtn"
                isLoading={isPosting}
                onClick={() => closeJobPosition()}
                variant="danger">
                Close Job
              </Button>
            )}

            {
              <>
                <Button
                  type="submit"
                  className="editPositionForm__saveBtn"
                  isLoading={isPosting}
                  isDisabled={!formHasUpdates}
                  colorScheme="brand"
                  variant="brandPrimary">
                  Save Job
                </Button>
                <Button
                  type="button"
                  className="editPositionForm__saveBtn"
                  isLoading={isPosting}
                  isDisabled={!formHasUpdates}
                  colorScheme="brand"
                  variant="brandPrimary"
                  onClick={handleSaveAndNew}>
                  Save & New
                </Button>
              </>
            }
          </div>
        </form>
      )}
      {/* <pre>{JSON.stringify(sectionOptions, null, 2)}</pre> */}
    </>
  )
}

export default EditPositionForm
