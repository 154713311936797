import { toast } from 'react-toastify'
import { TOAST_OPTIONS } from '../../constants/alerts'

const showToast = (message: string) => {
  toast.success(message, { ...TOAST_OPTIONS })
}

const showErrorToast = (error: string) => {
  toast.error(error, { ...TOAST_OPTIONS })
}

const showWarningToast = (message: string) => {
  toast.warning(message, {
    ...TOAST_OPTIONS,
  })
}

const showPromptToast = (message: string, options = {}) => {
  toast.info(message, {
    ...TOAST_OPTIONS,
    ...options,
  })
}

const Toast = {
  success: showToast,
  error: showErrorToast,
  warning: showWarningToast,
  prompt: showPromptToast,
}

export default Toast
