import './styles.scss'

type QuestionsProps = {
  questions: string[]
}

const QuestionsSection = ({ questions }: QuestionsProps) => {
  return (
    <div className="section_questions">
      <ol>
        {questions.map((q, idx) => (
          <li key={idx}>{q}</li>
        ))}
      </ol>
    </div>
  )
}

export default QuestionsSection
