import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { handleError } from 'core'
import { GroupMember, NormalizedGroupMember } from 'provider'
import { useMemo, useState } from 'react'
import { ManageMembers } from './ManageMembers'
import { MemberKind } from './types'

export type GroupManageMemberModalProps = {
  /**
   * The group id to manage members for
   *
   * @type {string}
   */
  groupId: string
  /**
   * The members (or admins) of the group.
   *
   * @type {GroupMember[]}
   */
  groupMembers: GroupMember[]
  /**
   * The kind of members to manage. Can be either Member or Admin.
   * If not provided, defaults to Member.
   *
   * @default MemberKind.Member
   *
   * @type {MemberKind}
   */
  kind?: MemberKind
  /**
   * The callback to call when a member is added.
   * @type {(members: string[]) => void}
   *
   */
  onMemberAdd: (members: string[]) => void
  /**
   * The callback to call when a member is removed.
   * @type {(members: string[]) => void}
   */
  onMemberRemove: (members: string[]) => void

  /**
   * Wheather or not the modal is open. Controls the modal's visibility.
   *
   * This is a controlled component, so you must provide this prop.
   * It is usually provided by a `useDisclosure()` hook.
   *
   * @type {boolean}
   */
  isOpen: boolean
  /**
   * The close function for the modal.
   *
   * This is usually provided by a `useDisclosure()` hook.
   *
   */
  onClose: () => void
  /**
   * The submit function for the modal.
   *
   * This is usually provided by a `useDisclosure()` hook.
   *
   * NOTE: Currently, this is not used, and is not required.
   */
  onSubmit?: () => void
}

/**
 * Modal for managing members or admins of a group.
 */
export const GroupManageMemberModal = ({
  groupId,
  groupMembers,
  kind = MemberKind.Member,
  onMemberAdd,
  onMemberRemove,
  isOpen,
  onClose,
  onSubmit,
}: GroupManageMemberModalProps) => {
  const manageLanguage = useMemo(() => {
    switch (kind) {
      case MemberKind.Admin:
        return 'Group Admins'
      case MemberKind.Member:
      default:
        return 'Group Members'
    }
  }, [kind])

  const [localUsersToAdd, setLocalUsersToAdd] = useState<NormalizedGroupMember[]>([])
  const [localUsersToRemove, setLocalUsersToRemove] = useState<NormalizedGroupMember[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const warnBeforeSave = useMemo(() => {
    return localUsersToAdd.length > 0 || localUsersToRemove.length > 0
  }, [localUsersToAdd, localUsersToRemove])

  const saveAll = async () => {
    setIsLoading(true)
    try {
      await onMemberAdd(localUsersToAdd.map((user) => user.id))
      await onMemberRemove(localUsersToRemove.map((user) => user.id))
    } catch (error: any) {
      handleError(setIsLoading, { errorMessage: error?.message })(error)
    }

    setIsLoading(false)
  }

  const handleClose = () => {
    if (warnBeforeSave) {
      // eslint-disable-next-line no-alert
      const shouldClose = window.confirm(
        'You have unsaved changes. Are you sure you want to close this modal? Any unsaved changes will be lost.',
      )
      if (shouldClose && onClose) {
        onClose()
      } else {
        return
      }
    } else {
      if (onClose) {
        onClose()
      }
    }
  }

  const handleSubmit = () => {
    if (onSubmit) {
      saveAll()
        .then(() => {
          setLocalUsersToAdd([])
          setLocalUsersToRemove([])
        })
        .then(() => {
          onSubmit()
        })
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} isCentered={true} size="5xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Manage {manageLanguage}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ManageMembers
            groupId={groupId}
            groupMembers={groupMembers}
            onUsersToBeAddedChange={setLocalUsersToAdd}
            onUsersToBeRemovedChange={setLocalUsersToRemove}
            kind={kind}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="brand"
            variant="brandPrimary"
            mr={3}
            onClick={handleSubmit}
            isLoading={isLoading}
            disabled={isLoading}>
            Save Changes
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
