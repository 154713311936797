import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  useDisclosure,
  Button,
} from '@chakra-ui/react'

import {
  faCopy,
  faObjectGroup,
  faLock,
  faLockOpen,
  faPeopleGroup,
  faPlus,
} from '@fortawesome/pro-solid-svg-icons'
import { faSquareEllipsis } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ColumnDef, Row } from '@tanstack/react-table'
import {
  PageWrapper,
  DebouncedInput,
  ActionDropdown,
  formatDate,
  Checkbox,
  Toast,
  Color,
  FilterTable,
  AlignType,
  Tag,
  TagGroup,
  handleError,
} from 'core'
import { api, Group } from 'provider'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDocumentTitle } from '../../hooks'
import { listGroups } from '../../actions/groups'
import { archiveGroupItemAction } from './actions'
import { DuplicateGroupModal } from './components/DuplicateGroupModal'
import { MergeGroupModal } from './components/MergeGroupModal'
import './styles.scss'

enum PublicStatus {
  Public = 'public',
  Private = 'private',
  All = 'all',
}

export const GroupPage = () => {
  useDocumentTitle('Manage User Groups')
  const [groupToArchive, setGroupToArchive] = useState<Group | null>(null)
  const [groupToCopy, setGroupToCopy] = useState<Group | undefined>(undefined)
  const archiveModalDisclosure = useDisclosure()
  const copyGroupDisclosure = useDisclosure()
  const mergeGroupDisclosure = useDisclosure()
  const [isLoadingData, setIsLoadingData] = useState(true)
  const [tableData, setTableData] = useState<Group[]>([])
  const [searchParams, setSearchParams] = useState<string>('')
  const [refreshDataCounter, setRefreshDataCounter] = useState(0)
  const [selectedRows, setSelectedRows] = useState<Row<Group>[]>([])
  const archiveCancelRef = useRef(null)
  const [publicStatus, setPublicStatus] = useState<PublicStatus>(PublicStatus.All)
  const [isMounted, setIsMounted] = useState(true)

  const navigate = useNavigate()

  useEffect(() => {
    setIsMounted(true)
    return () => {
      setIsMounted(false)
    }
  }, [])

  const selectedGroups = useMemo(() => selectedRows.map((row) => row.original), [selectedRows])

  const columns = useMemo<ColumnDef<Group>[]>(
    () => [
      {
        id: 'select',
        header: ({ table }) => (
          <Checkbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllPageRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div>
            <Checkbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },

      {
        header: 'Group Name',
        accessorKey: 'title',
        cell: (info) => (
          <Link to={`/groups/${info.row.original.id}`}>{info.getValue() as string}</Link>
        ),
        footer: (props) => props.column.id,
        enableColumnFilter: true,
      },
      {
        header: 'Member Count',
        accessorKey: 'memberCount',
        meta: {
          align: AlignType.center,
        },
        cell: (info) => <div style={{ textAlign: 'center' }}>{info.getValue() as string}</div>,
        footer: (props) => props.column.id,
      },

      {
        header: 'Created By',
        accessorKey: 'createdByName',
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableColumnFilter: true,
      },
      {
        header: 'Last Updated',
        accessorKey: 'updatedAt',
        meta: {
          align: AlignType.center,
        },
        cell: (info) => formatDate(info.getValue() as string),
        footer: (props) => props.column.id,
      },
      {
        header: 'Privacy',
        accessorKey: 'isPublic',
        meta: {
          align: AlignType.center,
        },
        cell: (info) => (
          <div style={{ color: '' }}>
            {info.getValue() ? (
              <FontAwesomeIcon icon={faLockOpen} size="lg" color={Color.Green} />
            ) : (
              <FontAwesomeIcon icon={faLock} size="lg" color={Color.Red} />
            )}
          </div>
        ),
        footer: (props) => props.column.id,
      },
      {
        id: 'actions',
        header: '',
        enableSorting: false,
        cell: ({ row }) => (
          <ActionDropdown
            actions={[
              {
                label: 'Duplicate',
                icon: faCopy,
                iconSize: 'xl',
                iconColor: Color.Purple,
                labelColor: Color.Purple,
                onClick: () => {
                  setGroupToCopy(row.original)
                  copyGroupDisclosure.onOpen()
                },
              },
              archiveGroupItemAction(row.original, () => {
                setGroupToArchive(row.original)
                archiveModalDisclosure.onOpen()
              }),
            ]}
            alignButton={'right'}
            variant={'actionGhost'}
            icon={faSquareEllipsis}
            iconColor={'#3F2C7F'}
            iconSize={'2xl'}
          />
        ),
      },
    ],
    [archiveModalDisclosure, copyGroupDisclosure],
  )

  const expireGroupListCache = useCallback(() => {
    setRefreshDataCounter((prev) => prev + 1)
  }, [])

  const archiveGroup = useCallback(() => {
    if (groupToArchive) {
      api.archiveGroup(groupToArchive.id).request.then(() => {
        setGroupToArchive(null)
        expireGroupListCache()
        archiveModalDisclosure.onClose()
      })
    }
  }, [groupToArchive, archiveModalDisclosure, expireGroupListCache])

  const fetchDataParams = useMemo(() => {
    if (publicStatus === 'all') {
      return {
        pager: 'none',
        search: searchParams,
      }
    }

    return {
      pager: 'none',
      isPublic: PublicStatus.Public === publicStatus,
      search: searchParams,
    }
  }, [searchParams, publicStatus])

  const tags: Tag[] = [
    {
      text: 'All',
      active: true,
      onActive: () => {
        switchPublicStatus(PublicStatus.All)
      },
    },
    {
      text: 'Public',
      active: false,
      onActive: () => {
        switchPublicStatus(PublicStatus.Public)
      },
    },
    {
      text: 'Private',
      active: false,
      onActive: () => {
        switchPublicStatus(PublicStatus.Private)
      },
    },
  ]

  const switchPublicStatus = (type: PublicStatus) => {
    if (type === publicStatus) {
      return
    }

    setPublicStatus(type)
  }

  const getTableData = useCallback(() => {
    if (!isMounted) return
    setIsLoadingData(true)
    const resolve = listGroups({ ...fetchDataParams })
    if (!isMounted) return
    resolve.request
      .then((response) => {
        if (!isMounted) return
        setIsLoadingData(false)
        setTableData(response)
      })
      .catch((error) => {
        if (!isMounted) return
        handleError(setIsLoadingData, {
          errorMessage: 'Could not retrieve groups. An error occurred.',
        })(error)
      })
    return resolve.cancelRequest
  }, [fetchDataParams, isMounted])

  useEffect(getTableData, [getTableData, refreshDataCounter])

  const submitCopyNewGroup = useCallback(
    async (groupId: string, title: string, isPrivate: boolean): Promise<any> => {
      setGroupToCopy(undefined)
      const resp = await api.copyGroup(groupId, { title, isPublic: !isPrivate }).request
      expireGroupListCache()
      navigate(`/groups/${resp.id}`)
    },
    [expireGroupListCache, navigate],
  )

  const ArchiveDialog = useCallback(
    () => (
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={archiveCancelRef}
        onClose={archiveModalDisclosure.onClose}
        isOpen={archiveModalDisclosure.isOpen}
        isCentered>
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Archive Group?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Are you sure you want to archive the group "{groupToArchive?.title}"?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={archiveCancelRef} onClick={archiveModalDisclosure.onClose}>
              No
            </Button>
            <Button colorScheme="red" ml={3} onClick={archiveGroup}>
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    ),
    [
      archiveGroup,
      archiveModalDisclosure.isOpen,
      archiveModalDisclosure.onClose,
      groupToArchive?.title,
    ],
  )

  return (
    <PageWrapper extraClassNames="groupPage">
      {ArchiveDialog()}
      <DuplicateGroupModal
        group={groupToCopy}
        isOpen={copyGroupDisclosure.isOpen}
        onClose={copyGroupDisclosure.onClose}
        onSubmit={submitCopyNewGroup}
      />
      <MergeGroupModal
        groups={selectedGroups}
        isOpen={mergeGroupDisclosure.isOpen}
        onClose={mergeGroupDisclosure.onClose}
        onSubmit={(groupIds: string[], title: string) => {
          return api
            .mergeGroups({ groups: groupIds, title: title })
            .request.then((resp) => {
              if (!isMounted) return
              mergeGroupDisclosure.onClose()
              expireGroupListCache()
              navigate(`/groups/${resp.id}`)
            })
            .catch((err: any) => {
              if (!isMounted) return
              if (err.data.errors.detail && err.data.errors.detail.length > 0) {
                Toast.error(err.data.errors.detail[0])
              }
            })
        }}
      />
      <div className="header">
        <div className="title">
          <FontAwesomeIcon className="page-icon" icon={faPeopleGroup} fixedWidth={true} />
          Manage User Groups
          {/* TODO: Once we have a way to send assessments to groups, we can uncomment this */}
          {/* <Tooltip label="Groups are a way to organize your employees into categories, departments, and teams. You can also use them to send assessments to groups of people all at once.">
            <FontAwesomeIcon icon={faCircleInfo} style={{ marginLeft: '0.5rem' }} />
          </Tooltip> */}
        </div>
      </div>
      <div className="manage-actions">
        <TagGroup tags={tags} label="Filter" />
        <div className="buttons">
          <ActionDropdown
            actions={[
              {
                label: 'Merge Selected',
                icon: faObjectGroup,
                iconSize: 'xl',
                iconColor: Color.Purple,
                labelColor: Color.Purple,
                onClick: () => {
                  mergeGroupDisclosure.onOpen()
                },
              },
            ]}
            label={'Bulk Actions'}
            showCaret={true}
            variant="brandPrimary"
          />

          <Button
            className="btnLeftMargin"
            type="button"
            variant="brandPrimary"
            onClick={() => navigate('/groups/new')}
            leftIcon={<FontAwesomeIcon icon={faPlus} />}>
            Create Group
          </Button>
        </div>
      </div>
      <div className="group-list">
        <div className="filterInput">
          <DebouncedInput
            value={''}
            onChange={(value) => setSearchParams(String(value))}
            className="inputField__input fa-font"
            placeholder="Find..."
          />
        </div>
        <FilterTable
          schema={columns}
          data={tableData}
          isLoading={isLoadingData}
          onRowSelection={setSelectedRows}
        />
      </div>
    </PageWrapper>
  )
}
