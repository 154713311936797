import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { EMAIL_REGEX, handleError, InputField } from 'core'
import { Invitation, AudienceType } from 'provider'
import { createInvitation } from '../../../actions/invitations'
import { useConfirmationModal } from '../../../hooks'
import { onSubmitForm } from '../../../utils'

import './styles.scss'
import { Button } from '@chakra-ui/react'

type CandidateForm = {
  firstName: string
  lastName: string
  email: string
}

type AddCandidateFormProps = {
  assessmentId: string
  positionId: string
  existingEmails?: string[]
  addNewInvitation?: (invitation: Invitation) => void
  confirmationModalNeeded: boolean
}

const AddCandidateForm = ({
  assessmentId,
  positionId,
  existingEmails,
  addNewInvitation,
  confirmationModalNeeded,
}: AddCandidateFormProps) => {
  const {
    handleSubmit,
    register,
    reset,
    watch,
    formState: { errors },
  } = useForm<CandidateForm>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
  })

  const [isPosting, setIsPosting] = useState(false)
  const [formValue, setFormValue] = useState<CandidateForm | undefined>()

  const { confirmationModal, setShowModal } = useConfirmationModal({
    textBody: (
      <>
        Once you invite a candidate, you cannot edit comparison sections.
        <br /> Do you want to continue?
      </>
    ),
    onSuccess: () => {
      setShowModal(false)
      formValue && postData(formValue)
    },
  })

  const onSubmit = (formData: CandidateForm) => {
    if (confirmationModalNeeded) {
      setShowModal(true)
      setFormValue(formData)
    } else {
      postData(formData)
    }
  }

  const postData = async (formData: CandidateForm) => {
    setIsPosting(true)
    const { firstName, lastName, email } = formData
    try {
      const newInvitation = await createInvitation({
        assessment: { id: assessmentId },
        jobPosition: { id: positionId },
        user: {
          firstName,
          lastName,
          email,
        },
        audience: AudienceType.Candidate,
      }).request
      addNewInvitation?.(newInvitation)
      setIsPosting(false)
      reset({ firstName: '', lastName: '', email: '' })
    } catch (err) {
      handleError(setIsPosting, { errorMessage: 'Could not create invitation' })(err)
    }
  }

  return (
    <form onSubmit={onSubmitForm(handleSubmit(onSubmit))} className="createInvitationForm">
      <InputField
        placeholder="First Name"
        registerFields={register('firstName', { required: 'Required field' })}
        getValue={() => watch('firstName')}
        errorMessage={errors.firstName?.message}
        isColumn={true}
      />
      <InputField
        placeholder="Last Name"
        registerFields={register('lastName', { required: 'Required field' })}
        getValue={() => watch('lastName')}
        errorMessage={errors.lastName?.message}
        isColumn={true}
      />
      <InputField
        placeholder="Email Address"
        registerFields={register('email', {
          required: 'Required field',
          pattern: {
            value: EMAIL_REGEX,
            message: 'Invalid email address',
          },
          validate: (email) =>
            existingEmails?.find(
              (existingEmail) => existingEmail.toLowerCase() === email.toLowerCase(),
            ),
        })}
        getValue={() => watch('email')}
        errorMessage={errors.email?.message}
        isColumn={true}
      />
      <>
        <Button
          className="createInvitationForm__saveBtn"
          type="submit"
          isLoading={isPosting}
          variant="brandPrimary">
          Send Invitation
        </Button>
      </>
      {confirmationModal}
    </form>
  )
}

export default AddCandidateForm
