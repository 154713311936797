import { api, GetSectionUsers, PatchSectionUser } from 'provider'

export const getTenant = () => api.getTenant()

export const getDashboardStatistics = () => api.getDashboardStatistics()

export const patchSectionUser = (sectionId: string, payload: PatchSectionUser) =>
  api.patchSectionUser(sectionId, payload)

export const getSectionUsers = (props: GetSectionUsers = {}) => api.getSectionUsers(props)

export const getSectionUserQuestion = (sectionId: string) => api.getNextQuestion(sectionId)

export const saveAnswer = (sectionId: string, answers: string[]) =>
  api.saveAnswer(sectionId, { choices: answers })

export const inactivateOrgComparison = (sectionUserId: string) =>
  api.inactivateOrgComparison(sectionUserId)

export const reactivateOrgComparison = (sectionUserId: string) =>
  api.reactivateOrgComparison(sectionUserId)
