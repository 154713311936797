import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Button, Grid, GridItem, Flex, Wrap, WrapItem, Spacer } from '@chakra-ui/react'
import BarChart from '../../components/Charts/BarChart'
import ScatterChart from '../../components/Charts/ScatterChart'
import MatrixChart from '../../components/Charts/MatrixChart'
import { getGroupProfileDetail } from '../../actions/profile'
import { useConfirmationModal, useDocumentTitle } from '../../hooks'
import { handleError, PageWrapper, LoadingIndicator } from 'core'
import { api, Profile, CancellableRequest } from 'provider'
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-regular-svg-icons'
import FinalizeModal from '../../components/FinalizeModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.scss'
import { GroupDetail } from 'packages/provider/src/models/Profile'

// * chart info * //
// https://www.chartjs.org/ is the package to render all the charts
// the reactjs chart wrapper is used as well https://react-chartjs-2.js.org/
// plugins: annotation plugin is used, label plugin is also used. see chartjs plugins
// each chart is rendered conditionally based on what the api call delivers
// if 'total_points' we render matrix etc.

const ScoreProfile = () => {
  useDocumentTitle('Edit Profile')
  const { groupProfileId } = useParams()

  const [showBar, setShowBar] = useState(false)
  const [showScatter, setShowScatter] = useState(false)
  const [showMatrix, setshowMatrix] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [groupDetail, setGroupDetail] = useState<GroupDetail | null>(null)
  const [currentChartIndex, setCurrentChartIndex] = useState<number>(0)
  const [chartData, setChartData] = useState<Profile>()
  const [chartsLength, setChartsLength] = useState<number>(0)
  const [callPoints, setCallPoints] = useState<boolean>(false)
  const [showFinalizeModal, setShowFinalizeModal] = useState<boolean>(false)
  // matrix point totals
  const [selectedPointTotal, setSelectedPointTotal] = useState<number>(0)
  const [pointTotal, setPointTotal] = useState<number>(0)
  const [allStatusLocked, setAllStatusLocked] = useState<boolean>(false)
  const [allCanFinalize, setAllCanFinalize] = useState<Array<boolean | undefined>>([])
  const [finalModal, setFinalModal] = useState<'finalize' | 'continue'>('continue')

  const navigate = useNavigate()

  const renderBarChart = () => {
    setShowBar(true)
    setShowScatter(false)
    setshowMatrix(false)
  }

  const renderQuadrantChart = () => {
    setShowScatter(true)
    setShowBar(false)
    setshowMatrix(false)
  }

  const renderMatrixChart = () => {
    setShowScatter(false)
    setshowMatrix(true)
    setShowBar(false)
  }

  const hideCharts = () => {
    setShowScatter(false)
    setshowMatrix(false)
    setShowBar(false)
  }

  useEffect(() => {
    setIsLoading(true)
    if (groupProfileId) {
      const planRequest = getGroupProfileDetail(groupProfileId)
      planRequest.request
        .then((groupDetail: GroupDetail) => {
          setGroupDetail(groupDetail)
          const status = groupDetail.categories.every((c) => c.isStatusLocked === true)
          setAllStatusLocked(status)
          const chartsLen = groupDetail.categories.length
          const finalize = Array.from({ length: chartsLen }, () => false)
          setAllCanFinalize(finalize)
          getCategory(groupProfileId, groupDetail, currentChartIndex)
        })
        .catch(handleError(setIsLoading))
        .finally(() => {
          setIsLoading(false)
        })
      return planRequest.cancelRequest
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupProfileId])

  const getCategory = (groupProfileId: string, groupDetail: GroupDetail, chartIndex: number) => {
    setIsLoading(true)
    setChartsLength(groupDetail.categories.length)
    const categoryId: string = groupDetail.categories[chartIndex].categoryId
    let fetchProfileCategory: CancellableRequest<Profile> | undefined
    if (groupProfileId) {
      fetchProfileCategory = api.getGroupProfileCategory(groupProfileId, categoryId)
      fetchProfileCategory.request
        .then((response) => {
          setChartData(response)
          const finalize = allCanFinalize
          finalize[chartIndex] = response.hasValidScore
          setAllCanFinalize(finalize)
          renderChart(response)
        })
        .catch(handleError(setIsLoading))
        .finally(() => {
          setIsLoading(false)
        })
    }
    return fetchProfileCategory?.cancelRequest
  }

  const renderChart = (response: Profile) => {
    if (response.categoryComponentType === 'quadrant_plot') {
      renderQuadrantChart()
    } else if (response.categoryComponentType === 'bar_chart') {
      renderBarChart()
    } else if (response.categoryComponentType === 'total_points') {
      renderMatrixChart()
    }
  }

  // validate matrix chart to see if they have the correct number of points selected
  const validateChart = () => {
    if (chartData?.categoryComponentType === 'total_points' && selectedPointTotal < pointTotal) {
      setShowModal(true)
    } else if (
      chartData?.categoryComponentType === 'total_points' &&
      selectedPointTotal === pointTotal
    ) {
      setCallPoints(true)
    } else {
      nextChart()
    }
  }

  useEffect(() => {
    if (callPoints) {
      nextChart()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callPoints])

  // take them to the next chart or send them to finalize score
  const nextChart = () => {
    setShowScatter(false)
    setShowBar(false)
    setCallPoints(false)
    setShowModal(false)
    const newIndex = currentChartIndex + 1
    setCurrentChartIndex(newIndex)
    if (groupProfileId && newIndex < chartsLength) {
      setshowMatrix(false)
      getCategory(groupProfileId, groupDetail, newIndex)
    } else if (
      chartData?.categoryComponentType === 'total_points' &&
      selectedPointTotal !== pointTotal
    ) {
      // if the last chart is matrix and they haven't gotten the total points
      // do not take them to finalize
      setShowModal(false)
    } else {
      //submit final score here
      setshowMatrix(false)
      const finalize = allCanFinalize.every((f) => f === true)
      if (allStatusLocked === false && finalize === true) {
        setFinalModal('finalize')
      }
      setShowFinalizeModal(true)
    }
  }

  const previousChart = () => {
    hideCharts()
    setShowFinalizeModal(false)
    const newIndex = currentChartIndex - 1
    setCurrentChartIndex(newIndex)
    if (groupProfileId && currentChartIndex > 0) {
      getCategory(groupProfileId, groupDetail, newIndex)
    } else {
      navigate(`/groups/${groupDetail.group.id}/profiles`)
    }
  }

  const { confirmationModal, setShowModal } = useConfirmationModal({
    textBody: (
      <>
        All of the necessary points have not been assigned.
        <br /> Click Cancel to finish assigning points or Continue to abandon your changes.
      </>
    ),
    onSuccess: nextChart,
  })

  const closeFinalizeModal = () => {
    setShowFinalizeModal(false)
    navigate(`/groups/${groupDetail.group.id}/profiles`)
  }

  const onFinalizeSuccess = () => {
    //make final api call here
    if (groupProfileId) {
      setIsLoading(true)
      const request = api.finalizeGroupProfile(groupProfileId)
      request.request
        .then(() => {})
        .catch(handleError(setIsLoading, { errorMessage: 'Cannot submit score at this time.' }))
        .finally(() => {
          navigate(`/groups/${groupDetail.group.id}/profiles`)
          setIsLoading(false)
        })
    }
  }

  // matrix chart selected point total
  const passSelectedPointTotal = (total: number) => {
    setSelectedPointTotal(total)
  }

  // matrix chart allowed points
  const passPointTotal = (total: number) => {
    setPointTotal(total)
  }

  const getScoreProfileTitle = (): string => {
    const groupTitle = groupDetail.group.title
    const profileTitle = groupDetail.title

    if (groupTitle.toLowerCase() === profileTitle.toLowerCase()) {
      return groupTitle
    }

    return `${groupTitle} - ${profileTitle}`
  }

  return (
    <PageWrapper>
      <div
        style={{
          alignContent: 'center',
          maxWidth: '1020px',
          padding: '0 10px',
        }}>
        {groupDetail && <h2 className="score-profile-title">{getScoreProfileTitle()}</h2>}
        <Grid templateColumns="repeat(2, 1fr)" gap={2}>
          <GridItem w="100%" colSpan={2}>
            {isLoading && <LoadingIndicator fullWidth={true} />}
            {groupProfileId && (
              <div>
                {showBar && chartData && (
                  <Wrap spacing="20px" justify="left">
                    {chartData.datasets?.map((d) => {
                      return (
                        <WrapItem key={d.id}>
                          <BarChart
                            key={d.id}
                            apiLabels={d.labels}
                            bucketMap={d.bucketMap}
                            groupProfileId={groupProfileId}
                            construct={d}
                            constructId={d.id}
                            apiData={d.data}
                            headLabel={d.title}
                            description={d.description}
                            isStatusLocked={chartData.isStatusLocked}
                            allCanFinalize={allCanFinalize}
                            setAllCanFinalize={setAllCanFinalize}
                            chartIndex={currentChartIndex}
                          />
                        </WrapItem>
                      )
                    })}
                  </Wrap>
                )}

                {showScatter && (
                  <ScatterChart
                    apiData={chartData}
                    groupProfileId={groupProfileId}
                    allCanFinalize={allCanFinalize}
                    setAllCanFinalize={setAllCanFinalize}
                    chartIndex={currentChartIndex}
                  />
                )}

                {showMatrix && (
                  <MatrixChart
                    apiData={chartData}
                    groupProfileId={groupProfileId}
                    passSelectedPointTotal={passSelectedPointTotal}
                    passPointTotal={passPointTotal}
                    callPoints={callPoints}
                    allCanFinalize={allCanFinalize}
                    setAllCanFinalize={setAllCanFinalize}
                    chartIndex={currentChartIndex}
                  />
                )}
              </div>
            )}
          </GridItem>
        </Grid>
        <Spacer h={5} />
        <Flex>
          <Button
            className="backButton"
            colorScheme={'brand'}
            onClick={previousChart}
            leftIcon={<FontAwesomeIcon icon={faArrowLeft} />}>
            Back
          </Button>
          <Spacer />
          <Button
            className="nextButton"
            colorScheme={'brand'}
            onClick={validateChart}
            rightIcon={<FontAwesomeIcon icon={faArrowRight} />}>
            Next
          </Button>
        </Flex>
      </div>
      {confirmationModal}
      {showFinalizeModal && (
        <FinalizeModal
          modalVersion={finalModal}
          onSuccess={onFinalizeSuccess ?? closeFinalizeModal}
          onCancel={previousChart}
          onClose={closeFinalizeModal}
          showModal={showFinalizeModal}
        />
      )}
    </PageWrapper>
  )
}

export default ScoreProfile
