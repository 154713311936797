import { api, CommonParams, GetMembers, UpsertMemberPayload } from 'provider'

export const getMembers = ({ isActive = true, ...commonParams }: GetMembers = {}) =>
  api.getMembers({ isActive, ...commonParams })

export const createMember = (newMember: UpsertMemberPayload) =>
  api.createMember({ ...newMember, email: newMember.email.toLowerCase() })

export const updateMember = (updatedMember: UpsertMemberPayload) => api.patchUser(updatedMember)

export const reactivateMember = (id: string, email: string, role: string) =>
  api.reactivateMember({ id, role, email, reactivate: true })

export const inactivateMember = (id: string, email: string, role: string) =>
  api.inactivateMember({ id, role, email, inactivate: true })

export const getRoles = (params?: CommonParams) => api.getRoles(params)
