import { Dispatch, SetStateAction, useState } from 'react'
import { useController, Path, UseControllerProps, FieldValues } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/pro-regular-svg-icons'
import { faUser, faUsers, faPencil, faX } from '@fortawesome/pro-solid-svg-icons'
import { Button, Tooltip } from '@chakra-ui/react'
import { InputWrapper, formatDate } from 'core'
import { Status, OptionalExceptFor, Section, Assessment } from 'provider'
import { ComparisonProfileModal } from '../ComparisonProfileModal'
import StatusTag from '../StatusTag'
import './styles.scss'

export type SectionWithId = OptionalExceptFor<Section, 'id'>

export enum SectionType {
  GROUP = 'sectionProfile',
  USER = 'sectionUser',
}

export type ComparisonOption = {
  // is this a sectionUser or sectionProfile
  sectionType?: SectionType
  // can be either sectionUserId or sectionProfileId deepening on sectionType
  id?: string
  // can be section label or group profile name
  title?: string
  // can be user name or group name
  user?: string
  // status date
  date?: string
  // current status
  status?: Status
}

export type ComparisonOptionSections = { [key: string]: ComparisonOption }

const emptyComparisonOption: Omit<ComparisonOption, 'positionSectionId'> = {
  sectionType: undefined,
  id: '',
  title: '',
  user: '',
  date: '',
  status: undefined,
}

type ComparisonProfileFieldProps = {
  positionSectionId: string
  assessment: Assessment
  section: SectionWithId
  comparisonOption: ComparisonOption
  className?: string
  isDisabled?: boolean
  isGroup?: boolean
  label?: string
  instructions?: string
  formUpdates: Dispatch<SetStateAction<boolean>>
  updateSectionOptions: Dispatch<SetStateAction<ComparisonOptionSections>>
}

type Props<T extends FieldValues, U extends Path<T>> = UseControllerProps<T, U> &
  ComparisonProfileFieldProps

function ComparisonProfileField<T extends FieldValues, U extends Path<T>>({
  positionSectionId,
  assessment,
  section,
  comparisonOption,
  className,
  isDisabled,
  isGroup = false,
  label,
  instructions,
  formUpdates,
  updateSectionOptions,
  ...controllerProps
}: Props<T, U>) {
  const {
    field,
    fieldState: { error },
  } = useController(controllerProps)

  const [showModal, setShowModal] = useState(false)
  const [selectedOption, setSelectedOption] = useState<ComparisonOption>(comparisonOption)
  const [selectedUser, setSelectedUser] = useState<string | undefined>(comparisonOption?.user)
  const [selectedTitle, setSelectedTitle] = useState<string | undefined>(comparisonOption?.title)
  const [selectedDate, setSelectedDate] = useState<string | undefined>(comparisonOption?.date)
  const [selectedStatus, setSelectedStatus] = useState<Status | undefined>(comparisonOption?.status)

  const onChange = (changed: ComparisonOption) => {
    setSelectedOption(changed)
    setSelectedUser(changed.user)
    setSelectedTitle(changed.title)
    setSelectedDate(changed.date)
    setSelectedStatus(changed.status)
    field.onChange(changed)
    updateSectionOptions((prevOptions) => ({
      ...prevOptions,
      [positionSectionId]: changed,
    }))
    formUpdates(true)
  }

  const onClear = () => {
    setSelectedOption(emptyComparisonOption)
    setSelectedUser(emptyComparisonOption.user)
    setSelectedTitle(emptyComparisonOption.title)
    setSelectedDate(emptyComparisonOption.date)
    setSelectedStatus(emptyComparisonOption.status)
    field.onChange(emptyComparisonOption)
    formUpdates(true)
  }
  const onModalClose = () => {
    setShowModal(false)
  }

  isGroup = comparisonOption?.sectionType === SectionType.GROUP ? true : false

  const errorMessage = error?.message

  return (
    <>
      <InputWrapper
        errorMessage={errorMessage}
        className={className}
        label={label}
        instructions={instructions}
        hasDefaultWidthRange={false}>
        <div>
          {!selectedOption?.id && (
            <div className="modalLink">
              <Button variant="link" onClick={() => setShowModal(true)}>
                Choose Existing or Create New
              </Button>
            </div>
          )}

          {selectedOption?.id && (
            <div className="form-group">
              <div className="moduleSectionBlock">
                <div className="icon-row">
                  <div className="section-users-title">{selectedTitle}</div>
                  {!isDisabled && (
                    <span className="icon-container">
                      <Tooltip label="Change Selection" placement="top" hasArrow>
                        <Button
                          className="icon-button icon-button--orange"
                          onClick={() => setShowModal(true)}>
                          <FontAwesomeIcon icon={faPencil} />
                        </Button>
                      </Tooltip>
                      <Tooltip label="Clear Selection" placement="top" hasArrow>
                        <Button className="icon-button icon-button--red" onClick={onClear}>
                          <FontAwesomeIcon icon={faX} />
                        </Button>
                      </Tooltip>
                    </span>
                  )}
                </div>
                <div className="moduleSectionMetaBlock">
                  <div className="text-muted">
                    <FontAwesomeIcon icon={isGroup ? faUsers : faUser} /> {selectedUser}
                  </div>
                  <div className="text-muted">
                    <FontAwesomeIcon icon={faCalendar} /> {formatDate(selectedDate)}
                  </div>
                  <StatusTag status={selectedStatus} />
                </div>
              </div>
            </div>
          )}
        </div>
      </InputWrapper>

      <ComparisonProfileModal
        assessment={assessment}
        section={section}
        isGroup={isGroup}
        onChange={onChange}
        isOpen={showModal}
        onClose={onModalClose}
        formUpdates={formUpdates}
      />
    </>
  )
}

export default ComparisonProfileField
