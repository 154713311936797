import { Switch, Tooltip, IconButton } from '@chakra-ui/react'
import { faCircleInfo, faLock, faLockOpen } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Color } from 'core'
import './styles.scss'
import { useState } from 'react'

export type PrivateGroupSwitchProps = {
  isPrivate: boolean
  onChange: (isPrivate: boolean) => void
}
export const PrivateGroupSwitch = ({ isPrivate, onChange }: PrivateGroupSwitchProps) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)

  const handleIconClick = () => {
    // Handle the icon click event
  }
  return (
    <div className="privateGroupSwitch">
      <div style={{ marginRight: '0.5rem' }}>
        <span>
          <FontAwesomeIcon
            icon={faLockOpen}
            size="lg"
            color={!isPrivate ? Color.Green : Color.Gray}
          />
        </span>
      </div>{' '}
      <Switch
        defaultChecked={isPrivate}
        onChange={(e) => onChange(!e.target.checked)}
        colorScheme="red">
        {' '}
      </Switch>
      <div style={{ marginRight: '0.5rem' }}>
        <span>
          <FontAwesomeIcon icon={faLock} size="lg" color={isPrivate ? Color.Red : Color.Gray} />
        </span>
      </div>{' '}
      <div className="tooltip">
        <Tooltip
          hasArrow
          isOpen={isTooltipOpen}
          onClose={() => setIsTooltipOpen(false)}
          closeOnClick={false}
          bg="wzBrand.mediumPurple"
          color="white"
          placement="left"
          label={
            'Public groups (unlocked) are visible to everyone.  Private groups (locked) are only visible to the admins of the group.'
          }>
          <IconButton
            aria-label="Info Icon"
            isfocusable={false.toString()}
            tabIndex={-1}
            icon={<FontAwesomeIcon icon={faCircleInfo} color="#3f2c7f" />}
            onMouseEnter={() => setIsTooltipOpen(true)}
            onMouseLeave={() => setIsTooltipOpen(false)}
            onClick={handleIconClick}
          />
        </Tooltip>
      </div>
    </div>
  )
}
