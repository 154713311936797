import classNames from 'classnames'
import './styles.scss'

type ParagraphProps = {
  title?: string
  paragraphs?: string[]
  isOrganization?: boolean
}

const Paragraphs = ({ title, paragraphs, isOrganization = false }: ParagraphProps) => {
  const conditionalClassNames = classNames({
    'resultParagraph__title--secondary': !isOrganization,
  })
  return (
    <div className="resultParagraph">
      <div className={`resultParagraph__title ${conditionalClassNames}`}>{title}</div>
      {paragraphs?.map((paragraph, idx) => (
        <div key={idx} className="resultParagraph__paragraph">
          {paragraph}
        </div>
      ))}
    </div>
  )
}

export default Paragraphs
