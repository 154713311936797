import { useEffect } from 'react'

export const useDocumentTitle = (title: string, prevailOnUnmount = false) => {
  useEffect(() => {
    document.title = `Workzinga: ${title}`
  }, [title])

  useEffect(
    () => () => {
      if (!prevailOnUnmount) {
        document.title = 'Workzinga'
      }
    },
    [prevailOnUnmount],
  )
}
