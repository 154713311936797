import { useEffect, useState } from 'react'
import { LoadingIndicator, PageWrapper } from 'core'
import {
  InvitationComplete,
  StatusCategories,
  AudienceType,
  WorkflowStatusType,
  useUserContext,
} from 'provider'
import { useNavigate, useParams } from 'react-router-dom'
import { getInvitationComplete } from '../../../actions/invitations'
import './styles.scss'
import { Button } from '@chakra-ui/react'

const InvitationCompletePage = () => {
  const { invitationId } = useParams<{ invitationId: string }>()
  const [isLoading, setIsLoading] = useState(true)
  const [pageText, setPageText] = useState<InvitationComplete>()
  const navigate = useNavigate()
  const { userContext } = useUserContext()

  useEffect(() => {
    if (invitationId) {
      const resultsRequest = getInvitationComplete(invitationId)
      resultsRequest.request.then((data) => {
        setPageText(data)
        setIsLoading(false)
      })
    }
  }, [invitationId])

  useEffect(() => {
    // Only show this page if invitation is finalized or status code is waiting for result
    // otherwise redirect to invitation welcome page
    const isFinalized = pageText?.currentStatus.isFinal
    const isWaitingForResult = pageText?.currentStatus.code === WorkflowStatusType.WaitingForResult

    if (pageText !== undefined && !isFinalized && !isWaitingForResult) {
      return navigate(`/invitations/${invitationId}/welcome`)
    }
  }, [pageText, invitationId, navigate])

  if (isLoading) {
    return <LoadingIndicator fullWidth={true} />
  }

  if (!pageText) {
    return <div>Unable to load this page</div>
  }

  return (
    <PageWrapper extraClassNames="invitationComplete">
      <div className="invitationComplete__box-container">
        <p className="invitationComplete__paragraph">{pageText.congratulationsParagraph}</p>
        <p className="invitationComplete__text">{pageText.descriptionParagraph}</p>
        <p className="invitationComplete__emoji">{pageText.emoji || ''}</p>

        {userContext?.userAudience === AudienceType.Candidate &&
          pageText.currentStatus.category !== StatusCategories.Canceled && (
            <div className="invitationComplete__actions">
              <Button
                className="invitationComplete__button"
                colorScheme="brand"
                onClick={() => navigate(`/invitations/${invitationId}/results`)}>
                View Results
              </Button>
              {/* <Button
                className="invitationComplete__button"
                onClick={() => navigate(`/invitations/guide/candidate/${invitationId}`)}>
                View Interview Guide
              </Button> */}
            </div>
          )}
      </div>
    </PageWrapper>
  )
}

export default InvitationCompletePage
