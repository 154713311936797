export type GetQueryParamsProps = {
  params: Record<string, string | undefined>
  excludeParams?: Record<string, boolean>
  existingParams?: string
}

type QueryParamLikeEntry<T extends object> = { [K in keyof T]: [K, T[K]] }[keyof T]

// I am leaving this alone for now, but have implemented an alternative (asQueryParams)
// which I export from this same file. It does not behave exactly like this, but we should discuss
// how we want to handle the conventions of the query parameters in this API (not_*) etc.
function getQueryParams({ params, excludeParams = {}, existingParams = '' }: GetQueryParamsProps) {
  return Object.entries(params).reduce((queryParams, [key, value]) => {
    if (value === undefined) {
      return queryParams
    }
    const paramSymbol = queryParams ? '&' : '?'
    const excludePrefix = key in excludeParams && excludeParams[key] ? 'not_' : ''
    return (queryParams += paramSymbol + excludePrefix + key + '=' + value)
  }, existingParams)
}

export function asQueryParams<QueryParamLikeType extends object>(params: QueryParamLikeType) {
  const queryParams = Object.entries(params).filter(
    (param): param is QueryParamLikeEntry<Record<string, string>> => param[1] !== undefined,
  )

  return new URLSearchParams(queryParams)
}

export default getQueryParams
