import { ParagraphCategory } from 'provider'
import ResultChart from '../../../../components/Charts/ResultChart'
import Paragraphs from '../Paragraph'
import './styles.scss'

type ParagraphChartProps = {
  labels: string[]
  datasets: {
    label?: string
    data: number[]
  }
}

type ParagraphCategoryProps = {
  category: ParagraphCategory
  organizationName?: string
  candidateName: string
  chart: ParagraphChartProps
  color: string
}

const ParagraphCategoryComponent = ({
  category: {
    moduleTitle,
    categoryTitle,
    categoryDescription,
    organizationText,
    candidateText,
    categoryAbbreviation,
  },
  organizationName,
  candidateName,
  chart,
  color,
}: ParagraphCategoryProps) => {
  const title = moduleTitle !== categoryTitle ? `${moduleTitle}: ${categoryTitle}` : categoryTitle

  return (
    <div className="resultCategory__grid">
      <div className="paragraphCategory__header resultCategory__grid--header">
        <div className="header">
          <div className="section_header--abbreviation-wrapper" style={{ backgroundColor: color }}>
            <div className="abbreviation">{categoryAbbreviation}</div>
          </div>
          <div className="headerTitle">{title}</div>
        </div>
        {categoryDescription && <div className="description">{categoryDescription}</div>}
      </div>
      <div className="resultCategory__grid--chart">{chart && <ResultChart apiData={chart} />}</div>
      <div className="paragraphCategory__paragraphs resultCategory__grid--paragraph">
        {organizationText && (
          <Paragraphs
            title={organizationName}
            paragraphs={organizationText}
            isOrganization={true}
          />
        )}
        <Paragraphs title={candidateName} paragraphs={candidateText} isOrganization={false} />
      </div>
    </div>
  )
}

export default ParagraphCategoryComponent
