import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  FormControl,
  FormLabel,
} from '@chakra-ui/react'
import { SelectDropdown, handleError, InputField } from 'core'
import { DropdownOption } from '../../../../models'
import { api, Assessment, AssessmentSection, useUserContext } from 'provider'
import { RefObject, useRef, useState, useEffect, useMemo, useCallback } from 'react'
import { mapToOption, onSubmitForm, sortOptionByLabel } from '../../../../utils'
import { useForm } from 'react-hook-form'

const mapAndSortSections = (sections: AssessmentSection[]) =>
  sections
    .map((section) => mapToOption(section, 'organizationText', 'sectionId'))
    .sort(sortOptionByLabel)

type CreateProfileForm = {
  title: string
  assessment: DropdownOption | undefined
  section: DropdownOption | undefined
}

export type CreateGroupProfileModalProps = {
  groupId: string
  isOpen: boolean
  onClose: () => void
  onSubmit?: (id: string) => void
}

export const CreateGroupProfileModal = ({
  groupId,
  isOpen,
  onClose,
  // eslint-disable-next-line unused-imports/no-unused-vars
  onSubmit,
}: CreateGroupProfileModalProps) => {
  // eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
  const [assessments, setAssessments] = useState<Assessment[]>()
  const [assessmentOptions, setAssessmentOptions] = useState<DropdownOption[]>([])
  const [sectionOptions, setSectionOptions] = useState<DropdownOption[]>([])
  const [selectedAssessment, setSelectedAssessment] = useState<DropdownOption>()
  const [selectedSection, setSelectedSection] = useState<DropdownOption>()
  // eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
  const [isPosting, setIsPosting] = useState(false)
  const { userContext } = useUserContext()
  const [isMounted, setIsMounted] = useState(true)

  useEffect(() => {
    setIsMounted(true)
    return () => {
      setIsMounted(false)
    }
  }, [])

  const {
    // eslint-disable-next-line no-unused-vars, unused-imports/no-unused-vars
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<CreateProfileForm>({
    defaultValues: {
      title: undefined,
      assessment: undefined,
    },
  })

  const initialRef = useRef(null) as RefObject<HTMLButtonElement>

  useEffect(() => {
    setAssessments(userContext?.tenant?.assessments)
    if (userContext?.tenant?.assessments) {
      setAssessmentOptions(
        userContext.tenant.assessments.map((assessment) => mapToOption(assessment, 'title', 'id')),
      )
    }
  }, [userContext])

  const getAssesmentById = useCallback(
    (id: string) => {
      return assessments?.find((assessment) => assessment.id === id)
    },
    [assessments],
  )

  const defaultAssessment = useMemo(() => {
    if (assessmentOptions.length === 1) {
      setSelectedAssessment(assessmentOptions[0])
      return assessmentOptions[0]
    } else {
      return undefined
    }
  }, [assessmentOptions])

  const setAssessment = (assessment: DropdownOption | undefined) => {
    if (assessment) {
      let newSectionOptions: DropdownOption[] = []
      setSelectedAssessment(assessment)
      const assessmentSections = getAssesmentById(assessment.value)?.sectionSet
      if (assessmentSections) {
        newSectionOptions = mapAndSortSections(assessmentSections)
        setSectionOptions(newSectionOptions)
      }
      if (newSectionOptions.length === 1) {
        setSelectedSection(newSectionOptions[0])
      } else {
        setSelectedSection(undefined)
      }
    }
  }

  const setSection = (section: DropdownOption | undefined) => {
    setSelectedSection(section)
  }

  const submitInvitation = async ({ title }: CreateProfileForm) => {
    if (!isMounted) return
    setIsPosting(true)
    if (selectedAssessment && selectedSection) {
      try {
        const groupData = await api.postGroupProfile({
          title: title,
          assessment: { id: selectedAssessment.value },
          section: { id: selectedSection?.value || '' },
          group: { id: groupId },
        }).request
        if (!isMounted) return
        const { id } = groupData
        if (id && onSubmit) onSubmit(id)
      } catch (err) {
        if (!isMounted) return
        handleError(undefined, {})(err)
      }
    }
    if (isMounted) setIsPosting(false)
  }

  return (
    <Modal
      motionPreset="scale"
      onClose={onClose}
      isOpen={isOpen}
      isCentered={true}
      initialFocusRef={initialRef}>
      <form onSubmit={onSubmitForm(handleSubmit(submitInvitation))} className="orgInvitationModal">
        <ModalContent>
          <ModalHeader>Create Group Profile</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className="groupSettings">
              <FormControl isRequired>
                <FormLabel htmlFor="title">Title</FormLabel>
                <InputField
                  registerFields={register('title', { required: 'Required field' })}
                  errorMessage={errors.title?.message}
                  getValue={() => watch('title')}
                  dataCy="title"
                />
              </FormControl>
              <br />
              <FormControl isRequired>
                <FormLabel htmlFor="assessment">Assessment</FormLabel>
                <SelectDropdown
                  defaultValue={defaultAssessment}
                  placeholder=""
                  field={{
                    onChange: setAssessment,
                    onBlur: () => {},
                    value: selectedAssessment,
                    name: 'assessment',
                    ref: () => {},
                  }}
                  fieldState={{
                    invalid: false,
                    isTouched: true,
                    isDirty: false,
                    isValidating: false,
                  }}
                  options={assessmentOptions}
                />
              </FormControl>
              <br />
              <FormControl isRequired>
                <FormLabel htmlFor="section">Section</FormLabel>
                <SelectDropdown
                  isDisabled={!sectionOptions.length}
                  placeholder=""
                  field={{
                    onChange: setSection,
                    onBlur: () => {},
                    value: selectedSection,
                    name: 'section',
                    ref: () => {},
                  }}
                  fieldState={{
                    invalid: false,
                    isTouched: true,
                    isDirty: false,
                    isValidating: false,
                  }}
                  options={sectionOptions}
                />
              </FormControl>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              variant="brandPrimary"
              isLoading={isPosting}
              isDisabled={isPosting}
              data-cy="saveGroupProfile">
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  )
}
