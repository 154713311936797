import { UserSearchResult } from 'src/models'
import {
  GroupAdmin,
  GroupMember,
  MockGroupAdmin,
  MockGroupMember,
  NormalizedGroupMember,
} from 'src/models/Group'

export const convertUserSearchResultToGroupMember = (user: UserSearchResult): MockGroupMember => {
  return {
    id: user.id,
    userFirstName: user.firstName,
    userLastName: user.lastName,
    userEmail: user.email,
    userId: user.id,
  } as MockGroupMember
}

export const convertGroupMemberToUserSearchResult = (
  user: GroupMember | MockGroupMember,
): UserSearchResult => {
  return {
    id: user.userId,
    firstName: user.userFirstName,
    lastName: user.userLastName,
    email: user.userEmail,
    fullName: `${user.userFirstName} ${user.userLastName}`,
  } as UserSearchResult
}

export const normalizeGroupMember = (
  member: GroupMember | MockGroupMember,
): NormalizedGroupMember => {
  return {
    id: member.userId,
    firstName: member.userFirstName,
    lastName: member.userLastName,
    email: member.userEmail,
    fullName: `${member.userFirstName} ${member.userLastName}`,
  } as UserSearchResult
}

export const normalizeUserSearchResult = (user: UserSearchResult): NormalizedGroupMember => {
  return {
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    fullName: `${user.firstName} ${user.lastName}`,
  } as NormalizedGroupMember
}

export const normalizeGroupMembers = (
  members: (GroupMember | MockGroupMember | UserSearchResult | GroupAdmin | MockGroupAdmin)[],
): NormalizedGroupMember[] => {
  return members.map((member) => {
    if ('userFirstName' in member) {
      return normalizeGroupMember(member)
    } else if ('firstName' in member) {
      return normalizeUserSearchResult(member)
    } else {
      throw new Error('Invalid member type')
    }
  })
}
