import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { handleError, InputField } from 'core'
import { Section, OptionalExceptFor } from 'provider'
import { onSubmitForm } from '../../utils'
import Modal from '../Modal'
import './styles.scss'
import { patchSectionUser } from '../../actions/assessments'
import { Button } from '@chakra-ui/react'

export type SectionWithId = OptionalExceptFor<Section, 'id'>

type UpdateSectionUserForm = {
  label: string
}

type UpdateSectionUserTitleProps = {
  showModal: boolean
  onClose: () => void
  onSave?: () => void
  editSectionUser: { id: string; label: string }
}

const UpdateSectionUserTitle = ({
  showModal,
  onClose,
  onSave = onClose,
  editSectionUser,
}: UpdateSectionUserTitleProps) => {
  const [isPosting, setIsPosting] = useState(false)
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<UpdateSectionUserForm>({
    defaultValues: { label: editSectionUser.label },
  })
  const submitLabelEdit = ({ label }: UpdateSectionUserForm) => {
    setIsPosting(true)
    patchSectionUser(editSectionUser.id, { label })
      .request.then(() => setIsPosting(false))
      .catch(handleError(setIsPosting, { errorMessage: 'Could not update comparison name.' }))
      .finally(() => {
        reset()
        onSave()
      })
  }
  const closeModal = () => {
    reset()
    onClose()
  }
  return (
    <Modal showModal={showModal} onClose={closeModal}>
      <form
        onSubmit={onSubmitForm(handleSubmit(submitLabelEdit))}
        className="updateSectionUserTitle">
        <div className="header">Update Invitation Comparison Name</div>
        <InputField
          placeholder="Comparison Name"
          registerFields={register('label', { required: 'Required field' })}
          getValue={() => watch('label')}
          errorMessage={errors.label?.message}
          className="updateSectionUserTitle__field"
        />
        <div className="updateSectionUserTitle__actions">
          <Button
            variant="danger"
            className="updateSectionUserTitle__cancelBtn"
            onClick={closeModal}>
            Cancel
          </Button>
          <Button
            type="submit"
            isLoading={isPosting}
            variant="brandPrimary"
            className="updateSectionUserTitle__saveBtn">
            Save
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default UpdateSectionUserTitle
