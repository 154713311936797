import {
  hasQuestionsSection,
  isColumnsSection,
  QuestionsSection as QuestionsSectionModel,
  ColumnsSection as ColumnsSectionModel,
} from 'provider'
import classNames from 'classnames'
import './styles.scss'
import QuestionsSection from './Questions'
import ColumnsSection from './Columns'

type GuideSectionsProps = {
  section: QuestionsSectionModel | ColumnsSectionModel
  color: string
  isOrganization?: boolean
}

const GuideSections = ({ section, color, isOrganization = false }: GuideSectionsProps) => {
  const conditionalClassNames = classNames({
    'section__title--secondary': isOrganization,
  })
  const title =
    section.moduleTitle !== section.title
      ? `${section.moduleTitle}: ${section.title}`
      : section.title
  return (
    <div className="section">
      <div className={`section_header ${conditionalClassNames}`}>
        <div className="section_header--abbreviation-wrapper" style={{ backgroundColor: color }}>
          <div className="abbreviation">{section.titleAbbreviation}</div>
        </div>
        <div className="section_header--header">
          <div className="title">{title}</div>
          <div className="subtitle">{section.subtitle}</div>
        </div>
      </div>
      <div key={section.id} className={`section__section section__${section.displayType}`}>
        {isColumnsSection(section) && <ColumnsSection columns={section.columns} />}
        {hasQuestionsSection(section) && <QuestionsSection questions={section.questions} />}
      </div>
    </div>
  )
}

export default GuideSections
