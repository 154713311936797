import { StatusKind, WorkflowStatusType } from '../models'
import getQueryParams from './getQueryParams'
import getStringIfDefined from './getStringIfDefined'

export type StatusParams = {
  status?: WorkflowStatusType
  excludeStatus?: boolean
  statusKind?: StatusKind
  excludeStatusKind?: boolean
  showInactive?: boolean
  last30Days?: boolean
  openPosition?: boolean
}

function getStatusParams({
  status,
  excludeStatus = false,
  statusKind: status_kind,
  excludeStatusKind = false,
  showInactive,
  last30Days,
  openPosition,
}: StatusParams) {
  const params = {
    status,
    status_kind,
    last_30_days: getStringIfDefined(last30Days),
    show_inactive: getStringIfDefined(showInactive),
    open_position: getStringIfDefined(openPosition),
  }
  const excludeParams = {
    status: excludeStatus,
    status_kind: excludeStatusKind,
  }

  return getQueryParams({ params, excludeParams })
}

export default getStatusParams
