import { ActionItem, Color } from 'core'
import { faBoxArchive, faTrashCan } from '@fortawesome/pro-solid-svg-icons'
import { Group } from 'provider'

export const archiveGroupItemAction = (group: Group, actionCallback?: () => void): ActionItem => {
  const action: ActionItem = {
    label: 'Archive Group',
    icon: faBoxArchive,
    iconSize: 'xl',
    onClick: () => {
      if (actionCallback) {
        actionCallback()
      }
    },
    iconColor: Color.Red,
    labelColor: Color.Red,
  }

  return action
}

export const archiveGroupMemberAction = (group: Group, actionCallback?: () => void): ActionItem => {
  const action: ActionItem = {
    label: 'Remove From Group',
    icon: faTrashCan,
    iconSize: 'xl',
    onClick: () => {
      if (actionCallback) {
        actionCallback()
      }
    },
    iconColor: Color.Red,
    labelColor: Color.Red,
  }

  return action
}
